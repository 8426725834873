<template>
    <h4 class="mt-0">Actualizar contenido 'Qué es PROJUVENTUD'</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="que_es_titulo">Título principal:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idProjuventud" name="idProjuventud" v-model="form.id" />
                        <input type="text" class="form-control" id="que_es_titulo" name="que_es_titulo" placeholder="Título principal" v-model="form.que_es_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="que_es_subtitulo">Sub título:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="que_es_subtitulo" name="que_es_subtitulo" placeholder="Sub título" v-model="form.que_es_subtitulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="que_es_contenido">Contenido 'Qué es PROJUVENTUD':</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.que_es_contenido" :config="editorConfig" id="que_es_contenido" @input="conteo_real(form.que_es_contenido, 'que_es_contenido')"></ckeditor>
                        <div id="que_es_contenido_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label  hidden class="col-md-4" to="id_usuario">Usuario:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="id_usuario" name="id_usuario" placeholder="usuario" v-model="form.id_usuario" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="tmp_name">Archivo en servidor:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="tmp_name" name="tmp_name" placeholder="Nombre en el server" v-model="form.tmp_name" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="file_name">Nombre del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="file_name" name="file_name" placeholder="nombre del archivo" v-model="form.file_name" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="extension">Extensión del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="extension" name="extension" placeholder="extensión" v-model="form.extension" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-5 col-form-label" for="archivo">Subir el video:</label>
                    <div class="col-md-7">
                        <div v-if="successAlert" class="alert alert-success alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="successAlert=false">&times;</a>
                            {{ successMessage }}
                        </div>
                        <div v-if="errorAlert" class="alert alert-danger alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="errorAlert=false">&times;</a>
                            {{ errorMessage }}
                        </div>
                        <input type="file" ref="file" />
                        <br>
                        <button type="button" @click="uploadFile" class="btn btn-default btn-sm">Almacenar archivo</button>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-5"></div>
                    <div class="col-md-7">
                        <table id="tblFiles" class="table table-striped">
                            <thead class="table-striped">
                                <tr>
                                    <th>Nombre del archivo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a :href="`${ ubApi }/download.php?tmpFileName=${ form.tmp_name }&fileName=${ form.file_name }`" target="_blank" title="Descargar"> {{ form.file_name }}</a></td>
                                    <td align="left"><a :href="`${ ubApi }/download.php?tmpFileName=${ form.tmp_name }&fileName=${ form.file_name }`" target="_blank" title="Descargar"><i class="bi bi-download"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateprojuventud" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateprojuventud" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateProjuventud" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getProjuventudesById from '@/helpers/getProjuventudesById'
import updateData from '@/helpers/updateData'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'

import axios from 'axios'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            ubApi: ubApi.value,
            id: '',
            data: [],
            datos: [],
            
            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,
            uploadedImage: '',

            form: {
                id: '',
                que_es_titulo: '',
                que_es_subtitulo: '',
                que_es_contenido: '',

                id_usuario: '',
                tmp_name: '',
                file_name: '',
                extension: '',
            },

            isSelected: false,
            saveDisabled: false,
            arrArchivos: [],
            iconImg: '',


            editor: ClassicEditor,
            editorData: '',
            editorConfig: { language: 'es'}

        }
    },
    methods: {
        async getProjuventudData( id ) {
            const projuventudArr = await getProjuventudesById( id )
            
            return projuventudArr[0].cat_projuventud
        },

        async setData( id ) {
            const datos = await getProjuventudesById( id )

            this.form.id = datos.id
            this.form.que_es_titulo = datos.que_es_titulo
            this.form.que_es_subtitulo = datos.que_es_subtitulo
            this.form.que_es_contenido = datos.que_es_contenido

            this.editorData = datos.que_es_contenido

            this.form.id_usuario = datos.id_usuario
            this.form.tmp_name = datos.tmp_name
            this.form.file_name = datos.file_name
            this.form.extension = datos.extension

        },

        async updateProjuventud() {
            const dataForm = {
                que_es_titulo: this.form.que_es_titulo,
                que_es_subtitulo: this.form.que_es_subtitulo,
                que_es_contenido: this.form.que_es_contenido,

                id_usuario: this.form.id_usuario,
                tmp_name: this.form.tmp_name,
                file_name: this.form.file_name,
                extension: this.form.extension
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_projuventud', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        async uploadFile() {
            this.file = this.$refs.file.files[0]
            var formData = new FormData()
            formData.append('file', this.file)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            await axios.post( ubApi.value + '/uploadFiles.php', formData, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {    /* Se asignan los datos para el update*/
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlert = z_errorAlert
            this.successAlert = z_successAlert
            this.errorMessage = z_errorMessage
            this.successMessage = z_successMessage
            this.$refs.file.value = z_fileValue

            if (z_successAlert) {
                // AvanceAcciones módulo para tabla de archivos
                const jsonInsFile = JSON.parse(JSON.stringify({
                    "id_usuario": z_userId,
                    "tmp_name": z_tmpFileName,
                    "file_name": z_baseName,
                    "extension": z_extension
                }))
                
                this.form.id_usuario=z_userId
                this.form.tmp_name=z_tmpFileName
                this.form.file_name=z_baseName
                this.form.extension=z_extension

            }
        },


        goBack() {
            this.$router.go(-1)
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>