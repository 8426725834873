/* Regresa todos los datos de los años con el id enviado de la tabla 'cat_clase_ind' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getClases_indById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_clase_ind/${ id }`)
    ]

    const [ cat_clase_ind ] = await Promise.all( promiseArr )

    return cat_clase_ind.data
}

export default getClases_indById