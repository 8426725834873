<template>
    <h4>Administración del catálogo de dimensión del indicador</h4>
    <hr>

    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar la dimensión del indicador? </h5>
            <h6> {{ datosDimension_indicador }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getDimensiones_indicadorById from '@/helpers/getDimensiones_indicadorById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosDimension_indicador: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'cat_dimension_indicador', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getDimension_indicador() {
            const dataDimension_indicador = await getDimensiones_indicadorById( charsService.decode(this.$route.params.id) );

            const cadena = `${ dataDimension_indicador.nom_dimension_indicador } `;

            return cadena
        }
    },
    async mounted() {
        this.datosDimension_indicador = await this.getDimension_indicador();
        // this.getDimension_indicador()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>