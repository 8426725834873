/* Regresa todas las registros marcados como tmp de la tabla 'archivos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getArchivosTmp = async() => {
    const promiseArr = [
        simejuvApi.get(`/archivos?transform=1&filter=tmp,eq,1`)
    ]

    const [ tmp ] = await Promise.all( promiseArr )

    return tmp.data.archivos
}

export default getArchivosTmp