<template>
    <h4 class="mt-0">Actualización de la introducción de las estadísticas en juventud</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_grupo_indicador">Nombre del grupo estadístico:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="id" name="id" v-model="form.id" />
                        <input type="text" class="form-control" id="nom_grupo_indicador" name="nom_grupo_indicador" placeholder="Grupo estadístico" v-model="form.nom_grupo_indicador" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="estadist_introduc_tit">Título del grupo estadistico:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="estadist_introduc_tit" name="estadist_introduc_tit" placeholder="Título del grupo estadistico" v-model="form.estadist_introduc_tit" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="estadist_introduccion">Introducción del grupo estadístico:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.estadist_introduccion" :config="editorConfig" id="estadist_introduccion" @input="conteo_real(form.estadist_introduccion, 'estadist_introduccion')"></ckeditor>
                        <div id="estadist_introduccion_count" class="noChars"></div>
                    
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateEstadisticas_int" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateEstadisticas_int" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateEstadisticas_int" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getEstadist_introduccionById from '@/helpers/getEstadist_introduccionById'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                nom_grupo_indicador: '',
                estadist_introduc_tit: '',
                estadist_introduccion: '',
            },
            editor: ClassicEditor,
            editorData: '',
            editorConfig: { language: 'es'}
        }
    },
    methods: {
        async setData( id ) {
            const datos = await getEstadist_introduccionById( id )

            this.form.id = datos.id
            this.form.nom_grupo_indicador = datos.nom_grupo_indicador
            this.form.estadist_introduc_tit = datos.estadist_introduc_tit
            this.form.estadist_introduccion = datos.estadist_introduccion

            this.editorData = datos.estadist_introduccion
        },

        async updateEstadisticas_int() {
            const dataForm = {
                nom_grupo_indicador: this.form.nom_grupo_indicador,
                estadist_introduc_tit: this.form.estadist_introduc_tit,
                estadist_introduccion: this.form.estadist_introduccion
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('estadist_introduccion', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },
        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },
        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        },

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>