/* Regresa un registro de la tabla de cat_tipo_ap */

import simejuvApi from '../api/simejuvApi'

const getDataByAnyTipo_ap = async( table, column, value ) => {
    const promiseArr = [
        simejuvApi.get(`/${ table }?transform=1&filter=${ column },eq,${ value }`)
    ]

    const [ datos ] = await Promise.all( promiseArr )
    
    return datos.data.cat_tipo_ap

}

export default getDataByAnyTipo_ap