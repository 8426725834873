<template>
    <h4 class="mt-0">Agregar una meta para el bienestar o parámetro</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="codigo_meta">Código de la meta para el bienestar o parámetro:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idUsr" name="idUsr" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.codigo_meta }" id="codigo_meta" name="codigo_meta" placeholder="#.#" v-model="form.codigo_meta" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_meta">Nombre de la meta para el bienestar o parámetro:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_meta }" id="nom_meta" name="nom_meta" placeholder="Nombre de la meta para el bieneatar o parámetro" v-model="form.nom_meta" />
                    </div>
                </div>
                <hr>
                <div class="form-group row">
                    <label class="col-md-4" to="dependencia">Unidad Responsable de reportar el avance:</label>
                    <div class="col-md-8">
                        <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia" :class="{ validated: noValid.dependencia }">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e in depsArr" :key="e.id" :value="e.id">{{ e.nom_corto_dependencia }}</option>
                        </select>
                    </div>
                </div>

                <hr>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUser" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUser" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra meta o parámetro" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addMeta_ficha" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getDependenciasNc from '@/helpers/getDependenciasNc'
import addData from '@/helpers/addData'
import getDataByAnyMeta_ficha from '@/helpers/getDataByAnyMeta_ficha'

export default {
    data() {
        return {
            form: {
                codigo_meta: '',
                nom_meta: '',
                dependencia: ''
            },
            noValid: {
                codigo_meta: false,
                nom_meta: false,
                dependencia: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        async getDependencias() {
            const deps = await getDependenciasNc()
            return deps
        },
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.codigo_meta = ''
            this.form.nom_meta = ''
            setTimeout(function() { document.getElementById('dependencia').getElementsByTagName('option')[0].selected = 'selected' }, 1);
            document.getElementById('dependencia').getElementsByTagName('option')[0].selected = 'selected'
            this.added = false
        },
        async addMeta_ficha() {
            this.noValid.codigo_meta = ( this.form.codigo_meta )? false : true
            this.noValid.nom_meta = ( this.form.nom_meta )? false : true
            this.noValid.dependencia = ( this.form.dependencia )? false : true


            const valnom_meta = await getDataByAnyMeta_ficha( 'meta_ficha', 'nom_meta', this.form.nom_meta );
            if ( valnom_meta.length < 1 ){
                if ( !this.noValid.codigo_meta && !this.noValid.nom_meta && !this.noValid.dependencia ) {
                    const dataForm = {
                        codigo_meta: this.form.codigo_meta,
                        nom_meta: this.form.nom_meta,
                        id_dependencia: this.form.dependencia
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addMeta_ficha = await addData('meta_ficha', jsonData)
                    if ( addMeta_ficha[0].res.status === 200 ) {
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'El formulario requiere atención en uno o varios campos. Favor de atenderlo', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una meta/parámetro registrada con ese nombre. Verifica los datos', type: 'error'
                });
            }
        }
    },
    async mounted() {
        this.depsArr = await this.getDependencias()
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>