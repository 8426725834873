/* Regresa todos los registros de la tabla 'cat_unidad_medida' en la base de datos */

// Esta linea se queda igual siempre (base url api)
import simejuvApi from '../api/simejuvApi'

// Se nombra la funcion
const getCatUnidades_medida = async() => {
    const promiseArr = [
        // Se modifica de acuerdo a la búsqueda en la tabla
        simejuvApi.get(`/cat_unidad_medida?transform=1&filter=borrar,lt,1`)
    ]

    // Cambiar el nombre de la variable contra los datos a mostrar
    const [ unidades_medida ] = await Promise.all( promiseArr )

    // Cambiar la primer variable a como se definió arriba
    // Cambiar la ultima variable a como se llama la tabla en la base de datos
    return unidades_medida.data.cat_unidad_medida
}

// Se exporta con el mismo nombre de la funcion
export default getCatUnidades_medida