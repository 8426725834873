<template>
    <h4 class="mt-0">Agregar un sector</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre del sector:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idSector_op" name="idSector_op" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_sector_op }" id="nom_sector_op" name="nom_sector_op" placeholder="Nombre del sector" v-model="form.nom_sector_op" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otro sector" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addSector" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import addData from '@/helpers/addData'
import getDataByAnySector_op from '@/helpers/getDataByAnySector_op'

export default {
    data() {
        return {
            form: {
                nom_sector_op: ''
            },
            noValid: {
                nom_sector_op: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nom_sector_op = ''
            this.added = false
        },
        async addSector() {
            this.noValid.nom_sector_op = ( this.form.nom_sector_op )? false : true

            const valNom_sector_op = await getDataByAnySector_op( 'cat_sector_op', 'nom_sector_op', this.form.nom_sector_op );
            
            if ( valNom_sector_op.length < 1 ){
                if ( !this.noValid.nom_sector_op) {
                    const dataForm = {
                        nom_sector_op: this.form.nom_sector_op
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addSector = await addData('cat_sector_op', jsonData)
                    if ( addSector[0].res && addSector[0].res.data > 0 ) {
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre del sector', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe un sector registrado con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>