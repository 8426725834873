<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el objetivo? </h5>
            <h6> {{ datosObjetivo }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getObjetivosById from '@/helpers/getObjetivosById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosObjetivo: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'cat_objetivos', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getObjetivo() {
            const dataObjetivo = await getObjetivosById( charsService.decode(this.$route.params.id) );

            const cadena = `${ dataObjetivo.nom_objetivo } `;

            return cadena
        }
    },
    async mounted() {
        this.datosObjetivo = await this.getObjetivo();
        // this.getObjetivo()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>