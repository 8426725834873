/* Regresa un registro de la tabla de cat_dependencias */

import simejuvApi from '../api/simejuvApi'

const getDataByAnyDependencia = async( table, column, value ) => {
    const promiseArr = [
        simejuvApi.get(`/${ table }?transform=1&filter=${ column },eq,${ value }`)
    ]

    const [ datos ] = await Promise.all( promiseArr )
    
    return datos.data.cat_dependencias

}

export default getDataByAnyDependencia