<template>
    <h4 class="mt-0">Mantenimiento de Programas Sectoriales</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="iddependencia">Dependencia o entidad:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="iddependencia" name="iddependencia" v-model="form.iddependencia">
                            <option v-for="e_d in dependenciasArr" :key="e_d.id" :value="e_d.id">{{ e_d.num_dependencia }} {{ e_d.nom_corto_dependencia }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="idprograma_sectorial">Programa sectorial:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="idprograma_sectorial" name="idprograma_sectorial" v-model="form.idprograma_sectorial">
                            <option v-for="eee in prog_sectorialesArr" :key="eee.id" :value="eee.id">{{ eee.id }} {{ eee.nom_programa_sectorial }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="idobjetivo_sectorial">Objetivo sectorial:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="idobjetivo_sectorial" name="idobjetivo_sectorial" v-model="form.idobjetivo_sectorial">
                            <option v-for="eee in objetivo_sectorialArr" :key="eee.id" :value="eee.id">{{ eee.num_objetivo_sectorial }} {{ eee.nom_objetivo_sectorial }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="idestrategia_sectorial">Estrategia sectorial:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="idestrategia_sectorial" name="idestrategia_sectorial" v-model="form.idestrategia_sectorial">
                            <option v-for="ee in estrategias_sectorialesArr" :key="ee.id" :value="ee.id">{{ ee.num_estrategia_sectorial }} {{ ee.nom_estrategia_sectorial }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="codigo_accion_sectorial">Código de la acción sectorial:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <input type="text" class="form-control" id="codigo_accion_sectorial" name="codigo_accion_sectorial" placeholder="#.#.#" v-model="form.codigo_accion_sectorial" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="nom_accion_sectorial">Nombre de la acción sectorial:</label>
                    <div class="col-md-9">
                        <textarea rows="3" class="form-control" name="nom_accion_sectorial" id="nom_accion_sectorial" v-model="form.nom_accion_sectorial" placeholder="Nombre de la acción sectorial" @keyup="conteo_real('nom_accion_sectorial')"></textarea>
                        <div id="nom_accion_sectorial_count" class="noChars"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="url_accion_sectorial">Url de la acción sectorial:</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control" id="url_accion_sectorial" name="url_accion_sectorial" placeholder="https://..." v-model="form.url_accion_sectorial" />
                    </div>
                </div>

               <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="idaccion_puntual">Relación con acción puntual del PROJUVENTUD:</label>
                     <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="idaccion_puntual" name="idaccion_puntual" v-model="form.idaccion_puntual">
                            <option v-for="e_ap in accion_puntualArr" :key="e_ap.id" :value="e_ap.id">{{ e_ap.codigo_accion_puntual }} {{ e_ap.nom_accion_puntual }}</option>
                        </select>
                    </div>
                </div>




                <div class="form-group row">
                    <label  hidden class="col-md-4" to="id_usuario">Usuario:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="id_usuario" name="id_usuario" placeholder="usuario" v-model="form.id_usuario" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="tmp_name">Archivo en servidor:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="tmp_name" name="tmp_name" placeholder="Nombre en el server" v-model="form.tmp_name" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="file_name">Nombre del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="file_name" name="file_name" placeholder="nombre del archivo" v-model="form.file_name" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="extension">Extensión del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="extension" name="extension" placeholder="extensión" v-model="form.extension" />
                    </div>
                </div>



                <div class="form-group row">
                    <label class="col-md-5 col-form-label" for="archivo">Subir la ficha del Programa Sectorial:</label>
                    <div class="col-md-7">
                        <div v-if="successAlert" class="alert alert-success alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="successAlert=false">&times;</a>
                            {{ successMessage }}
                        </div>
                        <div v-if="errorAlert" class="alert alert-danger alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="errorAlert=false">&times;</a>
                            {{ errorMessage }}
                        </div>
                        <input type="file" ref="file" />
                        <br>
                        <button type="button" @click="uploadFile" class="btn btn-default btn-sm">Almacenar archivo</button>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-5"></div>
                    <div class="col-md-7">
                        <table id="tblFiles" class="table table-striped">
                            <thead class="table-striped">
                                <tr>
                                    <th>Nombre del archivo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a :href="`${ ubApi }/download.php?tmpFileName=${ form.tmp_name }&fileName=${ form.file_name }`" target="_blank" title="Descargar"> {{ form.file_name_dec }}</a></td>
                                    <td align="left"><a :href="`${ ubApi }/download.php?tmpFileName=${ form.tmp_name }&fileName=${ form.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>                





                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateAccion_puntual" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateAccion_puntual" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateAccion_puntual" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getAccion_sectorialById from '@/helpers/getAccion_sectorialById'


import getCatsNc from '@/helpers/getCatsNc'


import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

import moment from 'moment'
import axios from 'axios'
import { ubApi } from '@/api/baseUrl'

moment.locale("es")

export default {
    
    data() {
        return {
            ubApi: ubApi.value,
            id: '',
            data: [],
            datos: [],

            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,
            uploadedImage: '',

            form: {
                id: '',
                iddependencia: '',
                idprograma_sectorial: '',
                idobjetivo_sectorial: '',
                idestrategia_sectorial: '',
                codigo_accion_sectorial: '',
                nom_accion_sectorial: '',
                url_accion_sectorial: '',
                idaccion_puntual: '',
                

                id_usuario: '',
                tmp_name: '',
                tmp_name_dec: '',
                file_name: '',
                file_name_dec: '',
                extension: '',

            },
            //isDisabledJ: '',
            //isDisabled: '',
            dependenciasArr: [],
            prog_sectorialesArr: [],
            objetivo_sectorialArr: [],
            estrategias_sectorialesArr: [],
            accion_puntualArr: [],

            arrArchivos: [],
            iconImg: '',

        }
    },
    methods: {

        async setData( id ) { //Se trae solo el nombre del elemento seleccionado
            const datos = await getAccion_sectorialById( id )
            this.form.id = datos.id
            this.form.iddependencia = datos.iddependencia
            this.form.idprograma_sectorial = datos.idprograma_sectorial
            this.form.idobjetivo_sectorial = datos.idobjetivo_sectorial
            this.form.idestrategia_sectorial = datos.idestrategia_sectorial
            this.form.codigo_accion_sectorial = datos.codigo_accion_sectorial
            this.form.nom_accion_sectorial = datos.nom_accion_sectorial
            this.form.url_accion_sectorial = datos.url_accion_sectorial
            this.form.idaccion_puntual = datos.idaccion_puntual

            this.form.id_usuario = datos.id_usuario
            this.form.tmp_name = charsService.encode(datos.tmp_name)
            this.form.tmp_name_dec = datos.tmp_name
            this.form.file_name = charsService.encode(datos.file_name)
            this.form.file_name_dec = datos.file_name
            this.form.extension = datos.extension

        },

        async updateAccion_puntual() {
            const dataForm = {
                iddependencia: this.form.iddependencia,
                idprograma_sectorial: this.form.idprograma_sectorial,
                idobjetivo_sectorial: this.form.idobjetivo_sectorial,
                idestrategia_sectorial: this.form.idestrategia_sectorial,
                codigo_accion_sectorial: this.form.codigo_accion_sectorial,
                nom_accion_sectorial: this.form.nom_accion_sectorial,
                url_accion_sectorial: this.form.url_accion_sectorial,
                idaccion_puntual: this.form.idaccion_puntual,

                id_usuario: this.form.id_usuario,
                tmp_name: this.form.tmp_name_dec,
                file_name: this.form.file_name_dec,
                extension: this.form.extension

            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('accion_sectorial', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },



        async uploadFile() {
            this.file = this.$refs.file.files[0]
            var formData = new FormData()
            formData.append('file', this.file)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            await axios.post( ubApi.value + '/uploadFiles.php', formData, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {    /* Se asignan los datos para el update*/
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlert = z_errorAlert
            this.successAlert = z_successAlert
            this.errorMessage = z_errorMessage
            this.successMessage = z_successMessage
            this.$refs.file.value = z_fileValue

            if (z_successAlert) {
                // AvanceAcciones módulo para tabla de archivos
                const jsonInsFile = JSON.parse(JSON.stringify({
//                    "modulo": "AvanceAcciones",
//                    "id_registro": this.$route.params.id,
                    "id_usuario": z_userId,
                    "tmp_name": z_tmpFileName,
                    "file_name": z_baseName,
                    "extension": z_extension
                }))
                
                this.form.id_usuario=z_userId
                this.form.tmp_name=z_tmpFileName
                this.form.tmp_name_dec=z_tmpFileName
                this.form.file_name=z_baseName
                this.form.file_name_dec=z_baseName
                this.form.extension=z_extension
            }
        },






        goBack() {
            this.$router.go(-1)
        },
        async getDependencias() {
            const deps = await getCatsNc('/cat_dependencias?transform=1&columns=id,num_dependencia,nom_corto_dependencia&filter=borrar,lt,1')
            return deps.cat_dependencias

        },
        async getProg_sectoriales() {
            const prog_sectoriales = await getCatsNc('/cat_prog_sectorial?transform=1&columns=id,nom_programa_sectorial&filter=borrar,lt,1')
            return prog_sectoriales.cat_prog_sectorial
        },
        async getObjetivos_sectoriales() {
            const objetivos_sectoriales = await getCatsNc('/cat_objetivo_sectorial?transform=1&columns=id,num_objetivo_sectorial,nom_objetivo_sectorial&filter=borrar,lt,1')
            return objetivos_sectoriales.cat_objetivo_sectorial
        },
        async getEstrategias_sectoriales() {
            const estrategias_sectoriales = await getCatsNc('/cat_estrategia_sectorial?transform=1&columns=id,num_estrategia_sectorial,nom_estrategia_sectorial&filter=borrar,lt,1')
            return estrategias_sectoriales.cat_estrategia_sectorial
        },
        async getAcciones_puntuales() {
            const acciones_puntuales = await getCatsNc('/accion_puntual?transform=1&columns=id,codigo_accion_puntual,nom_accion_puntual&filter=borrar,lt,1')
            return acciones_puntuales.accion_puntual
        },
        conteo_real( id ) {
            const charsCount = document.querySelector("#"+id).value.length
            document.querySelector('#'+ id +'_count').innerHTML = `Número de caraceres: ${charsCount}`
        }

    },
    async mounted() {
        this.setData( charsService.decode( this.$route.params.id ))
        window.scrollTo(0, 0)
        this.dependenciasArr = await this.getDependencias()
        this.prog_sectorialesArr = await this.getProg_sectoriales()
        this.objetivo_sectorialArr = await this.getObjetivos_sectoriales()
        this.estrategias_sectorialesArr = await this.getEstrategias_sectoriales()
        this.accion_puntualArr = await this.getAcciones_puntuales()
        this.conteo_real('nom_accion_sectorial')
    }
}
</script>

<style scoped>
    h4, h5 {
        color: #9F2241;
    }
    h6 {
        color: #757575;
    }


    .alignCond {
        text-align: right;
    }

    .divBtn {
        padding: 50px 0;
    }

    @media screen and (max-width: 992px) {
        .midSep {
            margin-bottom: 15px;
        }
    }

    @media screen and (max-width: 768px) {
        .hrCond {
            display: none;
        }

        .alignCond {
            text-align: left;
        }

        .btnCond {
            display: block;
            width: 100%;
        }
    }

    form {
        padding-top: 0;
    }
    :root {
        --v-calendar-input-font-size: medium;
    }
    
    .v-calendar .input-field input {
        font-size: var(--v-calendar-input-font-size) !important;
    }

    img {
        width: 100%;
        margin-bottom: 40px;
    }

    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    form {
        font-size: medium;
    }

    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    small {
        color: #235B4E;
        font-weight: bold;
    }  
    
    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>