<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el mes? </h5>
            <h6> {{ datosMes }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getMesesById from '@/helpers/getMesesById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosMes: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'cat_mes', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getMes() {
            const dataMes = await getMesesById( charsService.decode(this.$route.params.id) );

            const cadena = `${ dataMes.nom_mes } `;

            return cadena
        }
    },
    async mounted() {
        this.datosMes = await this.getMes();
        // this.getMes()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>