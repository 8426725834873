<template>
    <h4>Administración del catálogo de tipos de indicadores</h4>
    <hr>

    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el tipo de indicador? </h5>
            <h6> {{ datosTipo_indicador }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getTipos_indicadorById from '@/helpers/getTipos_indicadorById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosTipo_indicador: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'cat_tipo_indicador', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getTipo_indicador() {
            const dataTipo_indicador = await getTipos_indicadorById( charsService.decode(this.$route.params.id) );

            const cadena = `${ dataTipo_indicador.nom_tipo_indicador } `;

            return cadena
        }
    },
    async mounted() {
        this.datosTipo_indicador = await this.getTipo_indicador();
        // this.getTipo_indicador()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>