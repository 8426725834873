/* Regresa todos los objetivos prioritarios de la tabla 'cat_objetivos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getCoordinadoras = async() => {
    const promiseArr = [
        simejuvApi.get(`/accionp_ent_coord?transform=1`)
    ]

    const [ coordinadora ] = await Promise.all( promiseArr )

    return coordinadora.data.accionp_ent_coord
}

export default getCoordinadoras