<template>
    <h4 class="mt-0">Reporte de avance de acciones puntuales a nivel de objetivo</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                
                
                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="dependencia">Dependencia responsable:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia">
                            <option v-for="e_d in depsArr" :key="e_d.id" :value="e_d.id">{{ e_d.num_dependencia }} {{ e_d.nom_corto_dependencia }}</option>
                        </select>
                    </div>

                    <label class="col-md-3 col-sm-12 col-form-label" to="fecha_avance">Fecha de avance:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <Datepicker lang="es" name="fecha_avance" id="fecha_avance" v-model="form.fecha_avance"/>
                    </div>

                </div>

                
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="nombre_responsable">Nombre de la persona que reporta el avance:</label>
                    <div class="col-md-9 col-sm-12">
                        <input type="text" class="form-control" id="nombre_responsable" name="nombre_responsable" v-model="form.nombre_responsable" />
                    </div>
                </div>
                
                
                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="objetivo_prio">Objetivo prioritario:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="objetivo_prio" name="objetivo_prio" v-model="form.objetivo_prio">
                            <input type="hidden" id="idAccion_puntual" name="idAccion_puntual" v-model="form.id" />
                            <option v-for="eee in objetivo_priosArr" :key="eee.id" :value="eee.id">{{ eee.num_objetivo }} {{ eee.nom_corto_objetivo }}</option>
                        </select>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="avance_resumen">Descripción corta del avance:</label>
                    <div class="col-md-9 col-sm-12">
                        <input type="text" class="form-control" id="avance_resumen" name="avance_resumen" v-model="form.avance_resumen" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-form-label" for="avance_detalle">Descripción del avance:</label>
                    <div class="col-md-9">
                        <ckeditor :editor="editor" v-model="form.avance_detalle" :config="editorConfig" id="avance_detalle" @input="conteo_real(form.avance_detalle, 'avance_detalle')"></ckeditor>
                        <div id="avance_detalle_count" class="noChars"></div>
                    </div>
                </div>
                <h6 class="col-form-label">Periodo que cubre el avance</h6>
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="avance_fecha_inicio">Fecha de inicio:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <Datepicker lang="es" name="avance_fecha_inicio" id="avance_fecha_inicio" v-model="form.avance_fecha_inicio"/>
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="avance_fecha_termino">Fecha de fin:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <Datepicker lang="es" name="avance_fecha_termino" id="avance_fecha_termino" v-model="form.avance_fecha_termino"/>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" for="cantidad_jovenes_imp">Cantidad de jóvenes impactados:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <input class="form-control" type="number" name="cantidad_jovenes_imp" id="cantidad_jovenes_imp" v-model="form.cantidad_jovenes_imp" placeholder="Cantidad de jóvenes impactados" :disabled='isDisabled||isDisabledJ||form.cantidad_jovenes_imp_nd'>
                    </div>
                    <div class="col-md-3">
                        <input type="checkbox" name="cantidad_jovenes_imp_nd" id="cantidad_jovenes_imp_nd" v-model="form.cantidad_jovenes_imp_nd" :checked="form.cantidad_jovenes_imp_nd" @click="isDisabledJov" @load="isDisabledJov" > Dato no disponible
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateAccion_puntual" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateAccion_puntual" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateAccion_puntual" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getAccionp_av_objetivoById from '@/helpers/getAccionp_av_objetivoById'
import getDependenciasNc from '@/helpers/getDependenciasNc'

import '@/assets/css/vuedatepickerui.css'

import VueDatepickerUi from 'vue-datepicker-ui'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import charsService from '@/services/CharsService'

import '@ckeditor/ckeditor5-build-classic/build/translations/es'

import getCatNC from '@/helpers/getCatNC'
import getCatsNc from '@/helpers/getCatsNc'


import updateData from '@/helpers/updateData'


export default {
    components: {
        Datepicker: VueDatepickerUi
    },

    data() {
        return {
            id: '',
            data: [],
            datos: [],
            objetivo_prio: '',
            objetivo_prioNc: '',
            dependencia: '',
            dependenciaNc: '',
            form: {
                id: '',
                nombre_responsable: '',
                avance_resumen: '',
                avance_detalle: '',
                objetivo_prio: '',
                dependencia: '',
                fecha_avance: '',
                avance_fecha_inicio: '',
                avance_fecha_termino: '',
                cantidad_jovenes_imp: null,
                cantidad_jovenes_imp_nd: '',
                //sReporte: false,
            },
            isDisabledJ: '',
            isDisabled: '',
            objetivo_priosArr: [],
            depsArr: [],
            editor: ClassicEditor,
            editorData: '',
            editorConfig: { 
                language: 'es',
                wordCount: {
                    showParagraphs: false,
                    showWordCount: true,
                    showCharCount: true,
                    countSpacesAsChars: false,
                    countHTML: false,
                    maxWordCount: -1,
                    maxCharCount: 4000
                }
            }
        }
    },
    methods: {
        async setData( id ) { //Se trae solo el nombre del elemento seleccionado para colocarlo como primer registro del combobox
            const datos = await getAccionp_av_objetivoById( id )

       
            this.form.id = datos.id
            this.form.nombre_responsable = datos.nombre_responsable
            this.form.avance_resumen = datos.avance_resumen
            this.form.avance_detalle = datos.avance_detalle

            this.form.objetivo_prio = datos.idobjetivo
            this.objetivo_prioNc = await getCatNC(`/cat_objetivos/${ datos.idobjetivo }?transform=1&columns=id,nom_corto_objetivo`).nom_corto_objetivo

            this.form.dependencia = datos.iddependencia
            this.dependenciaNc = await getCatNC(`/cat_dependencias/${ datos.iddependencia }?transform=1&columns=id,nom_corto_dependencia`).nom_corto_dependencia

            this.form.fecha_avance = datos.fecha_avance
            this.form.avance_fecha_inicio = datos.avance_fecha_inicio
            this.form.avance_fecha_termino = datos.avance_fecha_termino

            this.form.cantidad_jovenes_imp = datos.cantidad_jovenes_imp
            this.form.cantidad_jovenes_imp_nd = datos.cantidad_jovenes_imp_nd

        },

        async updateAccion_puntual() {
            const dataForm = {
                nombre_responsable: this.form.nombre_responsable,
                avance_resumen: this.form.avance_resumen,
                avance_detalle: this.form.avance_detalle,
               
                idobjetivo: this.form.objetivo_prio,
                objetivo_prioNc: this.objetivo_prioNc,

                iddependencia: this.form.dependencia,
                dependenciaNc: this.dependenciaNc,

                fecha_avance: this.form.fecha_avance,
                avance_fecha_inicio: this.form.avance_fecha_inicio,
                avance_fecha_termino: this.form.avance_fecha_termino,
                cantidad_jovenes_imp: (this.form.cantidad_jovenes_imp > 0)? this.form.cantidad_jovenes_imp : null,
                cantidad_jovenes_imp_nd: this.form.cantidad_jovenes_imp_nd

            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('accionp_av_objetivo', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        },

        async getObjetivo_prios() {
            const objetivo_prios = await getCatsNc('/cat_objetivos?transform=1&columns=id,num_objetivo,nom_corto_objetivo&filter=borrar,lt,1')
            return objetivo_prios.cat_objetivos
        },


        async getDependencias() {
            const deps = await getCatsNc('/cat_dependencias?transform=1&columns=id,num_dependencia,nom_corto_dependencia&filter=borrar,lt,1')
            return deps.cat_dependencias

        },

        isDisabledJov() {
            const cantidad_jovenes_imp_nd = document.querySelector('#cantidad_jovenes_imp_nd').checked
            this.isDisabledJ = cantidad_jovenes_imp_nd
            if (cantidad_jovenes_imp_nd) {
                document.querySelector('#cantidad_jovenes_imp').value = ''
                document.querySelector('#cantidad_jovenes_imp').dispatchEvent(new Event('input'))
            }
        },

    },
    async mounted() {
       
        this.setData( charsService.decode( this.$route.params.id ))
        window.scrollTo(0, 0)
        this.objetivo_priosArr = await this.getObjetivo_prios()
        this.depsArr = await this.getDependencias()
        // this.getCatalogos()
       // this.conteo_real('avance_detalle')
    }
}
</script>

<style scoped>
    h4, h5 {
        color: #9F2241;
    }
    h6 {
        color: #757575;
    }


    .alignCond {
        text-align: right;
    }

    .divBtn {
        padding: 50px 0;
    }

    @media screen and (max-width: 992px) {
        .midSep {
            margin-bottom: 15px;
        }
    }

    @media screen and (max-width: 768px) {
        .hrCond {
            display: none;
        }

        .alignCond {
            text-align: left;
        }

        .btnCond {
            display: block;
            width: 100%;
        }
    }

    form {
        padding-top: 0;
    }
    :root {
        --v-calendar-input-font-size: medium;
    }
    
    .v-calendar .input-field input {
        font-size: var(--v-calendar-input-font-size) !important;
    }

    img {
        width: 100%;
        margin-bottom: 40px;
    }

    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    form {
        font-size: medium;
    }

    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    small {
        color: #235B4E;
        font-weight: bold;
    }  
    
    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>