<template>
    <div class="row">
        <div class="col-12 text-right">
            <button class="btn btn-primary btn-sm active" @click="downloadPdf">Descargar reporte</button>
        </div>
    </div>
    <h3>Metas para el Bienestar y Parámetros del Programa <br>Nacional de Juventud 2021-2024</h3>
    <hr>
    <div class="col-md-12 gral" ref="toPdf">
        <div class="row separar bg-light" v-for="e in arrMetasEnc" :key="e">
            <div class="row ml-3 mr-3" v-if="e.idObjV && arrCharts[e.idMeta]">
                <p><strong>Objetivo prioritario {{ e.idObj }}:</strong> {{ arrObjetivos[e.idObj] }}</p>
            </div>
            <div class="row ml-3 mr-3" v-if="arrCharts[e.idMeta]">
                <div class="col-md-8 border">
                    <span class="titMeta"><strong>{{ (e.idclase_ind === 2)? 'Parámetro' : 'Meta' }} {{ e.codMeta }}</strong> {{ e.nomMeta }}</span>
                    <LineCharts :chartData="{
                        //labels: ['Histórico 2013', 'Histórico 2015', 'Histórico 2017', 'Línea de base 2019', 'Meta programada 2021', 'Meta programada 2023', 'Meta programada 2024'],
                        labels: arrCharts[e.idMeta][0],
                        datasets: [
                            {
                                backgroundColor: '#8E7146',
                                data: arrCharts[e.idMeta][1],
                                pointRadius: 6,
                                pointHoverRadius: 6
                            },
                            {
                                type: 'bar',
                                backgroundColor: '#545656',
                                data: arrCharts[e.idMeta][2]
                            },
                            { hidden: true, label: '', backgroundColor: '#ffffff', data: [null], skipNull: true }
                        ]
                    }" :chartOptions="state.chartOptions" @on-receive="update(this)" />
                </div>
                <div class="col-md-4 border">
                    <div class="descMeta">{{ e.descMeta }}</div>
                    <div class="subtBold text-center mt-3">Meta 2024</div>
                    <div class="meta2024val text-center mt-3">{{ e.valMeta24 }}</div>
                    <div class="subtBold mt-3">Fuente de información:</div>
                    <div class="descMeta">{{ e.fuenteInfo }}</div>
                </div>
            </div>
            <div class="row ml-3 mr-3 mt-3" v-if="parseInt(usrThis) === 1 || parseInt(usrThis) === 2 || parseInt(usrThis) === 4">
                <router-link class="btn btn-primary active btnCond btn-sm" :to="`/avance/metas/edit/${ e.idMetaEnc }`">
                    <i class="glyphicon glyphicon-check"></i> Registrar avance
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import getMetas from '@/helpers/getMetas'
import getObjetivos from '@/helpers/getObjetivos'
import getVAvanceMetas from '@/helpers/getVAvanceMetas'
import getAvanceMetasById from '@/helpers/getAvanceMetasById'
import LineCharts from '@/components/Charts/LineCharts'

import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import charsService from '@/services/CharsService';

export default {
    components: {
        LineCharts
    },
    data() {
        return {
            arrMetas: [],
            arrMetasEnc: [],
            arrObjetivos: [],
            arrAvance: [],
            arrCharts: [],
            arrCatAvances: [],
            usrThis: charsService.decode(localStorage.getItem('tipo_usuario')),
            state: {
                chartData: {},
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0 // disables bezier curves
                        },
                        point: {
                            borderColor: "#000",
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true,
                                fontSize: 14,
                                fontFamily: 'Montserrat'
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                autoSkip: false,
                                fontSize: 14,
                                fontFamily: 'Montserrat',
                            },
                        }],
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            // title: () => { return '' },
                            label: (tooltipItems, data) => { return "   " + tooltipItems.yLabel; },
                        },
                        titleFontSize: 16,
                        titleFontFamily: 'Montserrat',
                        bodyFontSize: 16,
                        bodyFontFamily: 'Montserrat'
                    },
                }
            }
        }
    },
    methods: {
        async getAllMetas() {
            const consMetas = await getMetas()
            const arrShow = [];
            let idObjetivoAnt = '';

            
            for(let i = 0; i < consMetas.length; i++) {
                const idMeta = consMetas[i].id
                const idObj = consMetas[i].idobjetivo
                let idObjV = false
                const idclase_ind = consMetas[i].idclase_ind
                const codMeta = consMetas[i].codigo_meta
                const nomMeta = consMetas[i].nom_meta
                const descMeta = consMetas[i].descripcion_meta
                const valMeta24 = consMetas[i].meta_24_valor
                const fuenteInfo = consMetas[i].lb_var_a_fuente

                if( idObj !== idObjetivoAnt ) {
                    idObjetivoAnt = idObj
                    idObjV = true
                }

                arrShow.push({
                    idMeta,
                    idObj,
                    idObjV,
                    idclase_ind,
                    codMeta,
                    nomMeta,
                    descMeta,
                    valMeta24,
                    fuenteInfo
                })
            }

            return arrShow
        },

        async getCatalogos() {
            const consObjetivos = await getObjetivos();
            for (let h = 0; h < consObjetivos.length; h++) {
                this.arrObjetivos[consObjetivos[h].id] = consObjetivos[h].nom_objetivo
            }
        },

        async getAvanceMetas() {
            const consAvanceMetas = await getVAvanceMetas();
            for (let i = 0; i < consAvanceMetas.length; i++) {
                if (!this.arrAvance[consAvanceMetas[i].idmeta_ficha]) {
                    this.arrCharts[consAvanceMetas[i].idmeta_ficha] = []
                    this.arrAvance[consAvanceMetas[i].idmeta_ficha] = {
                        id: consAvanceMetas[i].idmeta_ficha,
                        hist_2012: consAvanceMetas[i].serie_hist_2012,
                        hist_2013: consAvanceMetas[i].serie_hist_2013,
                        hist_2014: consAvanceMetas[i].serie_hist_2014,
                        hist_2015: consAvanceMetas[i].serie_hist_2015,
                        hist_2016: consAvanceMetas[i].serie_hist_2016,
                        hist_2017: consAvanceMetas[i].serie_hist_2017,
                        hist_2018: consAvanceMetas[i].serie_hist_2018,
                        hist_2019: consAvanceMetas[i].serie_hist_2019,
                        hist_2020: consAvanceMetas[i].serie_hist_2020,
                        meta_2024: consAvanceMetas[i].meta_24_valor,
                        lb_valor: consAvanceMetas[i].lb_valor,
                        lb_anio: consAvanceMetas[i].lb_ano,
                        meta_anio: consAvanceMetas[i].meta_ano,
                        meta_programada: consAvanceMetas[i].meta_programada,
                    }
                }
            }

            for (let j = 1; j < this.arrAvance.length; j++) {
                const titulos = []
                const objData = []
                const objExtra = []

                if (this.arrAvance[j].hist_2012 || this.arrAvance[j].lb_anio === 2012 || this.arrAvance[j].meta_programada === 2012) {
                    (this.arrAvance[j].lb_anio !== 2012)? titulos.push('Histórico 2012') : titulos.push('Línea de base 2012') 
                    objData.push(this.arrAvance[j].hist_2012)

                    if ( this.arrAvance[j].lb_anio === 2012 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2012 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                if (this.arrAvance[j].hist_2013 || this.arrAvance[j].lb_anio === 2013 || this.arrAvance[j].meta_programada === 2013) {
                    (this.arrAvance[j].lb_anio !== 2013)? titulos.push('Histórico 2013') : titulos.push('Línea de base 2013') 
                    objData.push(this.arrAvance[j].hist_2013)
                    if ( this.arrAvance[j].lb_anio === 2013 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2013 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                if (this.arrAvance[j].hist_2014 || this.arrAvance[j].lb_anio === 2014 || this.arrAvance[j].meta_programada === 2014) {
                    (this.arrAvance[j].lb_anio !== 2014)? titulos.push('Histórico 2014') : titulos.push('Línea de base 2014') 
                    objData.push(this.arrAvance[j].hist_2014)
                    if ( this.arrAvance[j].lb_anio === 2014 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2014 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                if (this.arrAvance[j].hist_2015 || this.arrAvance[j].lb_anio === 2015 || this.arrAvance[j].meta_programada === 2015) {
                    (this.arrAvance[j].lb_anio !== 2015)? titulos.push('Histórico 2015') : titulos.push('Línea de base 2015') 
                    objData.push(this.arrAvance[j].hist_2015)
                    if ( this.arrAvance[j].lb_anio === 2015 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2015 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                if (this.arrAvance[j].hist_2016 || this.arrAvance[j].lb_anio === 2016 || this.arrAvance[j].meta_programada === 2016) {
                    (this.arrAvance[j].lb_anio !== 2016)? titulos.push('Histórico 2016') : titulos.push('Línea de base 2016') 
                    objData.push(this.arrAvance[j].hist_2016)
                    if ( this.arrAvance[j].lb_anio === 2016 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2016 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                if (this.arrAvance[j].hist_2017 || this.arrAvance[j].lb_anio === 2017 || this.arrAvance[j].meta_programada === 2017) {
                    (this.arrAvance[j].lb_anio !== 2017)? titulos.push('Histórico 2017') : titulos.push('Línea de base 2017') 
                    objData.push(this.arrAvance[j].hist_2017)
                    if ( this.arrAvance[j].lb_anio === 2017 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2017 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                if (this.arrAvance[j].hist_2018 || this.arrAvance[j].lb_anio === 2018 || this.arrAvance[j].meta_programada === 2018) {
                    (this.arrAvance[j].lb_anio !== 2018)? titulos.push('Histórico 2018') : titulos.push('Línea de base 2018') 
                    objData.push(this.arrAvance[j].hist_2018)
                    if ( this.arrAvance[j].lb_anio === 2018 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2018 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                if (this.arrAvance[j].hist_2019 || this.arrAvance[j].lb_anio === 2019 || this.arrAvance[j].meta_programada === 2019) {
                    (this.arrAvance[j].lb_anio !== 2019)? titulos.push('Histórico 2019') : titulos.push('Línea de base 2019') 
                    objData.push(this.arrAvance[j].hist_2019)
                    if ( this.arrAvance[j].lb_anio === 2019 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2019 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                if (this.arrAvance[j].hist_2020 || this.arrAvance[j].lb_anio === 2020 || this.arrAvance[j].meta_programada === 2020) {
                    (this.arrAvance[j].lb_anio !== 2020)? titulos.push('Histórico 2020') : titulos.push('Línea de base 2020') 
                    objData.push(this.arrAvance[j].hist_2020)
                    if ( this.arrAvance[j].lb_anio === 2020 )
                        objExtra.push(this.arrAvance[j].lb_valor)
                    else if ( this.arrAvance[j].meta_anio === 2020 )
                        objExtra.push(this.arrAvance[j].meta_programada)
                    else
                        objExtra.push(0)
                }

                titulos.push('Meta programada ' + this.arrAvance[j].meta_anio)
                objData.push(this.arrAvance[j].meta_programada)




                for (let i = 0; i < consAvanceMetas.length; i++) {
                    if (this.arrAvance[j].id === consAvanceMetas[i].idmeta_ficha) {
                        const metaAnio = consAvanceMetas[i].meta_ano
                        const metaProg = consAvanceMetas[i].meta_programada

                        if ( !titulos.includes('Meta programada ' + metaAnio) ) {
                            titulos.push('Meta programada ' + metaAnio)
                            objData.push(metaProg)
                        }

                        if ( this.arrCatAvances[ consAvanceMetas[i].idmeta_avance ] ) {
                            objExtra.push( this.arrCatAvances[ consAvanceMetas[i].idmeta_avance ] )
                        }
                    }
                }

                this.arrCharts[this.arrAvance[j].id].push(titulos)
                this.arrCharts[this.arrAvance[j].id].push(objData)
                this.arrCharts[this.arrAvance[j].id].push(objExtra)
            }
        },

        async getCatAvances() {
            const consAvances = await getAvanceMetasById()

            for (let j = 0; j < consAvances.length; j++) {
                this.arrCatAvances[ consAvances[j].id ] = consAvances[j].meta_alcanzada
            }
        },

        downloadPdf() {
            const doc = new jsPDF({
                orientation: 'p',
                unit: 'px',
                format: 'a4',
                hotfixes: ['px_scaling'],
            });
            let date = new Date()
            let day = `${(date.getDate())}`.padStart(2,'0');
            let month = `${(date.getMonth()+1)}`.padStart(2,'0');
            let year = date.getFullYear();
            
            let dateShow = `${day}-${month}-${year}`
            /** WITH CSS */
            var canvasElement = document.createElement('canvas');
            doc.context2d.pageWrapYEnabled = true;
            html2canvas(this.$refs.toPdf, {
                width: doc.internal.pageSize.getWidth() + 300,
                height: doc.internal.pageSize.getHeight() + 200
            }).then((canvas) => {
                const img = canvas.toDataURL("image/jpeg", 1);
                doc.addImage(img, "JPEG", 50, 50);
                doc.save("metas_parametros_" + (dateShow) + ".pdf");
            })
        }
    },
    async mounted() {
        this.arrMetas = await this.getAllMetas()
        for (let i=0; i<this.arrMetas.length; i++) {
            this.arrMetasEnc.push(
                {
                    idMeta: this.arrMetas[i].idMeta,
                    idMetaEnc: charsService.encode(this.arrMetas[i].idMeta),
                    codMeta: this.arrMetas[i].codMeta,
                    descMeta: this.arrMetas[i].descMeta,
                    fuenteInfo: this.arrMetas[i].fuenteInfo,
                    idObj: this.arrMetas[i].idObj,
                    idObjV: this.arrMetas[i].idObjV,
                    idclase_ind: this.arrMetas[i].idclase_ind,
                    nomMeta: this.arrMetas[i].nomMeta,
                    valMeta24: this.arrMetas[i].valMeta24,
                }
            )
        }
        this.getCatalogos()
        this.getCatAvances()
        this.getAvanceMetas()
        this.$gtag.event('Metas y Parámetros');    //Registra el Evento en Google Analytics con nombre
    }
}
</script>

<style scoped>
    .subTitle {
        font-weight: normal;
    }

    .article-body>.row>p {
        padding: 10px 15px 10px 0;
    }

    .meta {
        width: 100%;
        margin: 0;
    }

    h1.meta{
        margin-bottom: 20px;
    }
    
    .chart {
        padding-top: 20px;
    }

    img {
        width: 1057.5px;
        height: 1246.88px;
    }

    .link1 {
        position: absolute;
        right: 112px;
        top: 25px;
        color: transparent;
    }

    .link2{
        position: absolute;
        left: 54px;
        top: 527px;
        color: transparent;
    }

    .separar {
        padding: 20px 10px;
        display: block !important;
        margin-bottom: 50px;
    }

    .titMeta {
        display: inline-block;
        font-size: small;
        line-height: 20px;
    }

    .descMeta {
        font-size: 10pt;
    }

    .subtBold {
        font-size: 10pt;
        font-weight: bold;
    }

    .meta2024val {
        font-size: 24pt;
        margin-top: 0 !important;
        font-weight: bold;
    }

    .grafica {
        width: 100%;
        height: auto;
    }

    .gral {
        float: none;
    }

    .chartjs-render-monitor {
        display: block;
        width: 645px;
        height: 321px;
    }
</style>