<template>
    <h4>Administración del catálogo de periodos de recolección</h4>
    <hr>

    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el periodo de recolección? </h5>
            <h6> {{ datosPeriodo_recoleccion }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getPeriodos_recoleccionById from '@/helpers/getPeriodos_recoleccionById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosPeriodo_recoleccion: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'cat_periodo_recoleccion', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getPeriodo_recoleccion() {
            const dataPeriodo_recoleccion = await getPeriodos_recoleccionById( charsService.decode(this.$route.params.id) );

            const cadena = `${ dataPeriodo_recoleccion.nom_periodo_recoleccion } `;

            return cadena
        }
    },
    async mounted() {
        this.datosPeriodo_recoleccion = await this.getPeriodo_recoleccion();
        // this.getPeriodo_recoleccion()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>