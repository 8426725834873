<template>
    <h4 class="mt-0">Actualizar información del catálogo de estrategias</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="num_estrategia">Número de la Estrategia:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idEstrategia" name="idEstrategia" v-model="form.id" />
                        <input type="text" class="form-control" id="num_estrategia" name="num_estrategia" placeholder="Número de la Estrategia" v-model="form.num_estrategia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_estrategia">Nombre de la Estrategia:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_estrategia" name="nom_estrategia" placeholder="Nombre de la Estrategia" v-model="form.nom_estrategia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_corto_estrategia">Nombre corto de la Estrategia:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_corto_estrategia" name="nom_corto_estrategia" placeholder="Nombre corto de la Estrategia" v-model="form.nom_corto_estrategia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="objetivo">Nombre del objetivo:</label>
                    <div class="col-md-8">
                        <select class="form-control" id="objetivo" name="objetivo">
                            <option :value=" objetivo ">{{ objetivoNc }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateEstrategia" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateEstrategia" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateEstrategia" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getEstrategiasById from '@/helpers/getEstrategiasById'
import getObjetivoNC from '@/helpers/getObjetivoNC'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            objetivo: '',
            objetivoNc: '',
            form: {
                id: '',
                num_estrategia: '',
                nom_estrategia: '',
                nom_corto_estrategia: '',
                objetivo: '',
            }
        }
    },
    methods: {
        async getEstrategiasData( id ) {
            const estrategiaArr = await getEstrategiasById( id )
            
            return estrategiaArr[0].cat_estrategia
        },

        async setData( id ) {
            const datos = await getEstrategiasById( id )

            this.form.id = datos.id
            this.form.num_estrategia = datos.num_estrategia
            this.form.nom_estrategia = datos.nom_estrategia
            this.form.nom_corto_estrategia = datos.nom_corto_estrategia
            this.objetivoNc = await getObjetivoNC(datos.idobjetivo)
        },

        async updateEstrategia() {
            const dataForm = {
                num_estrategia: this.form.num_estrategia,
                nom_estrategia: this.form.nom_estrategia,
                nom_corto_estrategia: this.form.nom_corto_estrategia,
                objetivo: this.objetivo,
                objetivoNc: this.objetivoNc

            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_estrategia', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>