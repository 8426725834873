/* Regresa todos los datos de los años con el id enviado de la tabla 'cat_unidad_medida' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getUnidades_medidaById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_unidad_medida/${ id }`)
    ]

    const [ cat_unidad_medida ] = await Promise.all( promiseArr )

    return cat_unidad_medida.data
}

export default getUnidades_medidaById