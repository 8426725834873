<template>
    <div class="content">
        <div class="row">
            



            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <div  v-for="e in qryInter" :key="e.id">
                            <div class="col-md-3">
                                <div >
                                    <div class="col-sm-20 col-md-20">
                                        <div class="thumbnail">
                                            <img :src="arrArchInter[e.id]">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-9">

                                <h5>Intervención Gubernamental: {{ e.interGuberna }}</h5>
                                <div class="titulo">Objetivo de la Intervención Gubernamental</div>
                                <div class="col-md-12">    
                                    <div class="col-md-12">{{ e.objetivo }}</div>
                                </div>
                                <span class="titulo">Referencia Web de la Intervención Gubernamental </span><span><a v-bind:href="e.webIntervencion" target="_blank">{{ e.webIntervencion }}</a></span><br>
                                <div class="row">
                                    <div class="col-md-6 titulo">Entidad Federal responsable</div>
                                    <div class="col-md-6 titulo">Unidad o área ejecutora</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 objPrinc">{{ arrDeps[e.dependencia] }}</div>
                                    <div class="col-md-6 objPrinc">{{ e.areaImplementa }}</div>
                                </div>
                                                                
                                <div class="row" v-if="e.normatividad === 'Si'">
                                    <div class="col-md-6 titulo">La intervención cuenta con normatividad</div>
                                    <div class="col-md-6"><span class="titulo"> </span></div>
                                </div>
                                <div class="row" v-if="e.normatividad === 'Si'">
                                    <div class="col-md-6 titulo">Tipo de normatividad</div>
                                    <div class="col-md-6 titulo">Referencia web de la normatividad</div>
                                </div>
                                <div class="row" v-if="e.normatividad === 'Si'">
                                    <div class="col-md-6 objPrinc">{{ arrNormatividad[e.tipoNormatividad] }}</div>
                                    <div class="col-md-6"><a v-bind:href="e.webNormatividad" target="_blank">{{ e.webNormatividad }}</a></div>
                                </div>
                                
                                <div class="row" v-if="e.normatividad === 'No'">
                                    <div class="col-md-6 titulo">La intervención no cuenta con normatividad</div>
                                    <div class="col-md-6"><span class="titulo"> </span></div>
                                </div>

                                <div class="titulo">Población Objetivo</div>
                                <p>{{ e.poblacionObjetivo }}</p>
                                
                                <div class="titulo">Tipos y montos de apoyo</div>
                                <p>{{ e.tipoMontosApoyo }}</p>
                                
                                <div class="col-md-12">    
                                    <div class="row" v-if="e.nom_objetivo1 === 1" style="padding:2px">  
                                        <div class="col-md-12">{{ arrObjetivos[0].num_objetivo }}. {{ arrObjetivos[0].nom_objetivo }}</div><br>
                                    </div>      
                                </div> 
                                <div class="col-md-12">    
                                    <div class="row" v-if="e.nom_objetivo2 === 1" style="padding:2px">  
                                        <div class="col-md-12">{{ arrObjetivos[1].num_objetivo }}. {{ arrObjetivos[1].nom_objetivo }}</div><br>
                                    </div>      
                                </div> 
                                <div class="col-md-12">    
                                    <div class="row" v-if="e.nom_objetivo3 === 1" style="padding:2px">  
                                        <div class="col-md-12">{{ arrObjetivos[2].num_objetivo }}. {{ arrObjetivos[2].nom_objetivo }}</div><br>
                                    </div>      
                                </div> 
                                <div class="col-md-12">    
                                    <div class="row" v-if="e.nom_objetivo4 === 1" style="padding:2px">  
                                        <div class="col-md-12">{{ arrObjetivos[3].num_objetivo }}. {{ arrObjetivos[3].nom_objetivo }}</div><br>
                                    </div>      
                                </div> 
                                <div class="col-md-12">    
                                    <div class="row" v-if="e.nom_objetivo5 === 1" style="padding:2px">  
                                        <div class="col-md-12">{{ arrObjetivos[4].num_objetivo }}. {{ arrObjetivos[4].nom_objetivo }}</div><br>
                                    </div>      
                                </div> 
                                <div class="col-md-12">    
                                    <div class="row" v-if="e.nom_objetivo6 === 1" style="padding:2px">  
                                        <div class="col-md-12">{{ arrObjetivos[5].num_objetivo }}. {{ arrObjetivos[5].nom_objetivo }}</div><br>
                                    </div>      
                                </div> 
                            </div>
                            <hr class="linea_gris" /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import getAllIntervencionesAut from '@/helpers/getAllIntervencionesAut'
import getCatDepIntervencion from '@/helpers/getCatDepIntervencion'
import getCatTipoSolicitante from '@/helpers/getCatTipoSolicitante'
import getCatTipoNormatividad from '@/helpers/getCatTipoNormatividad'
import getCatObj_PROJUVENTUD from '@/helpers/getCatObjetivos'
import getArchivosIntervenciones from '@/helpers/getArchivosIntervenciones'
import { url } from '@/api/baseUrl'
//import getCatObjetivos_ODS from '@/helpers/getCatObjetivos_ODS'
//import getCatAlineacionEp from '@/helpers/getCatAlineacionEp'

export default {
    data() {
        return {
            qryInter: [],
            arrDeps: [],
            arrArchInter: [],
            arrSolic: [],
            arrNormatividad: [],
            arrAlinea: [],
            arrObjetivos: [],
            arrObjetivosODS: [],
        }
    },
    methods: {
        async getIntervenciones() {
            const interArr = await getAllIntervencionesAut();
            return interArr[0].intervenciones
        },

        async getCatDepInter() {
            const deps = await getCatDepIntervencion()

            for (let j = 0; j < deps.length; j++) {
                this.arrDeps[ deps[j].id ] = deps[j].dependencia
            }
        },

        async getArchInter() {          
            const archs = await getArchivosIntervenciones('IntervenReg')

            for (let j = 0; j < archs.length; j++) {
                this.arrArchInter[ archs[j].id_registro_sec ] = url.value + '/uploads/' + archs[j].tmp_name;
            }
        },
        


        async getCatSolic() {
            const tSolic = await getCatTipoSolicitante()

            for (let j = 0; j < tSolic.length; j++) {
                this.arrSolic[ tSolic[j].id ] = tSolic[j].tipo_solicitante
            }
        },
        async getCatTipoNorma() {
            const tNormatividad = await getCatTipoNormatividad()

            for (let j = 0; j < tNormatividad.length; j++) {
                this.arrNormatividad[ tNormatividad[j].id ] = tNormatividad[j].tipo_normatividad
            }
            //const tipoNorma = await getCatTipoNormatividad()
            //return tipoNorma
        },

        
        async getObjetivos() {
            const objetivo = await getCatObj_PROJUVENTUD()
            return objetivo
        }, 

/*
         async getObjetivos() {
            const tObjetivos = await getCatObj_PROJUVENTUD()

            for (let j = 0; j < tObjetivos.length; j++) {
                this.arrObjetivos[ tObjetivos[j].id ].num_objetivo = tObjetivos[j].num_objetivo
                this.arrObjetivos[ tObjetivos[j].id ].nom_objetivo = tObjetivos[j].nom_objetivo
                this.arrObjetivos[ tObjetivos[j].id ].enom_objetivo = 'e.nom_objetivo' + tObjetivos[j].num_objetivo
            }
        }, 
*/

        async getAlineacion() {
            //const alinea = await getCatAlineacionEp()

            //for (let j = 0; j < alinea.length; j++) {
            //    this.arrAlinea[ alinea[j].id ] = alinea[j].ep
            //}
        },
    },
    async mounted() {
        this.qryInter = await this.getIntervenciones()
        this.getCatDepInter();
        this.getArchInter();
        this.getCatSolic();
        this.getCatTipoNorma();
        this.getAlineacion();
        this.arrObjetivos = await this.getObjetivos()
       // this.getObjetivos();

        this.getObjetivosODS();
    }
}
</script>

<style scoped>
    h5 {
        color: #9D2449;
    }

    .panel {
        font-size: medium;
    }

    .objPrinc {
        font-weight: bold;
        color: #98989A;
    }

    .titulo {
        font-weight: bold;
        line-height: 40px;
    }
    .linea_gris {
        width: 100%;
      }
</style>