/* Regresa todos los registros de la vista 'v_meta_avance' en la base de datos */

// Esta linea se queda igual siempre (base url api)
import simejuvApi from '../api/simejuvApi'

// Se nombra la funcion
const getVAvanceMetas = async() => {
    const promiseArr = [
        // Se modifica de acuerdo a la búsqueda en la tabla
        simejuvApi.get(`/v_meta_avance?transform=1`)
    ]

    // Cambiar el nombre de la variable contra los datos a mostrar
    const [ avance ] = await Promise.all( promiseArr )

    // Cambiar la primer variable a como se definió arriba
    // Cambiar la ultima variable a como se llama la tabla en la base de datos
    return avance.data.v_meta_avance
}

// Se exporta con el mismo nombre de la funcion
export default getVAvanceMetas