<template>
    <h4>Administración del catálogo de estrategias</h4>
    <hr>
    <div class="row mb-3">
        <!-- Botón nueva estrategia -->
        <!-- Se deberá cambiar la ruta una vez creado el componente para la acción -->
        <div class="col-md-4 text-left">
            <input type="button" class="btn btn-sm btn-secondary" value="Nueva estrategia" @click=" $router.push('/estrategia/estrategiaAdmin/add') ">
        </div>
        <!-- Input de búsqueda -->
        <div class="col-md-3 text-right"><label class="text-small">Buscar:</label></div>
        <div class="col-md-5 text-right"><input v-model="searchTerm" class="form-control busqueda" /></div>
    </div>
    <div class="row">
        <div class="col-md-12 datatable">
            <!-- Configuración de la tabla con los campos a mostrar -->
            <table-lite
                :is-static-mode="true"
                :columns="table.columns"
                :pageSize=15
                :rows="table.rows"
                :total="table.totalRecordCount"
                :sortable="table.sortable"
                :messages="table.messages"
                :width=50
            ></table-lite>
            
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
// Componente de la tabla
import TableLite from "@/components/DataTable/TableLite";

// Importo los helpers (API)
import getCatEstrategias_v from '@/helpers/getCatEstrategias_v'
import charsService from '@/services/CharsService';

export default defineComponent({
    components: { TableLite },
    setup() {
        const searchTerm = ref("") // Variable para los datos de búsqueda
        const data = reactive([]); // Variable para los datos a mostrar en la tabla

        (async () => {
            // Se crea la variable para el arreglo haciendo la llamada a la función de la api
            const estrategiasArr = await getCatEstrategias_v()

            // Genero el arreglo de datos para llenar la tabla
            const datos = estrategiasArr
            for (let i = 0; i < datos.length; i++) {
                data.push({
                    id: datos[i].id,
                    num_objetivo: datos[i].num_objetivo,
                    num_estrategia: datos[i].num_estrategia,
                    nom_estrategia: datos[i].nom_estrategia
                });
            }
        })()
        
        // Configuración de la tabla
        const table = reactive({
            // Columnas de la tabla
            columns: [
                {
                    label: "Núm objetivo",
                    field: "num_objetivo",
                    width: "5%",
                    sortable: true,
                },
                {
                    label: "Núm estrategia",
                    field: "num_estrategia",
                    width: "5%",
                    sortable: true,
                },
                {
                    label: "Nombre de la estrategia",
                    field: "nom_estrategia",
                    width: "80%",
                    sortable: true,
                    
                     
                },
                // Botones de acciones (iconos)
                {
                    label: "",
                    field: "quick",
                    width: "10%",
                    display: function (row) {
                        // Iconos de acciones
                        return (
                            // TO-DO: Hay que cambiar la ruta a la acción correspondiente (definida en en index.ts de la carpeta router)
                            // puede ser /estrategia/estrategiaAdmin/edit...
                            '<a href="/estrategia/estrategiaAdmin/edit/'+ charsService.encode(row.id) +'" class="text-info" title="Editar"><i class="glyphicon glyphicon-pencil"></i></a> ' + '&nbsp;&nbsp;&nbsp;' + 
                            '<a href="/estrategia/estrategiaAdmin/remove/'+ charsService.encode(row.id) +'" class="text-info" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a> ' + '&nbsp;&nbsp;&nbsp;' + 
                            ''
                        );
                    },
                },
            ],
            rows: computed(() => {
                // Aquí se designan las columnas en las que se realizará la búsqueda desde el input
                return data.filter(
                    (x) =>
                        x.nom_estrategia.toLowerCase().includes(searchTerm.value.toLowerCase()) 
                    );
            }),
            totalRecordCount: computed(() => {
                // Se regresa el número de registros encontrados
                return table.rows.length
            }),
            sortable: {
                // Se define el orden por default
                order: "id",
                sort: "asc",
            },
            messages: {
                // Personalizo los mensajes que muestra la tabla
                pagingInfo: "Mostrando {0}-{1} de {2}",
                pageSizeChangeLabel: "Filas:",
                gotoPageLabel: "   Ir a la página:",
                noDataAvailable: "Sin datos para mostrar",
            },
        });

        return {
            searchTerm,
            table,
        }
    }
})
</script>

<style scoped>
    .card :deep .table .thead-dark th {
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
    .card :deep .table td, .card :deep .table tr{
        border: none;
    }
    .text-small {
        font-size: medium;
    }
    .busqueda {
        font-size: medium;
        height: auto;
    }
    .text-info {
        color: #343a40 !important;
        padding: 0 20px;
    }
    .datatable {
        padding-bottom: 10px;
        overflow-x: auto;
        margin-bottom: 25px;
        width:20; 
    }
    .card {
        border: 0 !important;
    }
</style>