<template>
    <h4 class="mt-0">Agregar una Dependencia Estatal</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>

                <div class="form-group row">
                    <label class="col-md-4 col-sm-12 col-form-label" to="idestado">Nombre del estado:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="idestado" name="idestado" v-model="form.idestado" :class="{ validated: noValid.idestado }">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e_tf in cat_estadoArr" :key="e_tf.id" :value="e_tf.id">{{ e_tf.id }} {{ e_tf.nom_estado }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4 col-sm-12 col-form-label" to="id_sector_op">Tipo de sector:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="id_sector_op" name="id_sector_op" v-model="form.id_sector_op" :class="{ validated: noValid.id_sector_op }">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e_tf in cat_sector_opArr" :key="e_tf.id" :value="e_tf.id">{{ e_tf.id }} {{ e_tf.nom_sector_op }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="num_dependencia_op">Número de la Dependencia:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idDependencia" name="idDependencia" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.num_dependencia_op }" id="num_dependencia_op" name="num_dependencia_op" placeholder="Número de la Dependencia" v-model="form.num_dependencia_op" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_dependencia_op">Nombre de la Dependencia:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_dependencia_op }" id="nom_dependencia_op" name="nom_dependencia_op" placeholder="Nombre de la Dependencia" v-model="form.nom_dependencia_op" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_corto_dependencia_op">Nombre corto de la Dependencia (Siglas):</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_corto_dependencia_op }" id="nom_corto_dependencia_op" name="nom_corto_dependencia_op" placeholder="Siglas" v-model="form.nom_corto_dependencia_op" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra Dependencia" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addDependencia" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getCatNC from '@/helpers/getCatNC'
import getCatsNc from '@/helpers/getCatsNc'

import addData from '@/helpers/addData'
import getDataByAnyDependencia_op from '@/helpers/getDataByAnyDependencia_op'

export default {
    data() {
        return {
            form: {
                idestado: '',
                id_sector_op: '',
                num_dependencia_op: '',
                nom_dependencia_op: '',
                nom_corto_dependencia_op: ''

            },
            noValid: {
                idestado: false,
                id_sector_op: false,
                num_dependencia_op: false,
                nom_dependencia_op: false,
                nom_corto_dependencia_op: false,
            },
            cat_estadoArr: [],
            cat_sector_opArr: [],
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        async getCat_estado() {
            const c_estado = await getCatsNc('/cat_estado?transform=1&columns=id,nom_estado&filter=id,lt,100')
            return c_estado.cat_estado

        },
        async getcat_sector_op() {
            const sector_op = await getCatsNc('/cat_sector_op?transform=1&columns=id,nom_sector_op&filter=id,lt,100')
            return sector_op.cat_sector_op
        },
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            setTimeout(function() { document.getElementById('idestado').getElementsByTagName('option')[0].selected = 'selected' }, 1);
            document.getElementById('idestado').getElementsByTagName('option')[0].selected = 'selected'
            setTimeout(function() { document.getElementById('id_sector_op').getElementsByTagName('option')[0].selected = 'selected' }, 1);
            document.getElementById('id_sector_op').getElementsByTagName('option')[0].selected = 'selected'
            this.form.num_dependencia_op = ''
            this.form.nom_dependencia_op = ''
            this.form.nom_corto_dependencia_op = ''
            this.added = false
        },
        async addDependencia() {
            this.noValid.idestado = ( this.form.idestado )? false : true
            this.noValid.id_sector_op = ( this.form.id_sector_op )? false : true

            this.noValid.num_dependencia_op = ( this.form.num_dependencia_op )? false : true
            this.noValid.nom_dependencia_op = ( this.form.nom_dependencia_op )? false : true
            this.noValid.nom_corto_dependencia_op = ( this.form.nom_corto_dependencia_op )? false : true

            const valnom_dependencia_op = await getDataByAnyDependencia_op( 'cat_dependencias_op', 'nom_dependencia_op', this.form.nom_dependencia_op );
            
            if ( valnom_dependencia_op.length < 1 ){
                if ( !this.noValid.idestado) {
                    if ( !this.noValid.id_sector_op) {
                        if ( !this.noValid.num_dependencia_op) {
                            if ( !this.noValid.nom_dependencia_op) {
                                if ( !this.noValid.nom_corto_dependencia_op) {
                                    const dataForm = {
                                        idestado: this.form.idestado,
                                        id_sector_op: this.form.id_sector_op,
                                        num_dependencia_op: this.form.num_dependencia_op,
                                        nom_dependencia_op: this.form.nom_dependencia_op,
                                        nom_corto_dependencia_op: this.form.nom_corto_dependencia_op
                                    }
                                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                                    const addDependencia = await addData('cat_dependencias_op', jsonData)
                                    if ( addDependencia[0].res.status === 200 ) {
                                        this.$notify({
                                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                                        });
                                        this.added = true
                                    } else {
                                        this.$notify({
                                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                                        });
                                    }
                                } else {
                                    this.$notify({
                                        title: 'Error', text: 'Favor de capturar el nombre corto de la Dependencia', type: 'error'
                                    });
                                }
                            } else {
                                this.$notify({
                                title: 'Error', text: 'Favor de capturar el nombre de la Dependencia', type: 'error'
                                });
                            }
                        } else {
                            this.$notify({
                                title: 'Error', text: 'Favor de capturar el número de la Dependencia', type: 'error'
                            });
                        }
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Favor de Seleccionar el tipo de sector', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de Seleccionar el estado', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una Dependencia Estatal registrada con ese nombre. Verificar los datos', type: 'error'
                });
            }
        },
    },
    async mounted() {
        this.cat_estadoArr = await this.getCat_estado()
        this.cat_sector_opArr = await this.getcat_sector_op()
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>