/* Regresa todos los datos del usuario con el id enviado de la tabla 'cat_sector_op' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getSectores_opById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_sector_op/${ id }`)
    ]

    const [ cat_sector_op ] = await Promise.all( promiseArr )

    return cat_sector_op.data
}

export default getSectores_opById