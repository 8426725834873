<template>
    <h4 class="mt-0">Actualizar información del catálogo de nivel de desagregación</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_nivel_desagregacion">Nombre del nivel de desagregación:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idNivel" name="idNivel" v-model="form.id" />
                        <input type="text" class="form-control" id="nom_nivel_desagregacion" name="nom_nivel_desagregacion" placeholder="Nombre del nivel de desagregación" v-model="form.nom_nivel_desagregacion" />
                    </div>
                </div>
                <hr>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateNivel" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateNivel" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateNivel" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getNivelesById from '@/helpers/getNivelesById'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                nom_nivel_desagregacion: '',
            }
        }
    },
    methods: {
        async getNivelData( id ) {
            const nivelArr = await getNivelesById( id )
            
            return nivelArr[0].cat_nivel_desagregacion
        },

        async setData( id ) {
            const datos = await getNivelesById( id )

            this.form.id = datos.id
            this.form.nom_nivel_desagregacion = datos.nom_nivel_desagregacion
        },

        async updateNivel() {
            const dataForm = {
                nom_nivel_desagregacion: this.form.nom_nivel_desagregacion
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_nivel_desagregacion', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>