/* Regresa todos los datos de accion_puntual con el id enviado de la tabla 'accion_puntual' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccions_puntualById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/accion_puntual/${ id }`)
    ]

    const [ accion_puntual ] = await Promise.all( promiseArr )

    return accion_puntual.data
}

export default getAccions_puntualById