<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el nivel de desagregación? </h5>
            <h6> {{ datosNivel }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getNivelesById from '@/helpers/getNivelesById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosNivel: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'cat_nivel_desagregacion', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getNivel() {
            const dataNivel = await getNivelesById( charsService.decode(this.$route.params.id) );

            const cadena = `${ dataNivel.nom_nivel_desagregacion } `;

            return cadena
        }
    },
    async mounted() {
        this.datosNivel = await this.getNivel();
        // this.getNivel()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>