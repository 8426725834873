<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <Form />
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import Form from '@/components/Metas/Form'

export default {
    components: {
        Navbar,
        Form
    }
}
</script>