/* Regresa todos los objetivos prioritarios de la vista 'v_op_serv_estatales' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getOp_serv_estatales = async() => {
    const promiseArr = [
        simejuvApi.get(`/v_op_serv_estatales?transform=1&filter=borrar,lt,1`)
    ]

    const [ v_opserv ] = await Promise.all( promiseArr )

    return v_opserv.data.v_op_serv_estatales
}

export default getOp_serv_estatales