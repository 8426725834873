/* Regresa todos los datos de los años con el id enviado de la tabla 'cat_anio' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAniosById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_anio/${ id }`)
    ]

    const [ cat_anio ] = await Promise.all( promiseArr )

    return cat_anio.data
}

export default getAniosById