<template>
    <h4>Acciones puntuales</h4>
    <hr>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar la acción puntual? </h5>
            <h6> {{ datosAccion_puntual }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getAccions_puntualById from '@/helpers/getAccions_puntualById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosAccion_puntual: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'accion_puntual', charsService.decode( this.$route.params.id ), jsonData )
            this.$router.push('../');
        },
        async getAccion_puntual() {
            const dataUser = await getAccions_puntualById(charsService.decode( this.$route.params.id ))

            const cadena = `${ dataUser.codigo_accion_puntual } - ${ dataUser.nom_accion_puntual }`;

            return cadena
        }
    },
    async mounted() {
        this.datosAccion_puntual = await this.getAccion_puntual();
        // this.getAccion_puntual()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>