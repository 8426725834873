/* Regresa todos los datos de los años con el id enviado de la tabla 'cat_tipo_ap' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getTipos_apById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_tipo_ap/${ id }`)
    ]

    const [ cat_tipo_ap ] = await Promise.all( promiseArr )

    return cat_tipo_ap.data
}

export default getTipos_apById