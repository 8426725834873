/* Regresa todos los datos de accion_sectorial con el id enviado de la tabla 'accion_sectorial' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccion_sectorialById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/accion_sectorial/${ id }`)
    ]

    const [ accion_sectorial ] = await Promise.all( promiseArr )

    return accion_sectorial.data
}

export default getAccion_sectorialById