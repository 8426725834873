/* Función que regresa el nombre corto del id enviado de la tabla 'cat_dependencias' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getDependenciaNC = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_dependencias/${ id }?transform=1&columns=id,nom_corto_dependencia`)
    ]

    const [ dep ] = await Promise.all( promiseArr )

    return dep.data.nom_corto_dependencia
}

export default getDependenciaNC