/* Regresa todos los datos de los años con el id enviado de la tabla 'cat_tipo_indicador' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getTipos_indicadorById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_tipo_indicador/${ id }`)
    ]

    const [ cat_tipo_indicador ] = await Promise.all( promiseArr )

    return cat_tipo_indicador.data
}

export default getTipos_indicadorById