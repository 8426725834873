import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/styles.css'
import Notifications from '@kyvg/vue3-notification'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueGtag from "vue-gtag-next";    //Google Analytics

const app = createApp(App)
app.use(ElementPlus)
app.use( CKEditor )
app.use(Notifications)
app.use(store)
app.use(router)
app.mount('#app')

app.use(VueGtag, {           //Google Analytics
    property: {              //Google Analytics
      id: "G-YX6GD6BEL7"     //Google Analytics
    }                        //Google Analytics
});                          //Google Analytics