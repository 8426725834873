/* Regresa todos los objetivos prioritarios de la tabla 'getAvanceAcciones' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAvanceAcciones = async( id='', verBloq=false ) => {
    let filtro = ''
    let filtroBloq = ''

    if ( id !== '' ) {
        filtro = '&filter=idaccion_puntual,eq,' + id
    }

    if ( !verBloq ) {
        filtroBloq = '&filter=bloquear,lt,1'
    }
    
    const promiseArr = [
        simejuvApi.get(`/accionp_avance?transform=1&filter=borrar,lt,1${ filtro }${filtroBloq}`)
    ]

    const [ avance ] = await Promise.all( promiseArr )

    return avance.data.accionp_avance
}

export default getAvanceAcciones