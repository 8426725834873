/* Regresa todos los datos del usuario con el id enviado de la tabla 'cat_dependencias_op' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getDependencias_opById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_dependencias_op/${ id }`)
    ]

    const [ cat_dependencias_op ] = await Promise.all( promiseArr )

    return cat_dependencias_op.data
}

export default getDependencias_opById