<template>
    <div class="content">
        <div class="row">
            <div class="col-md-12" style="backgroundColor:#F2F3F4">
                <div class="row">
                    <div class="col-md-6"  style="top:20px">
                        <div class="panel panel-warning" v-if="arrChartsNom[0] && arrChartsCant[0]">
                            <div class="panel-body text-center">
                                <h6>Intervenciones por Dependencia</h6>
                                <HorizontalBar :chartData="{
                                    labels: arrChartsNom[0],
                                    datasets: [
                                    {
                                        //backgroundColor: 'rgba(228,193,141,0.2)',   este es el bueno
                                        backgroundColor: 'rgba(142,113,70,0.3)',
                                        borderWidth: 1,
                                        //borderColor: '#8E7146',    //Si va
                                        
                                        data: arrChartsCant[0], 
                                        label: ['cantidad'] 
                                    }]
                                }" :chartOptions="state.chartOptionsHor" @on-receive="update(this)"/>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-6" style="top:15px">
                        <div class="panel panel-warning" v-if="arrChartsNom[2] && arrChartsCant[2]">
                            <div class="panel-body text-center">
                                <h6>Tipo de Intervención</h6>
                                <BarCharts :chartData="{
                                    labels: arrChartsNom[2],
                                    datasets: [
                                    {
                                        backgroundColor: '#8E7146',
                                        data: arrChartsCant[2], 
                                        label: ['cantidad'] 
                                    }]
                                }" :chartOptions="state.chartOptions" @on-receive="update(this)" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6" style="top:15px">
                        <div class="panel panel-warning" v-if="arrChartsNom[3] && arrChartsCant[3]">
                            <div class="panel-body text-center">
                                <h6>Cuenta con Normativa</h6>
                                <PieCharts :chartData="{
                                    labels: arrChartsNom[3],
                                    datasets: [
                                    {
                                        backgroundColor: ['#BC955C', '#8E7146'],
                                        data: arrChartsCant[3], 
                                        label: ['cantidad'] 
                                    }]
                                }" :chartOptions="state.chartOptionsPie" @on-receive="update(this)" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6" style="top:10px">
                        <div class="panel panel-warning" v-if="arrChartsNom[4] && arrChartsCant[4]">
                            <div class="panel-body text-center">
                                <h6>Tipo de normatividad</h6>
                                <PieCharts :chartData="{
                                    labels: arrChartsNom[4],
                                    datasets: [
                                    {
                                        backgroundColor: ['#e4c18d', '#BC955C', '#8E7146', '#574223'],
                                        data: arrChartsCant[4], 
                                        label: ['cantidad'] 
                                    }]
                                }" :chartOptions="state.chartOptionsPie" @on-receive="update(this)" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" style="top:10px">
                        <div class="panel panel-warning" v-if="arrChartsNom[5] && arrChartsCant[5]">
                            <div class="panel-body text-center">
                                <h6>Tipo de solicitante</h6>
                                <PieCharts :chartData="{
                                    labels: arrChartsNom[5],
                                    datasets: [
                                    {
                                        backgroundColor: ['#e4c18d', '#BC955C', '#8E7146', '#574223'],
                                        data: arrChartsCant[5], 
                                        label: ['cantidad'] 
                                    }]
                                }" :chartOptions="state.chartOptionsPie" @on-receive="update(this)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BarCharts from '@/components/Charts/BarCharts'
import HorizontalBar from '@/components/Charts/HorizontalBar'
import PieCharts from '@/components/Charts/PieCharts'
// import charsService from '@/services/CharsService';
import getCatDepIntervencion from '@/helpers/getCatDepIntervencion';
import getAllIntervencionesAut from '@/helpers/getAllIntervencionesAut';
//import getCatProgPresupuestario from '@/helpers/getCatProgPresupuestario';
import getCatTipoIntervencion from '@/helpers/getCatTipoIntervencion';
import getCatTipoNormatividad from '@/helpers/getCatTipoNormatividad';
import getCatTipoSolicitante from '@/helpers/getCatTipoSolicitante';

export default {
    components: {
        BarCharts,
        HorizontalBar,
        PieCharts
    },
    data() {
        return {
            catDeps: [],
            //catProPre: [],
            catTipo: [],
            catTipoNorma: [],
            catTipoSolic: [],
            arrChartsNom: [],
            arrChartsCant: [],
            state: {
                chartData: {},
                
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: true,
                    
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0 // disables bezier curves
                        },
                        point: {
                            borderColor: "#000",
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                fontSize: 12,
                                fontFamily: 'Montserrat',
                                stepSize: 1,
                                beginAtZero:true,
                                
                                //text-overflow: ellipsis,
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                beginAtZero:true,
                                autoSkip: false,
                                fontSize: 12,
                                fontFamily: 'Montserrat',
                                stepSize: 1
                            },
                        }],
                    },
                    legend: {     
                        display: false,
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            //title: () => { return '' },
                            label: (tooltipItems, data) => { return "   " + tooltipItems.yLabel; },
                        },
                        titleFontSize: 16,
                        titleFontFamily: 'Montserrat',
                        bodyFontSize: 16,
                        bodyFontFamily: 'Montserrat'
                    },
                },


                chartOptionsHor: {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    },
                    elements: {
                        line: {
                            tension: 0 // disables bezier curves
                        },
                        point: {
                            borderColor: "#2554FF",
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                fontSize: 12,
                                //fontweight: bold,
                                //style:"color:#FF0000",
                                //color:'#000000',
                                fontFamily: 'Montserrat',
                               // borderColor: '#fff',
                                stepSize: 1,
                                beginAtZero:true,
                                fullSize:true,
                                
                                //text-overflow:true,
                                mirror:'true',
                               // crossAlign:'far',
                                
                                
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                beginAtZero:true,
                                autoSkip: false,
                                fontSize: 12,
                                fontFamily: 'Montserrat',
                                stepSize: 1
                            },
                        }],
                    },
                    legend: {     
                        display: false
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            //title: () => { return '' },
                            label: (tooltipItems, data) => { return "   " + tooltipItems.xLabel; },
                        },
                        titleFontSize: 14,
                        titleFontFamily: 'Montserrat',
                        bodyFontSize: 14,
                        bodyFontFamily: 'Montserrat'
                    },
                },


                chartOptionsPie: {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        filler: {
                            propagate: true
                        },
                    },
                    elements: {
                        line: {
                            tension: 0 // disables bezier curves
                        },
                        point: {
                            borderColor: "#000",
                        }
                    },
                    legend: {     
                        display: true,
                        position: 'bottom',
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                             title: () => { return '' },
                            //label: (tooltipItems, data) => { return "   " + tooltipItems.yLabel; },
                        },
                        titleFontSize: 16,
                        titleFontFamily: 'Montserrat',
                        bodyFontSize: 16,
                        bodyFontFamily: 'Montserrat'
                    },
                },










            }
        }
    },
    methods: {
        async getCatDep() {
            const deps = await getCatDepIntervencion()

            for (let j = 0; j < deps.length; j++) {
                this.catDeps[ deps[j].id ] = deps[j].dependencia     //.substr(1,50)
            }
        },

        /*
        async getCatProPre() {
            const pp = await getCatProgPresupuestario()

            for (let j = 0; j < pp.length; j++) {
                this.catProPre[ pp[j].id ] = pp[j].programa
            }
        },
        */
        async getCatTipo() {
            const tipo = await getCatTipoIntervencion()

            for (let j = 0; j < tipo.length; j++) {
                this.catTipo[ tipo[j].id ] = tipo[j].tipo_intervencion
            }
        },

        async getCatTipoNorma() {
            const tipoNorma = await getCatTipoNormatividad()

            for (let j = 0; j < tipoNorma.length; j++) {
                this.catTipoNorma[ tipoNorma[j].id ] = tipoNorma[j].tipo_normatividad
            }
        },

        async getCatTipoSolic() {
            const tipoSolic = await getCatTipoSolicitante()

            for (let j = 0; j < tipoSolic.length; j++) {
                this.catTipoSolic[ tipoSolic[j].id ] = tipoSolic[j].tipo_solicitante
            }
        },

        async allIntervensiones() {
            const regs = await getAllIntervencionesAut()
            const newRegs = regs[0].intervenciones
            
            let arrDeps = [];
            let arrPP = [];
            let arrTipo = [];
            let arrNorma = [];
            let arrTipoNorma = [];
            let arrTipoSolic = [];

            for (let j = 0; j < newRegs.length; j++) {
               arrDeps.push( this.catDeps[ newRegs[j].dependencia ] )
               //arrPP.push( this.catProPre[ newRegs[j].cvePrograma ] )
               arrTipo.push( this.catTipo[ newRegs[j].tipoIntervencion ] )
               arrNorma.push( newRegs[j].normatividad )
               arrTipoNorma.push( this.catTipoNorma[ newRegs[j].tipoNormatividad ] )
               arrTipoSolic.push( this.catTipoSolic[ newRegs[j].tipoSolicitante ] )
            }

            const arrUnicosDeps = arrDeps.reduce((prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev), {})

            const arrDepsNom = Object.keys(arrUnicosDeps);
            const arrDepsCant = Object.values(arrUnicosDeps);

            this.arrChartsNom.push(arrDepsNom)
            this.arrChartsCant.push(arrDepsCant)


            const arrUnicosPP = arrPP.reduce((prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev), {})

            const arrPPNom = Object.keys(arrUnicosPP);
            const arrPPCant = Object.values(arrUnicosPP);

            this.arrChartsNom.push(arrPPNom)
            this.arrChartsCant.push(arrPPCant)

            const arrUnicosTipo = arrTipo.reduce((prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev), {})

            const arrTipoNom = Object.keys(arrUnicosTipo);
            const arrTipoCant = Object.values(arrUnicosTipo);

            this.arrChartsNom.push(arrTipoNom)
            this.arrChartsCant.push(arrTipoCant)

            const arrUnicosNorma = arrNorma.reduce((prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev), {})

            const arrNormaNom = Object.keys(arrUnicosNorma);
            const arrNormaCant = Object.values(arrUnicosNorma);

            this.arrChartsNom.push(arrNormaNom)
            this.arrChartsCant.push(arrNormaCant)

            const arrUnicosTipoNorma = arrTipoNorma.reduce((prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev), {})

            const arrTipoNormaNom = Object.keys(arrUnicosTipoNorma);
            const arrTipoNormaCant = Object.values(arrUnicosTipoNorma);

            this.arrChartsNom.push(arrTipoNormaNom)
            this.arrChartsCant.push(arrTipoNormaCant)

            const arrUnicosTipoSolic = arrTipoSolic.reduce((prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev), {})

            const arrTipoSolicNom = Object.keys(arrUnicosTipoSolic);
            const arrTipoSolicCant = Object.values(arrUnicosTipoSolic);

            this.arrChartsNom.push(arrTipoSolicNom)
            this.arrChartsCant.push(arrTipoSolicCant)
        },


    },
    async mounted() {
        this.getCatDep()
        //this.getCatProPre()
        this.getCatTipo()
        this.getCatTipoNorma()
        this.getCatTipoSolic()
        this.allIntervensiones()
    }
}
</script>

<style scoped>
    .subTitle {
        font-weight: normal;
    }

    .article-body>.row>p {
        padding: 10px 15px 10px 0;
    }

    .meta {
        width: 100%;
        margin: 0;
    }

    h1.meta{
        margin-bottom: 20px;
    }
    
    .chart {
        padding-top: 20px;
    }

    img {
        width: 1057.5px;
        height: 1246.88px;
    }

    .link1 {
        position: absolute;
        right: 112px;
        top: 25px;
        color: transparent;
    }

    .link2{
        position: absolute;
        left: 54px;
        top: 527px;
        color: transparent;
    }

    .separar {
        padding: 20px 10px;
        display: block !important;
        margin-bottom: 50px;
    }

    .titMeta {
        display: inline-block;
        font-size: small;
        line-height: 20px;
    }

    .descMeta {
        font-size: 10pt;
    }

    .subtBold {
        font-size: 10pt;
        font-weight: bold;
    }

    .meta2024val {
        font-size: 24pt;
        margin-top: 0 !important;
        font-weight: bold;
    }

    .grafica {
        width: 100%;
        height: auto;
    }

    .gral {
        float: none;
    }

    .chartjs-render-monitor {
        display: block;
        width: 645px;
        height: 321px;
    }
</style>