<template>
    <h4>Avance de acciones puntuales a nivel de objetivo prioritario</h4>
    <hr>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el avance de la accion puntual a nivel del objetivo prioritario? </h5>
            <h6> {{ datosAccionp_av_objetivo }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getAccionp_av_objetivoById from '@/helpers/getAccionp_av_objetivoById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosAccionp_av_objetivo: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'accionp_av_objetivo', charsService.decode( this.$route.params.id ), jsonData )
            this.$router.push('../');
        },
        async getAccionp_av_objetivo() {
            const dataUser = await getAccionp_av_objetivoById(charsService.decode( this.$route.params.id ))

            const cadena = `${ dataUser.fecha_avance } - ${ dataUser.avance_resumen }`;

            return cadena
        }
    },
    async mounted() {
        this.datosAccionp_av_objetivo = await this.getAccionp_av_objetivo();
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>