<template>
    <h4 class="mt-0">Agregar una unidad de medida</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre de la unidad de medida:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idUnidad_medida" name="idUnidad_medida" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_unidad_medida }" id="nom_unidad_medida" name="nom_unidad_medida" placeholder="Nombre de la unidad de medida" v-model="form.nom_unidad_medida" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUnidad_medida" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUnidad_medida" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra unidad de medida" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addUnidad_medida" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import addData from '@/helpers/addData'
import getDataByAnyUnidad_medida from '@/helpers/getDataByAnyUnidad_medida'

export default {
    data() {
        return {
            form: {
                nom_unidad_medida: ''
            },
            noValid: {
                nom_unidad_medida: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nom_unidad_medida = ''
            this.added = false
        },
        async addUnidad_medida() {
            this.noValid.nom_unidad_medida = ( this.form.nom_unidad_medida )? false : true

            const valNom_unidad_medida = await getDataByAnyUnidad_medida( 'cat_unidad_medida', 'nom_unidad_medida', this.form.nom_unidad_medida );
            
            if ( valNom_unidad_medida.length < 1 ){
                if ( !this.noValid.nom_unidad_medida) {
                    const dataForm = {
                        nom_unidad_medida: this.form.nom_unidad_medida
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addUnidad_medida = await addData('cat_unidad_medida', jsonData)
                    //if ( addUnidad_medida[0].res && addUnidad_medida[0].res > 0 ) {
                    if ( addUnidad_medida[0].res.status === 200 ) {    
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre de la unidad de medida', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una unidad de medida registrada con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>