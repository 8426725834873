<template>
    <h3>Registro de metas y parámetros</h3>
    <hr>
    <form role="form">
        <div class="form-group row">
            <h4>Datos generales de la meta o parámetro</h4>
            <hr>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="tipo_indicador">Tipo de indicador:</label>
            <div class="col-md-9 col-sm-12">
                <select class="form-control" name="tipo_indicador" id="tipo_indicador">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in catTipoInd" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="codigo_meta">Código de la meta:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="codigo_meta" placeholder="Código de la meta" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="nombre">Nombre:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="nombre" placeholder="Nombre" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="objetivo_prioritario">Objetivo prioritario:</label>
            <div class="col-md-9 col-sm-12">
                <select class="form-control" name="objetivo_prioritario" id="objetivo_prioritario">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in objPri" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="definicion">Definición o descripción:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="definicion" placeholder="Definición o descripción" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="desagregacion">Nivel de desagregación:</label>
            <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                <select class="form-control" name="desagregacion" id="desagregacion">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in nivDesag" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="frecuencia">Periodicidad o frecuencia de medición:</label>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <select class="form-control" name="frecuencia" id="frecuencia">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in perioricidad" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="tipo">Tipo:</label>
            <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                <select class="form-control" name="tipo" id="tipo">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in tipo" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="acumulado_periodico">Acumulado o periódico:</label>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <select class="form-control" name="acumulado_periodico" id="acumulado_periodico">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in acumPerio" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="um">Unidad de medida:</label>
            <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                <select class="form-control" name="um" id="um">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in um" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="recoleccion">Periodo de recolección de datos:</label>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <select class="form-control" name="recoleccion" id="recoleccion">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in recoleccion" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="dimension">Dimensión:</label>
            <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                <select class="form-control" name="dimension" id="dimension">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in dimension" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="disponibilidad">Disponibilidad de la información:</label>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <select class="form-control" name="disponibilidad" id="disponibilidad">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in meses" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="tendencia">Tendencia esperada:</label>
            <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                <select class="form-control" name="tendencia" id="tendencia">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in tendencia" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
            <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="u_responsable">Unidad Responsable de reportar el avance:</label>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <select class="form-control" name="u_responsable" id="u_responsable">
                     <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in responsable" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="metodo">Método de cálculo:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="metodo" placeholder="Método de cálculo" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="tipo_formula">Tipo de fórmula:</label>
            <div class="col-md-9 col-sm-12">
                <select class="form-control" name="tipo_formula" id="tipo_formula">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in formula" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="nombre_a">Nombre de la variable A:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="nombre_a" placeholder="Nombre de la variable A" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="nombre_b">Nombre de la variable B:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="nombre_b" placeholder="Nombre de la variable B" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="nombre_b">Observaciones:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="observaciones" placeholder="Observaciones" type="text">
            </div>
        </div>

        <div class="form-group row">
            <h4>Línea de base</h4>
            <hr>
        </div>
        <div class="form-group row">
            <div class="col-lg-6 col-md-12">
                <div class="form-group row">
                    <h5>Variable A</h5>
                    <hr>
                </div>
                <div class="form-group row">
                    <label class="col-lg-6 col-md-12 col-form-label" for="var_a_nombre">Nombre de la variable:</label>
                    <div class="col-lg-6 col-md-12">
                        <input class="form-control" id="var_a_nombre" placeholder="Nombre de la variable" type="text" disabled>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-6 col-md-12 col-form-label" for="var_a_valor">Valor:</label>
                    <div class="col-lg-6 col-md-12">
                        <input class="form-control" id="var_a_valor" placeholder="Valor" type="text">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-6 col-md-12 col-form-label" for="var_a_fuente">Fuente de información:</label>
                    <div class="col-lg-6 col-md-12">
                        <input class="form-control" id="var_a_fuente" placeholder="Fuente de información" type="text">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-6 col-md-12 col-form-label" for="var_a_url">URL:</label>
                    <div class="col-lg-6 col-md-12">
                        <input class="form-control" id="var_a_url" placeholder="URL" type="text">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="form-group row">
                    <h5>Variable B</h5>
                    <hr>
                </div>
                <div class="form-group row">
                    <label class="col-lg-6 col-md-12 col-form-label" for="var_b_nombre">Nombre de la variable:</label>
                    <div class="col-lg-6 col-md-12">
                        <input class="form-control" id="var_b_nombre" placeholder="Nombre de la variable" type="text" disabled>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-6 col-md-12 col-form-label" for="var_b_valor">Valor:</label>
                    <div class="col-lg-6 col-md-12">
                        <input class="form-control" id="var_b_valor" placeholder="Valor" type="text">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-6 col-md-12 col-form-label" for="var_b_fuente">Fuente de información:</label>
                    <div class="col-lg-6 col-md-12">
                        <input class="form-control" id="var_b_fuente" placeholder="Fuente de información" type="text">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-6 col-md-12 col-form-label" for="var_b_url">URL:</label>
                    <div class="col-lg-6 col-md-12">
                        <input class="form-control" id="var_b_url" placeholder="URL" type="text">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="anio_lb">Año de la línea base:</label>
            <div class="col-md-9 col-sm-12">
                <select class="form-control" name="anio_lb" id="anio_lb">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in anio_lb" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="nota_lb">Nota sobre la línea base:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="nota_lb" placeholder="Nota sobre la línea base" type="text">
            </div>
        </div>

        <div class="form-group row">
            <h4>Meta 2024</h4>
            <hr>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="valor_meta">Valor de la Meta:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="valor_meta" placeholder="Valor de la Meta" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="nota_meta">Nota sobre la meta:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="nota_meta" placeholder="Nota sobre la meta" type="text">
            </div>
        </div>

        <div class="form-group row">
            <h4>Serie histórica de la meta para el bienestar o parámetro</h4>
            <hr class="hrCond">
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" for="val_2012">2012:</label>
            <div class="col-md-2">
                <input class="form-control midSep" id="val_2012" placeholder="2012" type="text">
            </div>
            <label class="col-md-2 alignCond" for="val_2013">2013:</label>
            <div class="col-md-2">
                <input class="form-control midSep" id="val_2013" placeholder="2013" type="text">
            </div>
            <label class="col-md-2 alignCond" for="val_2014">2014:</label>
            <div class="col-md-2">
                <input class="form-control" id="val_2014" placeholder="2014" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" for="val_2015">2015:</label>
            <div class="col-md-2">
                <input class="form-control midSep" id="val_2015" placeholder="2015" type="text">
            </div>
            <label class="col-md-2 alignCond" for="val_2016">2016:</label>
            <div class="col-md-2">
                <input class="form-control midSep" id="val_2016" placeholder="2016" type="text">
            </div>
            <label class="col-md-2 alignCond" for="val_2017">2017:</label>
            <div class="col-md-2">
                <input class="form-control" id="val_2017" placeholder="2017" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" for="val_2018">2018:</label>
            <div class="col-md-2">
                <input class="form-control midSep" id="val_2018" placeholder="2018" type="text">
            </div>
            <label class="col-md-2 alignCond" for="val_2019">2019:</label>
            <div class="col-md-2">
                <input class="form-control midSep" id="val_2019" placeholder="2019" type="text">
            </div>
            <label class="col-md-2 alignCond" for="val_2020">2020:</label>
            <div class="col-md-2">
                <input class="form-control" id="val_2020" placeholder="2020" type="text">
            </div>
        </div>

        <div class="form-group row">
            <h4>Metas intermedias</h4>
            <hr>
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" for="val_2021">2021:</label>
            <div class="col-md-2">
                <input class="form-control midSep" id="val_2021" placeholder="2021" type="text">
            </div>
            <label class="col-md-2 alignCond" for="val_2022">2022:</label>
            <div class="col-md-2">
                <input class="form-control midSep" id="val_2022" placeholder="2022" type="text">
            </div>
            <label class="col-md-2 alignCond" for="val_2023">2023:</label>
            <div class="col-md-2">
                <input class="form-control" id="val_2023" placeholder="2023" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" for="val_2024">2024:</label>
            <div class="col-md-2">
                <input class="form-control" id="val_2024" placeholder="2024" type="text">
            </div>
        </div>


        <div class="form-group row divBtn">
            <div class="col-12 text-right">
                <button class="btn btn-primary active btnCond" type="button">Guardar</button>
            </div>
        </div>
            
    </form>
    <hr>
</template>

<script>
export default {
    data() {
        const catTipoInd = [
            {id: 1, desc: 'Meta'},
            {id: 2, desc: 'Parámetro'}
        ]

        const objPri = [
            {id: 1, desc: 'Promover la participación de las personas jóvenes en los asuntos públicos para incidir en el proyecto de nación.'},
            {id: 2, desc: 'Disminuir la discriminación entre y hacia las juventudes para impulsar su inclusión e igualdad efectiva de oportunidades.'},
            {id: 3, desc: 'Promover el derecho de las personas jóvenes a una vida en paz y libre de violencia para posibilitar el pleno desarrollo de su proyecto de vida.'},
            {id: 4, desc: 'Promover el ejercicio de los derechos sociales de las personas jóvenes como condición para alcanzar su bienestar.'},
            {id: 5, desc: 'Facilitar los procesos de emancipación y construcción de autonomía de las personas jóvenes para fortalecer la continuidad de su curso de vida.'},
            {id: 6, desc: 'Promover la generación de entornos adecuados y habitables, aptos para el desarrollo integral de las personas jóvenes.'}
        ]

        const nivDesag = [
            {id: 1, desc: 'Nacional'},
            {id: 2, desc: 'Personas jóvenes'},
            {id: 3, desc: 'Población de 15 a 29 años'},
            {id: 4, desc: 'Mujeres jóvenes de 15 a 29 años'},
            {id: 5, desc: 'Personas jóvenes de 12 a 29 años'},
            {id: 6, desc: 'Personas jóvenes de 15 a 29 años'},
            {id: 7, desc: 'Personas jóvenes de 18 a 29 años'}
        ]

        const perioricidad = [
            {id: 1, desc: 'Anual'},
            {id: 2, desc: 'Bienal'},
            {id: 3, desc: 'Trienal'},
            {id: 4, desc: 'Cuatrienal'},
            {id: 5, desc: 'Quinquenal'}
        ]

        const tipo = [
            {id: 1, desc: 'Estratégico'}
        ]

        const acumPerio = [
            {id: 1, desc: 'Acumulado'},
            {id: 2, desc: 'Periódico'}
        ]

        const um = [
            {id: 1, desc:'Eficiencia'},
            {id: 2, desc:'Porcentaje'},
            {id: 3, desc:'Recursos presupuestarios'},
            {id: 4, desc:'Tasa'}
        ]

        const recoleccion = [
            {id: 1, desc:'Otros'}
        ]

        const dimension = [
            {id: 1, desc:'Eficacia'}
        ]

        const meses = [
            {id: 1, desc:'enero'},
            {id: 2, desc:'febrero'},
            {id: 3, desc:'marzo'},
            {id: 4, desc:'abril'},
            {id: 5, desc:'mayo'},
            {id: 6, desc:'junio'},
            {id: 7, desc:'julio'},
            {id: 8, desc:'agosto'},
            {id: 9, desc:'septiembre'},
            {id: 10, desc:'octubre'},
            {id: 11, desc:'noviembre'},
            {id: 12, desc:'diciembre'}
        ]

        const tendencia = [
            {id: 1, desc:'Ascendente'},
            {id: 2, desc:'Descendente'}
        ]

        const responsable = [
            {id: 1, desc:'Instituto Mexicano de la Juventud'}
        ]

        const formula = [
            {id: 1, desc:'(a/b)*100'},
            {id: 2, desc:'a-b'},
            {id: 3, desc:'a/b'},
            {id: 4, desc:'a'}
        ]

        const anio_lb = [
            {id: 2021, desc: 2021},
            {id: 2022, desc: 2022},
            {id: 2023, desc: 2023},
            {id: 2024, desc: 2024}
        ]

        return {
            catTipoInd, objPri, nivDesag, perioricidad, tipo, acumPerio, um, recoleccion, dimension, meses, tendencia, responsable, formula, anio_lb
        }
    }
}
</script>

<style scoped>
    h4, h5 {
        color: #9F2241;
    }

    .alignCond {
        text-align: right;
    }

    .divBtn {
        padding: 50px 0;
    }

    @media screen and (max-width: 992px) {
        .midSep {
            margin-bottom: 15px;
        }
    }

    @media screen and (max-width: 768px) {
        .hrCond {
            display: none;
        }

        .alignCond {
            text-align: left;
        }

        .btnCond {
            display: block;
            width: 100%;
        }
    }

    form {
        padding-top: 0;
    }
</style>