<template>
    <h4 class="mt-0">Agregar un Estado</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Número del estado:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idEstado" name="idEstado" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.num_estado }" id="num_estado" name="num_estado" placeholder="Número de la estado" v-model="form.num_estado" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_Estado">Nombre del estado:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_estado }" id="nom_estado" name="nom_estado" placeholder="Nombre del estado" v-model="form.nom_estado" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateEstado" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateEstado" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otro estado" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addEstado" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import addData from '@/helpers/addData'
import getDataByAnyEstado from '@/helpers/getDataByAnyEstado'

export default {
    data() {
        return {
            form: {
                num_estado: '',
                nom_estado: ''
            },
            noValid: {
                num_estado: false,
                nom_estado: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.num_estado = ''
            this.form.nom_estado = ''
            this.added = false
        },
        async addEstado() {
            this.noValid.num_estado = ( this.form.num_estado )? false : true
            this.noValid.nom_estado = ( this.form.nom_estado )? false : true

            const valNom_estado = await getDataByAnyEstado( 'cat_estado', 'nom_estado', this.form.nom_estado );
            
            if ( valNom_estado.length < 1 ){
                if ( !this.noValid.num_estado) {
                    if ( !this.noValid.nom_estado) {
                        const dataForm = {
                            num_estado: this.form.num_estado,
                            nom_estado: this.form.nom_estado,
                            nom_corto_estado: this.form.nom_corto_estado
                        }
                        const jsonData = JSON.parse(JSON.stringify( dataForm ))
                        const addEstado = await addData('cat_estado', jsonData)
                        if ( addEstado[0].res.status === 200 ) {
                            this.$notify({
                                title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                            });
                            this.added = true
                        } else {
                            this.$notify({
                                title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                            });
                        }
                    } else {
                        this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre del estado', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el número del estado', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe un estado registrado con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>