<template>
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
        <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
        <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
        <li class="breadcrumb-item active" aria-current="page"><a href="/projuventud">PROJUVENTUD 2021-2024</a></li>
    </ol>
    <h2>PROJUVENTUD 2021-2024</h2>
    <hr class="red">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
            <router-link class="nav-link active" to="/projuventud/acciones">Regresar</router-link>
        </li>
    </ul>
    <div class="container">
        <h3>Registro de avance de acciones puntuales</h3>
        <hr>
        <p><strong>Acción puntual {{ thisAction.codigo_accion_puntual }}: </strong> {{ thisAction.nom_accion_puntual }}</p>
    </div>
    <div class="container mb-5">
        <div class="row">
            <div class="col-md-6 text-left"><h4>Registro de avance</h4></div>
            <div class="col-md-6 text-right"></div>
        </div>
        <div class="card">
            <div class="card-body">
                <form>
                    <div class="form-group row">
                        <label class="col-md-5 col-form-label" for="sReporte">No se reporta avance</label>
                        <div class="col-md-7">
                            <input type="checkbox" name="sReporte" id="sReporte" v-model="form.sReporte" @click="disableInputs">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label class="col-md-5 col-form-label" for="fecha_reporte">Fecha en que se reporta el avance</label>
                        <div class="col-md-7">
                            <div class="row">    
                                <div class="col-md-6">
                                    <!-- <Datepicker v-model="form.fecha_reporte" lang="es" name="fecha_reporte" id="fecha_reporte" /> -->
                                    <Datepicker v-model="form.fecha_reporte" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatReporte" name="fecha_reporte" id="fecha_reporte" placeholder="Seleccione fecha" class="datepicker"></Datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-5 col-form-label">Periodo que cubre el avance</label>
                        <div class="col-md-7">
                            <div class="row">
                                <label class="col-md-6 col-form-label" for="inicio_p">Inicio</label>
                                <label class="col-md-6 col-form-label" for="fin_p">Fin</label>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <!-- <Datepicker lang="es" name="inicio_p" id="inicio_p" v-model="form.inicio_p" :disabled='isDisabled' /> -->
                                    <Datepicker v-model="form.inicio_p" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatInicio" name="inicio_p" id="inicio_p" placeholder="Seleccione fecha" class="datepicker"></Datepicker>
                                </div>
                                <div class="col-md-6">
                                    <!-- <Datepicker lang="es" name="fin_p" id="fin_p" v-model="form.fin_p" :disabled='isDisabled' /> -->
                                    <Datepicker v-model="form.fin_p" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatFin" name="fin_p" id="fin_p" placeholder="Seleccione fecha" class="datepicker"></Datepicker>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group row">
                        <div v-show="form.sReporte==false"> 
                            <label class="col-md-5 col-form-label" for="desc_avance">Resumen de la actividad</label>
                        </div>
                        <div v-show="form.sReporte==true">    
                            <label class="col-md-5 col-form-label" for="desc_avance">Justificación del avance</label>
                        </div>
                        <div class="col-md-7">
                            <textarea class="form-control" name="desc_avance" id="desc_avance" v-model="form.desc_avance" placeholder="Registre" @keyup="conteo_real(form.desc_avance, 'desc_avance')"></textarea>
                            <div id="desc_avance_count" class="noChars"></div>
                            <!-- <ckeditor :editor="editor" v-model="form.desc_avance" :config="editorConfig" id="desc_avance" @input="conteo_real(form.desc_avance, 'desc_avance')"></ckeditor>
                            <div id="desc_avance_count" class="noChars"></div> -->
                        </div>
                    </div>
                    <div v-show="form.sReporte==false">
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="desc_det_avance">Descripción detallada del avance</label>
                            <div class="col-md-7">
                                <textarea class="form-control" name="desc_det_avance" id="desc_det_avance" v-model="form.desc_det_avance" placeholder="Descripción detallada del avance" :disabled='isDisabled' @keyup="conteo_real(form.desc_det_avance, 'desc_det_avance')"></textarea>
                                <div id="desc_det_avance_count" class="noChars"></div>
                                <!-- <ckeditor :editor="editor" v-model="form.desc_det_avance" :config="editorConfig" id="desc_det_avance" @input="conteo_real(form.desc_det_avance, 'desc_det_avance')"></ckeditor>
                                <div id="desc_det_avance_count" class="noChars"></div> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="cant_jovenes">Cantidad de jóvenes impactados</label>
                            <div class="col-md-2">
                                <input class="form-control" type="number" min="0" name="cant_jovenes" id="cant_jovenes" v-model="form.cant_jovenes" placeholder="Cantidad de jóvenes impactados" :disabled='isDisabled||isDisabledJ' onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57">
                            </div>
                            <div class="col-md-3">
                                <input type="checkbox" name="cant_jovenes_nd" id="cant_jovenes_nd" v-model="form.cant_jovenes_nd" @click="isDisabledJov" :disabled='isDisabled'> Dato no disponible
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="estado">Cobertura (estados)</label>
                            <div class="col-md-4">
                                <select class="form-control" multiple="multiple" name="coberturaEstados" id="coberturaEstados" v-model="form.coberturaEstados" size="10" :disabled='isDisabled'>
                                    <option value="" selected>[Seleccione]</option>
                                    <option v-for="e in catEstados" :key="e.id" :value="e.id" :selected="this.form.arrEstados.includes(e.id.toString())">{{ e.nc }}</option>
                                </select>
                                <div><small>Para seleccionar varios presione la tecla Mayus o Ctrl</small></div>
                            </div>
                        </div>

                    </div> <!--De v-show -->     
                        
                    <div class="form-group row">
                        <label class="col-md-5 col-form-label" for="dependencia">Dependencia</label>
                        <div class="col-md-4">
                            <select class="form-control" name="dependencia" id="dependencia" disabled v-model="form.dependencia">
                                <option v-for="e in catDependencias" :key="e.id" :value="e.id">{{ e.nc }}</option>
                            </select>
                        </div>
                    </div>
                        
                    <div v-show="form.sReporte==false">
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="colaboracion_otras_dep">En colaboración con otras dependencias</label>
                            <div class="col-md-7" id="colabDeps">
                                <input type="radio" value="Si" name="colaboracion_otras_dep" id="colaboracion_otras_dep_si" v-model="form.colaboracion_otras_dep" :disabled='isDisabled' @click="isOtrasDep"> Si &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <input type="radio" value="No" name="colaboracion_otras_dep" id="colaboracion_otras_dep_no" v-model="form.colaboracion_otras_dep" :disabled='isDisabled' @click="isOtrasDep"> No
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="cualesDependencias">Cuáles</label>
                            <div class="col-md-4">
                                <select class="form-control" multiple="multiple" name="cualesDependencias" id="cualesDependencias" v-model="form.cualesDependencias" :disabled='disOtrasDep' size="10" @change="otrasSelected">
                                    <option value="" selected>[Seleccione]</option>
                                    <option v-for="e in catDependencias" :key="e.id" :value="e.id" :selected="this.form.arrCuales.includes(e.id.toString())">{{ e.nc }}</option>
                                </select>
                                <br />
                                <input type="text" class="form-control" name="otrasDependencias" id="otrasDependencias" v-model="form.otrasDependencias" :disabled='isOtrasSelected' placeholder="Otras dependencias" />
                                <div><small>Para seleccionar varios presione la tecla Mayus o Ctrl</small></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="colaboracion_org_int">En colaboración con organismos internacionales</label>
                            <div class="col-md-7" id="colabOI">
                                <input type="radio" value="Si" name="colaboracion_org_int" id="colaboracion_org_int_si" v-model="form.colaboracion_org_int" :disabled='isDisabled'> Si &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <input type="radio" value="No" name="colaboracion_org_int" id="colaboracion_org_int_no" v-model="form.colaboracion_org_int" :disabled='isDisabled'> No
                            </div>
                        </div>


                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="archivo">Situación de vulnerabilidad</label>
                            <div class="col-md-7">
                                <input type="checkbox" name="indigena" id="indigena" v-model="form.indigena" :checked="form.indigena" :disabled='isDisabled'> Indígenas/Afromexicanas
                            </div>
                            <div class="col-md-7">
                                <input type="checkbox" name="discapacidad" id="discapacidad" v-model="form.discapacidad" :checked="form.discapacidad" :disabled='isDisabled'> Con Discapacidad
                            </div>

                        </div>
                        


                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="web">Sitio web de referencia</label>
                            <div class="col-md-4">
                                <input class="form-control" type="url" name="web" id="web" v-model="form.web" placeholder="Sitio web de referencia" :disabled='isDisabled'>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="archivo">Archivo de evidencia (Medios de verificación)</label>
                            <div class="col-md-7">
                                <div v-if="successAlert" class="alert alert-success alert-dismissible">
                                    <a href="#" class="close" aria-label="close" @click="successAlert=false">&times;</a>
                                    {{ successMessage }}
                                </div>
                                <div v-if="errorAlert" class="alert alert-danger alert-dismissible">
                                    <a href="#" class="close" aria-label="close" @click="errorAlert=false">&times;</a>
                                    {{ errorMessage }}
                                </div>
                                <input type="file" ref="file" :disabled='isDisabled' id="fileUp" />
                                <br>
                                <button type="button" @click="uploadFile" class="btn btn-default btn-sm" :disabled='isDisabled'>Almacenar archivo</button>
                            </div>
                        </div>
                    </div> <!--De v-show -->
                    <div v-show="false"> <!--Este apartado se eliminará definitivamente despues de estar completamente seguros de que asi debe funcionar el sistema -->
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="archivo">Bloquear publicación</label>
                            <div class="col-md-7">
                                <input type="checkbox" name="bloquear" id="bloquear" v-model="form.bloquear">
                            </div>
                        </div>
                    </div> <!--De v-show -->    

                    <div v-show="form.sReporte==false">
                        <div class="form-group row">
                            <div class="col-md-5"></div>
                            <div class="col-md-7">
                                <table id="tblFiles" class="table table-striped">
                                    <thead class="table-striped">
                                        <tr>
                                            <th>Nombre del archivo</th>
                                            <th colspan="2">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr v-for="(e, index) in arrArchivos" :key="e">
                                            <td><a :href="`${ ubApi }/download.php?tmpFileName=${ window.btoa(encodeURIComponent(e.tmp_name)) }&fileName=${ window.btoa(encodeURIComponent(e.file_name)) }`" target="_blank" title="Descargar"><img :src="require(`@/assets/images/icons/${ e.extension }.svg`)" class="iconImg" /> {{ e.file_name }}</a></td>
                                            <td><a :href="`${ ubApi }/download.php?tmpFileName=${ window.btoa(encodeURIComponent(e.tmp_name)) }&fileName=${ window.btoa(encodeURIComponent(e.file_name)) }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download-alt"></i></a></td>
                                            <td><a href="#" @click="deleteFile( e.id, index)" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a></td>
                                        </tr> -->
                                        <tr v-for="(e, index) in arrArchivos" :key="e">
                                            <!-- <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><img :src="require(`@/assets/images/icons/${ e.extension }.svg`)" class="iconImg" /> {{ e.file_name_dec }}</a></td>
                                            <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download-alt"></i></a></td>
                                            <td><a href="#" @click="deleteFile( e.id, index)" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a></td> -->
                                            <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><img :src="require(`@/assets/images/icons/${ e.extension }.svg`)" class="iconImg" /> {{ e.file_name_dec }}</a></td>
                                            <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download-alt"></i></a></td>
                                            <td><a href="#" @click="deleteFile( e.id, index)" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> <!--De v-show -->  
                    

                    <div v-show="form.sReporte==false">
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="desc_det_avance"><small>De acuerdo con el ejemplo y con la información que nos estas proporcionando, ayúdanos a reportar tu actividad, considera que la redacción deberá ser breve, concreta, en tiempo pasado y resaltando la actividad, cantidad de jóvenes impactados, cobertura y en su caso si aplica menciona la colaboración con otras dependencias ó la colaboración con Organismos Internacionales.</small></label>
                            <label class="col-md-7 col-form-label" for="desc_det_avance">Ejemplo:<br>
                                <small>Desde el IMJUVE se realizó la Jornada Nacional de Perspectiva de Juventudes, en la que se capacitó a más de 7,000 personas funcionarias de la administración pública, entre las que destacan funcionarias de la Secretaría de la Defensa Nacional y servidoras y servidores públicos de las 32 entidades federativas, la capacitación en esta perspectiva tiene como objetivo central el que las entidades de forma cotidiana diseñen, implementen y evalúen políticas públicas con un impacto positivo para las personas jóvenes independientemente de que les tengan como beneficiarias directas, es decir, que se logre la transversalización de la perspectiva de juventudes.</small>
                            </label>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="desc_det_avance">Propuesta de reporte</label>
                            <div class="col-md-7">
                                <textarea class="form-control" name="desc_det_avance" id="desc_det_avance" v-model="form.desc_det_avance" placeholder="Descripción detallada del avance" :disabled='isDisabled' @keyup="conteo_real(form.desc_det_avance, 'desc_det_avance')"></textarea>
                                <div id="desc_det_avance_count" class="noChars"></div>
                                <!-- <ckeditor :editor="editor" v-model="form.desc_det_avance" :config="editorConfig" id="desc_det_avance" @input="conteo_real(form.desc_det_avance, 'desc_det_avance')"></ckeditor>
                                <div id="desc_det_avance_count" class="noChars"></div> -->
                            </div>
                        </div>
                    </div>


                </form>





            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-6 text-left">
                        <router-link class="btn btn-default btn-sm active btnCond mb-4" to="/projuventud/acciones">Regresar</router-link>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-primary btn-sm active btnCond mb-4" @click="sendForm" :disabled="saveDisabled"><i class="glyphicon glyphicon-saved"></i> Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script scoped>
import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'
import '/src/assets/css/vue-datepicker/main.css'

import getEstadosNc from '@/helpers/getEstadosNc'
import getDependenciasNc from '@/helpers/getDependenciasNc'
import insertData from '@/helpers/addData'
import getArchivos from '@/helpers/getArchivos'
import updateData from '@/helpers/updateData'
import getArchivosAvance from '@/helpers/getArchivosAvance'
import getAccionPuntualById from '@/helpers/getAccionPuntualById'
import getArchivosTmp from '@/helpers/getArchivosTmp'
import axios from 'axios'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'
import sendMailService from '@/services/SendMailService'
import getUsersById from '@/helpers/getUsersById';

export default {
    components: {
        Datepicker,
    },
    data() {
        return {
            ubApi: ubApi.value,
            acciones: [],
            thisAction: [],
            disOtrasDep: '',
            desc_avance: '',
            cant_jovenes: '',
            catEstados: [],
            web: '',
            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,
            uploadedImage: '',
            catDependencias: [],
            fecha_reporte: new Date(),
            inicio_p: new Date().toLocaleDateString("es-MX").substr(0, 10),
            fin_p: new Date().toLocaleDateString("es-MX").substr(0, 10),
            newId: '',
            arrFilesUploaded: [],
            isOtrasSelected: true,

            form: {
                sReporte: false,
                //justificacion: '',
                fecha_reporte: '',
                inicio_p: '',
                fin_p: '',
                arrEstados: [],
                desc_avance: '',
                desc_det_avance: '',
                cant_jovenes: '',
                coberturaEstados: [],
                dependencia: charsService.decode(localStorage.getItem('dependencia')),
                cualesDependencias: [],
                otrasDependencias: '',
                indigena: '',
                discapacidad: '',
                web: '',
                bloquear: '',
                accion_puntual: charsService.decode(this.$route.params.id),
                arrCuales: []
            },

            hoy: new Date(),
            isSelected: false,
            saveDisabled: false,
            arrArchivos: [],
            iconImg: '',
            editor: ClassicEditor,
            editorData: '',
            editorConfig: { 
                language: 'es',
                wordCount: {
                    showParagraphs: false,
                    showWordCount: true,
                    showCharCount: true,
                    countSpacesAsChars: false,
                    countHTML: false,
                    maxWordCount: -1,
                    maxCharCount: 4000
                }
            }
        }
    },
    methods : {
        validarVacios( valor, campo, combo = false ) {
            let errors = 0

            if (!combo) {
                if (!valor) { 
                    document.querySelector( campo ).style.backgroundColor='#fddfe2'
                    errors++
                } else {
                    document.querySelector( campo ).style.backgroundColor=''
                }
            } else {
                if (valor.length > 0) { 
                    document.querySelector( campo ).style.backgroundColor=''
                } else {
                    document.querySelector( campo ).style.backgroundColor='#fddfe2'
                    errors++
                }
            }

            return errors
        },

        async sendForm() {
            let errors = 0
            
            errors += this.validarVacios(this.form.fecha_reporte, '[name="fecha_reporte"]')
            if ( this.form.sReporte ) {
                document.querySelector( '#desc_avance' ).style.backgroundColor=''
                document.querySelector( '#desc_det_avance' ).style.backgroundColor=''
                document.querySelector( '#colabDeps' ).style.backgroundColor=''
                document.querySelector( '#cualesDependencias' ).style.backgroundColor=''
                document.querySelector( '#colabOI' ).style.backgroundColor=''

                //errors += this.validarVacios(this.form.justificacion, '#justificacion')
                errors += this.validarVacios(this.form.desc_avance, '#desc_avance')
            } else {
                //document.querySelector( '#justificacion' ).style.backgroundColor=''
                
                errors += this.validarVacios(this.form.desc_avance, '#desc_avance')
                errors += this.validarVacios(this.form.desc_det_avance, '#desc_det_avance')
                errors += this.validarVacios(this.form.colaboracion_otras_dep, '#colabDeps')
                if ( this.form.colaboracion_otras_dep === 'Si' ) {
                    errors += this.validarVacios(this.form.cualesDependencias, '#cualesDependencias', true)
                }
                errors += this.validarVacios(this.form.colaboracion_org_int, '#colabOI')
            }

            if ( errors > 0 ){
                this.$notify({
                    title: 'Error', text: 'Hay campos requeridos que necesitan ser llenados. Por favor verifique', type: 'error', duration: 5000, speed: 1000
                })
                return
            } else {
                // console.log( this.form.cualesDependencias );
                const cobEst = ( this.form.coberturaEstados !== '' )? this.form.coberturaEstados.toString() : [];
                const cuadep = ( this.form.cualesDependencias !== '' )? this.form.cualesDependencias.toString() : [];

                const fechaAvance = (this.form.fecha_reporte)? this.form.fecha_reporte.toISOString().split('T')[0] : ''
                const fechaInicio = (this.form.inicio_p)? this.form.inicio_p.toISOString().split('T')[0] : ''
                const fechaFin = (this.form.fin_p)? this.form.fin_p.toISOString().split('T')[0] : ''

                const jsonUpd = JSON.parse(JSON.stringify({
                    "idaccion_puntual": charsService.decode(this.$route.params.id),
                    "sin_reporte_avance": this.form.sReporte,
                   // "sin_reporte_justificacion": this.form.justificacion,
                    "fecha_avance": fechaAvance,
                    "fecha_inicio": fechaInicio,
                    "fecha_termino": fechaFin,
                    "avance_resumen": this.form.desc_avance,
                    "avance_detalle": this.form.desc_det_avance,
                    "cantidad_jovenes_imp": this.form.cant_jovenes,
                    "cantidad_jovenes_imp_nd": this.form.cant_jovenes_nd,
                    "cobertura_edos": cobEst,
                    "id_dependencia": this.form.dependencia,
                    "indigena": this.form.indigena,
                    "discapacidad": this.form.discapacidad,
                    "sitio_web": this.form.web,
                    "colaboracion_otras_dep": this.form.colaboracion_otras_dep,
                    "colaboracion_org_int": this.form.colaboracion_org_int,
                    "cuales_dep": cuadep,
                    "otras_dependencias": this.form.otrasDependencias,
                    "bloquear": this.form.bloquear,
                }))

                const resUpd = await insertData('accionp_avance', jsonUpd);
                if (resUpd[0].res.status === 200 ) {
                    this.saveDisabled = true

                    this.newId = resUpd[0].res.data;
                    for (let x = 0; x < this.arrFilesUploaded.length; x++) {
                        //await updateData('archivos', this.arrFilesUploaded[x].fileId, JSON.parse(JSON.stringify({ id_registro_sec:this.newId,id_registro:this.form.accion_puntual,tmp:0 })))  //Antes
                        await updateData('archivos', this.arrFilesUploaded[x].fileId, JSON.parse(JSON.stringify({ id_registro_sec:this.newId,id_registro:this.form.accion_puntual,tmp:0 })))
                    }

                    const arrUsr = await getUsersById(charsService.decode(localStorage.getItem('id')));
                    await sendMailService.reportSave( arrUsr.nombre, arrUsr.a_paterno, arrUsr.email, this.form.accion_puntual)
                        .then( r => { 
                            r
                        })
                        .catch( e => { 
                            console.log( 'Error de envío', e ); 
                            return 
                        })

                    this.$notify({
                        title: 'Correcto', text: 'Se guardó correctamente la información', type: 'success', duration: 5000, speed: 1000
                    })
                    this.$router.push(`/projuventud/acciones`)
                }
            }
        },
        async getCatalogos() {
            const consEstados = await getEstadosNc()
            for(let i=0; i<consEstados.length; i++){
                this.catEstados.push({id: consEstados[i].id, nc: consEstados[i].nom_estado})
            }
            const consDependencias = await getDependenciasNc()
            for(let i=0; i<consDependencias.length; i++){
                this.catDependencias.push({id: consDependencias[i].id, nc: consDependencias[i].nom_corto_dependencia})
            }

        },
        async getAccionPuntual() {
            const consAccPuntById = await getAccionPuntualById( charsService.decode(this.$route.params.id) )
            this.thisAction = consAccPuntById[0]
        },

        async uploadFile() {
            this.file = this.$refs.file.files[0]
            var formData = new FormData()
            formData.append('file', this.file)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            // await axios.post('http://localhost/ubApi/uploadFiles.php', formData, {
            await axios.post( ubApi.value + '/uploadFiles.php', formData, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlert = z_errorAlert
            this.successAlert = z_successAlert
            this.errorMessage = z_errorMessage
            this.successMessage = z_successMessage
            this.$refs.file.value = z_fileValue

            if (z_successAlert) {
                // AvanceAcciones módulo para tabla de archivos
                const jsonInsFile = JSON.parse(JSON.stringify({
                    "modulo": "AvanceAcciones",
                    "id_registro": this.form.accion_puntual,    //Antes tenia 0
                    //"id_registro_sec": 0,   //Esta no estaba yo la puse
                    "id_usuario": z_userId,
                    "tmp_name": z_tmpFileName,
                    "file_name": z_baseName,
                    "extension": z_extension,
                    "tmp": false            //Antes era false
                }))
                const lastInsert = await insertData('archivos', jsonInsFile);
                this.arrFilesUploaded.push({ fileId: lastInsert[0].res.data })
                this.arrArchivos.push({borrar:0, extension:z_extension, file_name:z_baseName, file_name_dec:z_baseName, id:lastInsert[0].res.data, id_registro:this.$route.params.id, id_usuario:z_userId, modulo:"AvanceAcciones", tmp_name:z_tmpFileName})
                //this.arrArchivos.push({borrar:0, extension:z_extension, file_name:z_baseName, file_name_dec:z_baseName, id:lastInsert[0].res.data, id_registro:this.form.accion_puntual, id_usuario:z_userId, modulo:"AvanceAcciones", tmp_name:z_tmpFileName})
            
                //this.$notify({
                //    title: 'entro a insert archivo - accion_puntual', text: 'accion_puntual=' +  this.$route.params.id, type: 'success', duration: 5000, speed: 1000
                //});

            }

            console.log(this.arrArchivos);
        },

        async getArchivos() {
            return await getArchivos( 'AvanceAcciones', this.$route.params.id )
        },

        async deleteFile( id, i ) {
            if ( confirm('¿Confirma eliminar el archivo?') ) {
                this.arrArchivos.splice( i, 1)
                await updateData( 'archivos', id, JSON.parse(JSON.stringify({ borrar: 1 })) )
            }
        },

        async delTmp() {
            const getTmp = await getArchivosTmp()
            const arrTmp = []

            for (let z = 0; z < getTmp.length; z++) {
                await updateData('archivos', getTmp[z].id, JSON.parse(JSON.stringify({ borrar: 1, tmp: 0 })))
            }
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id, maxCar ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount + " de " + maxCar
        },

        isOtrasDep() {
            const verRadio = document.querySelector('#colaboracion_otras_dep_si').checked
            this.disOtrasDep = !verRadio
            const noVerRadio = document.querySelector('#colaboracion_otras_dep_no').checked
            if (noVerRadio) {
                this.form.cualesDependencias = []
            }

        },

        readFormatReporte() {
            return this.readFormat( this.form.fecha_reporte )
        },

        readFormatInicio() {
            return this.readFormat( this.form.inicio_p )
        },

        readFormatFin() {
            return this.readFormat( this.form.fin_p )
        },

        readFormat( date ) {
            if ( date !== '' ) {
                const day = (date.getDate() < 10)? '0' + date.getDate() : date.getDate()
                const month = (date.getMonth() < 9)? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
                const year = date.getFullYear()

                return `${day}-${month}-${year}`;
            } else {
                return ''
            }
        },
        
        isDisabledJov() {
            const cant_jovenes_nd = document.querySelector('#cant_jovenes_nd').checked
            this.isDisabledJ = cant_jovenes_nd
            if (cant_jovenes_nd) {
                document.querySelector('#cant_jovenes').value = ''
                document.querySelector('#cant_jovenes').dispatchEvent(new Event('input'))
            }
        },
        
        disableInputs() {
            const sReporte = document.querySelector('#sReporte').checked

            if (sReporte) {
                //document.querySelector('#inicio_p').value = ''
                //document.querySelector('#inicio_p').dispatchEvent(new Event('input'))
                //this.form.inicio_p = ''
                
                //document.querySelector('#fin_p').value = ''
                //document.querySelector('#fin_p').dispatchEvent(new Event('input'))
                //this.form.fin_p = ''

                //document.querySelector('#desc_avance').value = ''
                //document.querySelector('#desc_avance').dispatchEvent(new Event('input'))
                // this.conteo_real('desc_avance')

                document.querySelector('#desc_det_avance').value = ''
                document.querySelector('#desc_det_avance').dispatchEvent(new Event('input'))
                // this.conteo_real('desc_det_avance')

                document.querySelector('#cant_jovenes').value = ''
                document.querySelector('#cant_jovenes').dispatchEvent(new Event('input'))
                
                document.querySelector('#cant_jovenes_nd').value = ''
                document.querySelector('#cant_jovenes_nd').dispatchEvent(new Event('input'))
                this.form.cant_jovenes_nd = false
                this.isDisabledJ = false

                document.querySelector('#coberturaEstados').value = ''
                document.querySelector('#coberturaEstados').dispatchEvent(new Event('change'))
                document.querySelector('#coberturaEstados').disabled = true
                this.form.coberturaEstados = []
                
                //document.querySelector('#dependencia').value = ''
                //document.querySelector('#dependencia').dispatchEvent(new Event('change'))

                document.querySelector("#colaboracion_otras_dep_si").checked = false
                document.querySelector("#colaboracion_otras_dep_no").checked = false

                document.querySelector('#cualesDependencias').value = ''
                document.querySelector('#cualesDependencias').dispatchEvent(new Event('change'))
                document.querySelector('#cualesDependencias').disabled = true
                this.form.cualesDependencias = []

                document.querySelector('#otrasDependencias').value = ''
                document.querySelector('#otrasDependencias').dispatchEvent(new Event('input'))

                document.querySelector("#colaboracion_org_int_si").checked = false
                document.querySelector("#colaboracion_org_int_no").checked = false

                document.querySelector('#indigena').value = ''
                document.querySelector('#indigena').dispatchEvent(new Event('input'))
                this.form.indigena = false
                
                document.querySelector('#discapacidad').value = ''
                document.querySelector('#discapacidad').dispatchEvent(new Event('input'))
                this.form.discapacidad = false
                



                document.querySelector('#web').value = ''
                document.querySelector('#web').dispatchEvent(new Event('input'))

                document.querySelector('#fileUp').value = ''
                document.querySelector('#fileUp').dispatchEvent(new Event('input'))

                //document.querySelector('#bloquear').checked = false
            } else {
                //document.querySelector('#justificacion').value = ''
                //document.querySelector('#justificacion').dispatchEvent(new Event('input'))
                //this.conteo_real(this.form.justificacion, 'justificacion')

               // document.querySelector('#dependencia').value = 25
               // document.querySelector('#dependencia').dispatchEvent(new Event('change'))
            }
        },

        async setArrArchivos( id ) {
            const arrFiles = await getArchivosAvance( 'AvanceAcciones', this.form.accion_puntual, parseInt(charsService.decode( id )))

            arrFiles.forEach(archivo => {
                console.log( archivo );
                this.arrArchivos.push({tmp_name: charsService.encode(archivo.tmp_name), file_name: charsService.encode(archivo.file_name), extension: archivo.extension, file_name_dec:archivo.file_name })
            });
        },

        otrasSelected() {
            let el = document.querySelector("#cualesDependencias").querySelectorAll( "option" )[document.querySelector("#cualesDependencias").querySelectorAll( "option" ).length-1]
            if ( !el.selected ) {
                this.form.otrasDependencias = ''
                this.isOtrasSelected = true
            } else {
                this.isOtrasSelected = false
            }
        }
    },
    computed : {
        isDisabled: function(){
            return this.form.sReporte;
        },
        //isDisabledJov: function(){
        //    return this.form.cant_jovenes_nd;
        //},
    },
    async mounted() {
        //this.delTmp()    /Antes estaba, pero marcaba como borrados los adjuntos
        window.scrollTo(0, 0)

        this.getCatalogos()
        this.getAccionPuntual()
        // this.conteo_real_chars(this.form.justificacion, 'justificacion')
        /* this.conteo_real('desc_avance')
        this.conteo_real('desc_det_avance') */
        await this.setArrArchivos( this.$route.params.id )
        this.$gtag.event('Registro de avance de acciones puntuales');     //Registra el Evento en Google Analytics con nombre
    },
    
}
</script>

<style scoped>
    :root {
        --v-calendar-input-font-size: medium;
    }
    
    .v-calendar .input-field input {
        font-size: var(--v-calendar-input-font-size) !important;
    }

    img {
        width: 100%;
        margin-bottom: 40px;
    }

    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
        color: #FFF;
        text-decoration: none;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    form {
        font-size: medium;
    }

    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>