/* Regresa todos los objetivos prioritarios de la tabla 'cat_objetivos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccionPuntualById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/accion_puntual?transform=1&filter=borrar,lt,1&filter=id,eq,${ id }`)
    ]

    const [ accion ] = await Promise.all( promiseArr )

    return accion.data.accion_puntual
}

export default getAccionPuntualById