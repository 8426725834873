<template>
    <h4 class="mt-0">Agregar un nivel de desagregación</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre del nivel de desagregación:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idNivel" name="idNivel" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_nivel_desagregacion }" id="nom_nivel_desagregacion" name="nom_nivel_desagregacion" placeholder="Nombre del nivel de desagregación" v-model="form.nom_nivel_desagregacion" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateNivel" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateNivel" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otro nivel de desagregación" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addNivel" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import addData from '@/helpers/addData'
import getDataByAnyNivel from '@/helpers/getDataByAnyNivel'

export default {
    data() {
        return {
            form: {
                nom_nivel_desagregacion: ''
            },
            noValid: {
                nom_nivel_desagregacion: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nom_nivel_desagregacion = ''
            this.added = false
        },
        async addNivel() {
            this.noValid.nom_nivel_desagregacion = ( this.form.nom_nivel_desagregacion )? false : true

            const valNom_nivel_desagregacion = await getDataByAnyNivel( 'cat_nivel_desagregacion', 'nom_nivel_desagregacion', this.form.nom_nivel_desagregacion );
            
            if ( valNom_nivel_desagregacion.length < 1 ){
                if ( !this.noValid.nom_nivel_desagregacion) {
                    const dataForm = {
                        nom_nivel_desagregacion: this.form.nom_nivel_desagregacion
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addNivel = await addData('cat_nivel_desagregacion', jsonData)
                    if ( addNivel[0].res.status === 200 ) {
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre del nivel de desagregación', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe un nivel de desagregación registrado con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>