
<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item"><a href="/aten_ciud">PARTICIPACIÓN CIUDADANA</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/consepp">REPOSITORIO DE ACTAS DE SESIÓN DEL CONSEPP</a></li>
            </ol>

            <h2>Repositorio de actas de sesión del CONSEPP</h2>
            <hr class="red">

            <div class="panel panel-default">
                <div class="panel-body">
                    <RepoActas />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import RepoActas from '@/components/Participacion/RepoActas';

export default {
    components: {
        Navbar,
        RepoActas
    },
    async mounted() {
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    a.nav-link {
        text-decoration: none;
        background-color: #98989A;
        border-color: #98989A;
        color: #FFF;
    }

    a.nav-link:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .nav>li>a:focus, .nav>li>a:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .activeNav {
        background-color: #9D2449;
    }
</style>