/* Regresa todos los registros de la vista 'meta_ficha' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAllMetas_ficha = async() => {
    const promiseArr = [
        simejuvApi.get(`/meta_ficha?transform=1&filter=borrar,lt,1`)
    ]

    const [ Metasficha ] = await Promise.all( promiseArr )

    return [
        { metas: Metasficha.data.meta_ficha }
    ]
}

export default getAllMetas_ficha