<template>
    <h4 class="mt-0">Actualizar información del catálogo de objetivos</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="num_objetivo">Número del objetivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idObjetivo" name="idObjetivo" v-model="form.id" />
                        <input type="text" class="form-control" id="num_objetivo" name="num_objetivo" placeholder="Número del objetivo" v-model="form.num_objetivo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_objetivo">Nombre del objetivo:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_objetivo" name="nom_objetivo" placeholder="Nombre del objetivo" v-model="form.nom_objetivo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_corto_objetivo">Nombre corto del objetivo:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_corto_objetivo" name="nom_corto_objetivo" placeholder="Nombre corto del objetivo" v-model="form.nom_corto_objetivo" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateObjetivo" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateObjetivo" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateObjetivo" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getObjetivosById from '@/helpers/getObjetivosById'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                num_objetivo: '',
                nom_objetivo: '',
                nom_corto_objetivo: '',
            }
        }
    },
    methods: {
        async getObjetivoData( id ) {
            const objetivoArr = await getObjetivosById( id )
            
            return objetivoArr[0].cat_objetivos
        },

        async setData( id ) {
            const datos = await getObjetivosById( id )

            this.form.id = datos.id
            this.form.num_objetivo = datos.num_objetivo
            this.form.nom_objetivo = datos.nom_objetivo
            this.form.nom_corto_objetivo = datos.nom_corto_objetivo
        },

        async updateObjetivo() {
            const dataForm = {
                num_objetivo: this.form.num_objetivo,
                nom_objetivo: this.form.nom_objetivo,
                nom_corto_objetivo: this.form.nom_corto_objetivo
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_objetivos', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>