/* Regresa todos los objetivos prioritarios de la tabla 'cat_objetivos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getResponsables = async() => {
    const promiseArr = [
        simejuvApi.get(`/accionp_ent_resp?transform=1`)
    ]

    const [ responsable ] = await Promise.all( promiseArr )

    return responsable.data.accionp_ent_resp
}

export default getResponsables