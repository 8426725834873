<template>
    <div id="mainCarousel" class="carousel slide" data-ride="carousel">
        
        <!-- Wrapper for slides -->
        <div class="carousel-inner" role="listbox">
            <div class="item active">
                <div class="gradient"></div>
                <img src="@/assets/images/portada_alt1.png" alt="Banner 1">
                <div class="carousel-caption">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="@/assets/images/logo_imjuve.png" id="logo_imjuve" alt="IMJUVE">
                        </div>
                        <div class="col-md-8">
                            <h3>Bienvenido al Sistema de Monitoreo y Evaluación de la Política de Juventud</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="gradient"></div>
                <img src="@/assets/images/portada_alt2.png" alt="Banner 2">
                <div class="carousel-caption">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="@/assets/images/logo_imjuve.png" id="logo_imjuve" alt="IMJUVE">
                        </div>
                        <div class="col-md-8">
                            <h3>Bienvenido al Sistema de Monitoreo y Evaluación de la Política de Juventud</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .gradient {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.65) 15%, rgba(255,255,255,.65) 85%, rgba(255,255,255,0) 100%);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        bottom: 0;
    }

    .carousel-caption, .carousel-control {
        color: #000;
        text-shadow: 0px 0px 5px rgb(255, 255, 255);
        vertical-align: bottom;
        z-index: 101;
    }
    
    d-block {
        width: 100%;
    }

    #mainCarousel {
        margin: 70px 0;
    }
</style>