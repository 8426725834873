<template>
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
        <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
        <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
        <li class="breadcrumb-item active" aria-current="page"><a href="/projuventud">PROJUVENTUD 2021-2024</a></li>
    </ol>
    <h2>PROJUVENTUD 2021-2024</h2>
    <hr class="red">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
            <router-link class="btn btn-default btn-sm active" to="/projuventud/metas">Regresar</router-link>
        </li>
    </ul>
    <div class="container mb-5">
        <h3>Registro de avance de Metas para el Bienestar y Parámetros del Programa Nacional de Juventud 2021-2024</h3>
        <hr>
        <h4>Meta del bienestar del objetivo prioritario {{ arrDataMeta.idobjetivo }}</h4>
        <div class="row">
            <div class="col-md-9">
                <strong>{{ (arrDataMeta.idclase_ind === 2)? 'Parámetro' : 'Meta'  }}Meta {{ arrDataMeta.codigo_meta }} {{ arrDataMeta.nom_meta }}</strong>
            </div>
            <div class="col-md-3">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                
            </div>
        </div>
    </div>
    <div class="container mb-5">
        <div class="row">
            <div class="col-md-6 text-left"><h4>Registro de avance</h4></div>
            <div class="col-md-6 text-right">
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <form>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Año</th>
                                <th>Meta programada</th>
                                <th>Fórmula de cálculo</th>
                                <th>Avance de la meta o parámetro</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(k, i) in arrDataVista" :key="i">
                                <input type="hidden" v-model="form.id[i]">
                                <td>{{ k.meta_ano }}</td>
                                <td class="text-right">{{ k.meta_programada }}</td>
                                <td>{{ this.arrTiposForm[k.formula_calculo] }}</td>
                                <td>
                                    <div class="form-group row">
                                        <div class="col-md-6"><strong>Sin reporte de avance</strong></div>
                                        <div class="col-md-6"><input type="checkbox" :data-id="i" data-element="checkbox" :checked="isDisabled(i)" @click="disElem(i)" /></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><strong>{{ arrDataMeta.nom_variable_a }}</strong></div>
                                        <div class="col-md-6"><input type="number" class="form-control" :data-element="`item${i}`" v-model="form.varA[i]" :disabled="isDisabled(i)"></div>
                                    </div>
                                    <div class="form-group row" v-if="arrDataMeta.nom_variable_b">
                                        <div class="col-md-6"><strong>{{ arrDataMeta.nom_variable_b }}</strong></div>
                                        <div class="col-md-6"><input type="number" class="form-control" :data-element="`item${i}`" v-model="form.varB[i]" :disabled="isDisabled(i)"></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><strong>Valor del avance de la meta o parámetro</strong></div>
                                        <div class="col-md-6"><input type="number" class="form-control" :data-element="`item${i}`" v-model="form.valMeta[i]" :disabled="isDisabled(i)"></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><strong>Fecha de registro</strong></div>
                                        <div class="col-md-6"><Datepicker lang="es" size="5" v-model="form.fecha[i]" placeholder="Seleccione fecha" :disabled="isDisabled(i)" name="Prueba" /></div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><strong>Notas sobre el avance</strong></div>
                                        <div class="col-md-6"><input type="text" class="form-control" :data-element="`item${i}`" v-model="form.notas[i]" :disabled="isDisabled(i)"></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <router-link class="btn btn-default btn-sm active" to="/projuventud/metas">Regresar</router-link>
                        </div>
                        <div class="col-md-6 text-right">
                            <input type="button" class="btn btn-primary btn-sm active" value="Guardar" @click="saveData">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div> 
</template>

<script scoped>
import '@/assets/css/vuedatepickerui.css'
import VueDatepickerUi from 'vue-datepicker-ui'
import getMetasById from '@/helpers/getMetasById'
import getAvanceMetasById from '@/helpers/getAvanceMetasById'
import getTipo_formula from '@/helpers/getTipo_formula'
import getMetasAvance from '@/helpers/getMetasAvance'
import getTipo_calculoById from '@/helpers/getTipos_calculoById'
import updateDataRes from '@/helpers/updateDataRes'
import { notify } from '@kyvg/vue3-notification'
import charsService from '@/services/CharsService';

export default {
    components: {
        Datepicker: VueDatepickerUi,
    },
    data() {
        return {
            arrAvance: [],
            arrDataMeta: [],
            arrDataVista: [],
            arrTiposForm: [],
            saveDisabled: false,
            isChecked: true,
            form: {
                id: [],
                sinRep: [],
                varA: [],
                varB: [],
                valMeta: [],
                fecha: [],
                notas: []
            }
        }
    },
    methods: {
        async getDataMeta() {
            const consFicha = await getMetasById(charsService.decode( this.$route.params.id ))
            this.arrDataMeta = consFicha[0]
        },
        async getDataById( id ) {
            // const consData = await getVAvanceMetasById( id );
            const consData = await getAvanceMetasById( id );
            return consData
        },
        async getCatalogos() {
            const consTiposForm = await getTipo_formula()
            for (let k = 0; k < consTiposForm.length; k++) {
                this.arrTiposForm[consTiposForm[k].id] = consTiposForm[k].nom_tipo_formula
            }

            const consAvance = await getMetasAvance()
            for (let l = 0; l < consAvance.length; l++) {
                this.arrAvance[consAvance[l].id] = {
                    sin_reporte_avance: consAvance[l].sin_reporte_avance
                }
            }
        },
        async getTipoCal( id ) {
            return await getTipo_calculoById( id )
        },
        async saveData() {
            const arrResult = []
            let idsUpd = ''
            let arrResultJson = ''
            const checks = document.querySelectorAll('[data-element="checkbox"]');
            let fecha = ''
            
            for (let a = 0; a < this.form.id.length; a++) {
                
                if (this.form.fecha[a]) {
                    var dateTxt = new Date(this.form.fecha[a]).toLocaleDateString('es-MX')
                    var today = dateTxt.toString().split('/')
                    var dd = today[0]
                    var mm = today[1]
                    var yyyy = today[2]
                    fecha = `${yyyy}-${mm}-${dd}`
                } else {
                    fecha = null
                }

                arrResult.push({
                    sin_reporte_avance: (checks[a].checked)? checks[a].checked: null,
                    meta_var_a_valor: (this.form.varA[a])? this.form.varA[a] : null,
                    meta_var_b_valor: (this.form.varB[a])? this.form.varB[a] : null,
                    meta_alcanzada: (this.form.valMeta[a])? this.form.valMeta[a] : null,
                    // fecha_avance: this.form.fecha[a],
                    fecha_avance: fecha,
                    avance_comentario: (this.form.notas[a])? this.form.notas[a] : null,
                })

                if ( idsUpd !== '' ) idsUpd += ','

                idsUpd += this.form.id[a]
            }

            arrResultJson = JSON.parse(JSON.stringify( arrResult ))
            const resultUpd = await updateDataRes( 'meta_avance', idsUpd, arrResultJson )
            if ( resultUpd.status === 200 ) {
                this.$notify({
                    title: 'Correcto', text: 'Se guardó correctamente la información', type: 'success', duration: 5000, speed: 1000
                })
                this.$router.push(`/projuventud/metas`)
            } else {
                this.$notify({
                    title: 'Error!', text: 'Hubo un problema al guardar los datos. Vuelve a intentar.', type: 'error', duration: 5000, speed: 1000
                })
            }
        },
        async setData() {
            this.arrDataVista = await this.getDataById( this.arrDataMeta.id )

            for (let a = 0; a < this.arrDataVista.length; a++) {
                if (this.arrDataVista[a].fecha_avance) {
                    var today = this.arrDataVista[a].fecha_avance.split('-')
                    var dd = today[2]
                    var mm = today[1]
                    var yyyy = today[0]
                }

                const dateReg = (!this.arrDataVista[a].fecha_avance)? '' : `${mm}-${dd}-${yyyy}`
                this.form.id[a] = this.arrDataVista[a].id
                this.form.sinRep[a] = this.arrDataVista[a].sin_reporte_avance
                this.form.varA[a] = this.arrDataVista[a].meta_var_a_valor
                this.form.varB[a] = this.arrDataVista[a].meta_var_b_valor
                this.form.valMeta[a] = this.arrDataVista[a].meta_alcanzada
                this.form.fecha[a] = dateReg
                this.form.notas[a] = this.arrDataVista[a].avance_comentario
            }
        },
        isDisabled( i ) {
            return Boolean(this.form.sinRep[i]);
        },
        disElem( i ) {
            const allChecks = document.querySelectorAll('[data-element="checkbox"]');
            const checked = allChecks[i].checked

            const allElements = document.querySelectorAll('[data-element="item'+i+'"]');
            const allCalendars = document.querySelectorAll('[placeholder="Seleccione fecha"]');

            allElements.forEach((item) => {
                item.disabled = checked
                item.value = ''
                item.dispatchEvent(new Event('input'))
            })
            allCalendars[i].disabled = checked
            if (checked) {
                allCalendars[i].value = ''
                allCalendars[i].dispatchEvent(new Event('input'))
                this.arrDataVista[i].fecha_avance = ''
            }
            
        }
    },
    async mounted() {
        await this.getDataMeta()
        await this.getCatalogos()
        await this.setData()
        this.$gtag.event('Registro de avance de metas y parámetros');      //Registra el Evento en Google Analytics con nombre
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    img {
        width: 100%;
        margin-bottom: 40px;
    }

    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    .v-calendar .input-field input {
        --v-calendar-input-font-size: 70px
    }
    form {
        font-size: medium;
    }
</style>