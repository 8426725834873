<template>
    <div class="mainDiv">
        <Navbar :user="true" />
        <div class="mt-5 container principal text-left">
            <!-- Links (con el icono Home) -->
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/adminSec">Administración de la plataforma</a></li>
            </ol>
            <div class="row">
                <div class="col-md-3 col-sm-12 text-right center-resp pt-5 display-small">
                    <a class="btn btn-default active btn-sm width-resp" @click="$router.go(-1)"><i class="glyphicon glyphicon-th"></i> Regresar al menú</a>
                </div>
                <div class="col-md-9 col-sm-12">
                    <h3 class="mtr-4">Administración de la plataforma</h3>
                </div>
                <div class="col-md-3 col-sm-12 text-right center-resp pt-5 display-big">
                    <a class="btn btn-default active btn-sm width-resp" @click="$router.go(-1)"><i class="glyphicon glyphicon-th"></i> Regresar al menú</a>
                </div>
            </div>
            <hr class="red">
            <div class="row">
                <div class="col-md-12">
                    <!-- Componente a usar (se importa abajo) -->
                    <IntervencionesTbl />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
// Se importa el componente principal (Se declara abajo)
// Se puede seguir la ruta en (components/AdminSec)
import IntervencionesTbl from '@/components/AdminSec/Intervenciones/IntervencionesTbl'

export default {
    components: {
        Navbar,
        // Se declara el componente
        IntervencionesTbl
    },
    mounted() {
        window.scrollTo(0, 0)
    }
}
</script>