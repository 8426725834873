/* Regresa id y nombre de todos los registros de la tabla 'cat_municipios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

/* 
* @param incTodas: false: no regresa NO-SECTORIZADA y TODAS (default)
*                  true: regresa todos los registros
*/
const getMunicipiosNc = async( incTodas = false ) => {
    let filter = ''

    //if ( !incTodas ){
    //    filter = '&filter=id,lt,100'
    //}

    const promiseArr = [
        simejuvApi.get(`/cat_municipios?transform=1&columns=id,idestado,nom_municipio${ filter }`)
    ]

    const [ municipios ] = await Promise.all( promiseArr )

    return municipios.data.cat_municipios
}

export default getMunicipiosNc