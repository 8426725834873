/* Regresa todos los datos del usuario con el id enviado de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getNivelesById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_nivel_desagregacion/${ id }`)
    ]

    const [ cat_nivel_desagregacion ] = await Promise.all( promiseArr )

    return cat_nivel_desagregacion.data
}

export default getNivelesById