<template>
    <h4>Registro de avance de acciones puntuales a nivel de objetivo prioritario</h4>
    <hr>
    <div class="row mb-3">
        <!-- Botón nuevo avance -->
        <div class="col-md-4 text-left">
            <input type="button" class="btn btn-sm btn-secondary" value="Nuevo avance" @click=" $router.push('/accion_objetivo_av/accion_objetivo_avAdmin/add') ">
        </div>
        <!-- Input de búsqueda -->
        <div class="col-md-3 text-right"><label class="text-small">Buscar:</label></div>
        <div class="col-md-5 text-right"><input v-model="searchTerm" class="form-control busqueda" /></div>
    </div>
    <div class="row">
        <div class="col-md-12 datatable">
            <!-- Configuración de la tabla con los campos a mostrar -->
            <table-lite
                :is-static-mode="true"
                :columns="table.columns"
                :rows="table.rows"
                :total="table.totalRecordCount"
                :sortable="table.sortable"
                :messages="table.messages"
            ></table-lite>
            
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
// Componente de la tabla
import TableLite from "@/components/DataTable/TableLite";

// Importo los helpers
import getAllAccionp_av_objetivo from '@/helpers/getAllAccionp_av_objetivo'
import charsService from '@/services/CharsService';

export default defineComponent({
    components: { TableLite },
    setup() {
        const searchTerm = ref("") // Variable para los datos de búsqueda
        const data = reactive([]); // Variable para los datos a mostrar en la tabla
        let arrDependencias = [];


        (async () => {
            const tipoUsr = charsService.decode(localStorage.getItem('tipo_usuario'))
            const depUsr = charsService.decode(localStorage.getItem('dependencia'))
            let accionp_av_objetivoArr = ''

            if ( tipoUsr === 1 || tipoUsr === 2 ){
                accionp_av_objetivoArr = await getAllAccionp_av_objetivo()
            } else {
                accionp_av_objetivoArr = await getAllAccionp_av_objetivo( depUsr )
            }
            // Genero el arreglo de datos para llenar la tabla
            const datos = accionp_av_objetivoArr[0].accions
            for (let i = 0; i < datos.length; i++) {
                data.push({
                    id: datos[i].id,
                    nom_corto_dependencia: datos[i].nom_corto_dependencia,
                    num_objetivo: datos[i].num_objetivo,
                    nom_objetivo: datos[i].nom_objetivo,
                    fecha_avance: datos[i].fecha_avance,
                    avance_resumen: datos[i].avance_resumen,
                    
                });
            }
        })()
        
        // Configuración de la tabla
        const table = reactive({
            // Columnas de la tabla
            columns: [
               {
                    label: "Dependencia",
                    field: "nom_corto_dependencia",
                    width: "10%",
                    sortable: true,
                },
               {
                    label: "Objetivo priotitario",
                    field: "nom_objetivo",
                    width: "30%",
                    sortable: true,
                },
               {
                    label: "Fecha avance",
                    field: "fecha_avance",
                    width: "10%",
                    sortable: true,
                },
                {
                    label: "Descripción del avance",
                    field: "avance_resumen",
                    width: "30%",
                    sortable: true,
                },
                {
                    label: "Jóvenes impactados",
                    field: "cantidad_jovenes_imp",
                    width: "5%",
                    sortable: true,
                },
                {
                    label: "",
                    field: "quick",
                    width: "5",
                    display: function (row) {
                        // Iconos de acciones
                        return (
                            '<a href="/accion_objetivo_av/accion_objetivo_avAdmin/edit/'+ charsService.encode( row.id ) +'" class="text-info" title="Editar"><i class="glyphicon glyphicon-pencil"></i></a> ' + '&nbsp;' +
                            '<a href="/accion_objetivo_av/accion_objetivo_avAdmin/remove/'+ charsService.encode( row.id ) +'" class="text-info" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a> ' + '&nbsp;' 
                        );
                    },
                },
            ],
            rows: computed(() => {
                // Aquí se designan las columnas en las que se realizará la búsqueda desde el input
                return data.filter(
                    (x) =>
                        x.nom_corto_dependencia.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        x.nom_objetivo.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        x.fecha_avance.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        x.avance_resumen.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        x.cantidad_jovenes_imp.toLowerCase().includes(searchTerm.value.toLowerCase())
                    );
            }),
            totalRecordCount: computed(() => {
                // Se regresa el número de registros encontrados
                return table.rows.length
            }),
            sortable: {
                // Se define el orden por default
                order: "id",
                sort: "asc",
            },
            messages: {
                // Personalizo los mensajes que muestra la tabla
                pagingInfo: "Mostrando {0}-{1} de {2}",
                pageSizeChangeLabel: "Filas:",
                gotoPageLabel: "   Ir a la página:",
                noDataAvailable: "Sin datos para mostrar",
            },
        });

        return {
            searchTerm,
            table,
        }
    }
})
</script>

<style scoped>
    .card :deep .table .thead-dark th {
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
    .card :deep .table td, .card :deep .table tr{
        border: none;
    }
    .text-small {
        font-size: medium;
    }
    .busqueda {
        font-size: medium;
        height: auto;
    }
    .text-info {
        color: #343a40 !important;
        padding: 0 20px;
    }
    .datatable {
        padding-bottom: 10px;
        overflow-x: auto;
        margin-bottom: 25px;
    }
    .card {
        border: 0 !important;
    }
</style>