<template>
    <h4>Eliminar oferta del catálogo estatal de ofertas para las y los jóvenes</h4>
    <hr>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el registro del catálogo estatal de  ofertas para las y los jóvenes? </h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
//import getIntervencionesById from '@/helpers/getIntervencionesById'
import charsService from '@/services/CharsService';

export default {
    
    
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'op_serv_estatales', charsService.decode( this.$route.params.id ), jsonData )
            this.$router.push('../');
        },
    },
    async mounted() {
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>