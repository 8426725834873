/* Función que regresa el nombre corto del id enviado de la tabla 'cat_clase_ind' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getCatNC = async( filter ) => {
        const promiseArr = [
        simejuvApi.get(`${ filter }`)
    ]

    const [ cla_ind ] = await Promise.all( promiseArr )

    return cla_ind.data
}

export default getCatNC