<template>
    <h4 class="mt-0">Agregar un documento</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Titulo de la publicación:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idDescargable" name="idDescargable" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.publicacion_titulo }" id="publicacion_titulo" name="publicacion_titulo" placeholder="Titulo de la publicación" v-model="form.publicacion_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_descrip">Descripción de la publicación:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.publicacion_descrip" :config="editorConfig" id="publicacion_descrip"></ckeditor>
                    </div>
                </div>
               
               
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_autor">Autor:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="publicacion_autor" name="publicacion_autor" placeholder="Autor" v-model="form.publicacion_autor" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_fecha">Fecha de publicación:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="publicacion_fecha" name="publicacion_fecha" placeholder="Año, Mes, día" v-model="form.publicacion_fecha" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="url">URL del documento:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="url" name="url" placeholder="URL del documento" v-model="form.url" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="file_name">Nombre a mostrar del archivo:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="file_name" name="file_name" placeholder="Nombre a mostrar del archivo" v-model="form.file_name" />
                    </div>
                </div>


                <div hidden class="form-group row">
                    <label class="col-md-4" to="id_usuario">Usuario:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="id_usuario" name="id_usuario" placeholder="usuario" v-model="form.id_usuario" />
                    </div>
                </div>
                


                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otro documento" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addDescargable" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import '@/assets/css/vuedatepickerui.css'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'
import axios from 'axios'

import addData from '@/helpers/addData'
import getDataByAnyDescargable from '@/helpers/getDataByAnyDescargable'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            ubApi: ubApi.value,
            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,
            uploadedImage: '',

            form: {
                publicacion_titulo: '',
                publicacion_descrip: '',
                publicacion_autor: '',
                publicacion_fecha: '',
                url: '',
                file_name: '',
                id_usuario: '',
            },
            isSelected: false,
            saveDisabled: false,
            arrArchivos: [],
            iconImg: '',

            editor: ClassicEditor,
            editorData: '',
            editorConfig: { language: 'es' },


            noValid: {
                publicacion_titulo: false,
                publicacion_descrip: false,
                publicacion_autor: false,
                publicacion_fecha: false,
                id_usuario: false,
                url: false,
                file_name: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.publicacion_titulo = ''
            this.form.publicacion_descrip = ''
            this.form.publicacion_autor = ''
            this.form.publicacion_fecha = ''
            this.form.id_usuario = charsService.decode(localStorage.getItem('id'))
            this.form.url = ''
            this.form.file_name = ''


            this.added = false
        },
        async addDescargable() {
            this.noValid.publicacion_titulo = ( this.form.publicacion_titulo )? false : true
            this.noValid.publicacion_descrip = ( this.form.publicacion_descrip )? false : true
            this.noValid.publicacion_autor = ( this.form.publicacion_autor )? false : true
            this.noValid.publicacion_fecha = ( this.form.publicacion_fecha )? false : true
            this.noValid.id_usuario = ( this.form.id_usuario )? false : true
            this.noValid.file_name = ( this.form.file_name )? false : true
            this.noValid.url = ( this.form.url )? false : true

            const valPublicacion_titulo = await getDataByAnyDescargable( 'descargables', 'publicacion_titulo', this.form.publicacion_titulo );
            
            if ( valPublicacion_titulo.length < 1 ){
                if ( !this.noValid.publicacion_titulo) {
                    const extension = this.form.file_name.substr(-3);
                    const dataForm = {
                        publicacion_titulo: this.form.publicacion_titulo,
                        publicacion_descrip: this.form.publicacion_descrip,
                        publicacion_autor: this.form.publicacion_autor,
                        publicacion_fecha: this.form.publicacion_fecha,
                        id_usuario: charsService.decode(localStorage.getItem('id')),
                        file_name: this.form.file_name,
                        extension: extension,
                        url: this.form.url,
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addDescargable = await addData('descargables', jsonData)
                    if ( addDescargable[0].res.status === 200 ) {
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre de la publicación', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una publicación registrada con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>
<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

</style>