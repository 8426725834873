<template>

    <div class="row mt-8">
        <div class="col-lg-11 indicadores">
            <h5>Estadísticas de Juventudes Diversas</h5>
            
        </div>
        <div class="col-lg-1 indicadores">
            <div v-if="BtnDesplegarLista">
                <el-button type="primary" style="padding: 0px; border: 0px; background-color:#E5E5E5;"  @click="DespliegaListaFunc()" title="Regresar a la lista de indicadores">
                    <img :src="btn_regresar" class="iconImg" width="40"/>
                </el-button>
                
            </div>
        </div>    
        
    </div>

    <div class="row mt-8" style="backgroundColor:#FFFFFF">  <!-- INICIO DEL PANEL PRINCIPAL  -->
        
        <div class="demo-collapse" v-if="DesplegarLista">
                    <el-col :span="450">
                        <el-menu

                            name="b_obj5" id="b_obj5"
                            active-text-color="#9D2449"     
                            background-color="#FFFFFF"      
                            class="el-menu-vertical-demo"
                            default-active="1"
                            text-color="#000000"               
                            @open="handleOpen"
                            @close="handleClose"
                        >
                            <el-menu-item index="11" @click="f_b_obj5(1,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población de 12 a 29 años por entidad federativa, sexo, que habla lengua indígena y ademas hablan o no español</span>
                                <!-- C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INDICADORES\JUVENTUDES DIVERSAS\GRAFICAS\INEGI_exporta_4_1_2024_11_36_46-FINAL.xlsx  -->
                                <!-- https://www.inegi.org.mx/sistemas/Olap/Proyectos/bd/censos/cpv2020/P12Mas.asp -->
                            </el-menu-item> 

                            <el-menu-item index="21" @click="f_b_obj5(2,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población de 12 a 29 años, sexo, habla indígena y lengua INALI </span>
                                <!--  C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INDICADORES\JUVENTUDES DIVERSAS\GRAFICAS\INEGI_exporta_5_1_2024_17_1_1-FINAL.xlsx -->
                                <!--  https://www.inegi.org.mx/sistemas/Olap/Proyectos/bd/censos/cpv2020/P12Mas.asp -->
                            </el-menu-item> 

                            <el-menu-item index="21" @click="f_b_obj5(3,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población de 12 a 29 años con autoadscripción afromexicana o afrodescendiente por entidad federativa y sexo </span>
                                <!--  C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INDICADORES\JUVENTUDES DIVERSAS\GRAFICAS\INEGI_exporta_7_1_2024_0_47_50-AFRODES.xlsx -->
                                <!--  https://www.inegi.org.mx/sistemas/Olap/Proyectos/bd/censos/cpv2020/P12Mas.asp -->
                            </el-menu-item> 

                            <el-menu-item index="21" @click="f_b_obj5(4,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población de 12 a 29 años con limitación o discapacidad para bañarse vestirse o comer por entidad federativa y sexo </span>
                                <!--  C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INDICADORES\JUVENTUDES DIVERSAS\GRAFICAS\DISCAPACIDAD\DISCAP-BAÑARSE.xlsx -->
                                <!--  https://www.inegi.org.mx/sistemas/Olap/Proyectos/bd/censos/cpv2020/P12Mas.asp -->
                            </el-menu-item> 

                            <el-menu-item index="21" @click="f_b_obj5(5,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población de 12 a 29 años con limitación o discapacidad para caminar, subir o bajar por entidad federativa y sexo </span>
                                <!--  C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INDICADORES\JUVENTUDES DIVERSAS\GRAFICAS\DISCAPACIDAD\DISCAP-BAÑARSE.xlsx -->
                                <!--  https://www.inegi.org.mx/sistemas/Olap/Proyectos/bd/censos/cpv2020/P12Mas.asp -->
                            </el-menu-item> 

                            <el-menu-item index="21" @click="f_b_obj5(6,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población de 12 a 29 años con limitación o discapacidad para hablar o comunicarse por entidad federativa y sexo </span>
                                <!--  C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INDICADORES\JUVENTUDES DIVERSAS\GRAFICAS\DISCAPACIDAD\DISCAP-HABLAR.xlsx -->
                                <!--  https://www.inegi.org.mx/sistemas/Olap/Proyectos/bd/censos/cpv2020/P12Mas.asp -->
                            </el-menu-item> 

                            <el-menu-item index="21" @click="f_b_obj5(7,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población de 12 A 29 años con limitación o discapacidad para oír aun usando aparato auditivo por entidad y sexo </span>
                                <!--  C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INDICADORES\JUVENTUDES DIVERSAS\GRAFICAS\DISCAPACIDAD\DISCAP-OIR.xlsx -->
                                <!--  https://www.inegi.org.mx/sistemas/Olap/Proyectos/bd/censos/cpv2020/P12Mas.asp -->
                            </el-menu-item> 

                            <el-menu-item index="21" @click="f_b_obj5(8,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población de 12 A 29 años con limitación o discapacidad para ver aun usando lentes por entidad y sexo </span>
                                <!--  C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INDICADORES\JUVENTUDES DIVERSAS\GRAFICAS\DISCAPACIDAD\DISCAP-OIR.xlsx -->
                                <!--  https://www.inegi.org.mx/sistemas/Olap/Proyectos/bd/censos/cpv2020/P12Mas.asp -->
                            </el-menu-item> 

                        </el-menu>
                    </el-col>
        </div>

        <JD_01 v-bind:param_opc="param_opcRaiz" v-if="jd_01" />
        <JD_02 v-bind:param_opc="param_opcRaiz" v-if="jd_02" />
        <JD_03 v-bind:param_opc="param_opcRaiz" v-if="jd_03" />
        <JD_04 v-bind:param_opc="param_opcRaiz" v-if="jd_04" />
        <JD_05 v-bind:param_opc="param_opcRaiz" v-if="jd_05" />
        <JD_06 v-bind:param_opc="param_opcRaiz" v-if="jd_06" />
        <JD_07 v-bind:param_opc="param_opcRaiz" v-if="jd_07" />
        <JD_08 v-bind:param_opc="param_opcRaiz" v-if="jd_08" />

    </div> <!-- FIN DEL PANEL PRINCIPAL  -->
    
    <div class="col-lg-10" ref="toPdf" v-if="ocultala">
        <div class="panel-body"     v-if="ver_tabla === true">   <!-- SECCION DE LA TABLA  -->   
            <div class="row mb-3">
                <!-- Input de búsqueda -->
                <div class="col-md-3 text-right"><label class="text-small">Buscar:</label></div>
                <div class="col-md-5 text-right"><input v-model="searchTerm" class="form-control busqueda" /></div>
                </div>

                <div class="row">
                    <div class="col-md-12 datatable">
                        <!-- Configuración de la tabla con los campos a mostrar -->
                        <table-lite
                            :is-static-mode="true"
                            :columns="table.columns"
                            :rows="table.rows"
                            :total="table.totalRecordCount"
                            :sortable="table.sortable"
                            :messages="table.messages"
                        ></table-lite>
                    </div>
                </div>
        </div>
        
        <div class="panel-body" v-if="ver_indicadores === true">   <!-- SECCION DE LOS INDICADORES  -->  
            <JD_01 v-bind:param_opc="param_opcRaiz" v-if="jd_01" />
            <JD_02 v-bind:param_opc="param_opcRaiz" v-if="jd_02" />
            <JD_03 v-bind:param_opc="param_opcRaiz" v-if="jd_03" />
            <JD_04 v-bind:param_opc="param_opcRaiz" v-if="jd_04" />
            <JD_05 v-bind:param_opc="param_opcRaiz" v-if="jd_05" />
            <JD_06 v-bind:param_opc="param_opcRaiz" v-if="jd_06" />
            <JD_07 v-bind:param_opc="param_opcRaiz" v-if="jd_07" />
            <JD_08 v-bind:param_opc="param_opcRaiz" v-if="jd_08" />
        </div>
    </div>

</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
// Componente de la tabla
import TableLite from "@/components/DataTable/TableLite";

// Importo los helpers (API)
import getEstadist_indicadoresbyTipo from '@/helpers/getEstadist_indicadoresbyTipo'
import charsService from '@/services/CharsService';

import JD_01 from '@/components/Estadisticas/JD_01';
import JD_02 from '@/components/Estadisticas/JD_02';
import JD_03 from '@/components/Estadisticas/JD_03';
import JD_04 from '@/components/Estadisticas/JD_04';
import JD_05 from '@/components/Estadisticas/JD_05';
import JD_06 from '@/components/Estadisticas/JD_06';
import JD_07 from '@/components/Estadisticas/JD_07';
import JD_08 from '@/components/Estadisticas/JD_08';

import { ubApi, icons } from '@/api/baseUrl'
export default defineComponent({
    JD_01,
    JD_02,
    JD_03,
    JD_04,
    JD_05,
    JD_06,
    JD_07,
    JD_08,    

    components: { 
        TableLite, 
        JD_01,
        JD_02,
        JD_03,
        JD_04,
        JD_05,
        JD_06,
        JD_07,
        JD_08,        
    },
    
    data: ()  => ({
        ver_tabla: true,
        ver_indicadores: false,
        ocultala: false,

        DesplegarLista:true,
        BtnDesplegarLista:false,
        btn_grafica: icons.value + "/graph.png",
        btn_table: icons.value + "/table.png",
        btn_regresar: icons.value + "/btn_regresar.png",
        jd_01: false,
        jd_02: false,
        jd_03: false,
        jd_04: false,
        jd_05: false,
        jd_06: false,
        jd_07: false,
        jd_08: false,
    }),

    methods: {
        async DespliegaListaFunc() {
            this.resetAll()
            this.BtnDesplegarLista = false   
            this.DesplegarLista=true
        },
        async f_b_obj5(formulario,id_parametro ) {
            this.param_opcRaiz = id_parametro;
            this.BtnDesplegarLista = true
            this.resetAll()
            if (formulario == 1) {
                this.jd_01 = true             
            } else if (formulario == 2) {
                this.jd_02 = true
            } else if (formulario == 3) {
                this.jd_03 = true
            } else if (formulario == 4) {
                this.jd_04 = true
            } else if (formulario == 5) {
                this.jd_05 = true
            } else if (formulario == 6) {
                this.jd_06 = true
            } else if (formulario == 7) {
                this.jd_07 = true
            } else if (formulario == 8) {
                this.jd_08 = true
            }  
        },
        resetAll() {
            this.DesplegarLista=false
            this.jd_01 = false
            this.jd_02 = false
            this.jd_03 = false
            this.jd_04 = false
            this.jd_05 = false
            this.jd_06 = false
            this.jd_07 = false
            this.jd_08 = false
            
        },
    },
    
    setup() {
        const searchTerm = ref("") // Variable para los datos de búsqueda
        const data = reactive([]); // Variable para los datos a mostrar en la tabla

        (async () => {
            // Se crea la variable para el arreglo haciendo la llamada a la función de la api
            const dependenciasArr = await getEstadist_indicadoresbyTipo(1)

            // Genero el arreglo de datos para llenar la tabla
            const datos = dependenciasArr
            for (let i = 0; i < datos.length; i++) {
                data.push({
                    id: datos[i].id,
                    num_indicador: datos[i].num_indicador,
                    nom_indicador: datos[i].nom_indicador,
                    periodo: datos[i].periodo,
                });
            }
        })()
        
        // Configuración de la tabla
        const table = reactive({
            // Columnas de la tabla
            columns: [
                {
                    label: "Núm",
                    field: "num_indicador",
                    width: "2%",
                    sortable: true,
                },
                {
                    label: "Nombre del indicador",
                    field: "nom_indicador",
                    width: "73%",
                    sortable: true,
                },
                {
                    label: "Periodo",
                    field: "periodo",
                    width: "20%",
                    sortable: true,
                },
                // Botones de acciones (iconos)
                {
                    label: "",
                    field: "quick",
                    width: "5%",
                    display: function (row) {
                        return (
                            '<a class="btn" @click="DespliegaListaFunc(1)">boton </a>'
                       );
                    },
                },
            ],
            rows: computed(() => {
                // Aquí se designan las columnas en las que se realizará la búsqueda desde el input
                return data.filter(
                    (x) =>
                        x.nom_indicador.toLowerCase().includes(searchTerm.value.toLowerCase()) || x.periodo.toLowerCase().includes(searchTerm.value.toLowerCase())
                    );
            }),
            totalRecordCount: computed(() => {
                // Se regresa el número de registros encontrados
                return table.rows.length
            }),
            sortable: {
                // Se define el orden por default
                order: "id",
                sort: "asc",
            },
            messages: {
                // Personalizo los mensajes que muestra la tabla
                pagingInfo: "Mostrando {0}-{1} de {2}",
                pageSizeChangeLabel: "Filas:",
                gotoPageLabel: "   Ir a la página:",
                noDataAvailable: "Sin datos para mostrar",
            },
        });

        return {
            searchTerm,
            table,
        }
    }
})
</script>

<style scoped>
    .card :deep .table .thead-dark th {
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
    .card :deep .table td, .card :deep .table tr{
        border: none;
        width: 800px;
    }
    .text-small {
        font-size: medium;
    }
    .busqueda {
        font-size: medium;
        height: auto;
    }
    .text-info {
        color: #343a40 !important;
        padding: 0 20px;
    }
    .datatable {
        padding-bottom: 10px;
        overflow-x: auto;
        margin-bottom: 25px;
    }
    .card {
        border: 0 !important;
    }
</style>