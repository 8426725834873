/* Regresa todos los datos con el id enviado de la tabla 'cat_tipo_formula' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getTipo_formula = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_tipo_formula/?transform=1&filter=borrar,lt,1`)
    ]

    const [ cat_tipo_formula ] = await Promise.all( promiseArr )

    return cat_tipo_formula.data.cat_tipo_formula
}

export default getTipo_formula