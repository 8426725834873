<template>
    <hr class="red">
    <div v-show="form.muestra_cuest_IIPJ"> 
        
        <div class="mt-5 container principal text-left">
                <div class="container">
                    <div  v-for="e in datosPresup_juventud" :key="e.id">
                        <div class="row">
                            <div class="article-body" >
                                <p class="card-text" v-html="e.instrucciones"></p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <div class="container mb-5">
            <div class="card">
                <div class="card-body">
                    <form>
                        <h4>Cuestionario para evaluar la incorporación de la perspectiva de juventudes</h4>
                        <br>
                        
                        <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-form-label" to="nom_instancia" style="text-align: left">Nombre de la instancia:</label>
                            <div class="col-md-6 col-sm-12">
                                <input type="text" class="form-control" id="nom_instancia" name="nom_instancia" v-model="form.nom_instancia" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-form-label" to="nom_funcionario" style="text-align: left">Nombre de la persona funcionaria pública:</label>
                            <div class="col-md-6 col-sm-12">
                                <input type="text" class="form-control" id="nom_funcionario" name="nom_funcionario" v-model="form.nom_funcionario" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-form-label" to="nom_cargo" style="text-align: left">Cargo de la persona funcionaria pública:</label>
                            <div class="col-md-6 col-sm-12">
                                <input type="text" class="form-control" id="nom_cargo" name="nom_cargo" v-model="form.nom_cargo" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-form-label" to="email">Correo electrónico:</label>
                            <div class="col-md-6 col-sm-12">
                                <input type="text" class="form-control" id="email" name="email" v-model="form.email" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-sm-12 col-form-label" to="prog_presup" style="text-align: left">Nombre del programa presupuestario:</label>
                            <div class="col-md-6 col-sm-12">
                                <input type="text" class="form-control" id="prog_presup" name="prog_presup" v-model="form.prog_presup" />
                            </div>
                        </div>
                       
 
                        

<el-card class="box-card" style = "backgroundColor: #FBFCFC" shadow="hover">
    <template #header>
      <div class="card-header" >
        <span>Componentes del cuestionario para el calculo del IIPJ (5 componentes)</span>
      </div>
    </template>
                       

                                <div v-show="form.muestra_mod_1">    
                                    <el-scrollbar height="400px" class="scrollbar-demo-item" name="scroll_1">
                                        <h4>Componente 1. Problema público y juventudes</h4>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p1_1">1.1. ¿El programa atiende un problema, una necesidad, una demanda social, o una oportunidad de desarrollo de un grupo juvenil de forma que cambie la vida cotidiana de las personas jóvenes?</label>
                                            <div class="radio_cal" id="c1p1_1">
                                                <input type="radio" value="A" name="p1_1" id="p1_1_a" v-model="form.p1_1" class="radio_cal_r"> <label class="radio_cal_l" for="p1_1_a"> a) Sí, y lo identifica y enuncia con claridad. </label><br>
                                                <input type="radio" value="B" name="p1_1" id="p1_1_b" v-model="form.p1_1" class="radio_cal_r"> <label class="radio_cal_l" for="p1_1_b"> b) Sí, pero no lo identifica o enuncia con claridad. </label><br>
                                                <input type="radio" value="C" name="p1_1" id="p1_1_c" v-model="form.p1_1" class="radio_cal_r"> <label class="radio_cal_l" for="p1_1_c"> c) No, pero presenta elementos para identificarlo con claridad. </label><br>
                                                <input type="radio" value="D" name="p1_1" id="p1_1_d" v-model="form.p1_1" class="radio_cal_r"> <label class="radio_cal_l" for="p1_1_d"> d) No, y no presenta elementos para identificarlo con claridad. </label><br>
                                                <input type="radio" value="E" name="p1_1" id="p1_1_e" v-model="form.p1_1" class="radio_cal_r"> <label class="radio_cal_l" for="p1_1_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p1_2">1.2. ¿El programa incluye un diagnóstico de características sociodemográficas de la población objetivo, con estadísticas desagregadas por grupos de edad, que ilustran la situación de las personas jóvenes en el problema público que atiende el programa?</label>
                                            <div class="radio_cal" id="c1p1_2">
                                                <input type="radio" value="A" name="p1_2" id="p1_2_a" v-model="form.p1_2" class="radio_cal_r"> <label class="radio_cal_l" for="p1_2_a"> a) Sí, presenta un diagnóstico claro con estadísticas desagregadas por grupos de edad. </label><br>
                                                <input type="radio" value="B" name="p1_2" id="p1_2_b" v-model="form.p1_2" class="radio_cal_r"> <label class="radio_cal_l" for="p1_2_b"> b) Sí, presenta un diagnóstico relativamente claro pero sin estadísticas desagregadas por grupos de edad. </label><br>
                                                <input type="radio" value="C" name="p1_2" id="p1_2_c" v-model="form.p1_2" class="radio_cal_r"> <label class="radio_cal_l" for="p1_2_c"> c) No, pero presenta cierto diagnóstico relacionado con juventudes, aunque sin estadísticas claras desagregadas por grupos de edad. </label><br>
                                                <input type="radio" value="D" name="p1_2" id="p1_2_d" v-model="form.p1_2" class="radio_cal_r"> <label class="radio_cal_l" for="p1_2_d"> d) No presenta un diagnóstico relacionado con juventudes. </label><br>
                                                <input type="radio" value="E" name="p1_2" id="p1_2_e" v-model="form.p1_2" class="radio_cal_r"> <label class="radio_cal_l" for="p1_2_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p1_3">1.3. ¿El programa identifica y atiende el problema público de manera diferenciada entre mujeres y hombres jóvenes?</label>
                                            <div class="radio_cal" id="c1p1_3">
                                                <input type="radio" value="A" name="p1_3" id="p1_3_a" v-model="form.p1_3" class="radio_cal_r"> <label class="radio_cal_l" for="p1_3_a"> a) Sí, lo identifica y atiende. </label><br>
                                                <input type="radio" value="B" name="p1_3" id="p1_3_b" v-model="form.p1_3" class="radio_cal_r"> <label class="radio_cal_l" for="p1_3_b"> b) Sí, lo identifica, pero no está muy claro si lo atiende. </label><br>
                                                <input type="radio" value="C" name="p1_3" id="p1_3_c" v-model="form.p1_3" class="radio_cal_r"> <label class="radio_cal_l" for="p1_3_c"> c) Sí, aunque no lo identifica de manera clara ni lo atiende. </label><br>
                                                <input type="radio" value="D" name="p1_3" id="p1_3_d" v-model="form.p1_3" class="radio_cal_r"> <label class="radio_cal_l" for="p1_3_d"> d) No, no lo identifica ni lo atiende. </label><br>
                                                <input type="radio" value="E" name="p1_3" id="p1_3_e" v-model="form.p1_3" class="radio_cal_r"> <label class="radio_cal_l" for="p1_3_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p1_4">1.4. ¿El programa identifica y atiende la forma en que el problema público tiene efectos en las trayectorias de curso de vida de las personas jóvenes?</label>
                                            <div class="radio_cal" id="c1p1_4">
                                                <input type="radio" value="A" name="p1_4" id="p1_4_a" v-model="form.p1_4" class="radio_cal_r"> <label class="radio_cal_l" for="p1_4_a"> a) Sí, lo identifica y atiende. </label><br>
                                                <input type="radio" value="B" name="p1_4" id="p1_4_b" v-model="form.p1_4" class="radio_cal_r"> <label class="radio_cal_l" for="p1_4_b"> b) Sí, lo identifica, pero no está muy claro si lo atiende. </label><br>
                                                <input type="radio" value="C" name="p1_4" id="p1_4_c" v-model="form.p1_4" class="radio_cal_r"> <label class="radio_cal_l" for="p1_4_c"> c) Sí, aunque no lo identifica de manera clara ni lo atiende. </label><br>
                                                <input type="radio" value="D" name="p1_4" id="p1_4_d" v-model="form.p1_4" class="radio_cal_r"> <label class="radio_cal_l" for="p1_4_d"> d) No, no lo identifica ni lo atiende. </label><br>
                                                <input type="radio" value="E" name="p1_4" id="p1_4_e" v-model="form.p1_4" class="radio_cal_r"> <label class="radio_cal_l" for="p1_4_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p1_5">1.5. ¿El programa identifica y atiende la forma en que el problema público representa un obstáculo para la autonomía de las personas jóvenes?</label>
                                            <div class="radio_cal" id="c1p1_5">
                                                <input type="radio" value="A" name="p1_5" id="p1_5_a" v-model="form.p1_5" class="radio_cal_r"> <label class="radio_cal_l" for="p1_5_a"> a) Sí, lo identifica y atiende. </label><br>
                                                <input type="radio" value="B" name="p1_5" id="p1_5_b" v-model="form.p1_5" class="radio_cal_r"> <label class="radio_cal_l" for="p1_5_b"> b) Sí, lo identifica, pero no está muy claro si lo atiende. </label><br>
                                                <input type="radio" value="C" name="p1_5" id="p1_5_c" v-model="form.p1_5" class="radio_cal_r"> <label class="radio_cal_l" for="p1_5_c"> c) Sí, aunque no lo identifica de manera clara ni lo atiende. </label><br>
                                                <input type="radio" value="D" name="p1_5" id="p1_5_d" v-model="form.p1_5" class="radio_cal_r"> <label class="radio_cal_l" for="p1_5_d"> d) No, no lo identifica ni lo atiende. </label><br>
                                                <input type="radio" value="E" name="p1_5" id="p1_5_e" v-model="form.p1_5" class="radio_cal_r"> <label class="radio_cal_l" for="p1_5_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                    </el-scrollbar>
                                </div> <!-- De v-show muestra_mod_1 -->    
                                <div v-show="form.muestra_mod_2"> 
                                    <el-scrollbar height="400px" class="scrollbar-demo-item" name="scroll_2">
                                        <h4>Componente 2. Objetivos y metas con perspectiva de juventudes</h4>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p2_1">2.1. En el diseño del programa, ¿se establece la forma en que la población joven sería la beneficiaria del programa o acción resultante?</label>
                                            <div class="radio_cal" id="c1p2_1">
                                                <input type="radio" value="A" name="p2_1" id="p2_1_a" v-model="form.p2_1" class="radio_cal_r"> <label class="radio_cal_l" for="p2_1_a"> a) Sí, se establece de manera explícita y clara. </label><br>
                                                <input type="radio" value="B" name="p2_1" id="p2_1_b" v-model="form.p2_1" class="radio_cal_r"> <label class="radio_cal_l" for="p2_1_b"> b) Sí, aunque no se presenta de manera explícita. </label><br>
                                                <input type="radio" value="C" name="p2_1" id="p2_1_c" v-model="form.p2_1" class="radio_cal_r"> <label class="radio_cal_l" for="p2_1_c"> c) No, pero hay una mención indirecta a las personas jóvenes. </label><br>
                                                <input type="radio" value="D" name="p2_1" id="p2_1_d" v-model="form.p2_1" class="radio_cal_r"> <label class="radio_cal_l" for="p2_1_d"> d) No, no se menciona de forma alguna. </label><br>
                                                <input type="radio" value="E" name="p2_1" id="p2_1_e" v-model="form.p2_1" class="radio_cal_r"> <label class="radio_cal_l" for="p2_1_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p2_2">2.2. En los objetivos general y/o específicos del programa, ¿se menciona a las personas jóvenes con las palabras “jóvenes”, “juventudes”, o en referencia al grupo de edad entre 15 y 29 años?</label>
                                            <div class="radio_cal" id="c1p2_2">
                                                <input type="radio" value="A" name="p2_2" id="p2_2_a" v-model="form.p2_2" class="radio_cal_r"> <label class="radio_cal_l" for="p2_2_a"> a) Sí, tanto el objetivo general como al menos uno de los objetivos específicos hacen mención explícita. </label><br>
                                                <input type="radio" value="B" name="p2_2" id="p2_2_b" v-model="form.p2_2" class="radio_cal_r"> <label class="radio_cal_l" for="p2_2_b"> b) Sí, al menos uno de los objetivos específicos hace mención explícita. </label><br>
                                                <input type="radio" value="C" name="p2_2" id="p2_2_c" v-model="form.p2_2" class="radio_cal_r"> <label class="radio_cal_l" for="p2_2_c"> c) No, pero está implícito en los objetivos general o específicos. </label><br>
                                                <input type="radio" value="D" name="p2_2" id="p2_2_d" v-model="form.p2_2" class="radio_cal_r"> <label class="radio_cal_l" for="p2_2_d"> d) No, no se menciona de forma alguna. </label><br>
                                                <input type="radio" value="E" name="p2_2" id="p2_2_e" v-model="form.p2_2" class="radio_cal_r"> <label class="radio_cal_l" for="p2_2_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p2_3">2.3. ¿Los medios que se proponen para solucionar el problema estén relacionados con las líneas de acción del PROJUVENTUD 2021-2024?</label>
                                            <div class="radio_cal" id="c1p2_3">
                                                <input type="radio" value="A" name="p2_3" id="p2_3_a" v-model="form.p2_3" class="radio_cal_r"> <label class="radio_cal_l" for="p2_3_a"> a) Sí, están relacionados y se enuncia explícitamente. </label><br>
                                                <input type="radio" value="B" name="p2_3" id="p2_3_b" v-model="form.p2_3" class="radio_cal_r"> <label class="radio_cal_l" for="p2_3_b"> b) Sí, están relacionados pero no se enuncia de manera explícita la línea de acción. </label><br>
                                                <input type="radio" value="C" name="p2_3" id="p2_3_c" v-model="form.p2_3" class="radio_cal_r"> <label class="radio_cal_l" for="p2_3_c"> c) No, pero el programa tiene relación indirecta con una línea de acción del PROJUVENTUD. </label><br>
                                                <input type="radio" value="D" name="p2_3" id="p2_3_d" v-model="form.p2_3" class="radio_cal_r"> <label class="radio_cal_l" for="p2_3_d"> d) No, y no tiene relación con alguna línea de acción del PROJUVENTUD. </label><br>
                                                <input type="radio" value="E" name="p2_3" id="p2_3_e" v-model="form.p2_3" class="radio_cal_r"> <label class="radio_cal_l" for="p2_3_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p2_4">2.4. En los objetivos, ya sea general y/o específicos, ¿se vincula al menos a un derecho de las personas jóvenes y orientarlo a fortalecer la autonomía y capacidades de las juventudes?</label>
                                            <div class="radio_cal" id="c1p2_4">
                                                <input type="radio" value="A" name="p2_4" id="p2_4_a" v-model="form.p2_4" class="radio_cal_r"> <label class="radio_cal_l" for="p2_4_a"> a) Sí, tanto el objetivo general como al menos uno de los específicos se vincula al menos a un derecho de las personas jóvenes de manera explícita. </label><br>
                                                <input type="radio" value="B" name="p2_4" id="p2_4_b" v-model="form.p2_4" class="radio_cal_r"> <label class="radio_cal_l" for="p2_4_b"> b) Sí, al menos uno de los objetivos específicos se vincula al menos a un derecho de las personas jóvenes de manera explícita. </label><br>
                                                <input type="radio" value="C" name="p2_4" id="p2_4_c" v-model="form.p2_4" class="radio_cal_r"> <label class="radio_cal_l" for="p2_4_c"> c) Sí, pero no se relaciona de manera explícita. </label><br>
                                                <input type="radio" value="D" name="p2_4" id="p2_4_d" v-model="form.p2_4" class="radio_cal_r"> <label class="radio_cal_l" for="p2_4_d"> d) No, no están vinculados con un derecho de las personas jóvenes. </label><br>
                                                <input type="radio" value="E" name="p2_4" id="p2_4_e" v-model="form.p2_4" class="radio_cal_r"> <label class="radio_cal_l" for="p2_4_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p2_5">2.5. En la descripción de la población a la que va dirigido el programa (personas, organizaciones, empresas, etc.), ¿se incluyen las características de las personas jóvenes diferenciadas por condición económica, social, de género, étnica, de identidad cultural o regional, entre otras?</label>
                                            <div class="radio_cal" id="c1p2_5">
                                                <input type="radio" value="A" name="p2_5" id="p2_5_a" v-model="form.p2_5" class="radio_cal_r"> <label class="radio_cal_l" for="p2_5_a"> a) Sí, se incluyen de manera diferenciada al menos tres características, incluyendo condición étnica. </label><br>
                                                <input type="radio" value="B" name="p2_5" id="p2_5_b" v-model="form.p2_5" class="radio_cal_r"> <label class="radio_cal_l" for="p2_5_b"> b) Sí, se incluyen de manera diferenciada al menos dos características. </label><br>
                                                <input type="radio" value="C" name="p2_5" id="p2_5_c" v-model="form.p2_5" class="radio_cal_r"> <label class="radio_cal_l" for="p2_5_c"> c) Sí, se incluye de manera diferenciada al menos una característica. </label><br>
                                                <input type="radio" value="D" name="p2_5" id="p2_5_d" v-model="form.p2_5" class="radio_cal_r"> <label class="radio_cal_l" for="p2_5_d"> d) No, no se incluyen. </label><br>
                                                <input type="radio" value="E" name="p2_5" id="p2_5_e" v-model="form.p2_5" class="radio_cal_r"> <label class="radio_cal_l" for="p2_5_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                    </el-scrollbar>
                                </div> <!-- De v-show muestra_mod_2 -->    
                                <div v-show="form.muestra_mod_3"> 
                                    <el-scrollbar height="400px" class="scrollbar-demo-item" name="scroll_3">
                                        <h4>Componente 3. Presupuesto para juventudes</h4>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p3_1">3.1. ¿El presupuesto del programa para las personas jóvenes es fácilmente identificable en los documentos presupuestarios?</label>
                                            <div class="radio_cal" id="c1p3_1">
                                                <input type="radio" value="A" name="p3_1" id="p3_1_a" v-model="form.p3_1" class="radio_cal_r"> <label class="radio_cal_l" for="p3_1_a"> a) Sí, se presenta el programa con sus vertientes bien definidas. </label><br>
                                                <input type="radio" value="B" name="p3_1" id="p3_1_b" v-model="form.p3_1" class="radio_cal_r"> <label class="radio_cal_l" for="p3_1_b"> b) Sí, pero no es fácil identificar el dinero asignado a las juventudes. </label><br>
                                                <input type="radio" value="C" name="p3_1" id="p3_1_c" v-model="form.p3_1" class="radio_cal_r"> <label class="radio_cal_l" for="p3_1_c"> c) No, pero se presentan algunos elementos que apuntan a la asignación. </label><br>
                                                <input type="radio" value="D" name="p3_1" id="p3_1_d" v-model="form.p3_1" class="radio_cal_r"> <label class="radio_cal_l" for="p3_1_d"> d) No, no existen elementos para su identificación. </label><br>
                                                <input type="radio" value="E" name="p3_1" id="p3_1_e" v-model="form.p3_1" class="radio_cal_r"> <label class="radio_cal_l" for="p3_1_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p3_2">3.2. ¿Los documentos presupuestarios –al menos los informes trimestrales y la cuenta pública– contienen una explicación narrativa sobre la forma en que el presupuesto asignado y ejercido beneficiará a las personas jóvenes?</label>
                                            <div class="radio_cal" id="c1p3_2">
                                                <input type="radio" value="A" name="p3_2" id="p3_2_a" v-model="form.p3_2" class="radio_cal_r"> <label class="radio_cal_l" for="p3_2_a"> a) Sí, tanto los informes trimestrales como la cuenta pública contienen una explicación narrativa al respecto. </label><br>
                                                <input type="radio" value="B" name="p3_2" id="p3_2_b" v-model="form.p3_2" class="radio_cal_r"> <label class="radio_cal_l" for="p3_2_b"> b) Sí, al menos uno de estos informes contiene una explicación narrativa. </label><br>
                                                <input type="radio" value="C" name="p3_2" id="p3_2_c" v-model="form.p3_2" class="radio_cal_r"> <label class="radio_cal_l" for="p3_2_c"> c) No, pero se presentan elementos relacionados con juventudes en alguno de los documentos presupuestarios. </label><br>
                                                <input type="radio" value="D" name="p3_2" id="p3_2_d" v-model="form.p3_2" class="radio_cal_r"> <label class="radio_cal_l" for="p3_2_d"> d) No, no se presenta una explicación narrativa al respecto. </label><br>
                                                <input type="radio" value="E" name="p3_2" id="p3_2_e" v-model="form.p3_2" class="radio_cal_r"> <label class="radio_cal_l" for="p3_2_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p3_3">3.3. ¿El programa presupuestario cuenta con presupuesto asignado en el ejercicio fiscal en curso, especialmente para los componentes y vertientes relacionados con juventudes?</label>
                                            <div class="radio_cal" id="c1p3_3">
                                                <input type="radio" value="A" name="p3_3" id="p3_3_a" v-model="form.p3_3" class="radio_cal_r"> <label class="radio_cal_l" for="p3_3_a"> a) Sí, el programa cuenta con presupuesto asignado en este ejercicio fiscal para los componentes y vertientes relacionados con juventudes. </label><br>
                                                <input type="radio" value="B" name="p3_3" id="p3_3_b" v-model="form.p3_3" class="radio_cal_r"> <label class="radio_cal_l" for="p3_3_b"> b) Sí, el programa cuenta con presupuesto asignado en este ejercicio fiscal, pero no es claro que sea así para los componentes y vertientes relacionados con juventudes. </label><br>
                                                <input type="radio" value="C" name="p3_3" id="p3_3_c" v-model="form.p3_3" class="radio_cal_r"> <label class="radio_cal_l" for="p3_3_c"> c) Sí, el programa cuenta con presupuesto asignado en este ejercicio fiscal, pero no para los componentes y vertientes relacionados con juventudes. </label><br>
                                                <input type="radio" value="D" name="p3_3" id="p3_3_d" v-model="form.p3_3" class="radio_cal_r"> <label class="radio_cal_l" for="p3_3_d"> d) No, el programa no cuenta con presupuesto asignado en este ejercicio fiscal. </label><br>
                                                <input type="radio" value="E" name="p3_3" id="p3_3_e" v-model="form.p3_3" class="radio_cal_r"> <label class="radio_cal_l" for="p3_3_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p3_4">3.4. ¿El presupuesto aprobado para el programa –especialmente para los componentes y vertientes relacionados con juventudes– presentó reasignaciones negativas y/o subejercicios en el ejercicio fiscal inmediato anterior?</label>
                                            <div class="radio_cal" id="c1p3_4">
                                                <input type="radio" value="A" name="p3_4" id="p3_4_a" v-model="form.p3_4" class="radio_cal_r"> <label class="radio_cal_l" for="p3_4_a"> a) No, el presupuesto aprobado no tuvo reasignaciones negativas ni subejercicios en el ejercicio fiscal inmediato anterior. </label><br>
                                                <input type="radio" value="B" name="p3_4" id="p3_4_b" v-model="form.p3_4" class="radio_cal_r"> <label class="radio_cal_l" for="p3_4_b"> b) Sí, el presupuesto aprobado tuvo una reasignación negativa, pero no tuvo subejercicio al final del ejercicio fiscal inmediato anterior. </label><br>
                                                <input type="radio" value="C" name="p3_4" id="p3_4_c" v-model="form.p3_4" class="radio_cal_r"> <label class="radio_cal_l" for="p3_4_c"> c) Sí, el presupuesto aprobado tuvo un subejercicio al final del ejercicio fiscal inmediato anterior, pero no tuvo una reasignación negativa en las modificaciones de medio año. </label><br>
                                                <input type="radio" value="D" name="p3_4" id="p3_4_d" v-model="form.p3_4" class="radio_cal_r"> <label class="radio_cal_l" for="p3_4_d"> d) Sí, el presupuesto aprobado tuvo una reasignación negativa y un subejercicio en el ejercicio fiscal inmediato anterior. </label><br>
                                                <input type="radio" value="E" name="p3_4" id="p3_4_e" v-model="form.p3_4" class="radio_cal_r"> <label class="radio_cal_l" for="p3_4_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p3_5">3.5. ¿El presupuesto aprobado para el programa –especialmente para los componentes y vertientes relacionados con juventudes– es igual o mayor en términos reales al del ejercicio fiscal anterior?</label>
                                            <div class="radio_cal" id="c1p3_5">
                                                <input type="radio" value="A" name="p3_5" id="p3_5_a" v-model="form.p3_5" class="radio_cal_r"> <label class="radio_cal_l" for="p3_5_a"> a) Sí, el presupuesto se incrementó en términos reales respecto al ejercicio fiscal anterior. </label><br>
                                                <input type="radio" value="B" name="p3_5" id="p3_5_b" v-model="form.p3_5" class="radio_cal_r"> <label class="radio_cal_l" for="p3_5_b"> b) Sí, el presupuesto se mantuvo igual en términos reales respecto al ejercicio fiscal anterior. </label><br>
                                                <input type="radio" value="C" name="p3_5" id="p3_5_c" v-model="form.p3_5" class="radio_cal_r"> <label class="radio_cal_l" for="p3_5_c"> c) No, el presupuesto se redujo en términos reales pero tuvo una variación negativa menor al 5 por ciento respecto al ejercicio fiscal anterior. </label><br>
                                                <input type="radio" value="D" name="p3_5" id="p3_5_d" v-model="form.p3_5" class="radio_cal_r"> <label class="radio_cal_l" for="p3_5_d"> d) No, el presupuesto se redujo en términos reales más de 5 por ciento respecto al ejercicio fiscal anterior. </label><br>
                                                <input type="radio" value="E" name="p3_5" id="p3_5_e" v-model="form.p3_5" class="radio_cal_r"> <label class="radio_cal_l" for="p3_5_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                    </el-scrollbar>
                                </div> <!-- De v-show muestra_mod_3 -->    
                                <div v-show="form.muestra_mod_4"> 
                                    <el-scrollbar height="400px" class="scrollbar-demo-item" name="scroll_4">
                                        <h4>Componente 4. Indicadores de juventudes</h4>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p4_1">4.1. ¿El programa presupuestario cuenta con indicadores definidos que midan los procesos e impactos diferenciados para las personas jóvenes?</label>
                                            <div class="radio_cal" id="c1p4_1">
                                                <input type="radio" value="A" name="p4_1" id="p4_1_a" v-model="form.p4_1" class="radio_cal_r"> <label class="radio_cal_l" for="p4_1_a"> a) Sí, tiene indicadores definidos y miden impactos diferenciados. </label><br>
                                                <input type="radio" value="B" name="p4_1" id="p4_1_b" v-model="form.p4_1" class="radio_cal_r"> <label class="radio_cal_l" for="p4_1_b"> b) Sí, tiene indicadores definidos para juventudes pero no miden impactos diferenciados. </label><br>
                                                <input type="radio" value="C" name="p4_1" id="p4_1_c" v-model="form.p4_1" class="radio_cal_r"> <label class="radio_cal_l" for="p4_1_c"> c) Sí, tiene indicadores aunque no están bien definidos para juventudes ni miden impactos diferenciados. </label><br>
                                                <input type="radio" value="D" name="p4_1" id="p4_1_d" v-model="form.p4_1" class="radio_cal_r"> <label class="radio_cal_l" for="p4_1_d"> d) No, no tiene indicadores definidos ni miden impactos diferenciados. </label><br>
                                                <input type="radio" value="E" name="p4_1" id="p4_1_e" v-model="form.p4_1" class="radio_cal_r"> <label class="radio_cal_l" for="p4_1_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p4_2">4.2. ¿Los indicadores miden las características cualitativas de las personas jóvenes beneficiadas –de manera directa o indirecta– por el programa o solamente su número?</label>
                                            <div class="radio_cal" id="c1p4_2">
                                                <input type="radio" value="A" name="p4_2" id="p4_2_a" v-model="form.p4_2" class="radio_cal_r"> <label class="radio_cal_l" for="p4_2_a"> a) Sí, miden estas características cualitativas de manera directa. </label><br>
                                                <input type="radio" value="B" name="p4_2" id="p4_2_b" v-model="form.p4_2" class="radio_cal_r"> <label class="radio_cal_l" for="p4_2_b"> b) Sí, miden estas características cualitativas de manera indirecta. </label><br>
                                                <input type="radio" value="C" name="p4_2" id="p4_2_c" v-model="form.p4_2" class="radio_cal_r"> <label class="radio_cal_l" for="p4_2_c"> c) No, sólo miden el número de personas jóvenes que se benefician por el programa. </label><br>
                                                <input type="radio" value="D" name="p4_2" id="p4_2_d" v-model="form.p4_2" class="radio_cal_r"> <label class="radio_cal_l" for="p4_2_d"> d) No, no mide ni el número de personas jóvenes que se benefician por el programa ni sus características. </label><br>
                                                <input type="radio" value="E" name="p4_2" id="p4_2_e" v-model="form.p4_2" class="radio_cal_r"> <label class="radio_cal_l" for="p4_2_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p4_3">4.3. ¿Los indicadores están desagregados considerando las diferencias de las personas jóvenes entre sí y respecto a otros grupos de la población, de acuerdo con su condición social, económica, de género, étnica, identidad cultural, identidad regional, entre otras?</label>
                                            <div class="radio_cal" id="c1p4_3">
                                                <input type="radio" value="A" name="p4_3" id="p4_3_a" v-model="form.p4_3" class="radio_cal_r"> <label class="radio_cal_l" for="p4_3_a"> a) Sí, los indicadores están desagregados por al menos tres de estas características, incluyendo condición étnica. </label><br>
                                                <input type="radio" value="B" name="p4_3" id="p4_3_b" v-model="form.p4_3" class="radio_cal_r"> <label class="radio_cal_l" for="p4_3_b"> b) Sí, los indicadores están desagregados por al menos dos de estas características. </label><br>
                                                <input type="radio" value="C" name="p4_3" id="p4_3_c" v-model="form.p4_3" class="radio_cal_r"> <label class="radio_cal_l" for="p4_3_c"> c) Sí, los indicadores están desagregados por al menos una de estas características. </label><br>
                                                <input type="radio" value="D" name="p4_3" id="p4_3_d" v-model="form.p4_3" class="radio_cal_r"> <label class="radio_cal_l" for="p4_3_d"> d) No, los indicadores no están desagregados. </label><br>
                                                <input type="radio" value="E" name="p4_3" id="p4_3_e" v-model="form.p4_3" class="radio_cal_r"> <label class="radio_cal_l" for="p4_3_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p4_4">4.4. ¿Los indicadores miden la cobertura de los bienes y/o servicios entregados específicamente a las personas jóvenes?</label>
                                            <div class="radio_cal" id="c1p4_4">
                                                <input type="radio" value="A" name="p4_4" id="p4_4_a" v-model="form.p4_4" class="radio_cal_r"> <label class="radio_cal_l" for="p4_4_a"> a) Sí, miden la cobertura de personas jóvenes de manera desagregada entre distintas condiciones e identidades. </label><br>
                                                <input type="radio" value="B" name="p4_4" id="p4_4_b" v-model="form.p4_4" class="radio_cal_r"> <label class="radio_cal_l" for="p4_4_b"> b) Sí, miden la cobertura de personas jóvenes aunque no de manera desagregada. </label><br>
                                                <input type="radio" value="C" name="p4_4" id="p4_4_c" v-model="form.p4_4" class="radio_cal_r"> <label class="radio_cal_l" for="p4_4_c"> c) Sí, miden la cobertura de personas jóvenes aunque de manera indirecta. </label><br>
                                                <input type="radio" value="D" name="p4_4" id="p4_4_d" v-model="form.p4_4" class="radio_cal_r"> <label class="radio_cal_l" for="p4_4_d"> d) No, no miden la cobertura de personas jóvenes. </label><br>
                                                <input type="radio" value="E" name="p4_4" id="p4_4_e" v-model="form.p4_4" class="radio_cal_r"> <label class="radio_cal_l" for="p4_4_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p4_5">4.5. ¿Los indicadores miden la calidad de los bienes y/o servicios entregados específicamente a las personas jóvenes?</label>
                                            <div class="radio_cal" id="c1p4_5">
                                                <input type="radio" value="A" name="p4_5" id="p4_5_a" v-model="form.p4_5" class="radio_cal_r"> <label class="radio_cal_l" for="p4_5_a"> a) Sí, miden la calidad de manera diferenciada para las personas jóvenes y desagregada entre distintas condiciones e identidades. </label><br>
                                                <input type="radio" value="B" name="p4_5" id="p4_5_b" v-model="form.p4_5" class="radio_cal_r"> <label class="radio_cal_l" for="p4_5_b"> b) Sí, miden la calidad de manera diferenciada para las personas jóvenes, aunque no de manera desagregada entre distintas condiciones e identidades. </label><br>
                                                <input type="radio" value="C" name="p4_5" id="p4_5_c" v-model="form.p4_5" class="radio_cal_r"> <label class="radio_cal_l" for="p4_5_c"> c) Sí, miden la calidad pero no de manera diferenciada para las personas jóvenes. </label><br>
                                                <input type="radio" value="D" name="p4_5" id="p4_5_d" v-model="form.p4_5" class="radio_cal_r"> <label class="radio_cal_l" for="p4_5_d"> d) No, no miden la calidad de los bienes y/o servicios entregados. </label><br>
                                                <input type="radio" value="E" name="p4_5" id="p4_5_e" v-model="form.p4_5" class="radio_cal_r"> <label class="radio_cal_l" for="p4_5_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                    </el-scrollbar>
                                </div> <!-- De v-show muestra_mod_4 -->    
                                <div v-show="form.muestra_mod_5"> 
                                    <el-scrollbar height="400px" class="scrollbar-demo-item" name="scroll_5">
                                        <h4>Componente 5. Participación sustantiva de las juventudes</h4>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p5_1">5.1. ¿Las actividades propuestas posibilitan de manera activa la participación sustantiva y vinculante de las personas jóvenes en roles protagónicos?</label>
                                            <div class="radio_cal" id="c1p5_1">
                                                <input type="radio" value="A" name="p5_1" id="p5_1_a" v-model="form.p5_1" class="radio_cal_r"> <label class="radio_cal_l" for="p5_1_a"> a) Sí, posibilitan la participación sustantiva y tiene carácter vinculante. </label><br>
                                                <input type="radio" value="B" name="p5_1" id="p5_1_b" v-model="form.p5_1" class="radio_cal_r"> <label class="radio_cal_l" for="p5_1_b"> b) Sí, posibilitan la participación sustantiva pero no tienen carácter vinculante. </label><br>
                                                <input type="radio" value="C" name="p5_1" id="p5_1_c" v-model="form.p5_1" class="radio_cal_r"> <label class="radio_cal_l" for="p5_1_c"> c) Sí, pero sólo posibilitan la participación de manera consultiva. </label><br>
                                                <input type="radio" value="D" name="p5_1" id="p5_1_d" v-model="form.p5_1" class="radio_cal_r"> <label class="radio_cal_l" for="p5_1_d"> d) No, no la posibilitan. </label><br>
                                                <input type="radio" value="E" name="p5_1" id="p5_1_e" v-model="form.p5_1" class="radio_cal_r"> <label class="radio_cal_l" for="p5_1_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p5_2">5.2. ¿Las actividades incluyen la capacitación y/o certificación de las personas servidoras públicas para sensibilizarles en la perspectiva de juventudes?</label>
                                            <div class="radio_cal" id="c1p5_2">
                                                <input type="radio" value="A" name="p5_2" id="p5_2_a" v-model="form.p5_2" class="radio_cal_r"> <label class="radio_cal_l" for="p5_2_a"> a) Sí, se incluye la certificación y cuenta con mecanismos adecuados. </label><br>
                                                <input type="radio" value="B" name="p5_2" id="p5_2_b" v-model="form.p5_2" class="radio_cal_r"> <label class="radio_cal_l" for="p5_2_b"> b) Sí, se incluye la certificación, pero no cuenta con mecanismos adecuados. </label><br>
                                                <input type="radio" value="C" name="p5_2" id="p5_2_c" v-model="form.p5_2" class="radio_cal_r"> <label class="radio_cal_l" for="p5_2_c"> c) No, sólo se incluye una capacitación con mecanismos poco claros. </label><br>
                                                <input type="radio" value="D" name="p5_2" id="p5_2_d" v-model="form.p5_2" class="radio_cal_r"> <label class="radio_cal_l" for="p5_2_d"> d) No, no se incluye capacitación alguna. </label><br>
                                                <input type="radio" value="E" name="p5_2" id="p5_2_e" v-model="form.p5_2" class="radio_cal_r"> <label class="radio_cal_l" for="p5_2_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p5_3">5.3. ¿El programa proporciona habilidades y conocimientos a las personas jóvenes para que su participación sea sustantiva y vinculante?</label>
                                            <div class="radio_cal" id="c1p5_3">
                                                <input type="radio" value="A" name="p5_3" id="p5_3_a" v-model="form.p5_3" class="radio_cal_r"> <label class="radio_cal_l" for="p5_3_a"> a) Sí, proporciona habilidades y conocimientos medibles y verificables. </label><br>
                                                <input type="radio" value="B" name="p5_3" id="p5_3_b" v-model="form.p5_3" class="radio_cal_r"> <label class="radio_cal_l" for="p5_3_b"> b) Sí, proporciona habilidades y conocimientos, aunque su medición y verificación es dudosa. </label><br>
                                                <input type="radio" value="C" name="p5_3" id="p5_3_c" v-model="form.p5_3" class="radio_cal_r"> <label class="radio_cal_l" for="p5_3_c"> c) Sí, proporciona habilidades y conocimientos pero hasta cierto alcance. </label><br>
                                                <input type="radio" value="D" name="p5_3" id="p5_3_d" v-model="form.p5_3" class="radio_cal_r"> <label class="radio_cal_l" for="p5_3_d"> d) No, no proporciona habilidades y conocimientos. </label><br>
                                                <input type="radio" value="E" name="p5_3" id="p5_3_e" v-model="form.p5_3" class="radio_cal_r"> <label class="radio_cal_l" for="p5_3_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p5_4">5.4. ¿El programa considera actividades que estimulan el deseo de las personas jóvenes de querer utilizar los espacios de participación y de formación que se pongan al alcance?</label>
                                            <div class="radio_cal" id="c1p5_4">
                                                <input type="radio" value="A" name="p5_4" id="p5_4_a" v-model="form.p5_4" class="radio_cal_r"> <label class="radio_cal_l" for="p5_4_a"> a) Sí, considera actividades medibles y verificables. </label><br>
                                                <input type="radio" value="B" name="p5_4" id="p5_4_b" v-model="form.p5_4" class="radio_cal_r"> <label class="radio_cal_l" for="p5_4_b"> b) Sí, considera actividades, aunque su medición y verificación es dudosa. </label><br>
                                                <input type="radio" value="C" name="p5_4" id="p5_4_c" v-model="form.p5_4" class="radio_cal_r"> <label class="radio_cal_l" for="p5_4_c"> c) Sí, considera actividades pero hasta cierto alcance. </label><br>
                                                <input type="radio" value="D" name="p5_4" id="p5_4_d" v-model="form.p5_4" class="radio_cal_r"> <label class="radio_cal_l" for="p5_4_d"> d) No, no considera actividades de este tipo. </label><br>
                                                <input type="radio" value="E" name="p5_4" id="p5_4_e" v-model="form.p5_4" class="radio_cal_r"> <label class="radio_cal_l" for="p5_4_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-12 col-form-label" for="p5_5">5.5. ¿El programa propone la participación sustantiva de las personas jóvenes a lo largo de todo el ciclo presupuestario?</label>
                                            <div class="radio_cal" id="c1p5_5">
                                                <input type="radio" value="A" name="p5_5" id="p5_5_a" v-model="form.p5_5" class="radio_cal_r"> <label class="radio_cal_l" for="p5_5_a"> a) Sí, el programa incluye mecanismos de participación sustantiva para al menos dos etapas del ciclo presupuestario. </label><br>
                                                <input type="radio" value="B" name="p5_5" id="p5_5_b" v-model="form.p5_5" class="radio_cal_r"> <label class="radio_cal_l" for="p5_5_b"> b) Sí, el programa incluye mecanismos de participación sustantiva para al menos una etapa del ciclo presupuestario. </label><br>
                                                <input type="radio" value="C" name="p5_5" id="p5_5_c" v-model="form.p5_5" class="radio_cal_r"> <label class="radio_cal_l" for="p5_5_c"> c) Sí, el programa incluye mecanismos de participación para al menos una etapa del ciclo presupuestario, pero son sólo consultivos. </label><br>
                                                <input type="radio" value="D" name="p5_5" id="p5_5_d" v-model="form.p5_5" class="radio_cal_r"> <label class="radio_cal_l" for="p5_5_d"> d) No, el programa no incluye mecanismos de participación. </label><br>
                                                <input type="radio" value="E" name="p5_5" id="p5_5_e" v-model="form.p5_5" class="radio_cal_r"> <label class="radio_cal_l" for="p5_5_e"> e) No aplica. </label><br>
                                            </div>
                                        </div>
                                    </el-scrollbar>
                                </div> <!-- De v-show muestra_mod_5 -->    

</el-card>

                        <el-steps :active="this.paso_activo" finish-status="success" align-center>
                            <el-step title="Componentes" />
                            <el-step title="" />
                            <el-step title="" />
                            <el-step title="" />
                            <el-step title="" />
                            <el-step title="Calculo IIPJ" />
                        </el-steps>

                    </form>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-6 text-right">
                            <button class="btn btn-primary btn-sm active btnCond mb-4" @click="sendForm" :disabled="saveDisabled"><i class="glyphicon glyphicon-saved"></i> Siguiente componente</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- De v-show muestra_cuest_IIPJ -->


    <div v-show="form.muestra_IIPJ">
        <h4>Índice de Incorporación de la Perspectiva de Juventud (IIPJ) en Programas Presupuestales</h4>
        <br>
        <p>Antes de comenzar el llenado te sugerimos tener a la mano la Matriz de Indicadores de Resultados del Programa presupuestal a calcular, así como el presupuesto del año actual y el inmediato anterior.</p>
        <br>
        <h5>Nombre de la Instancia: {{form.nom_instancia}}</h5>
        <h5>Nombre de la persona funcionaria: {{form.nom_funcionario}}</h5>
        <h5>Cargo: {{form.nom_cargo}}</h5>
        <h5>Email: {{form.email}}</h5>
        <h5>Programa presupuestario: {{form.prog_presup}}</h5>
        <h5 style="text-align: right">Fecha de registro: {{this.fecha_hoy}}</h5>
        <!-- Se pinta la tabla del IIPJ -->
        <table class="table table-hover">
            <thead class="table-secondary" bgcolor="#C4C4C4">
                <tr>
                    <th rowspan="2" style="color:#212121" width = "50%">Componente</th>
                    <th rowspan="2" style="color:#212121" width = "30%">Grado de avance</th>
                    <th rowspan="2" style="color:#212121" width = "20%"> Indice </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1. Problema público y juventudes</td>
                    <td>
                        <el-progress :text-inside="true"  :percentage='this.iipj_c1' :color='this.iipj_c1_color' :stroke-width="25"/>
                    </td>
                    <td>    
                        {{ this.iipj_c1_txt }}
                    </td>
                </tr>
                <tr>
                    <td>2. Objetivos y metas con perspectiva de juventudes</td>
                    <td>
                        <el-progress :text-inside="true" :percentage='this.iipj_c2' :color='this.iipj_c2_color' :stroke-width="25"/>
                    </td>
                    <td>    
                        {{ this.iipj_c2_txt }}
                    </td>
                </tr>
                <tr>
                    <td>3. Presupuesto para juventudes</td>
                    <td>
                        <el-progress :text-inside="true" :percentage='this.iipj_c3' :color='this.iipj_c3_color' :stroke-width="25"/>
                    </td>
                    <td>    
                        {{ this.iipj_c3_txt }}
                    </td>
                </tr>
                <tr>
                    <td>4. Indicadores con perspectiva de juventudes</td>
                    <td>
                        <el-progress :text-inside="true" :percentage='this.iipj_c4' :color='this.iipj_c4_color' :stroke-width="25"/>
                    </td>
                    <td>    
                        {{ this.iipj_c4_txt }}
                    </td>
                </tr>
                <tr>
                    <td>5. Participación sustantiva de las juventudes</td>
                    <td>
                        <el-progress :text-inside="true" :percentage='this.iipj_c5' :color='this.iipj_c5_color' :stroke-width="25"/>
                    </td>
                    <td>    
                        {{ this.iipj_c5_txt }}
                    </td>
                </tr>
                <tr>
                    <td style="color:#9D2449"><strong>Índice de Incorporación de la Perspectiva de Juventudes</strong></td>
                    <td style="color:#9D2449">
                            <el-progress :text-inside="true" :percentage='this.iipj_ct' :color='this.iipj_ct_color' :stroke-width="35"/>
                    </td>
                    <td>            
                        <strong>
                            {{ this.iipj_ct_txt }}
                        </strong>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="card-footer">
            <div class="row">
                <div class="col-6 text-right">
                    <button class="btn btn-primary btn-sm active btnCond mb-4" @click="sendMail"><i class="glyphicon glyphicon-saved"></i> Enviar IIPJ al correo registrado</button>
                </div>
            </div>
        </div>
    </div> <!-- De v-show muestra_IIPJ --> 
</template>

<script scoped>
//import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'
import insertData from '@/helpers/addData'
import getPresupJuventud from '@/helpers/getPresupJuventud'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'
import sendMailService from '@/services/SendMailService'

export default {
    components: {
        //Datepicker,
    },
    data() {
        return {
            acciones: [],
            thisAction: [],
            datosPresup_juventud: [],
            paso_activo: 0,
            percentage:0,
            
            fecha_hoy: new Date(),
            //fecha_hoyTmp: new Date(),
            
            form: {
                muestra_cuest_IIPJ: true,
                muestra_mod_1: true,
                muestra_mod_2: false,
                muestra_mod_3: false,
                muestra_mod_4: false,
                muestra_mod_5: false,
                
                muestra_IIPJ: false,   //debe ser false
                //fecha_hoy: '',
                nom_instancia: '',
                nom_funcionario: '',
                nom_cargo: '',
                email: '',
                prog_presup: '',
            },
            iipj_c1: 0,
            iipj_c2: 0,
            iipj_c3: 0,
            iipj_c4: 0,
            iipj_c5: 0,
            iipj_ct: 0,

            iipj_txt_tmp: '',
            iipj_c1_txt: '',
            iipj_c2_txt: '',
            iipj_c3_txt: '',
            iipj_c4_txt: '',
            iipj_c5_txt: '',
            iipj_ct_txt: '',

            iipj_color_tmp: '',
            iipj_c1_color: '',
            iipj_c2_color: '',
            iipj_c3_color: '',
            iipj_c4_color: '',
            iipj_c5_color: '',
            iipj_ct_color: '',

            hoy: new Date(),
            //isSelected: false,
            saveDisabled: false,
            iconImg: '',
            editor: ClassicEditor,
            editorData: '',
        }
    },
    methods : {
        async getPresup_juventud() {
            const dataPresup_juventud = await getPresupJuventud();
            const arrShow = [];

            const instrucciones = `${ dataPresup_juventud[0].instrucciones } `;

               arrShow.push({
                    instrucciones
                })

            return arrShow
        },
        
        validarVacios( valor, campo, combo = false ) {
            let errors = 0

            if (!combo) {
                if (!valor) { 
                    document.querySelector( campo ).style.backgroundColor='#fddfe2'
                    errors++
                } else {
                    document.querySelector( campo ).style.backgroundColor=''
                }
            } else {
                if (valor.length > 0) { 
                    document.querySelector( campo ).style.backgroundColor=''
                } else {
                    document.querySelector( campo ).style.backgroundColor='#fddfe2'
                    errors++
                }
            }

            return errors
        },

        async sendForm() {
            let errors = 0
            
            //errors += this.validarVacios(this.form.fecha_hoy, '[name="fecha_hoy"]')
            errors += this.validarVacios(this.form.nom_instancia, '#nom_instancia')
            errors += this.validarVacios(this.form.nom_funcionario, '#nom_funcionario')
            errors += this.validarVacios(this.form.nom_cargo, '#nom_cargo')
            errors += this.validarVacios(this.form.email, '#email')
            errors += this.validarVacios(this.form.prog_presup, '#prog_presup')
            
            if (this.paso_activo == 0){    
                errors += this.validarVacios(this.form.p1_1, '#c1p1_1')
                errors += this.validarVacios(this.form.p1_2, '#c1p1_2')
                errors += this.validarVacios(this.form.p1_3, '#c1p1_3')
                errors += this.validarVacios(this.form.p1_4, '#c1p1_4')
                errors += this.validarVacios(this.form.p1_5, '#c1p1_5')
                if (errors == 0){
                    this.paso_activo = 1
                    this.form.muestra_mod_1 = false
                    this.form.muestra_mod_2 = true
                }
            }
            if (this.paso_activo == 1){    
                errors += this.validarVacios(this.form.p2_1, '#c1p2_1')
                errors += this.validarVacios(this.form.p2_2, '#c1p2_2')
                errors += this.validarVacios(this.form.p2_3, '#c1p2_3')
                errors += this.validarVacios(this.form.p2_4, '#c1p2_4')
                errors += this.validarVacios(this.form.p2_5, '#c1p2_5')
                if (errors == 0){
                        this.paso_activo = 2
                        this.form.muestra_mod_2 = false
                        this.form.muestra_mod_3 = true
                }
            }    
            if (this.paso_activo == 2){    
                errors += this.validarVacios(this.form.p3_1, '#c1p3_1')
                errors += this.validarVacios(this.form.p3_2, '#c1p3_2')
                errors += this.validarVacios(this.form.p3_3, '#c1p3_3')
                errors += this.validarVacios(this.form.p3_4, '#c1p3_4')
                errors += this.validarVacios(this.form.p3_5, '#c1p3_5')
                if (errors == 0){
                        this.paso_activo = 3
                        this.form.muestra_mod_3 = false
                        this.form.muestra_mod_4 = true
                }
            }
            if (this.paso_activo == 3){    
                errors += this.validarVacios(this.form.p4_1, '#c1p4_1')
                errors += this.validarVacios(this.form.p4_2, '#c1p4_2')
                errors += this.validarVacios(this.form.p4_3, '#c1p4_3')
                errors += this.validarVacios(this.form.p4_4, '#c1p4_4')
                errors += this.validarVacios(this.form.p4_5, '#c1p4_5')
                if (errors == 0){
                        this.paso_activo = 4
                        this.form.muestra_mod_4 = false
                        this.form.muestra_mod_5 = true
                }
            }
            if (this.paso_activo == 4){    
                errors += this.validarVacios(this.form.p5_1, '#c1p5_1')
                errors += this.validarVacios(this.form.p5_2, '#c1p5_2')
                errors += this.validarVacios(this.form.p5_3, '#c1p5_3')
                errors += this.validarVacios(this.form.p5_4, '#c1p5_4')
                errors += this.validarVacios(this.form.p5_5, '#c1p5_5')
                if (errors == 0){
                        this.paso_activo = 5
                        //this.form.muestra_mod_5 = false
                        //this.form.muestra_mod_6 = true
                }
            }

            if ( errors > 0 ){
                this.$notify({
                    title: 'Error', text: 'Hay campos requeridos que necesitan ser llenados. Por favor verifique', type: 'error', duration: 5000, speed: 1000
                })
                return
            } else {

//                const fecha_registro = (this.form.fecha_hoy)? this.form.fecha_hoy.toISOString().split('T')[0] : ''    
                //const fecha_registro = (this.fecha_hoy)? this.fecha_hoy.toISOString().split('T')[0] : ''    
                this.fecha_hoy = (this.fecha_hoy)? this.fecha_hoy.toISOString().split('T')[0] : ''    
                

                const jsonUpd = JSON.parse(JSON.stringify({
                    "fecha_registro": this.fecha_hoy,
                    "nom_instancia": this.form.nom_instancia,
                    "nom_funcionario": this.form.nom_funcionario,
                    "nom_cargo": this.form.nom_cargo,
                    "email": this.form.email,
                    "prog_presup": this.form.prog_presup,
                  
                    "p1_1": this.form.p1_1,
                    "p1_2": this.form.p1_2,
                    "p1_3": this.form.p1_3,
                    "p1_4": this.form.p1_4,
                    "p1_5": this.form.p1_5,
                    "p2_1": this.form.p2_1,
                    "p2_2": this.form.p2_2,
                    "p2_3": this.form.p2_3,
                    "p2_4": this.form.p2_4,
                    "p2_5": this.form.p2_5,
                    "p3_1": this.form.p3_1,
                    "p3_2": this.form.p3_2,
                    "p3_3": this.form.p3_3,
                    "p3_4": this.form.p3_4,
                    "p3_5": this.form.p3_5,
                    "p4_1": this.form.p4_1,
                    "p4_2": this.form.p4_2,
                    "p4_3": this.form.p4_3,
                    "p4_4": this.form.p4_4,
                    "p4_5": this.form.p4_5,
                    "p5_1": this.form.p5_1,
                    "p5_2": this.form.p5_2,
                    "p5_3": this.form.p5_3,
                    "p5_4": this.form.p5_4,
                    "p5_5": this.form.p5_5,
                }))
                const resUpd = await insertData('calc_cuest', jsonUpd);
                if (resUpd[0].res.status === 200 ) {
                    this.saveDisabled = true
                    this.$notify({
                        title: 'Correcto', text: 'Se guardó correctamente la información', type: 'success', duration: 5000, speed: 1000
                    })

                    await sendMailService.addCalc( this.form.nom_funcionario, '', this.form.email, '');

                    this.form.muestra_cuest_IIPJ = false
                    this.form.muestra_IIPJ = true
                    //this.$router.push(`/projuventud/acciones`)
                }
            }
            /* Calcula IIPJ */
            var n_iipj = 0
            var p_iipj = 0
            if (this.val_iipj_p(this.form.p1_1) > -1) {p_iipj +=this.val_iipj_p(this.form.p1_1), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p1_2) > -1) {p_iipj +=this.val_iipj_p(this.form.p1_2), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p1_3) > -1) {p_iipj +=this.val_iipj_p(this.form.p1_3), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p1_4) > -1) {p_iipj +=this.val_iipj_p(this.form.p1_4), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p1_5) > -1) {p_iipj +=this.val_iipj_p(this.form.p1_5), n_iipj += 1 }
            
            this.iipj_c1 = this.val_iipj(p_iipj,n_iipj)
            this.val_iipj_txt(this.iipj_c1)
            this.iipj_c1_txt = this.iipj_txt_tmp
            this.iipj_c1_color = this.iipj_color_tmp

            n_iipj = 0
            p_iipj = 0
            if (this.val_iipj_p(this.form.p2_1) > -1) {p_iipj +=this.val_iipj_p(this.form.p2_1), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p2_2) > -1) {p_iipj +=this.val_iipj_p(this.form.p2_2), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p2_3) > -1) {p_iipj +=this.val_iipj_p(this.form.p2_3), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p2_4) > -1) {p_iipj +=this.val_iipj_p(this.form.p2_4), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p2_5) > -1) {p_iipj +=this.val_iipj_p(this.form.p2_5), n_iipj += 1 }
            this.iipj_c2 = this.val_iipj(p_iipj,n_iipj)
            this.val_iipj_txt(this.iipj_c2)
            this.iipj_c2_txt = this.iipj_txt_tmp
            this.iipj_c2_color = this.iipj_color_tmp


            n_iipj = 0
            p_iipj = 0
            if (this.val_iipj_p(this.form.p3_1) > -1) {p_iipj +=this.val_iipj_p(this.form.p3_1), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p3_2) > -1) {p_iipj +=this.val_iipj_p(this.form.p3_2), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p3_3) > -1) {p_iipj +=this.val_iipj_p(this.form.p3_3), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p3_4) > -1) {p_iipj +=this.val_iipj_p(this.form.p3_4), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p3_5) > -1) {p_iipj +=this.val_iipj_p(this.form.p3_5), n_iipj += 1 }
            this.iipj_c3 = this.val_iipj(p_iipj,n_iipj)
            this.val_iipj_txt(this.iipj_c3)
            this.iipj_c3_txt = this.iipj_txt_tmp
            this.iipj_c3_color = this.iipj_color_tmp

            n_iipj = 0
            p_iipj = 0
            if (this.val_iipj_p(this.form.p4_1) > -1) {p_iipj +=this.val_iipj_p(this.form.p4_1), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p4_2) > -1) {p_iipj +=this.val_iipj_p(this.form.p4_2), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p4_3) > -1) {p_iipj +=this.val_iipj_p(this.form.p4_3), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p4_4) > -1) {p_iipj +=this.val_iipj_p(this.form.p4_4), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p4_5) > -1) {p_iipj +=this.val_iipj_p(this.form.p4_5), n_iipj += 1 }
            this.iipj_c4 = this.val_iipj(p_iipj,n_iipj)
            this.val_iipj_txt(this.iipj_c4)
            this.iipj_c4_txt = this.iipj_txt_tmp
            this.iipj_c4_color = this.iipj_color_tmp

            n_iipj = 0
            p_iipj = 0
            if (this.val_iipj_p(this.form.p5_1) > -1) {p_iipj +=this.val_iipj_p(this.form.p5_1), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p5_2) > -1) {p_iipj +=this.val_iipj_p(this.form.p5_2), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p5_3) > -1) {p_iipj +=this.val_iipj_p(this.form.p5_3), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p5_4) > -1) {p_iipj +=this.val_iipj_p(this.form.p5_4), n_iipj += 1 }
            if (this.val_iipj_p(this.form.p5_5) > -1) {p_iipj +=this.val_iipj_p(this.form.p5_5), n_iipj += 1 }
            this.iipj_c5 = this.val_iipj(p_iipj,n_iipj)
            this.val_iipj_txt(this.iipj_c5)
            this.iipj_c5_txt = this.iipj_txt_tmp
            this.iipj_c5_color = this.iipj_color_tmp


            this.iipj_ct = Math.round((this.iipj_c1+this.iipj_c2+this.iipj_c3+this.iipj_c4+this.iipj_c5)/5)
            //this.iipj_ct_txt = this.val_iipj_txt(this.iipj_ct)
            
            //this.iipj_c1 =      p_iipj/n_iipj
            this.val_iipj_txt(this.iipj_ct)
            this.iipj_ct_txt =       this.iipj_txt_tmp
            this.iipj_ct_color =    this.iipj_color_tmp

        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        },

        readFormatReporte() {       /* veririficar si se usa esta funcion */
            return this.readFormat( this.form.fecha_hoy )
        },

        readFormat( date ) {
            if ( date !== '' ) {
                const day = (date.getDate() < 10)? '0' + date.getDate() : date.getDate()
                const month = (date.getMonth() < 9)? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
                const year = date.getFullYear()
                return `${day}-${month}-${year}`;
            } else {
                return ''
            }
        },
        val_iipj_p( val ) {
            var val_p = -1
            if (val == 'A') {val_p = 100}
            else if (val == 'B') {val_p = 67}
            else if (val == 'C') {val_p = 33}
            else if (val == 'D') {val_p = 0}
        
            return val_p;
        },
        val_iipj( p,n ) {
            var val = 0
            if ( n > 0){
                val = Math.round(p/n)
            }
            return val
        },
        val_iipj_txt( val ) {
            if (val <= 20) {this.iipj_txt_tmp = 'Muy bajo'; this.iipj_color_tmp = '#FF0000' }
            else if (val > 20 && val <= 40) {this.iipj_txt_tmp = 'Bajo'; this.iipj_color_tmp = '#FF9900'}
            else if (val > 40 && val <= 60) {this.iipj_txt_tmp = 'Medio'; this.iipj_color_tmp = '#FFFF33'}
            else if (val > 60 && val <= 80) {this.iipj_txt_tmp = 'Alto'; this.iipj_color_tmp = '#33FF00'}
            else if (val > 80) {this.iipj_txt_tmp = 'Muy alto'; this.iipj_color_tmp = '#339900'}
            return
        },
    },
    customColorMethod (percentage) {
        if (percentage < 30) {
            return '#909399'
        }
        if (percentage < 70) {
            return '#e6a23c'
        }
        return '#67c23a'
    },
    async mounted() {
        
        this.datosPresup_juventud = await this.getPresup_juventud();
        //window.scrollTo(0, 0)
//        this.getAccionPuntual()
        //this.delTmp()
        this.$gtag.event('Cuestionario IIPJ');     //Registra el Evento en Google Analytics con nombre

        

    },
}
</script>

<style scoped>
    :root {
        --v-calendar-input-font-size: medium;
    }
    
    .v-calendar .input-field input {
        font-size: var(--v-calendar-input-font-size) !important;
    }

    img {
        width: 100%;
        margin-bottom: 40px;
    }

    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
        color: #FFF;
        text-decoration: none;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    form {
        font-size: medium;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }


    
    .subTitle {
        font-weight: normal;
        padding: 15px;
    }
    .article-body {
        padding: 15px;
    }

    .article-body>p {
        padding: 10px 0;
    }

    strong {
        color: #235B4E;
    }
    .article-body {
        text-align: justify;
    }

    .radio_cal {
        font-size: medium;
        padding: 3px 50px;
    }
    .radio_cal_r {
        vertical-align: top;
    }
    .radio_cal_l {
        width:95%;
        display:inline-block;
    }
   
    .scrollbar-demo-item {
        display: flex;
        align-items: left;
        justify-content: left;
        height: 400px;
        width: 100%;
        margin: 10px;
        text-align: left;
        border-radius: 4px; 
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
             
    }
</style>