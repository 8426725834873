/* Regresa id y nombre corto de todos los registros de la tabla 'cat_objetivos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

/* 
* @param incTodas: false: no regresa NO-SECTORIZADA y TODAS (default)
*                  true: regresa todos los registros
*/
const getObjetivosNc = async( incTodas = false ) => {
    let filter = ''

    if ( !incTodas ){
        filter = '&filter=id,lt,100'
    }

    const promiseArr = [
        simejuvApi.get(`/cat_objetivos?transform=1&columns=id,num_objetivo,nom_corto_objetivo${ filter }`)
    ]

    const [ deps ] = await Promise.all( promiseArr )

    return deps.data.cat_objetivos
}

export default getObjetivosNc