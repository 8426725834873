
<template>
    <div class="row">
        <div class="article-body" >
            <p class="card-text"> <b>Hola consejara/o del CONSEPP,</b> </p>
            <p>Este es tu espacio para realizar recomendaciones a la política pública de juventud del gobierno federal.</p>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-12">
            <form>
                <el-card class="box-card" style = "backgroundColor: #FBFCFC" shadow="hover">
                    <div class="form-group row">
                        <label class="col-md-4" to="dependencia">Dependencia:</label>
                        <div class="col-md-8">
                            <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia">
                                <option value="" selected>[Seleccione]</option>
                                <option v-for="e in depsArr" :key="e.id" :value="e.id">{{ e.nom_corto_dependencia }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4" to="areaEjecutora">Unidad o área ejecutora:</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" id="areaEjecutora" name="areaEjecutora" placeholder="Unidad o área ejecutora" v-model="form.areaEjecutora" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4" to="entidad">Entidad Federativa de atención:</label>
                        <div class="col-md-8">
                            <select class="form-control" id="entidad" name="entidad" v-model="form.entidad">
                                <option value="" selected>[Seleccione]</option>
                                <option v-for="e in edosArr" :key="e.id" :value="e.id">{{ e.nom_estado }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4" to="municipio">Municipio de atención:</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" id="municipio" name="municipio" placeholder="Municipio de atención" v-model="form.municipio" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4" to="titulo">Título de la recomendación:</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" id="titulo" name="titulo" placeholder="Título de la recomendación:" v-model="form.titulo" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4" to="descripcion">Descripción:</label>
                        <div class="col-md-8">
                            <textarea class="form-control" name="descripcion" id="descripcion" placeholder="Descripción" rows="3" v-model="form.descripcion"></textarea>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-4" for="archivo">Adjunta tu documento:</label>
                        <div class="col-md-7">
                            <div v-if="successAlert" class="alert alert-success alert-dismissible">
                                <a href="#" class="close" aria-label="close" @click="successAlert=false">&times;</a>
                                {{ successMessage }}
                            </div>
                            <div v-if="errorAlert" class="alert alert-danger alert-dismissible">
                                <a href="#" class="close" aria-label="close" @click="errorAlert=false">&times;</a>
                                {{ errorMessage }}
                            </div>
                            <input type="file" ref="file" :disabled='isDisabled' id="fileUp" />
                            <br>
                            <button type="button" @click="uploadFile" class="btn btn-default btn-sm" :disabled='isDisabled'>Almacenar archivo</button>
                        </div>
                    </div>

                    <div>
                        <div class="form-group row">
                            <div class="col-md-5"></div>
                            <div class="col-md-7">
                                <table id="tblFiles" class="table table-striped">
                                    <thead class="table-striped">
                                        <tr>
                                            <th>Nombre del archivo</th>
                                            <th colspan="2">Intervenciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(e, index) in arrArchivos" :key="e">
                                            <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><img :src="require(`@/assets/images/icons/${ e.extension }.svg`)" class="iconImg" /> {{ e.file_name_dec }}</a></td>
                                            <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download-alt"></i></a></td>
                                            <td><a href="#" @click="deleteFile( e.id, index)" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6 text-right">
                            <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
                            <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Limpiar" @click="clearSelect" />
                            <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Guardar" @click="addRecom" />
                        </div>
                    </div>
                </el-card>
            </form>
        </div>
    </div>
</template>

<script>
import getCatDependencias from '@/helpers/getCatDependencias'
import getCatEstados from '@/helpers/getCatEstados'
import addData from '@/helpers/addData'

//Para archivos
import insertData from '@/helpers/addData'
import updateData from '@/helpers/updateData'
import axios from 'axios'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';
import getUsersById from '@/helpers/getUsersById';

export default {
    data() {
        return {
            form: {
                dependencia: '',
                areaEjecutora: '',
                entidad: '',
                municipio: '',
                titulo: '',
                descripcion: '',
            },
            depsArr: [],
            edosArr: [],
            successAlert: false,
            errorAlert: false,
            isDisabled: false,
            arrArchivos: []
        }
    },
    methods: {
        clearSelect() {
            this.form.dependencia = ''
            this.form.areaEjecutora = ''
            this.form.entidad = ''
            this.form.municipio = ''
            this.form.titulo = ''
            this.form.descripcion = ''
        },
        async getDepIntervencion() {
            const depInt = await getCatDependencias()
            return depInt
        },
        async getEstados() {
            const edos = await getCatEstados()
            return edos
        },
        async uploadFile() {
            this.file = this.$refs.file.files[0]
            var formData = new FormData()
            formData.append('file', this.file)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            await axios.post( ubApi.value + '/uploadFiles.php', formData, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlert = z_errorAlert
            this.successAlert = z_successAlert
            this.errorMessage = z_errorMessage
            this.successMessage = z_successMessage
            this.$refs.file.value = z_fileValue

            if (z_successAlert) {
                // RecomConsepp módulo para tabla de archivos
                const jsonInsFile = JSON.parse(JSON.stringify({
                    "modulo": "RecomConsepp",
                    "id_registro": 0,
                    "id_registro_sec": 0,   //Esta no estaba yo la puse
                    "id_usuario": z_userId,
                    "tmp_name": z_tmpFileName,
                    "file_name": z_baseName,
                    "extension": z_extension,
                    "tmp": true
                }))
                const lastInsert = await insertData('archivos', jsonInsFile);
                this.arrFilesUploaded.push({ fileId: lastInsert[0].res.data })
                this.arrArchivos.push({borrar:0, extension:z_extension, file_name:z_baseName, file_name_dec:z_baseName, id:lastInsert[0].res.data, id_registro:this.$route.params.id, id_usuario:z_userId, modulo:"RecomConsepp", tmp_name:z_tmpFileName})

            }

            console.log(this.arrArchivos);
        },
        async addRecom() {
            const dataForm = {
                dependencia: this.form.dependencia,
                areaEjecutora: this.form.areaEjecutora,
                entidad: this.form.entidad,
                municipio: this.form.municipio,
                titulo: this.form.titulo,
                descripcion: this.form.descripcion
            }

            const jsonData = JSON.parse(JSON.stringify( dataForm ))
            const addRecomConsepp = await addData('recomendaciones_consepp', jsonData)
                if ( addRecomConsepp[0].res.status === 200 ) {
                    
                    //Archivos                    
                    this.newId = addRecomConsepp[0].res.data;
                    for (let x = 0; x < this.arrFilesUploaded.length; x++) {
                        await updateData('archivos', this.arrFilesUploaded[x].fileId, JSON.parse(JSON.stringify({ id_registro:this.newId,id_registro_sec:this.newId, tmp:true })))
                    }
                    this.$notify({
                        title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                    });
                    // Se envía el correo 
                    const arrUsr = await getUsersById(charsService.decode(localStorage.getItem('id')));
                    this.added = true
                    this.clearSelect
                }
        }
    },
    async mounted() {
        this.depsArr = await this.getDepIntervencion()
        this.edosArr = await this.getEstados()
        window.scrollTo(0, 0)
    }
}
</script>