<template>
    <h3>Programas sectoriales con acciones de juventud</h3>
    <div>
        <p>En este apartado podrás consultar los Programas Sectoriales de las dependencias así como la alineación con el Programa Nacional de Juventud 2021-2024.</p>
    </div>
    <hr>
    <div class="row">
        <div class="col-lg-3 busqueda">
           
            <div class="row mt-4" style="backgroundColor:#E5E5E5">
                <el-col :span="50">
                <h5 class="mb-2"> DEPENDENCIAS</h5>
            
                <el-menu
                    name="b_obj5" id="b_obj5"
                    active-text-color="#9D2449"     
                    background-color="#E5E5E5"      
                    class="el-menu-vertical-demo"
                    default-active="1"
                    text-color="#000000"               
                    @open="handleOpen"
                    @close="handleClose"
                >
                
                    <el-menu-item index="2" @click="f_b_obj5(2)">
                        <el-icon><icon-menu /></el-icon>
                        <span>BIENESTAR</span>
                    </el-menu-item>

                    <el-menu-item index="22" @click="f_b_obj5(22)">
                        <el-icon><icon-menu /></el-icon>
                        <span>CULTURA</span>
                    </el-menu-item>

                    <el-menu-item index="41" @click="f_b_obj5(41)">
                        <el-icon><icon-menu /></el-icon>
                        <span>GOBERNACIÓN</span>
                    </el-menu-item>

                    <el-menu-item index="49" @click="f_b_obj5(49)">
                        <el-icon><icon-menu /></el-icon>
                        <span>SALUD</span>
                    </el-menu-item>

                    <el-menu-item index="37" @click="f_b_obj5(37)">
                        <el-icon><icon-menu /></el-icon>
                        <span style="font-size=16px;">SE </span>
                    </el-menu-item>

                    <el-menu-item index="38" @click="f_b_obj5(38)">
                        <el-icon><icon-menu /></el-icon>
                        <span>SEDATU</span>
                    </el-menu-item>

                    <el-menu-item index="43" @click="f_b_obj5(43)">
                        <el-icon><icon-menu /></el-icon>
                        <span>SEMARNAT</span>
                    </el-menu-item>

                    <el-menu-item index="45" @click="f_b_obj5(45)">
                        <el-icon><icon-menu /></el-icon>
                        <span>SEP</span>
                    </el-menu-item>

                    <el-menu-item index="50" @click="f_b_obj5(50)">
                        <el-icon><icon-menu /></el-icon>
                        <span>SSPC</span>
                    </el-menu-item>

                    <el-menu-item index="51" @click="f_b_obj5(51)">
                        <el-icon><icon-menu /></el-icon>
                        <span>STPS</span>
                    </el-menu-item>

                    <!-- 
                    <el-menu-item index="101" @click="f_b_obj5(101)">
                        <el-icon><icon-menu /></el-icon>
                        <span>TODOS</span>
                    </el-menu-item>
                    /> -->

                </el-menu>
                </el-col>
            </div>
        </div>

        <!-- Panel derecho (resultados) -->
        <div class="col-lg-9" ref="toPdf">
            <div v-for="g in arrAcciones" :key="g" class="mt-3" :data-id="g.idAccion" >
                <div class="card">
                    <div class="card-header">
                        <el-card class="box-card" style="backgroundColor:#F5F5F5; borderColor:#BFBFBF; borderWidth:1px;" >
                            <template #header>
                            <div class="card-header">
                                <span class="dependenciaTitle dependencia">{{ g.nomDepSec  }} ({{ g.nomCortoDepSec  }})</span>
                            </div>
                            </template>
                            <div class="panel-body text-left">
                                <!-- Se pinta el detalle de cada acción -->
                                <div><span class="objetivoTitle objetivo">Objetivo sectorial {{ g.numObjSec }}: </span><span class="objetivo">{{ g.nomObjSec }}</span></div>
                                <br>
                                <div><span class="estrategiasTitle estrategias">Estrategia sectorial {{ g.numEstSec }}: </span><span class="estrategias">{{ g.nomEstSec }}</span></div>
                                <br>

                                <!-- Se pinta el detalle de la acción sectorial -->
                                <div>
                                    <div class="card">
                                        <div class="card-header" >
                                            <el-card class="box-card" style="backgroundColor:#FFFFFF">
                                                <template #header>
                                                <div class="card-header">
                                                    <span><strong>Acción sectorial {{ g.codAccionSec }}</strong></span>
                                                </div>
                                                </template>                                
                                                    {{ g.nomAccionSec }} 
                                                    <span v-show="g.url_acc_sec">
                                                         <a :href= "g.url_acc_sec" target="_blank" class="link1" >ver mas...</a> <br>
                                                    </span>    
                                                </el-card>
                                            <br>                
                                        </div>
                                    </div>
                                </div>                                      

                                <!-- Se pinta el detalle de cada acción puntual-->
                                <div>
                                    <div class="card" >
                                        <div class="card-header" >
                                            <el-card class="box-card" style="backgroundColor:#FFFFFF">
                                                <template #header>
                                                <div class="card-header">
                                                    <span><strong>Se relaciona con el PROJUVENTUD con la acción puntual {{ g.codAccion }}</strong></span>
                                                </div>
                                                </template>                                
                                                    {{ g.nomAccion }} <a href="https://simejuv.imjuventud.gob.mx/projuventud/acciones" target="_blank" class="link1" >ver mas...</a>
                                            </el-card>
                                            <br>                
                                        </div>
                                    </div>
                                </div>                                      

                                <div class="col-12 text-right">
                                    <span v-show="g.file_name">
                                        <a :href="`${ ubApi }/download.php?tmpFileName=${ g.tmp_name }&fileName=${ g.file_name }`" target="_blank" class="btn btn-primary btn-sm active"><i class="glyphicon glyphicon-check"></i> Ficha técnica </a>
                                    </span>
                                </div>

                            </div>                 
                        </el-card>
                        <br>                
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios'
import { ubApi } from '@/api/baseUrl'

// Importo los helpers
import getObjetivosSectoriales from '@/helpers/getObjetivosSectoriales'
import getEstrategiasSectoriales from '@/helpers/getEstrategiasSectoriales'
import getDependenciasNc from '@/helpers/getDependenciasNc'
import getResponsables from '@/helpers/getResponsables'
import getAccionesSectoriales from '@/helpers/getAccionesSectoriales'
import getAccionSectorialbyDep from '@/helpers/getAccionSectorialbyDep'
import getAccesoByUser from '@/helpers/getAccesoByUser'
import moment from 'moment'


import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            ubApi: ubApi.value,
            b_obj1: '',
            b_obj2: '',
            arrObjetivos: [],
            arrEstrategias: [],
            arrAcciones: [],
            arrAccionesFilt: [],
            arrDependencias: [],
            arrDependenciasObj: [],
            
            arrResponsables: [],
            arrTipoAp: [],
            arrAccionesCoord: [],
            arrAccionesAsignadas: [],
            depNombre: '',
            tipoUsr: '',
            accionesUsr: '',
            usrThis: charsService.decode(localStorage.getItem('tipo_usuario')),
            usrDep: charsService.decode(localStorage.getItem('dependencia')),
        }
    },
    methods: {
        async getAllAcciones() {
            const accionesArr = await getAccionesSectoriales()
            const arrShow = []
            let idObjetivoAnt = ''
            let idEstrategiaAnt = ''

            for (let i=0; i<accionesArr.length; i++) {
                const idObj = accionesArr[i].idobjetivo_sectorial           //idobjetivo
                let idObjV = false
                const idEstr = accionesArr[i].idestrategiap
                let idEstrV = false
                const idAccion = accionesArr[i].id
                const idAccionEnc = charsService.encode(accionesArr[i].id)
                
                const nomDepSec = accionesArr[i].nom_dependencia_sectorial
                const nomCortoDepSec = accionesArr[i].nom_corto_dependencia
                const numObjSec = accionesArr[i].num_objetivo_sectorial
                const nomObjSec = accionesArr[i].nom_objetivo_sectorial
                const numEstSec = accionesArr[i].num_estrategia_sectorial
                const nomEstSec = accionesArr[i].nom_estrategia_sectorial
                const codAccionSec = accionesArr[i].codigo_accion_sectorial
                const nomAccionSec = accionesArr[i].nom_accion_sectorial
                const url_acc_sec = accionesArr[i].url_accion_sectorial
                
                const tmp_name = charsService.encode(accionesArr[i].tmp_name)
                const file_name = (accionesArr[i].file_name)? charsService.encode(accionesArr[i].file_name) : null 
                const extension = accionesArr[i].extension


                const codAccion = accionesArr[i].codigo_accion_puntual
                const nomAccion = accionesArr[i].nom_accion_puntual

                
                // Se hace visible o no IdObjetivo
                if( idObj !== idObjetivoAnt ) {
                    idObjetivoAnt = idObj
                    idObjV = true
                }
                

                // Se hace visible o no IdEstrategiaP
                if( idEstr !== idEstrategiaAnt ) {
                    idEstrategiaAnt = idEstr
                    idEstrV = true
                }

                arrShow.push({
                    idAccion,
                    idAccionEnc,
                    idObj,
                    idObjV,
                    idEstr,
                    idEstrV,
                    nomDepSec,
                    nomCortoDepSec,
                    numObjSec,
                    nomObjSec,
                    numEstSec,
                    nomEstSec,
                    codAccionSec,
                    nomAccionSec,
                    url_acc_sec,
                    tmp_name,
                    file_name,
                    extension,
                    codAccion,
                    nomAccion,
                })
            }

            return arrShow
        },
        async getAccesoData( id ) {
            const accesoData = await getAccesoByUser( id )
            const accionesUsr = accesoData[0].acciones_usuario_2022
            const arrAccionesUsr = (accionesUsr)? accionesUsr.split(',') : ''

            if (arrAccionesUsr !== '') {
                arrAccionesUsr.forEach(accion => {
                    this.arrAccionesAsignadas.push(parseInt( accion ))
                })
            }
        },

        async getCatalogos() {
            const consObjetivos = await getObjetivosSectoriales()
            for (let h = 0; h < consObjetivos.length; h++) {
                this.arrObjetivos.push( { id: consObjetivos[h].id, noObj: consObjetivos[h].num_objetivo_sectorial, desc: consObjetivos[h].nom_objetivo_sectorial } )
            }

            const consEstrategias = await getEstrategiasSectoriales()
            for (let i = 0; i < consEstrategias.length; i++) {
                this.arrEstrategias.push( { id: consEstrategias[i].id, noEst: consEstrategias[i].num_estrategia_sectorial, idObj: consEstrategias[i].idobjetivo_sectorial, desc: consEstrategias[i].nom_estrategia_sectorial } )
            }



            const consDeps = await getDependenciasNc( true )
            for (let j = 0; j < consDeps.length; j++) {
                this.arrDependencias[consDeps[j].id] = consDeps[j].nom_corto_dependencia
                this.arrDependenciasObj.push({ id: consDeps[j].id, desc: consDeps[j].nom_corto_dependencia })
            }

            

            const consResponsables = await getResponsables()
            for (let l = 0; l < consResponsables.length; l++) {
                if (!this.arrResponsables[consResponsables[l].idaccion_puntual]) {
                    this.arrResponsables[consResponsables[l].idaccion_puntual] = this.arrDependencias[consResponsables[l].iddependencia]
                } else {
                     this.arrResponsables[consResponsables[l].idaccion_puntual] += ', ' + this.arrDependencias[consResponsables[l].iddependencia]
                }
            }
        },

        filter(array, key, value){
            let i, j, hash = [], item;
            for(i =  0, j = array.length; i<j; i++){
                item = array[i];
                if(typeof item[key] !== "undefined" && item[key] === value){
                    hash.push(item);
                }
            }
            return hash;
        },

        // Filtro exclusivo para cat de entidades (se hace referencia al registro que viene como "Todas")
        filterIn(array, key, arrayIn){
            let i, j, hash = [], item;
            for(i =  0, j = array.length; i<j; i++){
                item = array[i];
                if((typeof item[key] !== "undefined" && arrayIn.indexOf(item[key]) !== -1)){
                    hash.push(item);
                }
            }
            return hash;
        },

        async f_b_obj5(valor ) {
           
            this.arrAccionesFilt = []

            if (!valor || parseInt(valor) === 101) {
                this.arrAcciones = this.arrAccionesBak
                const accionResp = await getAccionSectorialbyDep( charsService.decode( localStorage.getItem('dependencia') ) )
                accionResp.forEach(accion => {
                    this.arrAccionesFilt.push( accion.idaccion_puntual )
                })
            } else {
                let arrAccResp = []

                if ( this.arrAccionesAsignadas.length < 1 ) {
                    const consAccResp = await getAccionSectorialbyDep( valor );
                    consAccResp.forEach( e => {
                        arrAccResp.push( e.id )
                        this.arrAccionesFilt.push(e.id)
                    });
                } else {
                    arrAccResp = this.arrAccionesAsignadas
                }
                this.arrAcciones = this.filterIn(this.arrAccionesBak, 'idAccion', arrAccResp)
            }
        },
        
    },
    async mounted() {
        this.getAccesoData(charsService.decode(localStorage.getItem('id')))
        await this.getCatalogos()
        this.arrAcciones = await this.getAllAcciones()
        this.arrAccionesBak = await this.getAllAcciones()

        if (parseInt(charsService.decode(localStorage.getItem('tipo_usuario'))) === 3) {
            const element = document.querySelector("#b_obj4")
            const event = new Event('change')
            element.value = charsService.decode(localStorage.getItem('dependencia'))
            element.dispatchEvent( event )
        }
        this.$gtag.event('Acciones puntuales');     //Registra el Evento en Google Analytics con nombre
    }
}
</script>

<style scoped>
    .callout {
        background-color: rgba(250, 250, 250, 0.5);
    }
    .dependenciaTitle, .objetivoTitle, .estrategiasTitle {
        color: #9F2241;
        font-weight: bold;
    }
    .dependencia {
        font-size: 20px;
        font-weight: bold;
    }
    .objetivo {
        font-size: 20px;
        font-weight: bold;
    }
    .estrategias {
        font-size: 18px;
    }
    .card {
        font-size: medium;
    }
    .apTitle {
        font-weight: bold;
    }

    .charts {
        width: 100%;
        height: auto;
    } 

    .icoLink {
        color: #212529;
    }

    .dontShow {
        display: none;
    }

    .btnCond {
        margin-bottom: 40px;
    }

</style>