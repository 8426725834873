<template>
    <h4 class="mt-0">Actualizar información del catálogo de unidades de medida</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_unidad_medida">Nombre de la unidad de medida:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idUnidad_medida" name="idUnidad_medida" v-model="form.id" />
                        <input type="text" class="form-control" id="nom_unidad_medida" name="nom_unidad_medida" placeholder="Nombre de la unidad de medida" v-model="form.nom_unidad_medida" />
                    </div>
                </div>
                <hr>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUnidad_medida" /> -->
                        <input type="button" class="btn btn-secondar btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUnidad_medida" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateUnidad_medida" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getUnidades_medidaById from '@/helpers/getUnidades_medidaById'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                nom_unidad_medida: '',
            }
        }
    },
    methods: {
        async getUnidad_medidaData( id ) {
            const Unidad_medidaArr = await getUnidades_medidaById( id )
            
            return Unidad_medidaArr[0].cat_unidad_medida
        },

        async setData( id ) {
            const datos = await getUnidades_medidaById( id )

            this.form.id = datos.id
            this.form.nom_unidad_medida = datos.nom_unidad_medida
        },

        async updateUnidad_medida() {
            const dataForm = {
                nom_unidad_medida: this.form.nom_unidad_medida
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_unidad_medida', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>