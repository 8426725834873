<template>
    <div class="form-group row">
        <div class="col-md-6 text-left">
            <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
            <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
        </div>
        <div class="col-md-6 text-right">
            <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
            <input type="button" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="saveIntervencion" />
        </div>
    </div>

    <h4 class="mt-0">Mantenimiento del catálogo de oferta para las y los jóvenes</h4>
    <hr>
    


<el-card class="box-card" style = "backgroundColor: #FBFCFC" shadow="hover">
    <template #header>
        <div class="card-header" >
        <span><strong>Autorización de ofertas para las y los jóvenes</strong></span>
        </div>
    </template>



            <div class="row mb-5">
                <div class="col-md-12">
                    <form>
                        <div class="form-group row">
                            <label class="col-md-4" to="estatus">Estatus:<div class="subQuest">Indicar si se publica o no en la Página WEB del SIMEJUV</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="estatus" id="estatus1" v-model="form.estatus" value='Pendiente de revisar' :checked="this.form.estatus == 'Pendiente de revisar' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">Pendiente de revisar</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="estatus" id="estatus2" v-model="form.estatus" value='No se publica' :checked="this.form.estatus == 'No se publica' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">No se publica</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="estatus" id="estatus3" v-model="form.estatus" value='Si se publica' :checked="this.form.estatus == 'Si se publica' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">Si se publica</span>
                                    </label><br>
                                </el-card>   
                        <!--    <span>Eligió: {{ form.estatus }}</span>    -->
                            </div>


                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_funcionario">Nombre de la persona funcionaria pública:<div class="subQuest">Nombre del funcionario que registra la Intervención Gubernamental</div></label>
                            <div class="col-md-8">
                                <div >
                                    <input type="text" class="form-control" id="nom_funcionario" name="nom_funcionario" placeholder="Nombre del funcionario" v-model="form.nom_funcionario" />
                                </div>
                            </div>    
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_cargo">Cargo de la persona funcionaria pública:<div class="subQuest">Cargo del funcionario que registra la Intervención Gubernamental</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="nom_cargo" name="nom_cargo" placeholder="Cargo del funcionario" v-model="form.nom_cargo" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4" to="email">Correo electrónico:<div class="subQuest">Correo oficial del funcionario</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="email" name="email" placeholder="Correo electrónico" v-model="form.email" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="dependencia">Dependencia o Entidad Federativa:<div class="subQuest">Nombre de la Dependencia o Entidad Federativa bajo la cual se desarrolla la intervención</div></label>
                            <div class="col-md-8">
                                <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia">
                                    <option value="" disabled selected>[Seleccione]</option>
                                    <option v-for="e in depsArr" :key="e.id" :value="e.id">{{ e.dependencia }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="areaImplementa">Unidad o área ejecutora:<div class="subQuest">Área específica responsable de la implementación de la intervención</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="areaImplementa" name="areaImplementa" placeholder="Unidad o área ejecutora" v-model="form.areaImplementa" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="interGuberna">Nombre de la intervención gubernamental:<div class="subQuest">Nombre asignado a la intervención para su identificación</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="interGuberna" name="interGuberna" placeholder="Nombre de la intervención gubernamental" v-model="form.interGuberna" />
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <label class="col-md-4" to="vigencia">Vigencia:<div class="subQuest">Se refiere al periodo durante el cual la oportunidad o servicio está activo y disponible para su solicitud y/o aprovechamiento por parte de la población objetivo</div></label>
                            <div class="col-md-8">
                                <div class="form-group row">
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <div><small>Fecha de Inicio</small></div>
                                        <Datepicker v-model="form.fecha_inicio" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatFechaInicio" name="fecha_inicio" id="fecha_inicio" placeholder="Seleccione fecha" class="datepicker"></Datepicker>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12"> </div> 
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <div><small>Fecha de Termino</small></div>
                                        <Datepicker v-model="form.fecha_fin" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatFechaFin" name="fecha_fin" id="fecha_fin" placeholder="Seleccione fecha" class="datepicker" :minDate="form.fecha_inicio"></Datepicker>
                                    </div>
                                </div>
                            </div>
                            <label class="col-md-8" to="vigencia"><div class="subQuest"> </div></label>
                            <div class="col-md-8">
                                <div><small>Observaciones:</small></div>
                                <textarea class="form-control" name="vigencia" id="vigencia" placeholder="Vigencia" rows="3" v-model="form.vigencia"></textarea>
                            </div>
                        </div>



                        <div class="form-group row">
                            <label class="col-md-4" to="tipoIntervencion">Tipo de Intervención:<div class="subQuest">Forma de la acción implementada con impacto en la población joven. Se presentan a través de participaciones, regulaciones o actividades.</div></label>
                            <div class="col-md-8">
                                <select class="form-control" id="tipoIntervencion" name="tipoIntervencion" v-model="form.tipoIntervencion" @change="otrasSelected">
                                    <option value="" disabled selected>[Seleccione]</option>
                                    <option v-for="e in tipoIntArr" :key="e.id" :value="e.id">{{ e.tipo_intervencion }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row" v-if="form.tipoIntervencion === 10">
                            <label class="col-md-4" to="otroIntervencion">"Otro" tipo de intervención:<div class="subQuest">Especifica el tipo de intervención que no se encuentra dentro de la clasificación anterior.</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="otroIntervencion" name="otroIntervencion" placeholder="Otro tipo de intervención" v-model="form.otroIntervencion" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="objetivo">Objetivo de la intervención gubernamental:<div class="subQuest">Especifica el propósito que persigue la intervención gubernamental al ser implementada</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="objetivo" id="objetivo" placeholder="Objetivo de la intervención gubernamental" rows="3" v-model="form.objetivo"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="tipoSolicitante">Tipo de población beneficiaria:<div class="subQuest">Se refiere al responsable de tramitar directamente el servicio o beneficio</div></label>
                            <div class="col-md-8">
                                <select class="form-control" id="tipoSolicitante" name="tipoSolicitante" v-model="form.tipoSolicitante" @click="LimpiaTipoActor">
                                    <option value="" disabled selected>[Seleccione]</option>
                                    <option v-for="e in tipoSolic" :key="e.id" :value="e.id">{{ e.tipo_solicitante }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row" v-show="form.tipoSolicitante === 2 || form.tipoSolicitante === 3">
                            <div class="form-group row">
                                <label class="col-md-4" to="tipoActor1">Tipo de actor solicitante/Responsable de la información:<div class="subQuest">Se refiere a la clasificación de actores sociales que pueden solicitar el beneficio o servicio de la intervención gubernamental.</div></label>
                                <div class="col-md-8">
                                    <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                        <div v-for="e in tipoResIn" :key="e" class="col-md-6" style="padding:2px">    
                                            <label class="toggle">
                                                <input class="toggle-checkbox" type="checkbox" :name="'tipoActor' + e.id" :id="'tipoActor' + e.id" :value='e.tipo_resp_info' :v-model="form.tipoActor[e.id]"  :checked="form.tipoActor[e.id] === 1" @click="tipoActorTodos([e.id])">
                                                <div class="toggle-switch"></div><span class="toggle-label">{{ e.tipo_resp_info }}</span>
                                            </label><br>    
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="poblacionObjetivo">Población objetivo:<div class="subQuest">Dirigido a jóvenes hombres ó mujeres cuyas edades estén entre 12 a 29 años.</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="poblacionObjetivo" id="poblacionObjetivo" placeholder="Población objetivo" rows="3" v-model="form.poblacionObjetivo"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="grupoEdad">Grupo de edad atendido:<div class="subQuest">Se refiere a la población a la cual están dirigidos los beneficios producidos por la intervención</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <label class="toggle">
                                        <input class="toggle-checkbox" type="checkbox" name="grupoEdad1" id="grupoEdad1" value="12 a 17 años" v-model="form.gpoEdad[1]"  :checked="form.gpoEdad[1] === 1">
                                        <div class="toggle-switch"></div><span class="toggle-label">12 a 17 años</span>
                                    </label><br> 
                                    <label class="toggle">
                                        <input class="toggle-checkbox" type="checkbox" name="grupoEdad2" id="grupoEdad2" value="18 a 29 años" v-model="form.gpoEdad[2]" :checked="form.gpoEdad[2] === 1">
                                        <div class="toggle-switch"></div><span class="toggle-label">18 a 29 años</span>
                                    </label><br>   
                                    <label class="toggle">
                                        <input class="toggle-checkbox" type="checkbox" name="grupoEdad3" id="grupoEdad3" value="No se especifica en tanto no es exclusivo para algún rango de edad" v-model="form.gpoEdad[3]" :checked="form.gpoEdad[3] === 1" @click="NoAplicaChecked">
                                        <div class="toggle-switch"></div><span class="toggle-label">No se especifica en tanto no es exclusivo para algún rango de edad</span>
                                    </label><br>   
                                </el-card>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="tipoMontosApoyo">Tipo y montos de apoyo:<div class="subQuest">Forma y descripción del apoyo, estímulo o subvención que ofrece la intervención gubernamental en caso de ser beneficiario(a)</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="tipoMontosApoyo" id="tipoMontosApoyo" placeholder="Tipo y montos de apoyo" rows="3" v-model="form.tipoMontosApoyo"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="webIntervencion">Referencia web de la intervención gubernamental:<div class="subQuest">Enlace hacia el sitio web donde pueda localizarse información completa respecto a la intervención gubernamental</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="webIntervencion" name="webIntervencion" placeholder="Referencia web de la intervención gubernamental" v-model="form.webIntervencion" />
                            </div>	
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nomPrograma">Programa presupuestario:<div class="subQuest">Clave del programa presupuestario a través del cuál se opera y/o desarrolla la intervención</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="nomPrograma" name="nomPrograma" placeholder="Programa presupuestario" v-model="form.nomPrograma" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="normatividad">¿Cuenta con normatividad? (Reglas, Políticas o Lineamientos de Operación):<div class="subQuest">La intervención gubernamental puede o no contar con algún tipo de normatividad que la rija.</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="normatividad" id="normatividad1" v-model="form.normatividad" value='Si' :checked="this.form.normatividad == 'Si' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">Si</span>
                                    </label>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="normatividad" id="normatividad2" v-model="form.normatividad" value='No' :checked="this.form.normatividad == 'No' " @click="LimpiaNormatividad">
                                        <div class="toggler-switch"></div><span class="toggler-label">No</span>
                                    </label><br>
                                </el-card>   
								<!--    <span>Eligió: {{ form.normatividad }}</span>    -->
                            </div>
                        </div>
                        <div class="form-group row" v-if="form.normatividad === 'Si'">
                            <label class="col-md-4" to="tipoNormatividad">Tipo de normatividad:<div class="subQuest">Se refiere al tipo de documento normativo que ampara la intervención gubernamental</div></label>
                            <div class="col-md-8">
                                <select class="form-control" id="tipoNormatividad" name="tipoNormatividad" v-model="form.tipoNormatividad">
                                    <option value="" disabled selected>[Seleccione]</option>
                                    <option v-for="e in tipoNorma" :key="e.id" :value="e.id">{{ e.tipo_normatividad }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row" v-if="form.normatividad === 'Si'">
                            <label class="col-md-4" to="webNormatividad">Referencia web de la normatividad:<div class="subQuest">Dirección electrónica donde se pueden encontrar la documentación normativa para regir la operación de las intervenciones gubernamentales.</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="webNormatividad" name="webNormatividad" placeholder="Referencia web de la normatividad" v-model="form.webNormatividad" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_estado">Ámbito geográfico de la intervención:<div class="subQuest">Se refiera a la determinación del espacio geográfico (nacional o por entidad federativa) donde es aplicada la intervención gubernamental.</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <div v-for="e in ambGeo" :key="e" class="col-md-4" style="padding:2px">                                    
                                        <label class="toggle">
                                            <input class="toggle-checkbox" type="checkbox" :name="'nom_estado' + e.id" :id="'nom_estado' + e.id" :value='e.nom_estado' :v-model="form.nom_estado[e.id]" :checked="form.nom_estado[e.id] === 1" @click="estadoNacional([e.id])">
                                            <div class="toggle-switch"></div><span class="toggle-label">{{ e.nom_estado }}</span>
                                        </label><br>
                                    </div>    
                                </el-card>    
                            </div>
                        </div>
                        <!-- Inicio de carga de archivos -->
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="archivo">Material gráfico sobre la intervención:<div class="subQuest">Sólo se permite subir imagenes en los siguientes formatos y tamaño: <br> - Imagenes en formato jpg, png y bmp. <br> - El tamaño máximo permitidos por imagen es de 5 MB</div></label>
                            <div class="col-md-7">
                                <div v-if="successAlert" class="alert alert-success alert-dismissible">
                                    <a href="#" class="close" aria-label="close" @click="successAlert=false">&times;</a>
                                    {{ successMessage }}
                                </div>
                                <div v-if="errorAlert" class="alert alert-danger alert-dismissible">
                                    <a href="#" class="close" aria-label="close" @click="errorAlert=false">&times;</a>
                                    {{ errorMessage }}
                                </div>
                                <input type="file" ref="file" :disabled='isDisabled' id="fileUp" />
                                <br>
                                <button type="button" @click="uploadFile" class="btn btn-default btn-sm" :disabled='isDisabled'>Almacenar archivo</button>
                            </div>
                        </div>
                        <div>
                            <div class="form-group row">
                                <div class="col-md-5"></div>
                                <div class="col-md-7">
                                    <table id="tblFiles" class="table table-striped">
                                        <thead class="table-striped">
                                            <tr>
                                                <th>Nombre del archivo</th>
                                                <th colspan="2">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(e, index) in arrArchivos" :key="e">
                                                <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><img :src="require(`@/assets/images/icons/${ e.extension }.svg`)" class="iconImg" /> {{ e.file_name_dec }}</a></td>
                                                <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download-alt"></i></a></td>
                                                <td><a href="#" @click="deleteFile( e.id, index)" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- Fin de carga de archivos -->

                        <div class="form-group row">
                            <label class="col-md-4" to="ejeGral">Eje General del Plan Nacional de Desarrollo 2019-2024:<div class="subQuest">Alineación de las intervenciones gubernamentales con cada uno de los ejes generales descritos en el PND 2019-2024</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="ejeGral" id="ejeGral1" v-model="form.ejeGral" value='Política y Gobierno' :checked="this.form.ejeGral == 'Política y Gobierno' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">Política y Gobierno</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="ejeGral" id="ejeGral2" v-model="form.ejeGral" value='Política Social' :checked="this.form.ejeGral == 'Política Social' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">Política Social</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="ejeGral" id="ejeGral3" v-model="form.ejeGral" value='Economía' :checked="this.form.ejeGral == 'Economía' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">Economía</span>
                                    </label><br>
                                </el-card>   
								<!--    <span>Eligió: {{ form.ejeGral }}</span>    -->
                            </div>





                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_objetivo1">Alineación con los Objetivos Prioritarios del Programa Nacional de Juventud 2021-2024<div class="subQuest">Alineación de las intervenciones con alguno de los Objetivos Prioritarios del PROJUVENTUD 2021-2024, documento rector para la definición, articulación e instrumentación de una política nacional en materia de juventud.</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <div v-for="e in arrObjetivos" :key="e" class="col-md-12" style="padding:2px">                                    
                                        <label class="toggle">
                                            <input class="toggle-checkbox" type="checkbox" :name="'nom_objetivo' + e.id" :id="'nom_objetivo' + e.id" :value='e.nom_objetivo' :v-model="form.nom_objetivo[e.id]" :checked="form.nom_objetivo[e.id] === 1">
                                            <div class="toggle-switch"></div><span class="toggle-label">{{ e.num_objetivo }}. {{ e.nom_objetivo }}</span>
                                        </label><br>
                                    </div>    
                                </el-card>    
                            </div>
                            




                        </div>
                        


                        <div class="form-group row">
                            <label class="col-md-4" to="nom_objetivo_ods">Armonización de los Objetivos de Desarrollo Sostenible de la Agenda 2030<div class="subQuest">Alineación de las intervenciones con alguno de los 17 Objetivos de Desarrollo Sostenible (ODS) que integran la Agenda 2030</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <div v-for="e in arrObjetivosODS" :key="e" class="col-md-8" style="padding:2px">                                    
                                        <label class="toggle">
                                            <input class="toggle-checkbox" type="checkbox" :name="'nom_objetivo_ods' + e.id" :id="'nom_objetivo_ods' + e.id" :value='e.nom_objetivo_ods' :v-model="form.nom_objetivo_ods[e.id]" :checked="form.nom_objetivo_ods[e.id] === 1">
                                            <div class="toggle-switch"></div><span class="toggle-label">{{ e.nom_objetivo_ods }}</span>
                                        </label><br>
                                    </div>    
                                </el-card>    
                            </div>
                        </div>



        
                        <div class="form-group row">
                            <div class="col-md-6 text-left">
                                <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
                                <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                            </div>
                            <div class="col-md-6 text-right">
                                <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
                                <input type="button" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="saveIntervencion" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>


</el-card>

</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'
import '/src/assets/css/vue-datepicker/main.css'

import updateData from '@/helpers/updateData'
import getCatDepIntervencion from '@/helpers/getCatDepIntervencion'
//import getCatProgPresupuestario from '@/helpers/getCatProgPresupuestario'
import getCatTipoIntervencion from '@/helpers/getCatTipoIntervencion'
import getCatTipoNormatividad from '@/helpers/getCatTipoNormatividad'
import getCatTipoSolicitante from '@/helpers/getCatTipoSolicitante'
import getCatTipoRespInfo from '@/helpers/getCatTipoRespInfo'
import getCatEstados from '@/helpers/getCatEstados'
import getCatObj_PROJUVENTUD from '@/helpers/getCatObjetivos'
import getCatObjetivos_ODS from '@/helpers/getCatObjetivos_ODS'
import getIntervencionesById from '@/helpers/getIntervencionesById'


import { mount } from '@vue/test-utils'

/* Archivos */
import getArchivos from '@/helpers/getArchivos'
import getArchivosAvance from '@/helpers/getArchivosAvance'
import axios from 'axios'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';
import insertData from '@/helpers/addData'

import moment from 'moment'                 //no va
moment.locale("es")



export default {
    components: {
        Datepicker,
    },
    data() {
        return {
            form: {
                id: '',
                nom_funcionario: '',
                nom_cargo: '',
                email: '',
                dependencia: '',
                areaImplementa: '',
                interGuberna: '',
                webIntervencion: '',
                nomPrograma: '',
                tipoIntervencion: '',
                otroIntervencion: '',
                objetivo: '',
                normatividad: '',
                tipoNormatividad: '',
                webNormatividad: '',
                tipoSolicitante: '',
                tipoActor: [],
                tipoMontosApoyo: '',
                poblacionObjetivo: '',
                gpoEdad: [],
                vigencia: '',
                fecha_inicio: '',
                fecha_fin: '',
                nom_estado: [],
                ejeGral: '',
                nom_objetivo: [],
                nom_objetivo_ods: [],
                estatus: '',

                

            },
            fecha_inicio: '',
            fecha_fin: '',

            ubApi: ubApi.value,
            noValid: {
                nom_clase_ind: false,
            },
            depsArr: [],
            //ppArr: [],
            tipoIntArr: [],
            tipoNorma: [],
            tipoSolic: [],
            tipoResIn: [],
            ambGeo: [],
            arrObjetivos: [],
            arrObjetivosODS: [],
            passDif: false,
            added: false,

            arrArchivos: [],
            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,



        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        
        async setData( id ) { //Se trae solo el nombre del elemento seleccionado para colocarlo como primer registro del combobox
            const datos = await getIntervencionesById( id )
            
            this.form.id = datos.id
            this.form.nom_funcionario = datos.nom_funcionario
            this.form.nom_cargo = datos.nom_cargo
            this.form.email = datos.email
            this.form.dependencia = datos.dependencia
            this.form.areaImplementa = datos.areaImplementa
            this.form.interGuberna = datos.interGuberna
            this.form.webIntervencion = datos.webIntervencion
            this.form.nomPrograma = datos.nomPrograma
            this.form.tipoIntervencion = datos.tipoIntervencion
            this.form.otroIntervencion = datos.otroIntervencion
            this.form.objetivo = datos.objetivo
            this.form.normatividad = datos.normatividad
            this.form.tipoNormatividad = datos.tipoNormatividad
            this.form.webNormatividad = datos.webNormatividad
            this.form.tipoSolicitante = datos.tipoSolicitante
            //this.form.tipoActor = datos.tipoActor
            this.form.tipoActor[1] = datos.tipoActor1
            this.form.tipoActor[2] = datos.tipoActor2
            this.form.tipoActor[3] = datos.tipoActor3
            this.form.tipoActor[4] = datos.tipoActor4
            this.form.tipoActor[5] = datos.tipoActor5
            this.form.tipoActor[6] = datos.tipoActor6
            this.form.tipoActor[7] = datos.tipoActor7
            this.form.tipoActor[8] = datos.tipoActor8
            this.form.tipoActor[9] = datos.tipoActor9
            this.form.tipoActor[10] = datos.tipoActor10
            this.form.tipoActor[11] = datos.tipoActor11
            this.form.tipoMontosApoyo = datos.tipoMontosApoyo
            this.form.poblacionObjetivo = datos.poblacionObjetivo
            this.form.gpoEdad[1] = datos.gpoEdad1
            this.form.gpoEdad[2] = datos.gpoEdad2
            this.form.gpoEdad[3] = datos.gpoEdad3
            this.form.vigencia = datos.vigencia
            this.form.fecha_inicio = new Date(datos.vigencia_inicio + ' 00:00')
            this.form.fecha_fin = new Date(datos.vigencia_fin + ' 00:00')

            this.form.nom_estado[1] = datos.nom_estado1
            this.form.nom_estado[2] = datos.nom_estado2
            this.form.nom_estado[3] = datos.nom_estado3
            this.form.nom_estado[4] = datos.nom_estado4
            this.form.nom_estado[5] = datos.nom_estado5
            this.form.nom_estado[6] = datos.nom_estado6
            this.form.nom_estado[7] = datos.nom_estado7
            this.form.nom_estado[8] = datos.nom_estado8
            this.form.nom_estado[9] = datos.nom_estado9
            this.form.nom_estado[10] = datos.nom_estado10
            this.form.nom_estado[11] = datos.nom_estado11
            this.form.nom_estado[12] = datos.nom_estado12
            this.form.nom_estado[13] = datos.nom_estado13
            this.form.nom_estado[14] = datos.nom_estado14
            this.form.nom_estado[15] = datos.nom_estado15
            this.form.nom_estado[16] = datos.nom_estado16
            this.form.nom_estado[17] = datos.nom_estado17
            this.form.nom_estado[18] = datos.nom_estado18
            this.form.nom_estado[19] = datos.nom_estado19
            this.form.nom_estado[20] = datos.nom_estado20
            this.form.nom_estado[21] = datos.nom_estado21
            this.form.nom_estado[22] = datos.nom_estado22
            this.form.nom_estado[23] = datos.nom_estado23
            this.form.nom_estado[24] = datos.nom_estado24
            this.form.nom_estado[25] = datos.nom_estado25
            this.form.nom_estado[26] = datos.nom_estado26
            this.form.nom_estado[27] = datos.nom_estado27
            this.form.nom_estado[28] = datos.nom_estado28
            this.form.nom_estado[29] = datos.nom_estado29
            this.form.nom_estado[30] = datos.nom_estado30
            this.form.nom_estado[31] = datos.nom_estado31
            this.form.nom_estado[32] = datos.nom_estado32
            this.form.nom_estado[33] = datos.nom_estado33
            this.form.ejeGral = datos.ejeGral
            
            this.form.nom_objetivo[1] = datos.nom_objetivo1
            this.form.nom_objetivo[2] = datos.nom_objetivo2
            this.form.nom_objetivo[3] = datos.nom_objetivo3
            this.form.nom_objetivo[4] = datos.nom_objetivo4
            this.form.nom_objetivo[5] = datos.nom_objetivo5
            this.form.nom_objetivo[6] = datos.nom_objetivo6

            this.form.nom_objetivo_ods[1] = datos.nom_objetivo_ods1
            this.form.nom_objetivo_ods[2] = datos.nom_objetivo_ods2
            this.form.nom_objetivo_ods[3] = datos.nom_objetivo_ods3
            this.form.nom_objetivo_ods[4] = datos.nom_objetivo_ods4
            this.form.nom_objetivo_ods[5] = datos.nom_objetivo_ods5
            this.form.nom_objetivo_ods[6] = datos.nom_objetivo_ods6
            this.form.nom_objetivo_ods[7] = datos.nom_objetivo_ods7
            this.form.nom_objetivo_ods[8] = datos.nom_objetivo_ods8
            this.form.nom_objetivo_ods[9] = datos.nom_objetivo_ods9
            this.form.nom_objetivo_ods[10] = datos.nom_objetivo_ods10
            this.form.nom_objetivo_ods[11] = datos.nom_objetivo_ods11
            this.form.nom_objetivo_ods[12] = datos.nom_objetivo_ods12
            this.form.nom_objetivo_ods[13] = datos.nom_objetivo_ods13
            this.form.nom_objetivo_ods[14] = datos.nom_objetivo_ods14
            this.form.nom_objetivo_ods[15] = datos.nom_objetivo_ods15
            this.form.nom_objetivo_ods[16] = datos.nom_objetivo_ods16
            this.form.nom_objetivo_ods[17] = datos.nom_objetivo_ods17
            this.form.estatus = datos.estatus
           

        },

        async saveIntervencion() {
            const fecha_inicio = moment( this.form.fecha_inicio ).format('YYYY/MM/DD')
            const fecha_fin =  moment( this.form.fecha_fin ).format('YYYY/MM/DD')

            const dataForm = {
                nom_funcionario: this.form.nom_funcionario,
                nom_cargo: this.form.nom_cargo,
                email: this.form.email,
                dependencia: this.form.dependencia,
                areaImplementa: this.form.areaImplementa,
                interGuberna: this.form.interGuberna,
                webIntervencion: this.form.webIntervencion,
                nomPrograma: this.form.nomPrograma,
                tipoIntervencion: this.form.tipoIntervencion,
                otroIntervencion: this.form.otroIntervencion,
                objetivo: this.form.objetivo,
                normatividad: this.form.normatividad,
                tipoNormatividad: (this.form.tipoNormatividad)? this.form.tipoNormatividad : null,
                webNormatividad: (this.form.webNormatividad)? this.form.webNormatividad : null,
                tipoSolicitante: this.form.tipoSolicitante,
                tipoActor1: (document.querySelector('#tipoActor1').checked)? 1 : null,
                tipoActor2: (document.querySelector('#tipoActor2').checked)? 1 : null,
                tipoActor3: (document.querySelector('#tipoActor3').checked)? 1 : null,
                tipoActor4: (document.querySelector('#tipoActor4').checked)? 1 : null,
                tipoActor5: (document.querySelector('#tipoActor5').checked)? 1 : null,
                tipoActor6: (document.querySelector('#tipoActor6').checked)? 1 : null,
                tipoActor7: (document.querySelector('#tipoActor7').checked)? 1 : null,
                tipoActor8: (document.querySelector('#tipoActor8').checked)? 1 : null,
                tipoActor9: (document.querySelector('#tipoActor9').checked)? 1 : null,
                tipoActor10: (document.querySelector('#tipoActor10').checked)? 1 : null,
                tipoActor11: (document.querySelector('#tipoActor11').checked)? 1 : null,
                tipoMontosApoyo: this.form.tipoMontosApoyo,
                gpoEdad1: (this.form.gpoEdad[1])? this.form.gpoEdad[1] : null,
                gpoEdad2: (this.form.gpoEdad[2])? this.form.gpoEdad[2] : null,
                gpoEdad3: (this.form.gpoEdad[3])? this.form.gpoEdad[3] : null,
                poblacionObjetivo: this.form.poblacionObjetivo,
                vigencia: this.form.vigencia,
                vigencia_inicio: fecha_inicio,
                vigencia_fin: fecha_fin,
                nom_estado1: (document.querySelector('#nom_estado1').checked)? 1 : null,
                nom_estado2: (document.querySelector('#nom_estado2').checked)? 1 : null,
                nom_estado3: (document.querySelector('#nom_estado3').checked)? 1 : null,
                nom_estado4: (document.querySelector('#nom_estado4').checked)? 1 : null,
                nom_estado5: (document.querySelector('#nom_estado5').checked)? 1 : null,
                nom_estado6: (document.querySelector('#nom_estado6').checked)? 1 : null,
                nom_estado7: (document.querySelector('#nom_estado7').checked)? 1 : null,
                nom_estado8: (document.querySelector('#nom_estado8').checked)? 1 : null,
                nom_estado9: (document.querySelector('#nom_estado9').checked)? 1 : null,
                nom_estado10: (document.querySelector('#nom_estado10').checked)? 1 : null,
                nom_estado11: (document.querySelector('#nom_estado11').checked)? 1 : null,
                nom_estado12: (document.querySelector('#nom_estado12').checked)? 1 : null,
                nom_estado13: (document.querySelector('#nom_estado13').checked)? 1 : null,
                nom_estado14: (document.querySelector('#nom_estado14').checked)? 1 : null,
                nom_estado15: (document.querySelector('#nom_estado15').checked)? 1 : null,
                nom_estado16: (document.querySelector('#nom_estado16').checked)? 1 : null,
                nom_estado17: (document.querySelector('#nom_estado17').checked)? 1 : null,
                nom_estado18: (document.querySelector('#nom_estado18').checked)? 1 : null,
                nom_estado19: (document.querySelector('#nom_estado19').checked)? 1 : null,
                nom_estado20: (document.querySelector('#nom_estado20').checked)? 1 : null,
                nom_estado21: (document.querySelector('#nom_estado21').checked)? 1 : null,
                nom_estado22: (document.querySelector('#nom_estado22').checked)? 1 : null,
                nom_estado23: (document.querySelector('#nom_estado23').checked)? 1 : null,
                nom_estado24: (document.querySelector('#nom_estado24').checked)? 1 : null,
                nom_estado25: (document.querySelector('#nom_estado25').checked)? 1 : null,
                nom_estado26: (document.querySelector('#nom_estado26').checked)? 1 : null,
                nom_estado27: (document.querySelector('#nom_estado27').checked)? 1 : null,
                nom_estado28: (document.querySelector('#nom_estado28').checked)? 1 : null,
                nom_estado29: (document.querySelector('#nom_estado29').checked)? 1 : null,
                nom_estado30: (document.querySelector('#nom_estado30').checked)? 1 : null,
                nom_estado31: (document.querySelector('#nom_estado31').checked)? 1 : null,
                nom_estado32: (document.querySelector('#nom_estado32').checked)? 1 : null,
                nom_estado33: (document.querySelector('#nom_estado33').checked)? 1 : null,
                ejeGral: this.form.ejeGral,
                
                nom_objetivo1: (document.querySelector('#nom_objetivo1').checked)? 1 : null,
                nom_objetivo2: (document.querySelector('#nom_objetivo2').checked)? 1 : null,
                nom_objetivo3: (document.querySelector('#nom_objetivo3').checked)? 1 : null,
                nom_objetivo4: (document.querySelector('#nom_objetivo4').checked)? 1 : null,
                nom_objetivo5: (document.querySelector('#nom_objetivo5').checked)? 1 : null,
                nom_objetivo6: (document.querySelector('#nom_objetivo6').checked)? 1 : null,

                nom_objetivo_ods1: (document.querySelector('#nom_objetivo_ods1').checked)? 1 : null,
                nom_objetivo_ods2: (document.querySelector('#nom_objetivo_ods2').checked)? 1 : null,
                nom_objetivo_ods3: (document.querySelector('#nom_objetivo_ods3').checked)? 1 : null,
                nom_objetivo_ods4: (document.querySelector('#nom_objetivo_ods4').checked)? 1 : null,
                nom_objetivo_ods5: (document.querySelector('#nom_objetivo_ods5').checked)? 1 : null,
                nom_objetivo_ods6: (document.querySelector('#nom_objetivo_ods6').checked)? 1 : null,
                nom_objetivo_ods7: (document.querySelector('#nom_objetivo_ods7').checked)? 1 : null,
                nom_objetivo_ods8: (document.querySelector('#nom_objetivo_ods8').checked)? 1 : null,
                nom_objetivo_ods9: (document.querySelector('#nom_objetivo_ods9').checked)? 1 : null,
                nom_objetivo_ods10: (document.querySelector('#nom_objetivo_ods10').checked)? 1 : null,
                nom_objetivo_ods11: (document.querySelector('#nom_objetivo_ods11').checked)? 1 : null,
                nom_objetivo_ods12: (document.querySelector('#nom_objetivo_ods12').checked)? 1 : null,
                nom_objetivo_ods13: (document.querySelector('#nom_objetivo_ods13').checked)? 1 : null,
                nom_objetivo_ods14: (document.querySelector('#nom_objetivo_ods14').checked)? 1 : null,
                nom_objetivo_ods15: (document.querySelector('#nom_objetivo_ods15').checked)? 1 : null,
                nom_objetivo_ods16: (document.querySelector('#nom_objetivo_ods16').checked)? 1 : null,
                nom_objetivo_ods17: (document.querySelector('#nom_objetivo_ods17').checked)? 1 : null,
                estatus: this.form.estatus
            }

            const jsonData = JSON.parse(JSON.stringify( dataForm ))
            const updateIntervenciones = await updateData('intervenciones', this.form.id, jsonData)

            if ( updateIntervenciones[0].res === 1 ) {
                this.$notify({
                    title: 'Correcto', text: 'Se guardó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },
        async getDepIntervencion() {
            const depInt = await getCatDepIntervencion()
            return depInt
        },
        //async getProgPresupuestario() {
        //    const progPres = await getCatProgPresupuestario()
        //    return progPres
        //},
        async getTipoIntervencion() {
            const tipoInter = await getCatTipoIntervencion()
            return tipoInter
        },
        async getTipoNormatividad() {
            const tipoNorma = await getCatTipoNormatividad()
            return tipoNorma
        },
        async getTipoSolicitante() {
            const tipoSol = await getCatTipoSolicitante()
            return tipoSol
        },
        async getTipoRespInfo() {
            const tipoRi = await getCatTipoRespInfo()
            return tipoRi
        },
        async getEstados() {
            const edos = await getCatEstados()
            return edos
        },
        async getObjetivos() {
            const objetivo = await getCatObj_PROJUVENTUD()
            return objetivo
        },
        async getObjetivosODS() {
            const objetivo_ods = await getCatObjetivos_ODS()
            return objetivo_ods
        },
        async LimpiaTipoActor() {   //Aqui vamos 
            const sTipo = document.querySelector('#tipoSolicitante').value
            if (sTipo == 1 || sTipo == 4) {
                this.form.tipoActor[1]=false
                this.form.tipoActor[2]=false
                this.form.tipoActor[3]=false
                this.form.tipoActor[4]=false
                this.form.tipoActor[5]=false
                this.form.tipoActor[6]=false
                this.form.tipoActor[7]=false
                this.form.tipoActor[8]=false
                this.form.tipoActor[9]=false
                this.form.tipoActor[10]=false
                this.form.tipoActor[11]=false
            }        
        },
        async tipoActorTodos(nTipo) {
            if (nTipo == 10) {    
                const sTipo = document.querySelector('#tipoActor10').checked
                if (sTipo == true) {
                    this.form.tipoActor[1]=false
                    this.form.tipoActor[2]=false
                    this.form.tipoActor[3]=false       
                    this.form.tipoActor[4]=false
                    this.form.tipoActor[5]=false
                    this.form.tipoActor[6]=false
                    this.form.tipoActor[7]=false
                    this.form.tipoActor[8]=false
                    this.form.tipoActor[9]=false
                    this.form.tipoActor[11]=false
                    document.querySelector('#tipoActor1').disabled = true
                    document.querySelector('#tipoActor2').disabled = true
                    document.querySelector('#tipoActor3').disabled = true
                    document.querySelector('#tipoActor4').disabled = true
                    document.querySelector('#tipoActor5').disabled = true
                    document.querySelector('#tipoActor6').disabled = true
                    document.querySelector('#tipoActor7').disabled = true
                    document.querySelector('#tipoActor8').disabled = true
                    document.querySelector('#tipoActor9').disabled = true
                    document.querySelector('#tipoActor11').disabled = true
                } else {
                    document.querySelector('#tipoActor1').disabled = false
                    document.querySelector('#tipoActor2').disabled = false
                    document.querySelector('#tipoActor3').disabled = false
                    document.querySelector('#tipoActor4').disabled = false
                    document.querySelector('#tipoActor5').disabled = false
                    document.querySelector('#tipoActor6').disabled = false
                    document.querySelector('#tipoActor7').disabled = false
                    document.querySelector('#tipoActor8').disabled = false
                    document.querySelector('#tipoActor9').disabled = false
                    document.querySelector('#tipoActor11').disabled = false
                }
            }
        },
        async NoAplicaChecked() {
            this.form.gpoEdad[1]=false
            this.form.gpoEdad[2]=false
                   
            const sEdad = document.querySelector('#grupoEdad3').checked
            if (sEdad) {
                document.querySelector('#grupoEdad1').disabled = true
                document.querySelector('#grupoEdad2').disabled = true
            } else {
                document.querySelector('#grupoEdad1').disabled = false
                document.querySelector('#grupoEdad2').disabled = false
            }        
        },
        async estadoNacional(nTipo) {
            if (nTipo == 33) {    
                const sTipo = document.querySelector('#nom_estado33').checked
                if (sTipo == true) {
                    for (let n = 1; n < 33; n++) {
                        this.form.nom_estado[n]=false
                        document.querySelector('#nom_estado'+n).disabled = true
                    }
                } else {
                    for (let n = 1; n < 33; n++) {
                        document.querySelector('#nom_estado'+n).disabled = false
                    }
                }
            }
        },
        LimpiaNormatividad() {
            this.form.webNormatividad=''
            this.form.tipoNormatividad = ''
        },
        otrasSelected() {
            this.form.otroIntervencion = ''
        },
        async uploadFile() {
            this.file = this.$refs.file.files[0]
            var formData = new FormData()
            formData.append('file', this.file)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            await axios.post( ubApi.value + '/uploadFiles.php', formData, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlert = z_errorAlert
            this.successAlert = z_successAlert
            this.errorMessage = z_errorMessage
            this.successMessage = z_successMessage
            this.$refs.file.value = z_fileValue

            if (z_successAlert) {
                // IntervenReg módulo para tabla de archivos
                const jsonInsFile = JSON.parse(JSON.stringify({
                    "modulo": "IntervenReg",
                    "id_registro": this.form.id,
                    "id_registro_sec": charsService.decode(this.$route.params.id),
                    "id_usuario": z_userId,
                    "tmp_name": z_tmpFileName,
                    "file_name": z_baseName,
                    "extension": z_extension,
                    "tmp": true
                }))
                const lastInsert = await insertData('archivos', jsonInsFile);
                this.arrArchivos.push({borrar:0, extension:z_extension, file_name:charsService.encode(z_baseName), file_name_dec:z_baseName, id:lastInsert[0].res.data, id_registro:this.form.id, id_usuario:z_userId, modulo:"IntervenReg", tmp_name:charsService.encode(z_tmpFileName)})
            }
        },
        readFormatFechaInicio() {
            return this.readFormat( this.form.fecha_inicio )
        },

        readFormatFechaFin() {
            return this.readFormat( this.form.fecha_fin )
        },

        readFormat( date ) {
            if ( date !== '' ) {
                const day = (date.getDate() < 10)? '0' + date.getDate() : date.getDate()
                const month = (date.getMonth() < 9)? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
                const year = date.getFullYear()

                return `${day}-${month}-${year}`;
            } else {
                return ''
            }
        },

        async getArchivos() {
            return await getArchivos( 'IntervenReg', charsService.decode(this.$route.params.id) )
        },

        async deleteFile( id, i ) {
            
            if ( confirm('¿Confirma eliminar el archivo?') ) {
                this.arrArchivos.splice( i, 1)
                await updateData( 'archivos', parseInt(charsService.decode( id )), JSON.parse(JSON.stringify({ borrar: 1 })) )
            }
        },
        async setArrArchivos( id ) {
            const arrFiles = await getArchivosAvance( 'IntervenReg', parseInt(charsService.decode( id )), parseInt(charsService.decode( id )))

            arrFiles.forEach(archivo => {
                console.log( archivo );
                this.arrArchivos.push({id: charsService.encode(archivo.id), tmp_name: charsService.encode(archivo.tmp_name), file_name: charsService.encode(archivo.file_name), extension: archivo.extension, file_name_dec:archivo.file_name })
            
            
               // this.$notify({
               //         title: 'ubApi=', text: ubApi , type: 'success', duration: 5000, speed: 1000
               //     })
            
            
            });
        },

    },
    async mounted() {
        await this.setData( charsService.decode( this.$route.params.id ))
        this.depsArr = await this.getDepIntervencion()
        //this.ppArr = await this.getProgPresupuestario()
        this.tipoIntArr = await this.getTipoIntervencion()
        this.tipoNorma = await this.getTipoNormatividad()
        this.tipoSolic = await this.getTipoSolicitante()
        this.tipoResIn = await this.getTipoRespInfo()
        this.ambGeo = await this.getEstados()
        this.arrObjetivos = await this.getObjetivos()
        this.arrObjetivosODS = await this.getObjetivosODS()
        if (this.form.gpoEdad[3]==1) {await this.NoAplicaChecked()}
        if (this.form.tipoActor[10]==1) {await this.tipoActorTodos(10)}
        if (this.form.nom_estado[33]==1) {await this.estadoNacional(33)}
        
        await this.setArrArchivos( this.$route.params.id )
        
        
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #DC3545;
    }

    .subQuest {
        font-size: small;
        color: #C0C0C0;
    }

    /*    Estilo del radio   */
    .el-radio, input[type="radio"]{
        border: 5px solid #AAAAAA;
        border-radius: 100%;
        height: 25px;
        width: 25px;
        position: relative;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
    }
/*    Fin estilo del radio   */
    form input[type="text"], select, textarea {
        padding:3px 10px;
        border:1px solid #f6f6f6;
        border-radius:1px;
        background-color: #E6E8EB;
        color: #000000;
        margin:8px 0;
        display:inline-block;
    }
    
    form input:focus {
       /* border: 2px solid #c8c8c8; */
        box-shadow:1px 1px 1px  #c8c8c8;
       /* color: #ff0000; */
        /* background: #8c8c8c; */ 
        background: rgba(142,113,70,0.2)
    }
    
    ::placeholder { color: #CCCCCC; }

    /* ------ Estilo de Toggle -----------*/
    .toggle {
        cursor: pointer;
        display: inline-block;
    }

    .toggle-switch {
        display: inline-block;
        background: #CCCCCC; /*#9D2449 Guinda*/
        border-radius: 16px;
        width: 40px;
        height: 20px;
        position: relative;
        vertical-align: middle;
        transition: background 0.45s;
    }
    .toggle-switch:before, .toggle-switch:after {
        content: "";
    }
    .toggle-switch:before {
        display: block;
        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
        border-radius: 50%;
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25);
        width: 18px;
        height: 18px;
        position: absolute;
        top: 1px;
        left: 2px;
        transition: left 0.45s;
    }
    .toggle:hover .toggle-switch:before {
        background: linear-gradient(to bottom, #fff 0%, #fff 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    .toggle-checkbox:checked + .toggle-switch {
        background: #CCCCCC;    /*#235B4E Verde*/
    }
    .toggle-checkbox:checked + .toggle-switch:before {  /* Cuando se selecciona */
        background: #999999;    /*#235B4E Verde*/
        left: 20px;
    }

    .toggle-checkbox:disabled +  .toggle-switch:before {  
        background: #CCCCCC;    /*#235B4E Verde*/
        /*background: linear-gradient(to bottom, #235B4E 0%, #235B4E 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5); */
        
    }

    .toggle-checkbox {
        position: absolute;
        visibility: hidden;
    }

    .toggle-label {
        margin-left: 5px;
        position: relative;
        top: 2px;
        font-weight: normal;
    }
    /* ---Fin de estilo de Toggle --------*/

    .scrollbar-demo-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        margin: 0px;
        text-align: left;
        border-radius: 0px;
        /*background: #bdbdbd;*/ 
        font-family: 'Montserrat';
        font-weight: normal;
        font-size: 18px;
    }


    /* ------ Estilo de Toggler Radio -----------*/
    .toggler {
        cursor: pointer;
        display: inline-block;
    }

    .toggler-switch {
        display: inline-block;
        background: #999999; /*#9D2449 Guinda*/
        border-radius: 16px;
        width: 25px;
        height: 25px;
        position: relative;
        vertical-align: middle;  
        transition: background 0.45s;
    }
    .toggler-switch:before, .toggler-switch:after {
        content: "";
    }
    .toggler-switch:before {
        display: block;
        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
        border-radius: 50%;
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25);
        width: 17px;
        height: 17px;
        position: absolute;
        top: 4px;
        left: 4px;
        transition: left 0.45s;
    }
    .toggler:hover .toggler-switch:before {
        background: linear-gradient(to bottom, #fff 0%, #fff 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    .toggler-radio:checked + .toggler-switch {
        background: #CCCCCC;    /*#235B4E Verde*/
    }
    .toggler-radio:checked + .toggler-switch:before {  /* Cuando se selecciona */
        background: #999999;    /*#235B4E Verde*/  
    }

     .toggler-radio:disabled +  .toggler-switch:before {  /* Aqui voy*/
        background: #CCCCCC;    /*#235B4E Verde*/
        /*background: linear-gradient(to bottom, #235B4E 0%, #235B4E 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5); */
    }

    .toggler-radio {
        position: absolute;
        visibility: hidden;
    }

    .toggler-label {
        margin-left: 5px;
        position: relative;
        top: 2px;
        font-weight: normal;
    }
    /* ---Fin de estilo de Toggler --------*/
    /* ---Inicio Archivos -----------------*/

    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    small {
        color: #235B4E;
        font-weight: bold;
    }
</style>