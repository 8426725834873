<template>
    <h4 class="mt-0">Agregar una acción puntual</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="objetivo_prio">Objetivo prioritario:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="objetivo_prio" name="objetivo_prio" v-model="form.objetivo_prio">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="eee in objetivo_priosArr" :key="eee.id" :value="eee.id">{{ eee.num_objetivo }} {{ eee.nom_corto_objetivo }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label"  to="estrategia">Nombre de la estrategia prioritaria:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="estrategia" name="estrategia" v-model="form.estrategia">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="ee in estrategiasArr" :key="ee.id" :value="ee.id">{{ ee.num_estrategia }} {{ ee.nom_corto_estrategia }}</option>
                        </select>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="codigo_accion_puntual">Código de la acción puntual:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <input type="text" class="form-control" id="codigo_accion_puntual" name="codigo_accion_puntual" placeholder="#.#.#" v-model="form.codigo_accion_puntual" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="nom_accion_puntual">Nombre de la acción puntual:</label>
                    <div class="col-md-9 col-sm-12">
                        <textarea rows="3" class="form-control" name="nom_accion_puntual" id="nom_accion_puntual" v-model="form.nom_accion_puntual" placeholder="Nombre de la acción puntual" @keyup="conteo_real('nom_accion_puntual')"></textarea>
                        <div id="nom_accion_puntual_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" for="cualesDependencias">Dependencias responsables</label>
                    <div class="col-md-3">
                        <select class="form-control" multiple="multiple" name="cualesDependencias" id="cualesDependencias" v-model="form.cualesDependencias">
                            <option value="" selected>[Seleccione]</option>
                            <option v-for="e in catDependencias" :key="e.id" :value="e.id" :selected="this.form.arrCuales.includes(e.id.toString())">{{ e.nc }}</option>
                        </select>
                        <div><small>Para seleccionar varios presione la tecla Mayus o Ctrl</small></div>
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="dependencia">Dependencia o entidad coordinadora:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e_d in depsArr" :key="e_d.id" :value="e_d.id">{{ e_d.num_dependencia }} {{ e_d.nom_corto_dependencia }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="tipo_ap">Tipo de acción puntual:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="tipo_ap" name="tipo_ap" v-model="form.tipo_ap">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e_ap in tipo_apsArr" :key="e_ap.id" :value="e_ap.id">{{ e_ap.id }} {{ e_ap.nom_tipo_ap }}</option>
                        </select>
                    </div>
                </div>

                <h6 class="col-form-label">Periodo para el cumplimiento de la acción puntual</h6>

                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="cump_fecha_inicio">Fecha de inicio:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <Datepicker lang="es" name="cump_fecha_inicio" id="cump_fecha_inicio" v-model="form.cump_fecha_inicio"/>
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="cump_fecha_termino">Fecha de fin:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <Datepicker lang="es" name="cump_fecha_termino" id="cump_fecha_termino" v-model="form.cump_fecha_termino"/>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" for="cantidad_jovenes">Cantidad de jóvenes a impactar</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <input class="form-control" type="number" name="cantidad_jovenes" id="cantidad_jovenes" v-model="form.cantidad_jovenes" placeholder="Cantidad de jóvenes a impactar" :disabled='isDisabled||isDisabledJ'>
                    </div>
                    <div class="col-md-3">
                        <input type="checkbox" name="cantidad_jovenes_nd" id="cantidad_jovenes_nd" v-model="form.cantidad_jovenes_nd" :checked="form.cantidad_jovenes_nd" @click="isDisabledJov" @load="isDisabledJov"> Dato no disponible
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUser" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUser" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra acción puntual" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addAccion_puntual" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import '@/assets/css/vuedatepickerui.css'
import VueDatepickerUi from 'vue-datepicker-ui'

import getCatsNc from '@/helpers/getCatsNc'
import getDependenciasNc from '@/helpers/getDependenciasNc'

import addData from '@/helpers/addData'
import getDataByAnyAccion_puntual from '@/helpers/getDataByAnyAccion_puntual'

import moment from 'moment'

moment.locale("es")

export default {
    components: {
        Datepicker: VueDatepickerUi
    },
    data() {
        return {
            form: {
                codigo_accion_puntual: '',
                nom_accion_puntual: '',
                estrategia: '',
                objetivo_prio: '',
                dependencia: '',
                cualesDependencias: [],
                arrCuales: [],

                tipo_ap: '',
                cump_fecha_inicio: '',
                cump_fecha_termino: '',
                cantidad_jovenes: null,
                cantidad_jovenes_nd: '',
            },
            noValid: {
                codigo_accion_puntual: false,
                nom_accion_puntual: false,
                estrategia: false,
                objetivo_prio: false,
                dependencia: false,
                tipo_ap: false,
                cump_fecha_inicio: false,
                cump_fecha_termino: false,
                cantidad_jovenes: false,
                cantidad_jovenes_nd: '',
            },
            isDisabled: '',
            isDisabledJ: '',
            estrategiasArr: [],
            objetivo_priosArr: [],
            catDependencias: [],
            depsArr: [],
            tipo_apsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        async getEstrategias() {
            const estrategias = await getCatsNc('/cat_estrategia?transform=1&columns=id,num_estrategia,nom_corto_estrategia&filter=borrar,lt,1')
            return estrategias.cat_estrategia
        },

        async getObjetivo_prios() {
            const objetivo_prios = await getCatsNc('/cat_objetivos?transform=1&columns=id,num_objetivo,nom_corto_objetivo&filter=borrar,lt,1')
            return objetivo_prios.cat_objetivos
        },

        async getCatalogos() {
            const consDependencias = await getDependenciasNc()
            for(let i=0; i<consDependencias.length; i++){
                this.catDependencias.push({id: consDependencias[i].id, nc: consDependencias[i].nom_corto_dependencia})
            }
        },


        async getDependencias() {
            const deps = await getCatsNc('/cat_dependencias?transform=1&columns=id,num_dependencia,nom_corto_dependencia&filter=borrar,lt,1')
            return deps.cat_dependencias

        },

        async getTipos_ap() {
            const tipos_ap = await getCatsNc('/cat_tipo_ap?transform=1&columns=id,nom_tipo_ap&filter=borrar,lt,1')
            return tipos_ap.cat_tipo_ap

        },

        isDisabledJov() {
            const cantidad_jovenes_nd = document.querySelector('#cantidad_jovenes_nd').checked
            this.isDisabledJ = cantidad_jovenes_nd
            if (cantidad_jovenes_nd) {
                document.querySelector('#cantidad_jovenes').value = ''
                document.querySelector('#cantidad_jovenes').dispatchEvent(new Event('input'))
            }
        },




        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.codigo_accion_puntual = ''
            this.form.nom_accion_puntual = ''
            setTimeout(function() { document.getElementById('dependencia').getElementsByTagName('option')[0].selected = 'selected' }, 1);
            document.getElementById('dependencia').getElementsByTagName('option')[0].selected = 'selected'
            this.added = false
        },
        async addAccion_puntual() {
            this.noValid.codigo_accion_puntual = ( this.form.codigo_accion_puntual )? false : true
            this.noValid.nom_accion_puntual = ( this.form.nom_accion_puntual )? false : true
            this.noValid.dependencia = ( this.form.dependencia )? false : true


            const valnom_accion_puntual = await getDataByAnyAccion_puntual( 'accion_puntual', 'nom_accion_puntual', this.form.nom_accion_puntual );
            if ( valnom_accion_puntual.length < 1 ){
                if ( !this.noValid.codigo_accion_puntual && !this.noValid.nom_accion_puntual && !this.noValid.dependencia ) {
                    const dataForm = {
                        codigo_accion_puntual: this.form.codigo_accion_puntual,
                        nom_accion_puntual: this.form.nom_accion_puntual,
                    
                        idestrategiap: this.form.estrategia,
                        //estrategiaNc: this.estrategiaNc,

                        idobjetivo: this.form.objetivo_prio,
                        //objetivo_prioNc: this.objetivo_prioNc,

                        cuales_dep: this.form.cualesDependencias.toString(),

                        iddependencia_coord: this.form.dependencia,
                        //dependenciaNc: this.dependenciaNc,
                        idtipo_ap: this.form.tipo_ap,
                        //tipo_apNc: this.tipo_apNc,

                        cump_fecha_inicio: moment( this.form.cump_fecha_inicio ).format('YYYY/MM/DD'),
                        cump_fecha_termino: moment( this.form.cump_fecha_termino ).format('YYYY/MM/DD'),
                        cantidad_jovenes: (this.form.cantidad_jovenes > 0)? this.form.cantidad_jovenes : null,
                        cantidad_jovenes_nd: this.form.cantidad_jovenes_nd





                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addAccion_puntual = await addData('accion_puntual', jsonData)
                    if ( addAccion_puntual[0].res.status === 200 ) {
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'El formulario requiere atención en uno o varios campos. Favor de atenderlo', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una acción puntual registrada con ese nombre. Verifica los datos', type: 'error'
                });
            }
        },

        conteo_real( id ) {
            const charsCount = document.querySelector("#"+id).value.length
            document.querySelector('#'+ id +'_count').innerHTML = `Número de caraceres: ${charsCount}`
        }
    },

 computed : {
    },
  
    async mounted() {
        this.estrategiasArr = await this.getEstrategias()
        this.objetivo_priosArr = await this.getObjetivo_prios()
        this.depsArr = await this.getDependencias()
        this.tipo_apsArr = await this.getTipos_ap()
        //this.form.sReporte = this.isDisabledJ
        this.isDisabledJov()
        this.getCatalogos()
        this.conteo_real('nom_accion_puntual')
    }
}
</script>

<style scoped>
    h4, h5 {
        color: #9F2241;
    }
    h6 {
        color: #757575;
    }


    .alignCond {
        text-align: right;
    }

    .divBtn {
        padding: 50px 0;
    }

    @media screen and (max-width: 992px) {
        .midSep {
            margin-bottom: 15px;
        }
    }

    @media screen and (max-width: 768px) {
        .hrCond {
            display: none;
        }

        .alignCond {
            text-align: left;
        }

        .btnCond {
            display: block;
            width: 100%;
        }
    }

    form {
        padding-top: 0;
    }
    :root {
        --v-calendar-input-font-size: medium;
    }
    
    .v-calendar .input-field input {
        font-size: var(--v-calendar-input-font-size) !important;
    }

    img {
        width: 100%;
        margin-bottom: 40px;
    }

    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    form {
        font-size: medium;
    }

    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    small {
        color: #235B4E;
        font-weight: bold;
    }    

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>