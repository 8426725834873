<template>
    <div >
        PARAMETRO DESPLEGADO EN VIVO: {{this.idGrafica}}
    </div>
                    <div class="col-md-6" style="top:15px">
                        <div class="panel panel-warning" v-if="idGrafica===1">
                            <div class="panel-body text-center">
                                
                                <iframe :src="url_ind_basicos" width="420px" height="345" frameborder="0" marginheight="0"></iframe>

                            </div>
                        </div>
                        <div class="panel panel-warning" v-if="idGrafica===2">
                            <div class="panel-body text-center">
                                
                                <iframe :src="url_ind_basicos" width="420px" height="345" frameborder="0" marginheight="0"></iframe>

                            </div>
                        </div>
                    </div>
                            <div class="panel-body text-center">uno
                                <el-button type="primary" style="padding: 0px; border: 0px;" @click="seleccionaGrafica(1)">
                                    <img :src="image_est_quinquenal_total" style="width:100%; height:100%;" alt="" >
                                </el-button>

                            </div>
                            <div class="panel-body text-center">dos
                                <el-button type="primary" style="padding: 0px; border: 0px;" @click="seleccionaGrafica(2)">
                                    <img :src="image_est_quinquenal_total" style="width:100%; height:100%;" alt="" >
                                </el-button>

                            </div>
</template>
<script>
import { ubApi, icons } from '@/api/baseUrl'
export default {
   
    data() {
        return {
            url_ind_basicos: '',
            idGrafica: 0,
        };
    },
    props:{
        msg: String,
        param_opc:String,
    },
    computed: {
        parametroid() {
            return this.param_opc;
        },
    },
    methods: {
        async seleccionaGrafica(numIndicador) {
            
            this.idGrafica = numIndicador;
            if (this.idGrafica == 1){         //Poblacion
                this.url_ind_basicos = ubApi.value + '/indicadores/estadistica/est_pob_joven_total.htm'
            }else if (this.idGrafica == 2){         //Educacion
                this.url_ind_basicos = ubApi.value + '/indicadores/estadistica/est_quinquenal_total.htm'
            } 
            this.$notify({
                title: 'entro', text: 'entro:-->' + this.idGrafica  , type: 'success', duration: 5000, speed: 1000
            });
            
        },
        carga_inicial() {
            this.idGrafica = this.parametroid //this.parametro_pasado2;
            if (this.idGrafica === 1){         //Poblacion
                this.url_ind_basicos = ubApi.value + '/indicadores/estadistica/est_pob_joven_total.htm'
            }else if (this.idGrafica === 2){         //Educacion
                this.url_ind_basicos = ubApi.value + '/indicadores/estadistica/est_quinquenal_total.htm'
            }    
        },
    },
    async mounted() {
        this.carga_inicial()  
        //lll
    },
    async beforeUpdate() {
        //this.carga_inicial()  
    },
};
</script>
<style scoped>
#container{
    color: red;
    margin-top: 50px;
}
</style>
