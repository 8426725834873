<template>
    <h4 class="mt-0">Agregar un periodo de recolección</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre del periodo de recolección:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idPeriodo_recoleccion" name="idPeriodo_recoleccion" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_periodo_recoleccion }" id="nom_periodo_recoleccion" name="nom_periodo_recoleccion" placeholder="Nombre del periodo de recolección" v-model="form.nom_periodo_recoleccion" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updatePeriodo_recoleccion" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updatePeriodo_recoleccion" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otro periodo de recolección" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addPeriodo_recoleccion" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import addData from '@/helpers/addData'
import getDataByAnyPeriodo_recoleccion from '@/helpers/getDataByAnyPeriodo_recoleccion'

export default {
    data() {
        return {
            form: {
                nom_periodo_recoleccion: ''
            },
            noValid: {
                nom_periodo_recoleccion: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nom_periodo_recoleccion = ''
            this.added = false
        },
        async addPeriodo_recoleccion() {
            this.noValid.nom_periodo_recoleccion = ( this.form.nom_periodo_recoleccion )? false : true

            const valNom_periodo_recoleccion = await getDataByAnyPeriodo_recoleccion( 'cat_periodo_recoleccion', 'nom_periodo_recoleccion', this.form.nom_periodo_recoleccion );
            
            if ( valNom_periodo_recoleccion.length < 1 ){
                if ( !this.noValid.nom_periodo_recoleccion) {
                    const dataForm = {
                        nom_periodo_recoleccion: this.form.nom_periodo_recoleccion
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addPeriodo_recoleccion = await addData('cat_periodo_recoleccion', jsonData)
                    //if ( addPeriodo_recoleccion[0].res && addPeriodo_recoleccion[0].res > 0 ) {
                    if ( addPeriodo_recoleccion[0].res.status === 200 ) {    
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre del periodo de recolección', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe un periodo de recolección registrado con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>