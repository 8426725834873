/* Regresa todos los datos de la tabla 'estadist_introduccion' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getEstadist_introduccionById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/estadist_introduccion/${ id }`)
    ]

    const [ estadist_introd ] = await Promise.all( promiseArr )

    return estadist_introd.data
}

export default getEstadist_introduccionById