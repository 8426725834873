<template>
    <h3>Estadísticas en Juventud</h3>
    <div>
        <p>En este apartado podrás consultar las estadísticas en materia de JUVENTUD.</p>
    </div>
    
    <div class="row" style="backgroundColor:#E5E5E5">
        <!-- Panel izquierdo (menu) -->
        <div class="col-lg-2 busqueda">
            
            <div class="row mt-4" style="backgroundColor:#E5E5E5">

                <div class="demo-collapse">
                    <el-collapse accordion>
                        <el-collapse-item name="1">
                            <template #title>
                                Población joven<el-icon class="header-icon">
                                <info-filled />
                            </el-icon>
                            </template>
                            
                            
                            <el-col :span="50">
                                <el-menu
                                    name="b_obj5" id="b_obj5"
                                    active-text-color="#9D2449"     
                                    background-color="#FFFFFF"      
                                    class="el-menu-vertical-demo"
                                    default-active="1"
                                    text-color="#000000"               
                                    @open="handleOpen"
                                    @close="handleClose"
                                >
                                
                                   
                                <!--    <el-menu-item index="01" @click="f_b_obj5(0,1)">
                                        <i class="glyphicon glyphicon-pencil"></i>
                                        <span> Prueba</span>
                                    </el-menu-item>     -->
                                    <el-menu-item index="11" @click="f_b_obj5(1,1)">
                                        <i class="glyphicon glyphicon-file"></i>
                                        <span> Introducción</span>
                                    </el-menu-item>
                                    <el-menu-item index="21" @click="f_b_obj5(2,1)">
                                        <i class="glyphicon glyphicon-list-alt"></i>
                                        <span> Estadísticas</span>
                                    </el-menu-item>

                                    <el-menu-item index="31" @click="f_b_obj5(3,1)">
                                        <i class="glyphicon glyphicon-signal"></i>
                                        <span> Gráficas</span>
                                    </el-menu-item>

                                </el-menu>
                            </el-col>
                        </el-collapse-item>

                        <el-collapse-item name="2">
                            <template #title>
                                Educación<el-icon class="header-icon">
                                <info-filled />
                            </el-icon>
                            </template>
                            
                            
                            <el-col :span="50">
                                <el-menu
                                    name="b_obj5" id="b_obj5"
                                    active-text-color="#9D2449"     
                                    background-color="#FFFFFF"      
                                    class="el-menu-vertical-demo"
                                    default-active="1"
                                    text-color="#000000"               
                                    @open="handleOpen"
                                    @close="handleClose"
                                >
                                
                                    <el-menu-item index="12" @click="f_b_obj5(1,2)">
                                        <i class="glyphicon glyphicon-file"></i>
                                        <span> Introducción</span>
                                    </el-menu-item>
                                    
                                    <el-menu-item index="22" @click="f_b_obj5(2,2)">
                                        <i class="glyphicon glyphicon-list-alt"></i>
                                        <span> Estadísticas</span>
                                    </el-menu-item>

                                    <el-menu-item index="32" @click="f_b_obj5(3,2)">
                                        <i class="glyphicon glyphicon-signal"></i>
                                        <span> Gráficas</span>
                                    </el-menu-item>
                                    

                                </el-menu>
                            </el-col>
                        </el-collapse-item>

                        <el-collapse-item name="3">
                            <template #title>
                                Empleo y Ocupación<el-icon class="header-icon">
                                <info-filled />
                            </el-icon>
                            </template>
                            
                            
                            <el-col :span="50">
                                <el-menu
                                    name="b_obj5" id="b_obj5"
                                    active-text-color="#9D2449"     
                                    background-color="#FFFFFF"      
                                    class="el-menu-vertical-demo"
                                    default-active="1"
                                    text-color="#000000"               
                                    @open="handleOpen"
                                    @close="handleClose"
                                >
                                
                                    <el-menu-item index="12" @click="f_b_obj5(1,3)">
                                        <i class="glyphicon glyphicon-file"></i>
                                        <span> Introducción</span>
                                    </el-menu-item>
                                    
                                    <el-menu-item index="22" @click="f_b_obj5(2,3)">
                                        <i class="glyphicon glyphicon-list-alt"></i>
                                        <span> Estadísticas</span>
                                    </el-menu-item>

                                    <el-menu-item index="32" @click="f_b_obj5(3,3)">
                                        <i class="glyphicon glyphicon-signal"></i>
                                        <span> Gráficas</span>
                                    </el-menu-item>

                                </el-menu>
                            </el-col>
                        </el-collapse-item>
                        <el-collapse-item name="4">
                            <template #title>
                                Seguridad y Violencia<el-icon class="header-icon">
                                <info-filled />
                            </el-icon>
                            </template>
                            
                            
                            <el-col :span="50">
                                <el-menu
                                    name="b_obj5" id="b_obj5"
                                    active-text-color="#9D2449"     
                                    background-color="#FFFFFF"      
                                    class="el-menu-vertical-demo"
                                    default-active="1"
                                    text-color="#000000"               
                                    @open="handleOpen"
                                    @close="handleClose"
                                >
                                
                                    <el-menu-item index="12" @click="f_b_obj5(1,4)">
                                        <i class="glyphicon glyphicon-file"></i>
                                        <span> Introducción</span>
                                    </el-menu-item>
                                    
                                    <el-menu-item index="22" @click="f_b_obj5(2,4)">
                                        <i class="glyphicon glyphicon-list-alt"></i>
                                        <span> Estadísticas</span>
                                    </el-menu-item>

                                    <el-menu-item index="32" @click="f_b_obj5(3,4)">
                                        <i class="glyphicon glyphicon-signal"></i>
                                        <span> Gráficas</span>
                                    </el-menu-item>

                                </el-menu>
                            </el-col>
                        </el-collapse-item>
                        <el-collapse-item name="5">
                            <template #title>
                                Salud Seguridad Social<el-icon class="header-icon">
                                <info-filled />
                            </el-icon>
                            </template>
                            
                            
                            <el-col :span="50">
                                <el-menu
                                    name="b_obj5" id="b_obj5"
                                    active-text-color="#9D2449"     
                                    background-color="#FFFFFF"      
                                    class="el-menu-vertical-demo"
                                    default-active="1"
                                    text-color="#000000"               
                                    @open="handleOpen"
                                    @close="handleClose"
                                >
                                
                                    <el-menu-item index="12" @click="f_b_obj5(1,5)">
                                        <i class="glyphicon glyphicon-file"></i>
                                        <span> Introducción</span>
                                    </el-menu-item>
                                    
                                    <el-menu-item index="22" @click="f_b_obj5(2,5)">
                                        <i class="glyphicon glyphicon-list-alt"></i>
                                        <span> Estadísticas</span>
                                    </el-menu-item>

                                    <el-menu-item index="32" @click="f_b_obj5(3,5)">
                                        <i class="glyphicon glyphicon-signal"></i>
                                        <span> Gráficas</span>
                                    </el-menu-item>

                                </el-menu>
                            </el-col>
                        </el-collapse-item>
                        <el-collapse-item name="6">
                            <template #title>
                                Juventudes Diversas<el-icon class="header-icon">
                                <info-filled />
                            </el-icon>
                            </template>
                            
                            
                            <el-col :span="50">
                                <el-menu
                                    name="b_obj5" id="b_obj5"
                                    active-text-color="#9D2449"     
                                    background-color="#FFFFFF"      
                                    class="el-menu-vertical-demo"
                                    default-active="1"
                                    text-color="#000000"               
                                    @open="handleOpen"
                                    @close="handleClose"
                                >
                                
                                    <el-menu-item index="12" @click="f_b_obj5(1,6)">
                                        <i class="glyphicon glyphicon-file"></i>
                                        <span> Introducción</span>
                                    </el-menu-item>
                                    
                                    <el-menu-item index="22" @click="f_b_obj5(2,6)">
                                        <i class="glyphicon glyphicon-list-alt"></i>
                                        <span> Estadísticas</span>
                                    </el-menu-item>

                                    <el-menu-item index="32" @click="f_b_obj5(3,6)">
                                        <i class="glyphicon glyphicon-signal"></i>
                                        <span> Gráficas</span>
                                    </el-menu-item>

                                </el-menu>
                            </el-col>
                        </el-collapse-item>

                    </el-collapse>
                </div>

            </div>
        </div>

        <!-- Panel derecho (resultados) -->
        <div class="col-lg-10" ref="toPdf">
            

            <div class="panel-body">

                <div v-if="prueba === true">
                    <Child :msg="text" v-bind:param_opc="param_opcRaiz"></Child>
                </div>
                
                <Estadist_introduccion v-bind:param_opc="param_opcRaiz" v-if="estadist_introduccion" />
                <Estadist_indicador1 v-bind:param_opc="param_opcRaiz" v-if="estadist_indicador1" />
                <Ver v-if="ver" />
                
           <!-- <Reportes v-bind:param_opc="param_opcRaiz" v-if="reportes" /> -->
                <estadist_indicadoresTbl_pob v-bind:param_opc="param_opcRaiz" v-if="estadist_indicadoresTbl_pob" />
                <estadist_indicadoresTbl_edu v-bind:param_opc="param_opcRaiz" v-if="estadist_indicadoresTbl_edu" />
                <estadist_indicadoresTbl_emp v-bind:param_opc="param_opcRaiz" v-if="estadist_indicadoresTbl_emp" />
                <estadist_indicadoresTbl_sv v-bind:param_opc="param_opcRaiz" v-if="estadist_indicadoresTbl_sv" />
                <estadist_indicadoresTbl_sss v-bind:param_opc="param_opcRaiz" v-if="estadist_indicadoresTbl_sss" />
                <estadist_indicadoresTbl_jd v-bind:param_opc="param_opcRaiz" v-if="estadist_indicadoresTbl_jd" />
                
            </div>




        </div>
        
    </div>
</template>

<script>
import charsService from '@/services/CharsService';
import Child from '@/components/Estadisticas/Prueba';
import Estadist_indicador1 from '@/components/Estadisticas/Estadist_indicador1';
import Ver from '@/components/Estadisticas/Ver';
import estadist_indicadoresTbl_pob from '@/components/Estadisticas/Estadist_indicadoresTbl_pob';
import estadist_indicadoresTbl_edu from '@/components/Estadisticas/Estadist_indicadoresTbl_edu';
import estadist_indicadoresTbl_emp from '@/components/Estadisticas/Estadist_indicadoresTbl_emp';
import estadist_indicadoresTbl_sv from '@/components/Estadisticas/Estadist_indicadoresTbl_sv';
import estadist_indicadoresTbl_sss from '@/components/Estadisticas/Estadist_indicadoresTbl_sss';
import estadist_indicadoresTbl_jd from '@/components/Estadisticas/Estadist_indicadoresTbl_jd';
import Estadist_introduccion from '@/components/Estadisticas/Estadist_introduccion';

import { url } from '@/api/baseUrl';
import VueRouter from "vue-router";

export default {
    
    components: {
        Child,
        Estadist_indicador1,
        Ver,
        estadist_indicadoresTbl_pob,
        estadist_indicadoresTbl_edu,
        estadist_indicadoresTbl_emp,
        estadist_indicadoresTbl_sv,
        estadist_indicadoresTbl_sss,
        estadist_indicadoresTbl_jd,
        Estadist_introduccion
    },

    data: ()  => ({
            param_opcRaiz:1000,
            estadist_introduccion: true,
            prueba: false,
            estadist_indicador1: false,
            ver: false,
            estadist_indicadoresTbl_pob: false,
            estadist_indicadoresTbl_edu: false,
            estadist_indicadoresTbl_emp: false,
            estadist_indicadoresTbl_sv: false,
            estadist_indicadoresTbl_sss: false,
            estadist_indicadoresTbl_jd: false,
    }),

    
    

    methods: {
        async f_b_obj5(formulario,id_parametro ) {
            this.param_opcRaiz = id_parametro;
            this.resetAll()
            
            if (formulario == 0) {
               
                this.estadist_introduccion = true

            } else if (formulario == 1) {
                this.estadist_introduccion = true
                
            } else if (formulario == 2) {
                
                this.estadist_indicador1 = true
            } else if (formulario == 3) {
                if (id_parametro == 1) {
                    this.estadist_indicadoresTbl_pob = true
                } else if (id_parametro == 2) {
                    this.estadist_indicadoresTbl_edu = true
                } else if (id_parametro == 3) {
                    this.estadist_indicadoresTbl_emp = true
                } else if (id_parametro == 4) {
                    this.estadist_indicadoresTbl_sv = true
                } else if (id_parametro == 5) {
                    this.estadist_indicadoresTbl_sss = true
                } else if (id_parametro == 6) {
                    this.estadist_indicadoresTbl_jd = true
                }
                
                
            } else if (formulario == 4) {
                //this.estadist_introduccion = true 
                this.ver = true            
            } else if (formulario == 5) {
                this.prueba = true             
            }  

        },

        resetAll() {
            this.estadist_introduccion = false
            this.estadist_indicador1 = false
            this.prueba = false
            this.ver = false
            this.estadist_indicadoresTbl_pob = false
            this.estadist_indicadoresTbl_edu = false
            this.estadist_indicadoresTbl_emp = false
            this.estadist_indicadoresTbl_sv = false
            this.estadist_indicadoresTbl_sss = false
            this.estadist_indicadoresTbl_jd = false
            
        },
        activeTab() {
            
            if ( this.$route.params.mod === 'prueba' ) {
                this.resetAll()
                this.prueba = true
            }else if ( this.$route.params.mod === 'estadist_introduccion' ) {
                this.resetAll()
                this.estadist_introduccion = true
            } else if ( this.$route.params.mod === 'estadist_indicador1' ) {
                this.resetAll()
                this.estadist_indicador1 = true
            } else if ( this.$route.params.mod === 'ver' ) {
                this.resetAll()
                this.ver = true
            } else if ( this.$route.params.mod === 'estadist_indicadoresTbl_pob' ) {
                this.resetAll()
                this.estadist_indicadoresTbl_pob = true
            }
        },
    },
    async mounted() {
        this.activeTab()
        this.f_b_obj5(0,0)
        this.$gtag.event('Estadísticas en Juventud');     //Registra el Evento en Google Analytics con nombre
    },
    beforeUpdate() {
        this.activeTab()
    }
}
</script>

<style scoped>
    .callout {
        background-color: rgba(250, 250, 250, 0.5);
    }
    .dependenciaTitle, .objetivoTitle, .estrategiasTitle {
        color: #9F2241;
        font-weight: bold;
    }
    .dependencia {
        font-size: 20px;
        font-weight: bold;
    }
    .objetivo {
        font-size: 20px;
        font-weight: bold;
    }
    .estrategias {
        font-size: 18px;
    }
    .card {
        font-size: medium;
    }
    .apTitle {
        font-weight: bold;
    }

    .charts {
        width: 100%;
        height: auto;
    } 

    .icoLink {
        color: #212529;
    }

    .dontShow {
        display: none;
    }

    .btnCond {
        margin-bottom: 40px;
    }

</style>