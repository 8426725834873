<template>
    


    <div class="row mt-8">
        <div class="col-lg-11 indicadores">
            <h5>Estadísticas de educación</h5>
            
        </div>
        <div class="col-lg-1 indicadores">
            <div v-if="BtnDesplegarLista">
                <el-button type="primary" style="padding: 0px; border: 0px; background-color:#E5E5E5;"  @click="DespliegaListaFunc()" title="Regresar a la lista de indicadores">
                    <img :src="btn_regresar" class="iconImg" width="40"/>
                </el-button>
                
            </div>
        </div>    
        
    </div>

    <div class="row mt-8" style="backgroundColor:#FFFFFF">  <!-- INICIO DEL PANEL PRINCIPAL  -->
        
        <div class="demo-collapse" v-if="DesplegarLista">
                    <el-col :span="450">
                        <el-menu

                            name="b_obj5" id="b_obj5"
                            active-text-color="#9D2449"     
                            background-color="#FFFFFF"      
                            class="el-menu-vertical-demo"
                            default-active="1"
                            text-color="#000000"               
                            @open="handleOpen"
                            @close="handleClose"
                        >

                            <el-menu-item index="41" @click="f_b_obj5(4,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Distribución porcentual de la población de 18 a 34 años, según condición de práctica físico-deportiva en su tiempo libre</span>
                                <!--C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INEGI\EDUCACION\DEPORTE\01EST_Tabulados_MOPRADEF_Noviembre_2022.xlsx   -->
                                <!--https://www.inegi.org.mx/programas/mopradef/#Tabulados   -->
                            </el-menu-item> 
                            <el-menu-item index="51" @click="f_b_obj5(5,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Uso de tecnologías de la información en los hogares por jovenes  12 a 34 años</span>
                                <!--C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INEGI\EDUCACION\INTERNET Y TELEFONIA\Nacional\solo jovenes\2022_u1na602 - Procesado.xlsx -->
                                <!--https://inegi.org.mx/programas/dutih/2022/#informacion_general -->
                            </el-menu-item> 
                            <el-menu-item index="61" @click="f_b_obj5(6,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Visitantes a museos por entidad de registro, por jovenes de 12 a 29 años edad y sexo, año 2022</span>
                                <!--C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INEGI\EDUCACION\MUSEOS\VisitantesTab01_JOVENES.xlsx -->
                                <!--https://www.inegi.org.mx/app/tabulados/interactivos/?pxq=Museos_VisitantesTab01_17d99c92-310c-4f83-9940-e3dc63b3284d -->
                            </el-menu-item> 

                        </el-menu>
                    </el-col>
                
        </div>

        <Educ_01 v-bind:param_opc="param_opcRaiz" v-if="educ_01" />
        <Educ_02 v-bind:param_opc="param_opcRaiz" v-if="educ_02" />
        <Educ_03 v-bind:param_opc="param_opcRaiz" v-if="educ_03" />
        <Educ_04 v-bind:param_opc="param_opcRaiz" v-if="educ_04" />
        <Educ_05 v-bind:param_opc="param_opcRaiz" v-if="educ_05" />
        <Educ_06 v-bind:param_opc="param_opcRaiz" v-if="educ_06" />


    </div> <!-- FIN DEL PANEL PRINCIPAL  -->
    









    <div class="col-lg-10" ref="toPdf" v-if="ocultala">
        <div class="panel-body"     v-if="ver_tabla === true">   <!-- SECCION DE LA TABLA  -->   
            <div class="row mb-3">
                <!-- Input de búsqueda -->
                <div class="col-md-3 text-right"><label class="text-small">Buscar:</label></div>
                <div class="col-md-5 text-right"><input v-model="searchTerm" class="form-control busqueda" /></div>
                </div>

                <div class="row">
                    <div class="col-md-12 datatable">
                        <!-- Configuración de la tabla con los campos a mostrar -->
                        <table-lite
                            :is-static-mode="true"
                            :columns="table.columns"
                            :rows="table.rows"
                            :total="table.totalRecordCount"
                            :sortable="table.sortable"
                            :messages="table.messages"

                        ></table-lite>
                        
                    </div>
                </div>
        </div>
        
        <div class="panel-body" v-if="ver_indicadores === true">   <!-- SECCION DE LOS INDICADORES  -->  
            <Educ_01 v-bind:param_opc="param_opcRaiz" v-if="educ_01" />
            <Educ_02 v-bind:param_opc="param_opcRaiz" v-if="educ_02" />
            <Educ_03 v-bind:param_opc="param_opcRaiz" v-if="educ_03" />
            <Educ_04 v-bind:param_opc="param_opcRaiz" v-if="educ_04" />
            <Educ_05 v-bind:param_opc="param_opcRaiz" v-if="educ_05" />
            <Educ_06 v-bind:param_opc="param_opcRaiz" v-if="educ_06" />
        </div>
    </div>

</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
// Componente de la tabla
import TableLite from "@/components/DataTable/TableLite";

// Importo los helpers (API)
//import getCatDependencias from '@/helpers/getCatDependencias'
import getEstadist_indicadoresbyTipo from '@/helpers/getEstadist_indicadoresbyTipo'
import charsService from '@/services/CharsService';

import Educ_01 from '@/components/Estadisticas/Educ_01';
import Educ_02 from '@/components/Estadisticas/Educ_02';
import Educ_03 from '@/components/Estadisticas/Educ_03';
import Educ_04 from '@/components/Estadisticas/Educ_04';
import Educ_05 from '@/components/Estadisticas/Educ_05';
import Educ_06 from '@/components/Estadisticas/Educ_06';


import { ubApi, icons } from '@/api/baseUrl'


export default defineComponent({
    Educ_01,
    Educ_02,
    Educ_03,
    Educ_04,
    Educ_05,
    Educ_06,
    
    components: { 
        TableLite, 
        Educ_01,
        Educ_02,
        Educ_03,
        Educ_04,
        Educ_05,
        Educ_06,
    },
    
    data: ()  => ({
        ver_tabla: true,
        ver_indicadores: false,
        ocultala: false,

        DesplegarLista:true,
        BtnDesplegarLista:false,
        btn_grafica: icons.value + "/graph.png",
        btn_table: icons.value + "/table.png",
        btn_regresar: icons.value + "/btn_regresar.png",
        educ_01: false,
        educ_02: false,
        educ_03: false,
        educ_04: false,
        educ_05: false,
        educ_06: false,
    }),



    methods: {
        async DespliegaListaFunc() {
            this.resetAll()
            this.BtnDesplegarLista = false   
            this.DesplegarLista=true
        },
        async f_b_obj5(formulario,id_parametro ) {
            this.param_opcRaiz = id_parametro;
            this.BtnDesplegarLista = true
            this.resetAll()
            if (formulario == 1) {
                this.educ_01 = true             
            } else if (formulario == 2) {
                this.educ_02 = true
            } else if (formulario == 3) {
                this.educ_03 = true
            } else if (formulario == 4) {
                this.educ_04 = true    
            } else if (formulario == 5) {
                this.educ_05 = true    
            } else if (formulario == 6) {
                this.educ_06 = true    
            }  
        },
        resetAll() {
            this.DesplegarLista=false
            this.educ_01 = false
            this.educ_02 = false
            this.educ_03 = false
            this.educ_04 = false
            this.educ_05 = false
            this.educ_06 = false
            
        },
    },

    setup() {
        const searchTerm = ref("") // Variable para los datos de búsqueda
        const data = reactive([]); // Variable para los datos a mostrar en la tabla

        (async () => {
            // Se crea la variable para el arreglo haciendo la llamada a la función de la api
            const dependenciasArr = await getEstadist_indicadoresbyTipo(1)

            // Genero el arreglo de datos para llenar la tabla
            const datos = dependenciasArr
            for (let i = 0; i < datos.length; i++) {
                data.push({
                    id: datos[i].id,
                    num_indicador: datos[i].num_indicador,
                    nom_indicador: datos[i].nom_indicador,
                    periodo: datos[i].periodo,
                });
            }
        })()
        
        // Configuración de la tabla
        const table = reactive({
            // Columnas de la tabla
            columns: [
                {
                    label: "Núm",
                    field: "num_indicador",
                    width: "2%",
                    sortable: true,
                },
                {
                    label: "Nombre del indicador",
                    field: "nom_indicador",
                    width: "73%",
                    sortable: true,
                },
                {
                    label: "Periodo",
                    field: "periodo",
                    width: "20%",
                    sortable: true,
                },
                // Botones de acciones (iconos)
                {
                    label: "",
                    field: "quick",
                    width: "5%",
                    display: function (row) {
                        return (
                             //     '<a href="/dependencia/dependenciaAdmin/edit/'+ charsService.encode(row.id) +'" class="text-info" title="Editar"><i class="glyphicon glyphicon-pencil"></i></a> ' + '&nbsp;&nbsp;&nbsp;' 
                           
                            // '<a href="#" class="text-info" title="Editar"><i class="glyphicon glyphicon-pencil"></i></a> ' + '&nbsp;&nbsp;&nbsp;' 
                            
                            //'<button type="button"  style="padding: 0px; border: 0px; background-color:#ffffff;"  @click="DespliegaListaFunc(1)" >'+
                            //'<img :src="btn_grafica" class="iconImg" width="40"/>'+
                            //'</button>'

                            //'<button type="button" data-id="' +
                            //row.id +
                            //'" class="is-rows-el quick-btn" onclick="DespliegaListaFunc(1)"> Boton</button>'

                            '<a class="btn" @click="DespliegaListaFunc(1)">boton </a>'


                        );
                    },
                },
            ],
            rows: computed(() => {
                // Aquí se designan las columnas en las que se realizará la búsqueda desde el input
                return data.filter(
                    (x) =>
                        x.nom_indicador.toLowerCase().includes(searchTerm.value.toLowerCase()) || x.periodo.toLowerCase().includes(searchTerm.value.toLowerCase())
                    );
            }),
            totalRecordCount: computed(() => {
                // Se regresa el número de registros encontrados
                return table.rows.length
            }),
            sortable: {
                // Se define el orden por default
                order: "id",
                sort: "asc",
            },
            messages: {
                // Personalizo los mensajes que muestra la tabla
                pagingInfo: "Mostrando {0}-{1} de {2}",
                pageSizeChangeLabel: "Filas:",
                gotoPageLabel: "   Ir a la página:",
                noDataAvailable: "Sin datos para mostrar",
            },
            
        });

        return {
            searchTerm,
            table,
        }
    }
})
</script>

<style scoped>
    .card :deep .table .thead-dark th {
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
    .card :deep .table td, .card :deep .table tr{
        border: none;
        width: 800px;
    }
    .text-small {
        font-size: medium;
    }
    .busqueda {
        font-size: medium;
        height: auto;
    }
    .text-info {
        color: #343a40 !important;
        padding: 0 20px;
    }
    .datatable {
        padding-bottom: 10px;
        overflow-x: auto;
        margin-bottom: 25px;
    }
    .card {
        border: 0 !important;
    }
</style>