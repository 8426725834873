<template>
    <h4>Administración del catálogo de años</h4>
    <hr>

    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el año? </h5>
            <h6> {{ datosAnio }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getAniosById from '@/helpers/getAniosById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosAnio: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'cat_anio', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getAnio() {
            const dataAnio = await getAniosById( charsService.decode(this.$route.params.id ));

            const cadena = `${ dataAnio.nom_anio } `;

            return cadena
        }
    },
    async mounted() {
        this.datosAnio = await this.getAnio();
        // this.getAnio()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>