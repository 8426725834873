import baseApi from '../api/baseApi'

const validateTkn = async () => {
    const promiseArr = [
        baseApi.get(`/getToken.php`)
    ]

    const [ token ] = await Promise.all( promiseArr )

    if ( token.data !== localStorage.getItem('token') ) {
       // window.location.href = './login'  //para publicar en server hay que habilitarlo
    }
}

export default validateTkn