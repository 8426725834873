<template>
    <div class="row mt-8">
        <div class="col-lg-11 indicadores">
            <h5>Estadísticas de población</h5>
            
        </div>
        <div class="col-lg-1 indicadores">
            <div v-if="BtnDesplegarLista">
                <el-button type="primary" style="padding: 0px; border: 0px; background-color:#E5E5E5;"  @click="DespliegaListaFunc()" >
                    <img :src="btn_regresar" class="iconImg" width="40"/>
                </el-button>
                
            </div>
        </div>    
        
    </div>

    <div class="row mt-8" style="backgroundColor:#FFFFFF">  <!-- INICIO DEL PANEL PRINCIPAL  -->
        
        <div class="demo-collapse" v-if="DesplegarLista">
                    <el-col :span="450">
                        <el-menu

                            name="b_obj5" id="b_obj5"
                            active-text-color="#9D2449"     
                            background-color="#FFFFFF"      
                            class="el-menu-vertical-demo"
                            default-active="1"
                            text-color="#000000"               
                            @open="handleOpen"
                            @close="handleClose"
                        >
                            <el-menu-item index="11" @click="f_b_obj5(1,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Porcentaje de la población joven respecto al total de la población joven y el resto de la población</span>
                            </el-menu-item>
                            <el-menu-item index="21" @click="f_b_obj5(2,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Pirámide de población por edad y sexo del año 2020</span>
                            </el-menu-item>

                            <el-menu-item index="31" @click="f_b_obj5(3,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población joven por grupos quinquenales de edad según tamaño de localidad</span>
                                
                                <!-- C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INEGI\POBLACION\ESTADISTICAS\cpv2020_b_eum_01_poblacion LO MISMO PERO EN VERTICA.xlsx -->
                            </el-menu-item> 

                            <el-menu-item index="31" @click="f_b_obj5(4,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Población Joven por Entidad Federativa</span>
                                <!-- C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\INEGI\POBLACION\ESTADISTICAS\Poblacion_01 - POR ESTADO.xlsx -->
                                <!-- https://www.sedeco.cdmx.gob.mx/storage/app/media/uploaded-files/resultados-del-censo-pob-y-viv-2020-1.pdf -->
                            </el-menu-item> 

                            <!-- 
                            <el-menu-item index="31" @click="f_b_obj5(5,1)">
                                <i class="glyphicon glyphicon-stats"></i>
                                <span> Tasa de fecundidad en niñas de 10 a 14 años de 1990 a 2022  (VA EN SALUD Y SEGURIDAD SOCIAL)</span>
                            -->    
                                <!-- https://datos.gob.mx/busca/dataset/salud-sexual-y-reproductiva -->
                                <!-- C:\Borrar\IMJUVE\ETAPA 3\eNTREGABLES\2\ESTADISTICAS\CONAPO\POBLACION\BD_Fecundidad_en_Ninas_y_Adolescentes_menores_de_15_años_17112022.xlsx -->
                            <!--
                            </el-menu-item>  
                            -->
                        </el-menu>
                    </el-col>
                
        </div>

        <Pob_01 v-bind:param_opc="param_opcRaiz" v-if="pob_01" />
        <Pob_02 v-bind:param_opc="param_opcRaiz" v-if="pob_02" />
        <Pob_03 v-bind:param_opc="param_opcRaiz" v-if="pob_03" />
        <Pob_04 v-bind:param_opc="param_opcRaiz" v-if="pob_04" />


    </div> <!-- FIN DEL PANEL PRINCIPAL  -->
    <div class="col-lg-10" ref="toPdf" v-if="ocultala">
        <div class="panel-body"     v-if="ver_tabla === true">   <!-- SECCION DE LA TABLA  -->   
            <div class="row mb-3">
                <!-- Input de búsqueda -->
                <div class="col-md-3 text-right"><label class="text-small">Buscar:</label></div>
                <div class="col-md-5 text-right"><input v-model="searchTerm" class="form-control busqueda" /></div>
                </div>

                <div class="row">
                    <div class="col-md-12 datatable">
                        <!-- Configuración de la tabla con los campos a mostrar -->
                        <table-lite
                            :is-static-mode="true"
                            :columns="table.columns"
                            :rows="table.rows"
                            :total="table.totalRecordCount"
                            :sortable="table.sortable"
                            :messages="table.messages"

                        ></table-lite>
                        
                    </div>
                </div>
        </div>
        
        <div class="panel-body" v-if="ver_indicadores === true">   <!-- SECCION DE LOS INDICADORES  -->  
            <Pob_01 v-bind:param_opc="param_opcRaiz" v-if="pob_01" />
            <Pob_02 v-bind:param_opc="param_opcRaiz" v-if="pob_02" />
            <Pob_03 v-bind:param_opc="param_opcRaiz" v-if="pob_03" />
            <Pob_04 v-bind:param_opc="param_opcRaiz" v-if="pob_04" />
        </div>
    </div>

</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
// Componente de la tabla
import TableLite from "@/components/DataTable/TableLite";

// Importo los helpers (API)
//import getCatDependencias from '@/helpers/getCatDependencias'
import getEstadist_indicadoresbyTipo from '@/helpers/getEstadist_indicadoresbyTipo'
import charsService from '@/services/CharsService';

import Pob_01 from '@/components/Estadisticas/Pob_01';
import Pob_02 from '@/components/Estadisticas/Pob_02';
import Pob_03 from '@/components/Estadisticas/Pob_03';
import Pob_04 from '@/components/Estadisticas/Pob_04';
import { ubApi, icons } from '@/api/baseUrl'
export default defineComponent({
    Pob_01,
    Pob_02,
    Pob_03,
    Pob_04,
    
    components: { 
        TableLite, 
        Pob_01,
        Pob_02,
        Pob_03,
        Pob_04,
    },
    
    data: ()  => ({
        ver_tabla: true,
        ver_indicadores: false,
        ocultala: false,

        DesplegarLista:true,
        BtnDesplegarLista:false,
        btn_grafica: icons.value + "/graph.png",
        btn_table: icons.value + "/table.png",
        btn_regresar: icons.value + "/btn_regresar.png",
        pob_01: false,
        pob_02: false,
        pob_03: false,
        pob_04: false,
    }),

    methods: {
        async DespliegaListaFunc() {
            this.resetAll()
            this.BtnDesplegarLista = false   
            this.DesplegarLista=true
        },
        async f_b_obj5(formulario,id_parametro ) {
            this.param_opcRaiz = id_parametro;
            this.BtnDesplegarLista = true
            this.resetAll()
            if (formulario == 1) {
                this.pob_01 = true             
            } else if (formulario == 2) {
                this.pob_02 = true
            } else if (formulario == 3) {
                this.pob_03 = true
            } else if (formulario == 4) {
                this.pob_04 = true    
            }  
        },
        resetAll() {
            this.DesplegarLista=false
            this.pob_01 = false
            this.pob_02 = false
            this.pob_03 = false
            this.pob_04 = false
            
        },
    },
    setup() {
        const searchTerm = ref("") // Variable para los datos de búsqueda
        const data = reactive([]); // Variable para los datos a mostrar en la tabla

        (async () => {
            // Se crea la variable para el arreglo haciendo la llamada a la función de la api
            const dependenciasArr = await getEstadist_indicadoresbyTipo(1)

            // Genero el arreglo de datos para llenar la tabla
            const datos = dependenciasArr
            for (let i = 0; i < datos.length; i++) {
                data.push({
                    id: datos[i].id,
                    num_indicador: datos[i].num_indicador,
                    nom_indicador: datos[i].nom_indicador,
                    periodo: datos[i].periodo,
                });
            }
        })()
        
        // Configuración de la tabla
        const table = reactive({
            // Columnas de la tabla
            columns: [
                {
                    label: "Núm",
                    field: "num_indicador",
                    width: "2%",
                    sortable: true,
                },
                {
                    label: "Nombre del indicador",
                    field: "nom_indicador",
                    width: "73%",
                    sortable: true,
                },
                {
                    label: "Periodo",
                    field: "periodo",
                    width: "20%",
                    sortable: true,
                },
                // Botones de acciones (iconos)
                {
                    label: "",
                    field: "quick",
                    width: "5%",
                    display: function (row) {
                        return (
                            '<a class="btn" @click="DespliegaListaFunc(1)">boton </a>'
                        );
                    },
                },
            ],
            rows: computed(() => {
                // Aquí se designan las columnas en las que se realizará la búsqueda desde el input
                return data.filter(
                    (x) =>
                        x.nom_indicador.toLowerCase().includes(searchTerm.value.toLowerCase()) || x.periodo.toLowerCase().includes(searchTerm.value.toLowerCase())
                    );
            }),
            totalRecordCount: computed(() => {
                // Se regresa el número de registros encontrados
                return table.rows.length
            }),
            sortable: {
                // Se define el orden por default
                order: "id",
                sort: "asc",
            },
            messages: {
                // Personalizo los mensajes que muestra la tabla
                pagingInfo: "Mostrando {0}-{1} de {2}",
                pageSizeChangeLabel: "Filas:",
                gotoPageLabel: "   Ir a la página:",
                noDataAvailable: "Sin datos para mostrar",
            },
         });

        return {
            searchTerm,
            table,
        }
    }
})
</script>

<style scoped>
    .card :deep .table .thead-dark th {
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
    .card :deep .table td, .card :deep .table tr{
        border: none;
        width: 800px;
    }
    .text-small {
        font-size: medium;
    }
    .busqueda {
        font-size: medium;
        height: auto;
    }
    .text-info {
        color: #343a40 !important;
        padding: 0 20px;
    }
    .datatable {
        padding-bottom: 10px;
        overflow-x: auto;
        margin-bottom: 25px;
    }
    .card {
        border: 0 !important;
    }
</style>