<template>
    <h4>Orden del catálogo de ofertas para las y los jóvenes</h4>
    <hr>
    <div class="row mb-3">
        
    </div>
    <div class="row">
        <div class="col-md-12">
            <draggable :list="list" :disabled="!enabled" item-key="name" class="list-group" ghost-class="ghost" :move="checkMove" @start="dragging = true" @end="dragging = false">
                <template #item="{ element }">
                    <div class="list-group-item" :class="{ 'not-draggable': !enabled }">
                        {{ element.name }}
                    </div>
                </template>
            </draggable>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-right fin">
            <input type="button" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar orden" @click="saveOrder" />
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
import draggable from 'vuedraggable'

// Importo los helpers
import getAllIntervenciones from '@/helpers/getAllIntervenciones'
import updateData from '@/helpers/updateData'

// Importo el servicio de codificación
import charsService from '@/services/CharsService';

export default defineComponent({
    name: "simple",
    display: "Simple",
    order: 0,
    components: {
        draggable
    },
    data() {
        return {
            table: '',
            list: [],
            enabled: true,
            dragging: false
        };
  },
  methods: {
    async getTable() {
        const interArr = await getAllIntervenciones()
        const datos = interArr[0].intervenciones;
        
        datos.forEach(element => {
            this.list.push({ name: element.interGuberna, id: element.id })
        });
    },
    async saveOrder() {
        try {
            const newList = this.list;
            let saveList = [];
            newList.forEach(async (element, index) => {
                saveList.push({ index: index+1, id: element.id })

                const jsonData = JSON.parse(JSON.stringify({ orden: index+1 }))
                await updateData('intervenciones', element.id, jsonData)
            });

            this.$notify({
                title: 'Correcto', text: 'Se guardó correctamente la información', type: 'success', duration: 5000, speed: 1000
            });
        } catch (error) {
            this.$notify({
                title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
            });
        }
        
    }
  },
  mounted() {
    this.table = this.getTable();
  }
})
</script>

<style scoped>
    .buttons {
        margin-top: 35px;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .not-draggable {
        cursor: no-drop;
    }

    .fin {
        margin-bottom: 20px;
    }
</style>