/* Regresa todos los datos del accionp_ent_resp con el id enviado de la tabla 'accionp_ent_resp' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccionp_ent_respById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/accionp_ent_resp/${ id }`)
    ]

    const [ accionp ] = await Promise.all( promiseArr )

    return accionp.data
}

export default getAccionp_ent_respById