/* Regresa un registro de la tabla de cat_nivel_desagregacion */

import simejuvApi from '../api/simejuvApi'

const getDataByAnyNivel = async( table, column, value ) => {
    const promiseArr = [
        simejuvApi.get(`/${ table }?transform=1&filter=${ column },eq,${ value }`)
    ]

    const [ datos ] = await Promise.all( promiseArr )
    
    return datos.data.cat_nivel_desagregacion

}

export default getDataByAnyNivel