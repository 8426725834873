/* Regresa todos los datos de formulas con el id enviado de la tabla 'cat_tipo_formula' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getFormulasById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_tipo_formula/${ id }`)
    ]

    const [ cat_tipo_formula ] = await Promise.all( promiseArr )

    return cat_tipo_formula.data
}

export default getFormulasById