/* Regresa un registro de la tabla de accion_puntual */

import simejuvApi from '../api/simejuvApi'

const getDataByAnyAccion_puntual = async( table, column, value ) => {
    const promiseArr = [
        simejuvApi.get(`/${ table }?transform=1&filter=${ column },eq,${ value }`)
    ]

    const [ datos ] = await Promise.all( promiseArr )
    
    return datos.data.accion_puntual

}

export default getDataByAnyAccion_puntual