<template>
    <h4 class="mt-0">Actualizar parámetros y contenido de la página principal</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="bienvenida_contenido">Texto de bienvenida:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idBienvenida" name="idBienvenida" v-model="form.id" />
                        <ckeditor :editor="editor" v-model="form.bienvenida_contenido" :config="editorConfig" id="bienvenida_contenido" @input="conteo_real(form.bienvenida_contenido, 'bienvenida_contenido')"></ckeditor>
                        <div id="bienvenida_contenido_count" class="noChars"></div>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-md-4" to="bienvenida_contenido">Parámetros del botón izquierdo</label>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt1_titulo">Título:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt1_titulo" name="bt1_titulo" placeholder="Título" v-model="form.bt1_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt1_desc">Descripción:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.bt1_desc" :config="editorConfig" id="bt1_desc" @input="conteo_real(form.bt1_desc, 'bt1_desc')"></ckeditor>
                        <div id="bt1_desc_count" class="noChars"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt1_link_desc">Nombre de la Url:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt1_link_desc" name="bt1_link_desc" placeholder="Nombre de la Url" v-model="form.bt1_link_desc" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt1_link">Url:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt1_link" name="bt1_link" placeholder="Url" v-model="form.bt1_link" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt1_link_img">Nombre de la imagen:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt1_link_img" name="bt1_link_img" placeholder="Nombre de la imagen" v-model="form.bt1_link_img" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="bienvenida_contenido">Parámetros del botón central</label>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt2_titulo">Título:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt2_titulo" name="bt2_titulo" placeholder="Título" v-model="form.bt2_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt2_desc">Descripción:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.bt2_desc" :config="editorConfig" id="bt2_desc" @input="conteo_real(form.bt2_desc, 'bt2_desc')"></ckeditor>
                        <div id="bt2_desc_count" class="noChars"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt2_link_desc">Nombre de la Url:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt2_link_desc" name="bt2_link_desc" placeholder="Nombre de la Url" v-model="form.bt2_link_desc" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt2_link">Url:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt2_link" name="bt2_link" placeholder="Url" v-model="form.bt2_link" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt2_link_img">Nombre de la imagen:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt2_link_img" name="bt2_link_img" placeholder="Nombre de la imagen" v-model="form.bt2_link_img" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="bienvenida_contenido">Parámetros del botón derecho</label>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt3_titulo">Título:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt3_titulo" name="bt3_titulo" placeholder="Título" v-model="form.bt3_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt3_desc">Descripción:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.bt3_desc" :config="editorConfig" id="bt3_desc" @input="conteo_real(form.bt3_desc, 'bt3_desc')"></ckeditor>
                        <div id="bt3_desc_count" class="noChars"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt3_link_desc">Nombre de la Url:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt3_link_desc" name="bt3_link_desc" placeholder="Nombre de la Url" v-model="form.bt3_link_desc" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt3_link">Url:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt3_link" name="bt3_link" placeholder="Url" v-model="form.bt3_link" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt3_link_img">Nombre de la imagen:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt3_link_img" name="bt3_link_img" placeholder="Nombre de la imagen" v-model="form.bt3_link_img" />
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateBienvenida" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getBienvenidasById from '@/helpers/getBienvenidasById'
import updateData from '@/helpers/updateData'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import charsService from '@/services/CharsService'

import '@ckeditor/ckeditor5-build-classic/build/translations/es'


export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                bienvenida_contenido: '',
                url_imagen_iz: '',
                bt1_titulo: '',
                bt1_desc: '',
                bt1_link_desc: '',
                bt1_link: '',
                bt1_link_img: '',
                bt2_titulo: '',
                bt2_desc: '',
                bt2_link_desc: '',
                bt2_link: '',
                bt2_link_img: '',
                bt3_titulo: '',
                bt3_desc: '',
                bt3_link_desc: '',
                bt3_link: '',
                bt3_link_img: '',
            },

            editor: ClassicEditor,
            editorData: '',
            editorConfig: { 
                language: 'es',
                wordCount: {
                    showParagraphs: false,
                    showWordCount: true,
                    showCharCount: true,
                    countSpacesAsChars: false,
                    countHTML: false,
                    maxWordCount: -1,
                    maxCharCount: 4000
                }
            }
        }
    },
    methods: {
        async getBienvenidaData( id ) {
            const bienvenidaArr = await getBienvenidasById( id )
            
            return bienvenidaArr[0].cat_bienvenida
        },

        async setData( id ) {
            const datos = await getBienvenidasById( id )

            this.form.id = datos.id
            this.form.bienvenida_contenido = datos.bienvenida_contenido
            this.form.url_imagen_iz = datos.url_imagen_iz
            this.form.bt1_titulo = datos.bt1_titulo
            this.form.bt1_desc = datos.bt1_desc
            this.form.bt1_link_desc = datos.bt1_link_desc
            this.form.bt1_link = datos.bt1_link
            this.form.bt1_link_img = datos.bt1_link_img
            this.form.bt2_titulo = datos.bt2_titulo
            this.form.bt2_desc = datos.bt2_desc
            this.form.bt2_link_desc = datos.bt2_link_desc
            this.form.bt2_link = datos.bt2_link
            this.form.bt2_link_img = datos.bt2_link_img
            this.form.bt3_titulo = datos.bt3_titulo
            this.form.bt3_desc = datos.bt3_desc
            this.form.bt3_link_desc = datos.bt3_link_desc
            this.form.bt3_link = datos.bt3_link
            this.form.bt3_link_img = datos.bt3_link_img
        },

        async updateBienvenida() {
            const dataForm = {
                bienvenida_contenido: this.form.bienvenida_contenido,
                url_imagen_iz: this.form.url_imagen_iz,
                bt1_titulo: this.form.bt1_titulo,
                bt1_desc: this.form.bt1_desc,
                bt1_link_desc: this.form.bt1_link_desc,
                bt1_link: this.form.bt1_link,
                bt1_link_img: this.form.bt1_link_img,
                bt2_titulo: this.form.bt2_titulo,
                bt2_desc: this.form.bt2_desc,
                bt2_link_desc: this.form.bt2_link_desc,
                bt2_link: this.form.bt2_link,
                bt2_link_img: this.form.bt2_link_img,
                bt3_titulo: this.form.bt3_titulo,
                bt3_desc: this.form.bt3_desc,
                bt3_link_desc: this.form.bt3_link_desc,
                bt3_link: this.form.bt3_link,
                bt3_link_img: this.form.bt3_link_img
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_bienvenida', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        }

    },
    async mounted() {
        this.setData( charsService.decode( this.$route.params.id ))
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    #bt1_desc {
        height: 500px;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>