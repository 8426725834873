/* Regresa todos los registros de la vista 'v_dependencia_op' en la base de datos */

// Esta linea se queda igual siempre (base url api)
import simejuvApi from '../api/simejuvApi'

// Se nombra la funcion
const getCatDependencias_op = async() => {
    const promiseArr = [
        // Se modifica de acuerdo a la búsqueda en la tabla
        simejuvApi.get(`/v_dependencia_op?transform=1&filter=borrar,lt,1`)
    ]

    // Cambiar el nombre de la variable contra los datos a mostrar
    const [ vdependencias ] = await Promise.all( promiseArr )

    // Cambiar la ultima variable a como se llama la tabla en la base de datos
    return vdependencias.data.v_dependencia_op
}

// Se exporta con el mismo nombre de la funcion
export default getCatDependencias_op