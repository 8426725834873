/* Regresa los registros de la tabla 'archivos' que correspondan con el módulo y registrio pasados como parámetros en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getArchivos = async( modulo, idRegistro ) => {
    const promiseArr = [
        simejuvApi.get(`/archivos?transform=1&filter=modulo,eq,${modulo}&filter=id_registro,eq,${idRegistro}&filter=borrar,lt,1`)
    ]

    const [ archivos ] = await Promise.all( promiseArr )

    return archivos.data.archivos
}

export default getArchivos