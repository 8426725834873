/* Regresa todos los objetivos prioritarios de la tabla 'accion_puntual' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccionesPuntuales = async() => {
    const promiseArr = [
        simejuvApi.get(`/accion_puntual?transform=1&filter=borrar,lt,1`)
    ]

    const [ acciones ] = await Promise.all( promiseArr )

    return acciones.data.accion_puntual
}

export default getAccionesPuntuales