<template>
    <h4 class="mt-0">Agregar una estrategia/Instancia</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Número de la Estrategia:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idEstrategia" name="idEstrategia" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.num_estrategia }" id="num_estrategia" name="num_estrategia" placeholder="Número de la estrategia" v-model="form.num_estrategia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_estrategia">Nombre de la Estrategia:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_estrategia }" id="nom_estrategia" name="nom_estrategia" placeholder="Nombre de la Estrategia" v-model="form.nom_estrategia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_corto_estrategia">Nombre corto de la Estrategia:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_corto_estrategia }" id="nom_corto_estrategia" name="nom_corto_estrategia" placeholder="Nombre corto de la Estrategia" v-model="form.nom_corto_estrategia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="objetivo">Nombre del objetivo:</label>
                    <div class="col-md-8">
                        <select class="form-control" id="objetivo" name="objetivo" v-model="form.objetivo" :class="{ validated: noValid.objetivo }">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e in depsArr" :key="e.id" :value="e.id">{{ e.num_objetivo }} {{ e.nom_corto_objetivo }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateEstrategia" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateEstrategia" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra Estrategia" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addEstrategia" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getObjetivosNc from '@/helpers/getObjetivosNc'
import addData from '@/helpers/addData'
import getDataByAnyEstrategia from '@/helpers/getDataByAnyEstrategia'

export default {
    data() {
        return {
            form: {
                num_estrategia: '',
                nom_estrategia: '',
                nom_corto_estrategia: '',
                objetivo: ''
            },
            noValid: {
                num_estrategia: false,
                nom_estrategia: false,
                nom_corto_estrategia: false,
                objetivo: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        async getObjetivos() {
            const deps = await getObjetivosNc()
            return deps
        },
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.num_estrategia = ''
            this.form.nom_estrategia = ''
            this.form.nom_corto_estrategia = ''
            setTimeout(function() { document.getElementById('objetivo').getElementsByTagName('option')[0].selected = 'selected' }, 1);
            document.getElementById('objetivo').getElementsByTagName('option')[0].selected = 'selected'
            this.added = false
        },
        async addEstrategia() {
            this.noValid.num_estrategia = ( this.form.num_estrategia )? false : true
            this.noValid.nom_estrategia = ( this.form.nom_estrategia )? false : true
            this.noValid.nom_corto_estrategia = ( this.form.nom_corto_estrategia )? false : true
           this.noValid.objetivo = ( this.form.objetivo )? false : true

            const valNom_estrategia = await getDataByAnyEstrategia( 'cat_estrategia', 'nom_estrategia', this.form.nom_estrategia );
            
            if ( valNom_estrategia.length < 1 ){
                if ( !this.noValid.num_estrategia) {
                    if ( !this.noValid.nom_estrategia) {
                        if ( !this.noValid.nom_corto_estrategia) {
               
               
                            const dataForm = {
                                num_estrategia: this.form.num_estrategia,
                                nom_estrategia: this.form.nom_estrategia,
                                nom_corto_estrategia: this.form.nom_corto_estrategia,
                                idobjetivo: this.form.objetivo,
                            }
                            const jsonData = JSON.parse(JSON.stringify( dataForm ))
                            const addEstrategia = await addData('cat_estrategia', jsonData)
                            if ( addEstrategia[0].res.status === 200 ) {
                                this.$notify({
                                    title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                                });
                                this.added = true
                            } else {
                                this.$notify({
                                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                                });
                            }
                        } else {
                            this.$notify({
                                title: 'Error', text: 'Favor de capturar el nombre corto de la Estrategia', type: 'error'
                            });
                        }
                    } else {
                        this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre de la Estrategia', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el número de la Estrategia', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una Estrategia registrada con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    },
    async mounted() {
        this.depsArr = await this.getObjetivos()
    }

}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>