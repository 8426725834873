<template>
    <div class="mainDiv">
        <Navbar :user="true" />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/adminSec">Administración de la plataforma</a></li>
            </ol>
            <h3>Administración de la plataforma</h3>
            <hr class="red">
            <div class="row">
                <div class="col-md-12">
                    <ProjuventudesEdit v-if="action === 'edit'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import ProjuventudesEdit from '@/components/AdminSec/Projuventud/ProjuventudEdit'

export default {
    components: {
        Navbar,
        ProjuventudesEdit,
    },
    data() {
        let action = ''

        return {
            action
        }
    },
    mounted() {
        this.action = this.$route.params.action

        if (this.action !== 'edit') {
            this.$router.push('/projuventud/projuventudAdmin')
        }

        window.scrollTo(0, 0)
    }
}
</script>