<template>
    <h4 class="mt-0">Mantenimiento de documentos descargables</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_titulo">Título de la publicación:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idDescargable" name="idDescargable" v-model="form.id" />
                        <input type="text" class="form-control" id="publicacion_titulo" name="publicacion_titulo" placeholder="Título de la publicación" v-model="form.publicacion_titulo" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_descrip">Descripción de la publicación:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.publicacion_descrip" :config="editorConfig" id="publicacion_descrip"></ckeditor>
                    </div>
                </div>
               
               
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_autor">Autor:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="publicacion_autor" name="publicacion_autor" placeholder="Autor" v-model="form.publicacion_autor" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_fecha">Fecha de publicación:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="publicacion_fecha" name="publicacion_fecha" placeholder="Año, Mes, día" v-model="form.publicacion_fecha" />
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-md-4" to="url">URL del documento:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="url" name="url" placeholder="URL del documento" v-model="form.url" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="file_name">Nombre a mostrar del archivo:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="file_name" name="file_name" placeholder="Nombre a mostrar del archivo" v-model="form.file_name" />
                    </div>
                </div>


                <div hidden class="form-group row">
                    <label class="col-md-4" to="id_usuario">Usuario:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="id_usuario" name="id_usuario" placeholder="usuario" v-model="form.id_usuario" />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDescargable" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDescargable" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateDescargable" />
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import '@/assets/css/vuedatepickerui.css'



// import axios from 'axios'
import getDescargablesById from '@/helpers/getDescargablesById'
import updateData from '@/helpers/updateData'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'

//import insertData from '@/helpers/addData'
//import getArchivos from '@/helpers/getArchivos'
import axios from 'axios'
import { ubApi } from '@/api/baseUrl'

import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            ubApi: ubApi.value,
            id: '',
            data: [],
            datos: [],
            

            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,
            uploadedImage: '',

            form: {
                id: '',
                publicacion_titulo: '',
                publicacion_descrip: '',
                publicacion_autor: '',
                publicacion_fecha: '',
                url: '',
                id_usuario: '',
                file_name: ''
            },

            form_ext: {
                id: '',
                publicacion_titulo: '',
                publicacion_descrip: '',
                publicacion_autor: '',
                publicacion_fecha: '',
                url: '',
                id_usuario: '',
                file_name: '',
            },

            isSelected: false,
            saveDisabled: false,
            arrArchivos: [],
            iconImg: '',


            editor: ClassicEditor,
            editorData: '',
            editorConfig: { language: 'es' }
        }
    },
    methods: {
        async getDescargableData( id ) {
            const descargableArr = await getDescargablesById( id )
            
            return descargableArr[0].descargables
        },

        async setData( id ) {
            const datos = await getDescargablesById( id )

            this.form.id = datos.id
            this.form.publicacion_titulo = datos.publicacion_titulo
            this.form.publicacion_descrip = datos.publicacion_descrip
            this.form.publicacion_autor = datos.publicacion_autor
            this.form.publicacion_fecha = datos.publicacion_fecha

            this.form.url = datos.url
            this.form.id_usuario = datos.id_usuario
            this.form.file_name = datos.file_name

            /* this.form_ext.id = datos.id
            this.form_ext.publicacion_titulo = datos.publicacion_titulo
            this.form_ext.publicacion_descrip = datos.publicacion_descrip
            this.form_ext.publicacion_autor = datos.publicacion_autor
            this.form_ext.publicacion_fecha = datos.publicacion_fecha

            this.form_ext.url = datos.url
            this.form_ext.id_usuario = datos.id_usuario
            this.form_ext.file_name = datos.file_name */
        },

        async updateDescargable() {
            const extension = this.form.file_name.substr(-3);
            const dataForm = {
                publicacion_titulo: this.form.publicacion_titulo,
                publicacion_descrip: this.form.publicacion_descrip,
                publicacion_autor: this.form.publicacion_autor,
                publicacion_fecha: this.form.publicacion_fecha,

                url: this.form.url,
                id_usuario: charsService.decode(localStorage.getItem('id')),
                file_name: this.form.file_name,
                extension: extension
            };

            console.log(dataForm);

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('descargables', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },



        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData( charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>


<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

</style>