<template>
    <h4 class="mt-0">Actualizar información del catálogo de tipos de acciones puntuales</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_tipo_ap">Nombre del tipo de acción puntual:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idTipo_ap" name="idTipo_ap" v-model="form.id" />
                        <input type="text" class="form-control" id="nom_tipo_ap" name="nom_tipo_ap" placeholder="Nombre del tipo de acción puntual" v-model="form.nom_tipo_ap" />
                    </div>
                </div>
                <hr>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateTipo_ap" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateTipo_ap" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateTipo_ap" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getTipos_apById from '@/helpers/getTipos_apById'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                nom_tipo_ap: '',
            }
        }
    },
    methods: {
        async getTipo_apData( id ) {
            const Tipo_apArr = await getTipos_apById( id )
            
            return Tipo_apArr[0].cat_tipo_ap
        },

        async setData( id ) {
            const datos = await getTipos_apById( id )

            this.form.id = datos.id
            this.form.nom_tipo_ap = datos.nom_tipo_ap
        },

        async updateTipo_ap() {
            const dataForm = {
                nom_tipo_ap: this.form.nom_tipo_ap
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_tipo_ap', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>