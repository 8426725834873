/* Regresa todos los datos de meta_ficha con el id enviado de la tabla 'meta_ficha' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getMetas_fichaById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/meta_ficha/${ id }`)
    ]

    const [ meta_ficha ] = await Promise.all( promiseArr )

    return meta_ficha.data
}

export default getMetas_fichaById