<template>
    <h4 class="mt-0">Actualizar información del catálogo de Dependencias</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="num_dependencia">Número de la Dependencia:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idDependencia" name="idDependencia" v-model="form.id" />
                        <input type="text" class="form-control" id="num_dependencia" name="num_dependencia" placeholder="Número de la Dependencia" v-model="form.num_dependencia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_dependencia">Nombre de la Dependencia:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_dependencia" name="nom_dependencia" placeholder="Nombre de la Dependencia" v-model="form.nom_dependencia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_corto_dependencia">Nombre corto de la Dependencia (Siglas):</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_corto_dependencia" name="nom_corto_dependencia" placeholder="Siglas" v-model="form.nom_corto_dependencia" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getDependenciasById from '@/helpers/getDependenciasById'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                num_dependencia: '',
                nom_dependencia: '',
                nom_corto_dependencia: '',
            }
        }
    },
    methods: {
        async getDependenciaData( id ) {
            const dependenciaArr = await getDependenciasById( id )
            
            return dependenciaArr[0].cat_dependencias
        },

        async setData( id ) {
            const datos = await getDependenciasById( id )

            this.form.id = datos.id
            this.form.num_dependencia = datos.num_dependencia
            this.form.nom_dependencia = datos.nom_dependencia
            this.form.nom_corto_dependencia = datos.nom_corto_dependencia
        },

        async updateDependencia() {
            const dataForm = {
                num_dependencia: this.form.num_dependencia,
                nom_dependencia: this.form.nom_dependencia,
                nom_corto_dependencia: this.form.nom_corto_dependencia
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_dependencias', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>