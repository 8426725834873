<template>
  <h3>Mapeo de oportunidades y servicios en materia de juventud</h3>
  <div>
      <p>En el mapa se presentan los estados y municipios que cuentan con cobertura de servicios y oportunidades para las juventudes.</p>
  </div>
  <hr>
  <div class="row">
      <div class="col-lg-3 busqueda">
        <div class="card">
          <div class="card-header">
            <el-card class="box-card" style="backgroundColor:#F5F5F5; borderColor:#BFBFBF; borderWidth:1px;" >
                <template #header>
                    <div class="card-header">
                        <h5>Filtros</h5>
                    </div>
                </template> 
                <select name="b_obj1" id="b_obj1" class="form-control mt-2" v-model="b_obj1" @change="f_b_obj2">
                    <option selected value="" class="color-grey">Estado</option>
                    <option v-for="x in arrEstados" :key="x.id" :value="x.id"  class="color-grey">{{ x.nom_estado }}</option>
                </select>

                <select name="b_obj3" id="b_obj3" class="form-control mt-2" v-model="b_obj3" @change="f_b_obj2">
                    <option selected value="" class="color-grey">Municipio</option>
                    <option v-for="x in arrMunicipios" :key="x.id" :value="x.id"  class="color-grey">{{ x.id }} - {{ x.nom_municipio }}</option>
                </select>

                <select name="b_obj2" id="b_obj2" class="form-control mt-2" v-model="b_obj2" @change="f_b_obj2">
                    <option selected value="" class="color-grey">Dependencia</option>
                    <option v-for="x in arrDependenciasOp" :key="x.id" :value="x.id"  class="color-grey">{{ x.nom_dependencia_op }}</option>
                </select>
                <select name="b_obj4" id="b_obj4" class="form-control mt-2" v-model="b_obj4" @change="f_b_obj2">
                    <option selected value="" class="color-grey">Sector</option>
                    <option v-for="x in arrSectores" :key="x.id" :value="x.id"  class="color-grey">{{ x.nom_sector_op }}</option>
                </select>

                <br>
                <br>
                <br>
            </el-card>
          </div>
        </div>  
      </div>

            <!-- Panel central del mapa -->
            <div class="col-lg-9 Mapa">
                <div class="card">
                <div class="card-header">
                    <el-card class="box-card" style="backgroundColor:#F5F5F5; borderColor:#BFBFBF; borderWidth:1px;" >
                        <template #header>
                            <div class="card-header">
                                <h5 class="dependenciaTitle">Oferta de Oportunidades y Servicios para las y los Jóvenes</h5>
                            </div>
                        </template> 

                    <div style="height: 75vh; width: 70vw;">
                        <l-map
                       
                        v-model="zoom"
                        v-model:zoom="zoom"
                        :center="[20.60, -96.00]"
                        @move="log('move')"
                        >
                        <l-tile-layer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        ></l-tile-layer>
                        <l-control-layers />
                        <div v-for="i in arrAcciones" :key="i" class="mt-3" :data-id="i.idOp_serv_estatales" >
                            <!-- <l-marker :lat-lng="[i.lat_decimal, i.lon_decimal]" @moveend="log('moveend')" :icon="icon"> -->
                            <l-marker :lat-lng="[i.lat_decimal, i.lon_decimal]" draggable @moveend="log('moveend')" :icon="icon" >     
                                <l-popup class="leaflet-popup-content"> 
                                                                                
                                    
                                    
                                    
                                    <div>
                                        <el-card class="box-card"  style="backgroundColor:#F5F5F5; borderColor:#BFBFBF; borderWidth:1px; width:240px; " >
                                            <template #header>
                                                <div class="card-header">
                                                    <h7 class="dependenciaTitle">Dependencia: {{ i.nom_dependencia_op }}</h7>
                                                </div>
                                            </template>                    
                                            <div class="titulo">Programa</div>
                                            <div class="col-md-12">    
                                                <div class="col-md-12">{{ i.nom_programa }}</div>
                                            </div>
                                            <div class="titulo">Público meta</div>
                                            <div class="col-md-12">    
                                                <div class="col-md-12">{{ i.publico_meta }}</div>
                                            </div>
                                            <div class="titulo">Descripción</div>
                                            <div class="col-md-12">    
                                                <div class="col-md-12">{{ i.programa_descripcion }}</div>
                                            </div>
                                            <div class="titulo">Objetivo</div>
                                            <div class="col-md-12">    
                                                <div class="col-md-12">{{ i.objetivo_programa }}</div>
                                            </div>


                                            <div class="titulo">Referencia web de la oferta</div>
                                            <div class="col-md-12">   
                                                <span><a v-bind:href="i.referencia_web" target="_blank">{{ i.referencia_web }}</a></span>
                                            </div>



                                            <div class="row">
                                                <div class="col-md-6 titulo">Estado</div>
                                                <div class="col-md-6 titulo">Municipio</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 objPrinc">{{ i.nom_estado }}</div>
                                                <div class="col-md-6 objPrinc">{{ i.nom_municipio_orig }}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 titulo">nivel_implementacion</div>
                                                <div class="col-md-6 titulo">Vigencia</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 objPrinc">{{ i.nivel_implementacion }}</div>
                                                <div class="col-md-6 objPrinc">{{ i.vigencia }}</div>
                                            </div>
                        
                                        </el-card>
                                        <br>                
                                    </div>  

                                    

                                </l-popup>
                            </l-marker>

                            <!-- ESTE ES EL BUENO QUE SI SE MUESTRA| es como debe quedar-->
                            <!-- <l-marker :lat-lng="[20.578496, -99.371594]" draggable @moveend="log('moveend')" :icon="icon">
                                <l-tooltip>
                                <l-popup>
                                    Ejemplo2
                                </l-popup>
                                </l-tooltip>
                            </l-marker> -->
                        </div>
                        </l-map>
                        <!-- <button @click="changeIcon">Poner nueva marca</button> -->
                    </div>

                    </el-card>
                    <br>                
                </div>
                </div>  
            </div>  

        <!-- Se pinta la tabla de ofertas -->
        <div class="col-lg-3">
        </div>

        <div class="col-lg-9">
            <div class="card-header">
                <el-card class="box-card" style="backgroundColor:#F5F5F5; borderColor:#BFBFBF; borderWidth:1px;">
                    <template #header style="max-height: 300px;">
                        <div class="card-header">
                            <h5>Catálogo de Oportunidades y Servicios Estatales para las y los Jóvenes</h5>
                        </div>
                    </template>
                    <!-- Se pintan la TABLA -->
                    <div class="card-body" style="margin-bottom: 0; overflow-x: auto; overflow-y: auto; max-height: 300px; width: 100%;">
                        <!-- Se pinta la tabla de los avances -->
                        <table class="table table-hover">
                            <thead class="table-secondary">
                                <tr>
                                    <th rowspan="2">Num</th>
                                    <th rowspan="2">Estado</th>
                                    <th rowspan="2">Dependencia</th>
                                    <th rowspan="2">Sector</th>
                                    <th rowspan="2">Programa</th>
                                    <th rowspan="2">Descripción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(h,index) in arrAcciones" :key="h">
                                    <td style="text-align: center;">{{ index+1 }}</td>
                                    <td>{{ h.nom_estado }}</td>
                                    <td>{{ h.nom_dependencia_op }}</td>
                                    <td>{{ h.nom_sector_op }}</td>
                                    <td>{{ h.nom_programa }}</td>
                                    <td>{{ h.programa_descripcion }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </el-card>
                <br>                
            </div> 
        </div>             





  </div>
</template>

<script>
import axios from 'axios'
//import { ubApi } from '@/api/baseUrl'

// Importo los helpers
import getOp_serv_estatales from '@/helpers/getOp_serv_estatales'
import getAccionSectorialbyDep from '@/helpers/getAccionSectorialbyDep'

import getCatEstados from '@/helpers/getCatEstados'
import getMunicipiosNc from '@/helpers/getMunicipiosNc' 
import getDependenciasOp from '@/helpers/getDependenciasOp'
import getCatSectores_op from '@/helpers/getCatSectores_op' 

import moment from 'moment'
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import charsService from '@/services/CharsService';


import {
LMap,
//LIcon,
LTileLayer,
LMarker,
LControlLayers,
//LTooltip,
LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import { latLng, icon } from "leaflet";
import { ubApi, icons } from '@/api/baseUrl'

export default {
  components: {
    LMap,
    //LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    //LTooltip,
    LPopup,
  },
  data() {
      return {
          arrAcciones: [],
          //arrAccionesFilt: [],
          b_obj1: '',
          b_obj2: '',
          b_obj3: '',
          b_obj4: '',  
          
          arrEstados: [],
          arrMunicipios: [],
          arrMunicipiosBak: [],
          arrDependenciasOp: [],
          arrDependenciasOpBak: [],
          arrSectores: [],
          zoom: 7,
          iconWidth: 25,
          iconHeight: 40,
          markerLatLng: [20.485935, -99.087053],    //es nuevo
          icon: icon({
            iconUrl: icons.value + "/marker-icon.png",     //eSTE SI SE MUESTRA
            iconSize: [this.iconWidth, this.iconHeight],
            iconAnchor: [16, 37]
          }),

      }
  },
  methods: {
      log(a) {
        console.log(a);
      },
      changeIcon() {
        this.iconWidth += 2;
        if (this.iconWidth > this.iconHeight) {
          this.iconWidth = Math.floor(this.iconHeight / 2);
        }
      },
      async getAllAcciones() {
          const opservArr = await getOp_serv_estatales()
          const arrShow = []

          for (let i=0; i<opservArr.length; i++) {

              const idOp_serv_estatales = opservArr[i].id
              
              const idestado = opservArr[i].idestado
              const idmunicipio = opservArr[i].idmunicipio
              const iddependencia_op = opservArr[i].iddependencia_op
              const id_sector_op = opservArr[i].id_sector_op

              const nom_dependencia_op = opservArr[i].nom_dependencia_op

              const nom_programa = opservArr[i].nom_programa
              const publico_meta = opservArr[i].publico_meta
              const programa_descripcion = opservArr[i].programa_descripcion
              const objetivo_programa = opservArr[i].objetivo_programa
              const referencia_web = opservArr[i].referencia_web
              const nom_sector_op = opservArr[i].nom_sector_op  
              const nom_estado = opservArr[i].nom_estado
              const nom_municipio_orig = opservArr[i].nom_municipio_orig
              const nivel_implementacion = opservArr[i].nivel_implementacion
              const vigencia = opservArr[i].vigencia

              const lat_decimal = opservArr[i].lat_decimal
              const lon_decimal = opservArr[i].lon_decimal

              arrShow.push({
                  idOp_serv_estatales,

                  idestado,
                  idmunicipio,
                  iddependencia_op,
                  id_sector_op,
                  nom_dependencia_op,
                  nom_estado,
                  nom_municipio_orig,
                  nivel_implementacion,
                  vigencia,
                  nom_programa,
                  publico_meta,
                  programa_descripcion,
                  objetivo_programa,
                  referencia_web,
                  nom_sector_op,
                  lat_decimal,
                  lon_decimal,
              })
          }

          return arrShow
      },
      async getCatalogos() {
        //Estados
        const consEdos = await getCatEstados(  )    
        for (let j = 0; j < consEdos.length; j++) {
            this.arrEstados.push({ id: consEdos[j].id, nom_estado: consEdos[j].nom_estado })
        }

        //Municipios
        const consMun = await getMunicipiosNc(  )    
        for (let j = 0; j < consMun.length; j++) {
            this.arrMunicipios.push({ id: consMun[j].id,idestado: consMun[j].idestado, nom_municipio: consMun[j].nom_municipio })
        }
        this.arrMunicipiosBak  = this.arrMunicipios;

        //Dependencias
        const consDeps = await getDependenciasOp( true )    
        for (let j = 0; j < consDeps.length; j++) {
            this.arrDependenciasOp.push({ id: consDeps[j].id,idestado: consDeps[j].idestado, nom_dependencia_op: consDeps[j].nom_dependencia_op })
        }
        this.arrDependenciasOpBak  = this.arrDependenciasOp;
        
        //Sectores
        const consSect = await getCatSectores_op(  )    
        for (let j = 0; j < consSect.length; j++) {
            this.arrSectores.push({ id: consSect[j].id, nom_sector_op: consSect[j].nom_sector_op })
        }
                     

      },
      ObtienePuntosMapa(){
          let IndexMun = this.arrMunicipios.indexOf('01002');
          //let IndexMun = this.arrMunicipios.indexOfItem('01002');

          //let IndexMun = this.arrMunicipios.findIndex('01002');     //No funciona
          //let IndexMun = this.arrMunicipios.find((Mun) => Mun.id === "01002");

          this.$notify({
            title: 'Index Municipio', text: 'Index=' +  IndexMun, type: 'success', duration: 5000, speed: 1000
          });

      },

      filter(array, key, value){
          let i, j, hash = [], item;
          for(i =  0, j = array.length; i<j; i++){
                item = array[i];
              if(typeof item[key] !== "undefined" && item[key] === value){
                  hash.push(item);
              }
          }
          return hash;
      },

      // Filtro exclusivo para cat de entidades (se hace referencia al registro que viene como "Todas")
      filterIn(array, key, arrayIn){
          let i, j, hash = [], item;
          for(i =  0, j = array.length; i<j; i++){
              item = array[i];
              if((typeof item[key] !== "undefined" && arrayIn.indexOf(item[key]) !== -1)){
                  hash.push(item);
              }
          }
          return hash;
      },
      async f_b_obj2() {
            //Filtro 
            const valor1 = document.querySelector("#b_obj1").value;
            const valor2 = document.querySelector("#b_obj2").value;
            const valor3 = document.querySelector("#b_obj3").value;
            const valor4 = document.querySelector("#b_obj4").value;
            

            let arrAccionesTmp = [];
            arrAccionesTmp  = this.arrAccionesBak;
            if (valor1)
            {    
                arrAccionesTmp = this.filter(arrAccionesTmp, 'idestado', parseInt(valor1))
                this.arrMunicipios = this.filter(this.arrMunicipiosBak, 'idestado', parseInt(valor1))
                this.arrDependenciasOp = this.filter(this.arrDependenciasOpBak, 'idestado', parseInt(valor1))
            }
            if (valor3)
                arrAccionesTmp = this.filter(arrAccionesTmp, 'idmunicipio', (valor3))
            if (valor2)
                arrAccionesTmp = this.filter(arrAccionesTmp, 'iddependencia_op', parseInt(valor2))
            if (valor4)
                arrAccionesTmp = this.filter(arrAccionesTmp, 'id_sector_op', parseInt(valor4))
            this.arrAcciones = arrAccionesTmp;
            //Arma arreglo de puntos del Mapa
            //await this.ObtienePuntosMapa()
            //}    
            //Filtro por dependencias
            //const valor = document.querySelector("#b_obj2").value;
            //if (!valor)
            //    this.arrAcciones = this.arrAccionesBak
            //else
            //    this.arrAcciones = this.filter(this.arrAccionesBak, 'iddependencia_op', parseInt(valor))    
      },
  },
  async mounted() {
      await this.getCatalogos()
      this.arrAcciones = await this.getAllAcciones()
      this.arrAccionesBak = await this.getAllAcciones()
     
      this.$gtag.event('Geolocalización de Oportunidades');     //Registra el Evento en Google Analytics con nombre
  }
}
</script>

<style scoped>
  .callout {
      background-color: rgba(250, 250, 250, 0.5);
  }
  .card {
      font-size: medium;
  }
  .icoLink {
      color: #212529;
  }

  .dontShow {
      display: none;
  }

  .btnCond {
      margin-bottom: 40px;
  }



  h5 {
      color: #9D2449;
  }

  .panel {
      font-size: medium;
  }

  .titulo {
      font-weight: bold;
      line-height: 40px;
  }
  .linea_gris {
      width: 100%;
  }
  .leaflet-popup-content {
    height: 350px;
    overflow-y: scroll;
  }
  .small-popup {
    max-width: 600px;
    max-height: 300px;
  }


 .card-header {
    
 }

</style>