/* Regresa todos los registros de la vista 'v_accionp_av_objetivo' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAllAccionp_av_objetivo = async( dependenciaId = '' ) => {
    let filtros = ''

    if (dependenciaId === '') {
        filtros = '&filter=borrar,lt,1'
    } else {
        filtros = `&filter[]=iddependencia,eq,${dependenciaId}&filter[]=borrar,lt,1`
    }
    
    const promiseArr = [
        simejuvApi.get(`/v_accionp_av_objetivo?transform=1${filtros}`)
    ]

    const [ Accionpuntual ] = await Promise.all( promiseArr )

    return [
        { accions: Accionpuntual.data.v_accionp_av_objetivo }
    ]
}

export default getAllAccionp_av_objetivo