<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/doctos">DOCUMENTOS DESCARGABLES</a></li>
            </ol>
            <h2>Documentos descargables</h2>
            <hr class="red">

            <div class="row mb-3">
                <!-- Input de búsqueda -->
                <div class="col-md-3 text-right"><label class="text-small">Buscar:</label></div>
                <div class="col-md-5 text-right"><input v-model="searchTerm" class="form-control busqueda" /></div>
            </div>
            <div class="row">
                <div class="col-md-12 datatable">
                    <!-- Configuración de la tabla con los campos a mostrar -->
                    <table-lite
                        :is-static-mode="true"
                        :columns="table.columns"
                        :rows="table.rows"
                        :total="table.totalRecordCount"
                        :sortable="table.sortable"
                        :messages="table.messages"
                    ></table-lite>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'

import { defineComponent, reactive, ref, computed } from "vue";
// Componente de la tabla
import TableLite from "@/components/DataTable/TableLite";

// Importo los helpers (API)
import getDescargable from '@/helpers/getDescargable'

import { ubApi, icons } from '@/api/baseUrl'

export default defineComponent({
    components: { 
        TableLite,
        Navbar

    },
    setup() {
        const searchTerm = ref("") // Variable para los datos de búsqueda
        const data = reactive([]); // Variable para los datos a mostrar en la tabla

        (async () => {
            // Se crea la variable para el arreglo haciendo la llamada a la función de la api
            const descargableArr = await getDescargable()

            // Genero el arreglo de datos para llenar la tabla
            const datos = descargableArr
            for (let i = 0; i < datos.length; i++) {
                data.push({
                    id: datos[i].id,
                    publicacion_titulo: datos[i].publicacion_titulo,
                    publicacion_descrip: datos[i].publicacion_descrip,
                    publicacion_autor: datos[i].publicacion_autor,
                    publicacion_fecha: datos[i].publicacion_fecha,
                    tmp_name: datos[i].tmp_name,
                    file_name: datos[i].file_name,
                    extension: datos[i].extension,
                    url: datos[i].url
                });
            }
        })()
        
        // Configuración de la tabla
        const table = reactive({
            // Columnas de la tabla
            columns: [
                {
                    label: "Título de la publicación",
                    field: "publicacion_titulo",
                    width: "25%",
                    sortable: true,
                },
                {
                    label: "Descripción",
                    field: "publicacion_descrip",
                    width: "25%",
                    sortable: true,
                    display: function (row) {  //Desplegar el campo con formato en la tabla
                        return (
                           row.publicacion_descrip 
                        );
                    },
                },
                {
                    label: "Autor",
                    field: "publicacion_autor",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "Fecha de publicación",
                    field: "publicacion_fecha",
                    width: "10%",
                    sortable: true,
                },

                // Botones de acciones (iconos)
                {
                    label: "Descargar documento",
                    field: "quick",
                    width: "25%",
                    display: function (row) {
                        // Iconos de acciones
                        if ( row.url ){
                            return (
                                '<a href="' + row.url + '" target="_blank" title="Descargar"><img src="' + icons.value + '/enlace.jpg" class="iconImg" width="30" /> ' + row.file_name + '</a>'
                            );
                        } else {
                            return (
                                '<a href="' + ubApi.value + '/download.php?tmpFileName=' + window.btoa(encodeURIComponent(row.tmp_name)) +'&fileName=' + window.btoa(encodeURIComponent(row.file_name)) +'" target="_blank" title="Descargar"><img src="' + icons.value + '/' + row.extension + '.svg" class="iconImg" width="30" /> ' + row.file_name + '</a>'
                            );
                        }
                    },
                },
            ],
            rows: computed(() => {
                // Aquí se designan las columnas en las que se realizará la búsqueda desde el input
                return data.filter(
                    (x) =>
                        x.publicacion_titulo.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        x.publicacion_descrip.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        x.publicacion_autor.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        x.publicacion_fecha.toLowerCase().includes(searchTerm.value.toLowerCase())  
                    );
            }),
            totalRecordCount: computed(() => {
                // Se regresa el número de registros encontrados
                return table.rows.length
            }),
            sortable: {
                // Se define el orden por default
                order: "publicacion_fecha",
                sort: "desc",
            },
            messages: {
                // Personalizo los mensajes que muestra la tabla
                pagingInfo: "Mostrando {0}-{1} de {2}",
                pageSizeChangeLabel: "Filas:",
                gotoPageLabel: "   Ir a la página:",
                noDataAvailable: "Sin datos para mostrar",
            },
        });

        return {
            searchTerm,
            table,
        }
    },
    async mounted() {
        this.$gtag.event('Documentos descargables');    //Pata registrar el Evento en Google Analytics con nombre
    }
})
</script>

<style scoped>
    .card :deep .table .thead-dark th {
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
    .card :deep .table td, .card :deep .table tr{
        border: none;
    }
    .text-small {
        font-size: medium;
    }
    .busqueda {
        font-size: medium;
        height: auto;
    }
    .text-info {
        color: #343a40 !important;
        padding: 0 20px;
    }
    .datatable {
        padding-bottom: 10px;
        overflow-x: auto;
        margin-bottom: 25px;
    }
    .card {
        border: 0 !important;
    }
    .iconImg {
        max-height: 50px !important;
        width: auto;
    }
</style>