<template>
    <h4 class="mt-0">Parámetros generales para el registro del avance de acciones puntuales</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>


                <div class="form-group row">
                    <label class="col-md-5 col-form-label" for="fecha_limite">Fecha límite para reportar avances de acciones puntuales:</label>
                    <div class="col-md-7">
                        <div class="row">    
                            <div class="col-md-6">
                                <Datepicker v-model="form.fecha_limite" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatFecha_limite" name="fecha_limite" id="fecha_limite" placeholder="Seleccione fecha" class="datepicker"></Datepicker>
                            </div>
                        </div>
                    </div>    
                </div>

                <br>
                <hr>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateFecha_limite" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateFecha_limite" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateFecha_limite" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import getParams_genById from '@/helpers/getParams_genById'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';
import moment from 'moment'

export default {
    components: {
        Datepicker,
    },
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            fecha_limite: '',
            form: {
                id: '',
                fecha_limite: '',
            }
        }
    },
    methods: {
        async getParam_genData( id ) {
            const param_genArr = await getParams_genById( id )
            
            return param_genArr[0].param_gen
        },

        async setData( id ) {
            const datos = await getParams_genById( id )      //debe ser id

            this.form.id = datos.id
            this.form.fecha_limite = new Date(datos.fecha_limite_ap + ' 00:00')
            
        },
        readFormatFecha_limite() {
            return this.readFormat( this.form.fecha_limite )
        },
        
        readFormat( date ) {
            if ( date !== '' ) {
                const day = (date.getDate() < 10)? '0' + date.getDate() : date.getDate()
                const month = (date.getMonth() < 9)? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
                const year = date.getFullYear()

                return `${day}-${month}-${year}`;
            } else {
                return ''
            }
        },

        async updateFecha_limite() {
            let errors = 0
            
            errors += this.validarVacios(this.form.fecha_limite, '[name="fecha_limite"]')
            if ( errors > 0 ){
                this.$notify({
                    title: 'Error', text: 'Hay campos requeridos que necesitan ser llenados. Por favor verifique', type: 'error', duration: 5000, speed: 1000
                })
                return
            } else {

                         
                const fecha_limite = moment( this.form.fecha_limite ).format('YYYY/MM/DD')
                const jsonUpd = JSON.parse(JSON.stringify({
                    "fecha_limite_ap": fecha_limite,
                }))
                
                
                const resUpd = await updateData('param_gen', charsService.decode(this.$route.params.id) , jsonUpd)
                
                if ( resUpd[0].res === 1 ) {
                    this.saveDisabled = true
                    this.$notify({
                        title: 'Correcto', text: 'Se guardó correctamente la información', type: 'success', duration: 5000, speed: 1000
                    })
                }

            }
        },
        validarVacios( valor, campo, combo = false ) {
            let errors = 0

            if (!combo) {
                if (!valor) { 
                    document.querySelector( campo ).style.backgroundColor='#fddfe2'
                    errors++
                } else {
                    document.querySelector( campo ).style.backgroundColor=''
                }
            } else {
                if (valor.length > 0) { 
                    document.querySelector( campo ).style.backgroundColor=''
                } else {
                    document.querySelector( campo ).style.backgroundColor='#fddfe2'
                    errors++
                }
            }

            return errors
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        await this.setData(charsService.decode( this.$route.params.id ))
        
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }
    
    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    
</style>