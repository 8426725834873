<template>
    <div class="row">
        <div class="row bg-light" v-for="e in datosBienvenida" :key="e.id">
            <div class="col-lg-3 col-md-4 col-sm-6 text-center">
                <img src= "@/assets/images/sideImage.png" alt="Jovenes">
            </div>
            <div class="col-lg-9 col-md-8 col-sm-6">
                <div class="article-body" >
                    <p class="card-text" v-html="e.cadena"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getCatBienvenida from '@/helpers/getCatBienvenida'

export default {
    data() {
        return { 
            datosBienvenida: [],
        }
    },
    methods: {
        async getBienvenida() {
            const dataBienvenida = await getCatBienvenida()
            const arrShow = []

            const cadena = `${ dataBienvenida[0].bienvenida_contenido }`
            const cadenaurl = `${ dataBienvenida[0].url_imagen_iz }`
            arrShow.push({
                    cadena,
                    cadenaurl
                })

            return arrShow
        }
    },
    async mounted() {
        this.datosBienvenida = await this.getBienvenida()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
    input {
        padding: 5px 40px;
        margin: 25px 0 50px;
        text-align: justify;
    }
    .article-body {
        text-align: justify;
    }

</style>