<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/estadisticas">ESTADÍSTICAS EN JUVENTUD</a></li>
            </ol>
            <h2>Estadísticas en Juventud</h2>

            <hr class="red">
            <!-- <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" :class="{hide1:detalle1||detalle2}"> -->
            
            <div class="row">
                <div class="article-body" >
                    <EstadisJuventud/>
                </div>
            </div> 

        </div>
    </div>
</template>

<script>

import getPresupJuventud from '@/helpers/getPresupJuventud'

import Navbar from '@/components/Common/Navbar'
import EstadisJuventud from '@/components/Estadisticas/EstadisJuventud';

export default {
    components: {
        Navbar,
        EstadisJuventud
    },
    data() {
        return {
            estadisJuventud: false, 
            datosPresup_juventud: [],
            muestra_btn_estadisJuventud:true,
        }
    },
    methods: {
        resetAll() {
            this.estadisJuventud = false
        },
        activeTab() {
            if ( this.$route.params.mod === 'estadisjuventud' ) {
                this.resetAll()
                this.estadisJuventud = true
                this.muestra_btn_estadisJuventud=false
            }
        },
        async getPresup_juventud() {
            const dataPresup_juventud = await getPresupJuventud();
            const arrShow = [];

            const presup_titulo = `${ dataPresup_juventud[0].presup_titulo } `;
            //const que_es_subtitulo = `${ dataPresup_juventud[0].que_es_subtitulo } `;
            const presup_contenido = `${ dataPresup_juventud[0].presup_contenido } `;
            const instrucciones = `${ dataPresup_juventud[0].instrucciones } `;
            //const url_videoDB =  url.value + '/uploads/' + `${ dataPresup_juventud[0].tmp_name }`;

               arrShow.push({
                    presup_titulo,
                    //que_es_subtitulo,
                    presup_contenido,
                    instrucciones,
                })

            return arrShow
        }
    },
    async mounted() {
        this.activeTab()
        this.datosPresup_juventud = await this.getPresup_juventud();
        // this.$gtag.event('page_view', {'page_title' : 'Que es PROJUVENTUD'}); //Para registrar el evento en Google Analytics y colocar titulo a la Página
        //this.$gtag.event('Que es PROJUVENTUD');                                  //Registra el Evento en Google Analytics con nombre    
        window.scrollTo(0, 0)
    },
    beforeUpdate() {
        this.activeTab()
    } 
}

</script>
<style scoped>
    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    a.nav-link {
        text-decoration: none;
        background-color: #98989A;
        border-color: #98989A;
        color: #FFF;
    }

    a.nav-link:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .nav>li>a:focus, .nav>li>a:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .activeNav {
        background-color: #9D2449;
    }
    
    
    
    
    
    .subTitle {
        font-weight: normal;
        padding: 15px;
    }
    .article-body {
        padding: 15px;
    }

    .article-body>p {
        padding: 10px 0;
    }

    strong {
        color: #235B4E;
    }
    .article-body {
        text-align: justify;
    }
</style>