<template>
    <h4 class="mt-0">Actualización de títulos y descripciónes del módulo 'Presupuesto con perspectiva de juventud'</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="presup_titulo">Título principal:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idPresupJuventud" name="idPresupJuventud" v-model="form.id" />
                        <input type="text" class="form-control" id="presup_titulo" name="presup_titulo" placeholder="Título principal" v-model="form.presup_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="presup_contenido">Contenido:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.presup_contenido" :config="editorConfig" id="presup_contenido" @input="conteo_real(form.presup_contenido, 'presup_contenido')"></ckeditor>
                        <div id="presup_contenido_count" class="noChars"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="instrucciones">Instrucciones de la calculadora:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.instrucciones" :config="editorConfig" id="instrucciones" @input="conteo_real(form.instrucciones, 'instrucciones')"></ckeditor>
                        <div id="instrucciones_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updatePresupJuventud" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updatePresupJuventud" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updatePresupJuventud" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getPresupJuventudesById from '@/helpers/getPresupJuventudesById'
import updateData from '@/helpers/updateData'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'

//import axios from 'axios'
//import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            //ubApi: ubApi.value,
            id: '',
            data: [],
            datos: [],

            form: {
                id: '',
                presup_titulo: '',
                presup_contenido: '',
                instrucciones: '',
            },

            editor: ClassicEditor,
            editorData: '',
            editorConfig: { language: 'es'}

        }
    },
    methods: {
        async getPresupJuventudData( id ) {
            const PresupJuventudArr = await getPresupJuventudesById( id )
            
            return PresupJuventudArr[0].presup_juventud
        },

        async setData( id ) {
            const datos = await getPresupJuventudesById( id )

            this.form.id = datos.id
            this.form.presup_titulo = datos.presup_titulo
            this.form.presup_contenido = datos.presup_contenido
            this.form.instrucciones = datos.instrucciones

            this.editorData = datos.presup_contenido
            this.editorData = datos.instrucciones
        },

        async updatePresupJuventud() {
            const dataForm = {
                presup_titulo: this.form.presup_titulo,
                presup_contenido: this.form.presup_contenido,
                instrucciones: this.form.instrucciones
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('presup_juventud', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>