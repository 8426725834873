/* Regresa un registro de la tabla de accion_puntual */

import simejuvApi from '../api/simejuvApi'

const getDataByAnyaccionp_av_objetivo = async( table, column, value ) => {
    const promiseArr = [
        simejuvApi.get(`/${ table }?transform=1&filter=${ column },eq,${ value }`)
    ]

    const [ datos ] = await Promise.all( promiseArr )
    
    return datos.data.accionp_av_objetivo

}

export default getDataByAnyaccionp_av_objetivo