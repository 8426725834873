<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <Registro />
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import Registro from '@/components/Acciones/Registro'

export default {
    components: {
        Navbar,
        Registro
    }
}
</script>