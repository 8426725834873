<template>
    <h3>Registro de acciones puntuales</h3>
    <hr>
    <form role="form">
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="objetivo">Nombre del objetivo prioritario:</label>
            <div class="col-md-9 col-sm-12">
                <select class="form-control" name="objetivo" id="objetivo">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in objetivo" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="estrategia">Nombre de la estrategia prioritaria:</label>
            <div class="col-md-9 col-sm-12">
                <select class="form-control" name="estrategia" id="estrategia">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in estrategia" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="codigo_ap">Código de la acción puntual:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="codigo_ap" placeholder="Código de la acción puntual" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="nombre_ap">Nombre de la acción puntual:</label>
            <div class="col-md-9 col-sm-12">
                <input class="form-control" id="nombre_ap" placeholder="Nombre de la acción puntual" type="text">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="tipo_ap">Tipo de acción puntual:</label>
            <div class="col-md-9 col-sm-12">
                <select class="form-control" name="tipo_ap" id="tipo_ap">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in tipo_ap" :key="e.id" value="{{ e.id }}">{{ e.desc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="tipo_ap">Dependencias y/o Entidades responsables de instrumentar la acción puntual:</label>
            <div class="col-md-9 col-sm-12 pl-5">
                <div class="row">
                    <div class="col-md-4 col-sm-6 inLine" v-for="e in listChecks" :key="e.id">
                        <label><input type="checkbox" value="{{ e.nc }}"> {{ e.nc }}</label> 
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="dependencia">Dependencia o Entidad coordinadora:</label>
            <div class="col-md-9 col-sm-12">
                <select class="form-control" name="dependencia" id="dependencia">
                    <option value="" disabled selected>[Seleccione]</option>
                    <option v-for="e in listSelect" :key="e.id" value="{{ e.id }}">{{ e.nc }}</option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="periodo_ap">Periodo para el cumplimiento de la acción puntual:</label>
            <div class="col-md-9 col-sm-12">
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" for="periodo_ap_i">Fecha de inicio:</label>
                    <div class="col-md-3">
                        <input class="form-control" id="periodo_ap_i" placeholder="Inicio" type="text">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" for="periodo_ap_i">Fecha de término:</label>
                    <div class="col-md-3">
                        <input class="form-control" id="periodo_ap_i" placeholder="Término" type="text">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-sm-12 col-form-label" for="no_jovenes">Cantidad de jóvenes a impactar:</label>
            <div class="col-md-9 col-sm-12">
                <div class="form-group row">
                    <div class="col-md-6">
                        <input type="checkbox" value="nd"> <strong>Dato no disponible</strong>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <input class="form-control" id="no_jovenes" placeholder="Cantidad de jóvenes a impactar" type="text">
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row divBtn">
            <div class="col-12 text-right">
                <button class="btn btn-primary active btnCond" type="button">Guardar</button>
            </div>
        </div>
    </form>

    <hr>
</template>

<script>
export default {
    data() {
        const objetivo = [
            {id: 1, desc:'Promover la participación de las personas jóvenes en los asuntos públicos para incidir en el proyecto de nación.'},
            {id: 2, desc:'Disminuir la discriminación entre y hacia las juventudes para impulsar su inclusión e igualdad efectiva de oportunidades.'},
            {id: 3, desc:'Promover el derecho de las personas jóvenes a una vida en paz y libre de violencia para posibilitar el pleno desarrollo de su proyecto de vida.'},
            {id: 4, desc:'Promover el ejercicio de los derechos sociales de las personas jóvenes como condición para alcanzar su bienestar.'},
            {id: 5, desc:'Facilitar los procesos de emancipación y construcción de autonomía de las personas jóvenes para fortalecer la continuidad de su curso de vida.'},
            {id: 6, desc:'Promover la generación de entornos adecuados y habitables, aptos para el desarrollo integral de las personas jóvenes.'}
        ]

        const estrategia = [
            {id: 11, desc:'Impulsar la creación, diversificación y fortalecimiento de mecanismos de participación en los asuntos públicos para las personas jóvenes para fortalecer su inclusión en los procesos de toma de decisiones.'},
            {id: 12, desc:'Fomentar entre las personas jóvenes una cultura cívica, de conocimiento de sus derechos y respeto al Estado de Derecho para fortalecer el ejercicio de su ciudadanía.'},
            {id: 13, desc:'Ampliar la presencia y participación de las personas jóvenes en procesos institucionales de toma de decisiones para reconocer su pluralidad y enriquecer la vida democrática del país.'},
            {id: 21, desc:'Impulsar la creación, aprobación y armonización de un nuevo marco jurídico e institucional que garantice el reconocimiento de las personas jóvenes como sujetos de derecho para garantizar su inclusión e igualdad sustantiva. '},
            {id: 22, desc:'Promover entre la sociedad y las instituciones públicas el reconocimiento de la diversidad de las juventudes, subrayando la importancia de desarrollar acciones diferenciadas con perspectiva de juventudes a fin de erradicar los tipos y prácticas de discriminación de las que son víctimas. '},
            {id: 23, desc:'Prevenir la discriminación estructural que pesa sobre las personas jóvenes a fin de erradicar los estereotipos, prejuicios y asimetrías que sustentan las brechas de desigualdad social que las afectan. '},
            {id: 31, desc:'Mitigar la violencia causada por el crimen organizado y sus efectos en el bienestar de las personas jóvenes para facilitar el adecuado desarrollo de su curso de vida. '},
            {id: 32, desc:'Mitigar la violencia que ocurre entre y contra personas jóvenes en sus diversos entornos de convivencia cotidiana para facilitar el adecuado desarrollo de su curso de vida. '},
            {id: 41, desc:'Impulsar una educación pertinente y de calidad para todas las juventudes, a fin de contribuir al ejercicio pleno de sus derechos sociales. '},
            {id: 42, desc:'Impulsar el acceso a servicios de salud integrales y de calidad que atiendan las necesidades específicas de las juventudes, a fin de contribuir al ejercicio pleno de sus derechos sociales. '},
            {id: 43, desc:'Apoyar el acceso a una alimentación nutritiva, suficiente y de calidad para todas las personas jóvenes, a fin de contribuir al ejercicio pleno de sus derechos sociales.'},
            {id: 44, desc:'Impulsar el goce y la libre manifestación cultural y creativa para todas las juventudes, a fin de contribuir al ejercicio pleno de sus derechos sociales.'},
            {id: 45, desc:'Apoyar la práctica del deporte y la cultura física para todas las juventudes, a fin de contribuir al ejercicio pleno de sus derechos sociales.'},
            {id: 51, desc:'Promover el ejercicio del derecho al trabajo digno para todas las juventudes, a fin de generar condiciones propicias para la adecuada continuidad de su curso de vida. '},
            {id: 52, desc:'Promover el ejercicio del derecho a una vivienda adecuada y accesible para todas las juventudes, a fin de generar condiciones propicias para la adecuada continuidad de su curso de vida.'},
            {id: 53, desc:'Promover procesos de innovación y generación de capacidades para el desarrollo de actividades productivas y tecnológicas entre las personas jóvenes a fin de facilitar su mejor integración posible a los mercados. '},
            {id: 61, desc:'Impulsar el acceso y democratización de las TIC para las personas jóvenes como base para su integración efectiva a la sociedad de la información y el conocimiento. '},
            {id: 62, desc:'Promover el desarrollo de entornos urbanos habitables como condición primordial para el desarrollo de los proyectos y aspiraciones de las personas jóvenes. '},
            {id: 63, desc:'Integrar a las personas jóvenes en las acciones desarrolladas para la mitigación del cambio climático y la protección del medio ambiente a fin de que se reconozcan como agentes centrales para su desarrollo.'}
        ]

        const tipo_ap = [
            {id: 1, desc:'Específica'},
            {id: 2, desc:'General'},
            {id: 3, desc:'De Coordinación de la Estrategia'}
        ]

        const dependencia = [
            {id: 1,     coord:false,    nc:'AGRICULTURA',    desc:'Secretaría de Agricultura y Desarrollo Rural'},
            {id: 2,     coord:true,     nc:'BIENESTAR',      desc:'Secretaría de Bienestar'},
            {id: 3,     coord:false,    nc:'CEAV',           desc:'Comisión Ejecutiva de Atención a Víctimas'},
            {id: 4,     coord:false,    nc:'CFE',            desc:'Comisión Federal de Electricidad'},
            {id: 5,     coord:false,    nc:'CIIT',           desc:'Corredor Interoceánico del Istmo de Tehuantepec'},
            {id: 6,     coord:false,    nc:'CMHCM',          desc:'Coordinación de Memoria Histórica y Cultural de México'},
            {id: 7,     coord:true,     nc:'CNBV',           desc:'Comisión Nacional Bancaria y de Valores'},
            {id: 8,     coord:false,    nc:'CONABIO',        desc:'Comisión Nacional para el Conocimiento y Uso de la Biodiversidad'},
            {id: 9,     coord:false,    nc:'CONACYT',        desc:'Consejo Nacional de Ciencia y Tecnología'},
            {id: 10,    coord:false,    nc:'CONADE',         desc:'Comisión Nacional de Cultura Física y Deporte'},
            {id: 11,    coord:false,    nc:'CONADIC',        desc:'Comisión Nacional contra las Adicciones'},
            {id: 12,    coord:false,    nc:'CONADIS',        desc:'Consejo Nacional para el Desarrollo y la Inclusión de las Personas con Discapacidad'},
            {id: 13,    coord:false,    nc:'CONAFE',         desc:'Consejo Nacional de Fomento Educativo'},
            {id: 14,    coord:false,    nc:'CONAFOR',        desc:'Comisión Nacional Forestal'},
            {id: 15,    coord:false,    nc:'CONAGUA',        desc:'Comisión Nacional del Agua'},
            {id: 16,    coord:false,    nc:'CONANP',         desc:'Comisión Nacional de Áreas Naturales Protegidas'},
            {id: 17,    coord:true,     nc:'CONAPO',         desc:'Consejo Nacional de Población'},
            {id: 18,    coord:true,     nc:'CONAPRED',       desc:'Consejo Nacional Para Prevenir la Discriminación'},
            {id: 19,    coord:false,    nc:'CONAVI',         desc:'Comisión Nacional de Vivienda'},
            {id: 20,    coord:false,    nc:'CONAVIM',        desc:'Comisión Nacional para Prevenir y Erradicar la Violencia Contra las Mujeres'},
            {id: 21,    coord:false,    nc:'CONSAR',         desc:'Comisión Nacional del Sistema de Ahorro para el Retiro'},
            {id: 22,    coord:false,    nc:'CULTURA',        desc:'Secretaría de Cultura'},
            {id: 23,    coord:false,    nc:'DIF',            desc:'Sistema Nacional para el Desarrollo Integral de la Familia'},
            {id: 24,    coord:false,    nc:'FONATUR',        desc:'Fondo Nacional de Fomento al Turismo'},
            {id: 25,    coord:true,     nc:'IMJUVE',         desc:'Instituto Mexicano de la Juventud'},
            {id: 26,    coord:false,    nc:'IMSS',           desc:'Instituto Mexicano del Seguro Social'},
            {id: 27,    coord:true,     nc:'INAES',          desc:'Instituto Nacional de la Economía Social'},
            {id: 28,    coord:false,    nc:'INALI',          desc:'Instituto Nacional de Lenguas Indígenas'},
            {id: 29,    coord:false,    nc:'INAPAM',         desc:'Instituto Nacional de las Personas Adultas Mayores'},
            {id: 30,    coord:false,    nc:'INM',            desc:'Instituto Nacional de Migración'},
            {id: 31,    coord:true,     nc:'INMUJERES',      desc:'Instituto Nacional de las Mujeres'},
            {id: 32,    coord:true,     nc:'INPI',           desc:'Instituto Nacional de los Pueblos Indígenas'},
            {id: 33,    coord:false,    nc:'INSP',           desc:'Instituto Nacional de Salud Pública'},
            {id: 34,    coord:false,    nc:'IPN',            desc:'Instituto Politécnico Nacional'},
            {id: 35,    coord:false,    nc:'ISSSTE',         desc:'Instituto de Seguridad y Servicios Sociales de los Trabajadores del Estado'},
            {id: 36,    coord:false,    nc:'SCT',            desc:'Secretaría de Comunicaciones y Transportes'},
            {id: 37,    coord:false,    nc:'SE',             desc:'Secretaría de Economía'},
            {id: 38,    coord:false,    nc:'SEDATU',         desc:'Secretaría de Desarrollo Agrario, Territorial y Urbano'},
            {id: 39,    coord:false,    nc:'SEDENA',         desc:'Secretaría de la Defensa Nacional'},
            {id: 40,    coord:false,    nc:'SEGALMEX',       desc:'Seguridad Alimentaria Mexicana'},
            {id: 41,    coord:true,     nc:'SEGOB',          desc:'Secretaría de Gobernación'},
            {id: 42,    coord:false,    nc:'SEMAR',          desc:'Secretaría de Marina'},
            {id: 43,    coord:false,    nc:'SEMARNAT',       desc:'Secretaría de Medio Ambiente y Recursos Naturales'},
            {id: 44,    coord:false,    nc:'SENER',          desc:'Secretaría de Energía'},
            {id: 45,    coord:true,     nc:'SEP',            desc:'Secretaría de Educación Pública'},
            {id: 46,    coord:false,    nc:'SE-SIPINNA',     desc:'Secretaría Ejecutiva del Sistema Nacional de Protección de Niñas, Niños y Adolescentes'},
            {id: 47,    coord:true,     nc:'SHCP',           desc:'Secretaría de Hacienda y Crédito Público'},
            {id: 48,    coord:false,    nc:'SRE',            desc:'Secretaría de Relaciones Exteriores'},
            {id: 49,    coord:true,     nc:'SSA',            desc:'Secretaría de Salud'},
            {id: 50,    coord:false,    nc:'SSPC',           desc:'Secretaría de Seguridad y Protección Ciudadana'},
            {id: 51,    coord:true,     nc:'STPS',           desc:'Secretaría del Trabajo y Previsión Social'},
            {id: 100,   coord: true,    nc:'No Sectorizada', desc:'No Sectorizada'}
        ]

        return {
            objetivo, estrategia, tipo_ap, dependencia
        }
    },
    computed : {
        listChecks: function () {
            return this.dependencia.filter(i => i.id < 100)
        },
        listSelect: function () {
            return this.dependencia.filter(i => i.coord === true)
        }
    }
}
</script>

<style scoped>

    .inLine {
        white-space: nowrap;
    }

    @media screen and (max-width: 768px) {
        .btnCond {
            display: block;
            width: 100%;
        }
    }

    form {
        padding-top: 0;
    }

</style>