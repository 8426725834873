/* Regresa todos los datos del usuario con el id enviado de la tabla 'descargables' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getDescargablesById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/descargables/${ id }`)
    ]

    const [ descargable ] = await Promise.all( promiseArr )

    return descargable.data
}

export default getDescargablesById