<template>
    <div class="row mt-5 mb-3">
        <div class="col-sm-6 col-md-4" v-for="e in datosBienvenida" :key="e.id">
            <div class="thumbnail">
                <img :src="require(`@/assets/images/${e.img}`)" class="card-img-top" alt="imagen">
                <div class="caption">
                    <h5 class="card-title text-left ">{{ e.title }}</h5>
                    <p class="card-text" v-html="e.txt"></p>
                    <a :href="e.url" target="_blank" class="link1" ><small class="text-muted">{{ e.link }}</small></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getCatBienvenida from '@/helpers/getCatBienvenida'

export default {
    data() {
        return { 
            datosBienvenida: [],
        }
    },
    methods: {
        async getBienvenida() {
            const dataBienvenida = await getCatBienvenida()
            const arrShow = []
            
            arrShow.push(
                {
                    img: `${dataBienvenida[0].bt1_link_img}`,
                    title: `${ dataBienvenida[0].bt1_titulo }`,
                    txt: `${ dataBienvenida[0].bt1_desc }`,
                    url: `${ dataBienvenida[0].bt1_link }`,
                    link: `${ dataBienvenida[0].bt1_link_desc }`
                },           
                {
                    img: `${dataBienvenida[0].bt2_link_img}`,
                    title: `${ dataBienvenida[0].bt2_titulo }`,
                    txt: `${ dataBienvenida[0].bt2_desc }`,
                    url: `${ dataBienvenida[0].bt2_link}`,
                    link: `${ dataBienvenida[0].bt2_link_desc }`
                },           
                {
                    img: `${dataBienvenida[0].bt3_link_img}`,
                    title: `${ dataBienvenida[0].bt3_titulo }`,
                    txt: `${ dataBienvenida[0].bt3_desc }`,
                    url: `${ dataBienvenida[0].bt3_link}`,
                    link: `${ dataBienvenida[0].bt3_link_desc }`
                }           
            )

            return arrShow
        }
    },
    async mounted() {
        this.datosBienvenida = await this.getBienvenida()
        window.scrollTo(0, 0)
    }
}
</script>

<style>
    .card-title {
        text-transform: uppercase;
    }
    .mt-5 {
        margin-top: 30px;
    }
    .mb-5 {
        margin-bottom: 20px;
    }
</style>