/* Regresa todos los datos del usuario con el id enviado de la tabla 'op_serv_estatales' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getOp_serv_estatalesById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/op_serv_estatales/${ id }`)
    ]

    const [ op_serv_estatales ] = await Promise.all( promiseArr )

    return op_serv_estatales.data
}

export default getOp_serv_estatalesById