<template>
    <h4 class="mt-0">Actualización de títulos y descripciónes del módulo 'Catálogo de oferta para las y los jóvenes'</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="interv_titulo">Título principal:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idIntervencion" name="idIntervencion" v-model="form.id" />
                        <input type="text" class="form-control" id="interv_titulo" name="interv_titulo" placeholder="Título principal" v-model="form.interv_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="interv_cont_tit">Contenido del título principal:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.interv_cont_tit" :config="editorConfig" id="interv_cont_tit" @input="conteo_real(form.interv_cont_tit, 'interv_cont_tit')"></ckeditor>
                        <div id="interv_cont_tit_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="interv_contenido">Contenido principal:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.interv_contenido" :config="editorConfig" id="interv_contenido" @input="conteo_real(form.interv_contenido, 'interv_contenido')"></ckeditor>
                        <div id="interv_contenido_count" class="noChars"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="interv_subtitulo">Titulo del cuadro de registro:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.interv_subtitulo" :config="editorConfig" id="interv_subtitulo" @input="conteo_real(form.interv_subtitulo, 'interv_subtitulo')"></ckeditor>
                        <div id="interv_subtitulo_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateInterv_param" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateInterv_param" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateInterv_param" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getInterv_paramById from '@/helpers/getInterv_paramById'
import updateData from '@/helpers/updateData'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/es'

//import axios from 'axios'
//import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            //ubApi: ubApi.value,
            id: '',
            data: [],
            datos: [],

            form: {
                id: '',
                interv_titulo: '',
                interv_cont_tit: '',
                interv_contenido: '',
                interv_subtitulo: '',
            },

            editor: ClassicEditor,
            editorData: '',
            editorConfig: { language: 'es'}

        }
    },
    methods: {
        async getInterv_paramData( id ) {
            const Interv_paramArr = await getInterv_paramById( id )
            
            return Interv_paramArr[0].interv_param
        },

        async setData( id ) {
            const datos = await getInterv_paramById( id )

            this.form.id = datos.id
            this.form.interv_titulo = datos.interv_titulo
            this.form.interv_cont_tit = datos.interv_cont_tit
            this.form.interv_contenido = datos.interv_contenido
            this.form.interv_subtitulo = datos.interv_subtitulo

            this.editorData = datos.interv_cont_tit
            this.editorData = datos.interv_contenido
            this.editorData = datos.interv_subtitulo
        },

        async updateInterv_param() {
            const dataForm = {
                interv_titulo: this.form.interv_titulo,
                interv_cont_tit: this.form.interv_cont_tit,
                interv_contenido: this.form.interv_contenido,
                interv_subtitulo: this.form.interv_subtitulo
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('interv_param', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>