<template>
    <div class="mainDiv">
        <Navbar :user="true" />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/adminSec">Administración de la plataforma</a></li>
            </ol>
            <h3>Administración de la plataforma</h3>
            <hr class="red">
            <div class="row">
                <div class="col-md-12">
                    <Tendencias_indicadorEdit v-if="action === 'edit'" />
                    <Tendencias_indicadorAdd v-if="action === 'add'" />
                    <Tendencias_indicadorRemove v-if="action === 'remove'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import Tendencias_indicadorEdit from '@/components/AdminSec/Tendencias_indicador/Tendencias_indicadorEdit'
import Tendencias_indicadorAdd from '@/components/AdminSec/Tendencias_indicador/Tendencias_indicadorAdd'
import Tendencias_indicadorRemove from '@/components/AdminSec/Tendencias_indicador/Tendencias_indicadorRemove'

export default {
    components: {
        Navbar,
        Tendencias_indicadorEdit,
        Tendencias_indicadorAdd,
        Tendencias_indicadorRemove,
    },
    data() {
        let action = ''

        return {
            action
        }
    },
    mounted() {
        this.action = this.$route.params.action

        if (this.action !== 'edit' && this.action !== 'add' && this.action !== 'remove') {
            this.$router.push('/tendencias_indicador/tendencias_indicadorAdmin')
        }

        window.scrollTo(0, 0)
    }
}
</script>