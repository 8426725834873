/* Regresa todos los objetivos prioritarios de la tabla 'cat_objetivo_sectorial' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getObjetivosSectoriales = async() => {
    const promiseArr = [
        simejuvApi.get(`/cat_objetivo_sectorial?transform=1&filter=borrar,lt,1`)
    ]

    const [ objetivosSec ] = await Promise.all( promiseArr )

    return objetivosSec.data.cat_objetivo_sectorial
}

export default getObjetivosSectoriales