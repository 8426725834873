/* Regresa todos los datos del usuario con el id enviado de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getDependenciasById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_dependencias/${ id }`)
    ]

    const [ cat_dependencias ] = await Promise.all( promiseArr )

    return cat_dependencias.data
}

export default getDependenciasById