/* Regresa todos los datos del usuario con el id enviado de la tabla 'presup_juventud' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getPresup_juventudesById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/presup_juventud/${ id }`)
    ]

    const [ presup_juventud ] = await Promise.all( promiseArr )

    return presup_juventud.data
}

export default getPresup_juventudesById