/* Regresa todos los datos del usuario con el id enviado de la tabla 'param_gen' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getParamGenById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/param_gen/${ id }`)
    ]

    const [ param_gen ] = await Promise.all( promiseArr )

    return param_gen.data
}

export default getParamGenById