<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <Avance v-if="isAcciones && isAdd" />
            <AvanceEdit v-if="isAcciones && isEdit" />
            <AvanceMetasEdit v-if="isMetas && isEdit" />
            <!-- <AvanceMetas v-if="isMetas" /> -->
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import Avance from '@/components/Projuventud/Acciones/Avance'
import AvanceEdit from '@/components/Projuventud/Acciones/AvanceEdit'
// import AvanceMetas from '@/components/Projuventud/Metas/Metas'
import AvanceMetasEdit from '@/components/Projuventud/Metas/AvanceMetasEdit'

export default {
    components: {
        Navbar,
        Avance,
        AvanceEdit,
        // AvanceMetas,
        AvanceMetasEdit
    },
    data() {
        return {
            isMetas: (this.$route.params.tipo === 'metas'),
            isAcciones: (this.$route.params.tipo === 'acciones'),

            isAdd: (this.$route.params.event === 'add'),
            isEdit: (this.$route.params.event === 'edit'),
        }
    }
}
</script>