<template>
    
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <el-card class="box-card" style = "backgroundColor: #FBFCFC" shadow="hover">
                    <template #header>
                        <div class="card-header" >
                            <span><strong><p class="card-text" v-html="this.estadist_introduc_tit"></p></strong></span>
                        </div>
                    </template>


                    <div class="row">
                            <div class="article-body" >
                                <iframe :src="url_ind_basicos" width="100%" height="800" frameborder="0" marginheight="0"></iframe>
                            </div>
                    </div>

                </el-card>
            </form>
        </div>
    </div>

</template>

<script>
import { ubApi, icons } from '@/api/baseUrl'
import getEstadist_introduccionById from '@/helpers/getEstadist_introduccionById'
export default {
    
    data() {
        
        return {
            id: 0,
            url_ind_basicos: '',
            estadist_introduc_tit: '',
        }
    },
    props:{
        msg: String,
        param_opc:String,
    },
    computed: {
        parametroid() {
            return this.param_opc;
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        async setData( ) {
            this.id = this.parametroid //this.parametro_pasado2;
            const datos = await getEstadist_introduccionById( this.id )
            //this.estadist_introduccion = datos.estadist_introduccion;
            this.estadist_introduc_tit = "ESTADISTICAS BASICAS DE " +  datos.estadist_introduc_tit;
            if (this.parametroid == 1){         //Poblacion
                this.url_ind_basicos = ubApi.value + '/indicadores/ind_poblacion_basicos.htm'
            }else if (this.parametroid == 2){         //Educacion
                this.url_ind_basicos = ubApi.value + '/indicadores/ind_educ_basicos.htm'
            }else if (this.parametroid == 3){         //Empleo
                this.url_ind_basicos = ubApi.value + '/indicadores/ind_emp_ocup_basicos.htm'
            }else if (this.parametroid == 6){         //Juventudes diversas
                this.url_ind_basicos = ubApi.value + '/indicadores/ind_jd_basicos.htm'
            }


        },

    },
    async mounted() {
        await this.setData()
       // window.scrollTo(0, 0)
    },
    beforeUpdate() {
        this.setData()
    }
}
</script>

<style scoped>

</style>