<template>
<div style="backgroundColor:#E5E5E5; padding:0px ;"> Indicador: Población joven por grupos quinquenales de edad según tamaño de localidad, año censal 2020.</div>
    <div class="content">
        <div class="row">
            <div class="col-md-12" style="backgroundColor:#F2F3F4; padding:10px ;">
                <div class="content" style="backgroundColor:#ffffff; padding:0px ;">
                    <el-button type="primary" style="padding: 0px; border: 0px; background-color:#ffffff;"  @click="SeccionaDesplegar(1)" >
                        <img :src="btn_grafica" class="iconImg" width="40"/>
                    </el-button>
                    <el-button type="primary" style="padding: 0px; border: 0px; background-color:#ffffff;" @click="SeccionaDesplegar(2)">
                        <img :src="btn_table" class="iconImg" width="38" alt="Tabla de datos"/>
                    </el-button>
                </div> 

                <div class="row" v-if="idSeccionaDesplegar === 1">       <!-- SECCION DE LAS GRAFICAS -->
                    <div class="col-md-12" style="top:15px">
                        <div class="panel panel-warning"    >
                            <div class="panel-body text-center">
                                <iframe :src="url_ind_basicos" width="870px" height="345" frameborder="0" marginheight="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="article-body" v-if="idSeccionaDesplegar === 2">     <!-- SECCION DE LAS TABLAS -->
                    <iframe :src="url_cuadro_pob_tam_localidad" width="100%" height="800" frameborder="0" marginheight="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { ubApi, icons } from '@/api/baseUrl'


export default {
   
  data() {
    return {
        image_est_pob_piramide_1_nal: ubApi.value + '/indicadores/imgs/est_pob_piramide_1_nal_small.png',
        image_est_pob_piramide_2_nal: ubApi.value + '/indicadores/imgs/est_pob_piramide_2_nal_small.png',
        
        url_cuadro_pob_tam_localidad: ubApi.value + '/indicadores/estadistica/cuadro_pob_tam_localidad.htm',

        btn_grafica: icons.value + "/graph.png",
        btn_table: icons.value + "/table.png",
        url_ind_basicos: '',
        idSeccionaDesplegar:1,      //Graficas
        
        idGrafica: 0,
    };
  },
  props:{
    param_opc:String,
  },
  computed: {
    parametroid() {
        return this.param_opc;
    },
  },
  methods: {
        async SelecGraficaBoton(numGrafBtn) {
            this.SeleccionaGrafica(numGrafBtn)
        }, 

        SeleccionaGrafica(numGrafica) {
             this.idGrafica = numGrafica
            if (this.idGrafica === 1){         
                this.url_ind_basicos = ubApi.value + '/indicadores/estadistica/est_pob_tam_localidad.htm'
            }  
        },
        async SeccionaDesplegar(valor ) {
            this.idSeccionaDesplegar = valor;
        },
        cargaInicial() {
            this.SeleccionaGrafica(this.parametroid)    
        },

   },   //de methods
  async mounted() {
        this.cargaInicial()
  },
  async beforeUpdate() {
        //  this.cargaInicial()
  },
  
};
</script>
<style scoped>
#container{
    color: red;
    margin-top: 50px;
}
</style>
