<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el documento descargable? </h5>
            <h6> {{ datosDescargable }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getDescargablesById from '@/helpers/getDescargablesById'
import charsService from '@/services/CharsService'

export default {
    data() {
        return { datosDescargable: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'descargables', charsService.decode(this.$route.params.id), jsonData, false )
            this.$router.push('../');
        },
        async getDescargable() {
            const dataDescargable = await getDescargablesById( charsService.decode(this.$route.params.id) );

            const cadena = `${ dataDescargable.publicacion_titulo },  ${ dataDescargable.publicacion_autor },  ${ dataDescargable.publicacion_fecha }`;

            return cadena
        }
    },
    async mounted() {
        this.datosDescargable = await this.getDescargable();
        // this.getDescargable()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>