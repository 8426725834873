/* Regresa todos los id de estadisticas de acuerdo al grupo de indicadores seleccionado */

import simejuvApi from '../api/simejuvApi'

// Se nombra la funcion
const getEstadist_indicadoresbyTipo = async( id ) => {
    const promiseArr = [
        // Se modifica de acuerdo a la búsqueda en la tabla
        simejuvApi.get(`/estadist_indicadores?transform=1&filter=idtipo_indicador,eq,${id}`)
    ]

    // Cambiar el nombre de la variable contra los datos a mostrar
    const [ estadistIndicador ] = await Promise.all( promiseArr )

    // Cambiar la primer variable a como se definió arriba
    // Cambiar la ultima variable a como se llama la tabla en la base de datos
    return estadistIndicador.data.estadist_indicadores
}

// Se exporta con el mismo nombre de la funcion
export default getEstadist_indicadoresbyTipo