<template>
    <div class="mainDiv">
        <Navbar />
        <Carousel />
        <div class="container mt-5 mb-5 text-left">
            <Bienvenida />
        </div>
        <div class="home container mt-5 mb-5">
            <Cards />
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import Carousel from '@/components/Home/Carousel'
import Bienvenida from '@/components/Home/Bienvenida'
import Cards from '@/components/Home/Cards'

export default {
  components: {
    Navbar,
    Carousel,
    Bienvenida,
    Cards
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>