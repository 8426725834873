/* Regresa todos los registros de la vista 'accion_puntual' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAllAccions_puntual = async() => {
    const promiseArr = [
        simejuvApi.get(`/accion_puntual?transform=1&filter=borrar,lt,1`)
    ]

    const [ Accionpuntual ] = await Promise.all( promiseArr )

    return [
        { accions: Accionpuntual.data.accion_puntual }
    ]
}

export default getAllAccions_puntual