<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/projuventud">PROJUVENTUD 2021-2024</a></li>
            </ol>
            <h2>PROJUVENTUD 2021-2024</h2>
            <hr class="red">
            <!-- <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" :class="{hide1:detalle1||detalle2}"> -->
            <ul class="nav nav-pills">
                <li role="presentation" :class="{ activeNav:queEs }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:queEs }" :to="{ params: {mod: 'quees', title: 'Qué es' } }">Qué es PROJUVENTUD</router-link></li>
                <li role="presentation" :class="{ activeNav:metas }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:metas }" :to="{ params: {mod: 'metas', title: 'Metas' } }">Metas para el bienestar y parámetros</router-link></li>
                <li role="presentation" :class="{ activeNav:acciones }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:acciones }" :to="{ params: {mod: 'acciones', title: 'Acciones' } }">Acciones puntuales</router-link></li>
                <li role="presentation" :class="{ activeNav:informes }"><router-link class="flex-sm-fill text-center nav-link" :class="{ active:informes }" :to="{ params: {mod: 'informes', title: 'Informes' } }">Informes</router-link></li>
            </ul>
            <div class="panel panel-default">
                <div class="panel-body">
                    <QueEs v-if="queEs" />
                    <Metas v-if="metas" />
                    <Acciones v-if="acciones" />
                    <Informes v-if="informes" />
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import QueEs from '@/components/Projuventud/QueEs';
import Metas from '@/components/Projuventud/Metas/Metas';
import Acciones from '@/components/Projuventud/Acciones/Acciones';
import Informes from '@/components/Projuventud/Informes';

export default {
    components: {
        Navbar,
        QueEs,
        Metas,
        Acciones,
        Informes
    },
    data() {
        return {
            queEs: false,
            metas: false,
            acciones: false,
            informes: false
        }
    },
    methods: {
        resetAll() {
            this.queEs = false
            this.metas = false
            this.acciones = false
            this.informes = false
        },
        activeTab() {
            if ( this.$route.params.mod === 'quees' ) {
                this.resetAll()
                this.queEs = true
            } else if ( this.$route.params.mod === 'metas' ) {
                this.resetAll()
                this.metas = true
            } else if ( this.$route.params.mod === 'acciones' ) {
                this.resetAll()
                this.acciones = true
            } else if ( this.$route.params.mod === 'informes' ) {
                this.resetAll()
                this.informes = true
            }
        }
    },
    mounted() {
        this.activeTab()
        window.scrollTo(0, 0)
    },
    beforeUpdate() {
        this.activeTab()
    }
}
</script>

<style scoped>
    .nav>li, .nav>li>a {
        position: inherit;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #9D2449;
    }

    a.nav-link {
        text-decoration: none;
        background-color: #98989A;
        border-color: #98989A;
        color: #FFF;
    }

    a.nav-link:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .nav>li>a:focus, .nav>li>a:hover {
        text-decoration: none;
        background-color: #6F7271;
        border-color: #6F7271;
        color: #FFF;
    }

    .activeNav {
        background-color: #9D2449;
    }
</style>