/* Función que regresa el nombre corto del id enviado de la tabla 'cat_objetivos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getObjetivoNC = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_objetivos/${ id }?transform=1&columns=id,nom_corto_objetivo`)
    ]

    const [ dep ] = await Promise.all( promiseArr )

    return dep.data.nom_corto_objetivo
}

export default getObjetivoNC