/* Regresa todos los objetivos prioritarios de la tabla 'cat_objetivos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getObjetivos = async() => {
    const promiseArr = [
        simejuvApi.get(`/cat_objetivos?transform=1&filter=borrar,lt,1`)
    ]

    const [ objetivos ] = await Promise.all( promiseArr )

    return objetivos.data.cat_objetivos
}

export default getObjetivos