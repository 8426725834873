/* Regresa id y nombre de todos los registros de la tabla 'cat_estados' en la base de datos */

import simejuvApi from '../api/simejuvApi'

/* 
* @param incTodas: false: no regresa NO-SECTORIZADA y TODAS (default)
*                  true: regresa todos los registros
*/
const getEstadosNc = async( incTodas = false ) => {
    let filter = ''

    if ( !incTodas ){
        filter = '&filter=id,lt,100'
    }

    const promiseArr = [
        simejuvApi.get(`/cat_estado?transform=1&columns=id,nom_estado${ filter }`)
    ]

    const [ edos ] = await Promise.all( promiseArr )

    return edos.data.cat_estado
}

export default getEstadosNc