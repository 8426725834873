/* Regresa todos los datos de la tabla 'interv_param' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getInterv_paramById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/interv_param/${ id }`)
    ]

    const [ interv_param ] = await Promise.all( promiseArr )

    return interv_param.data
}

export default getInterv_paramById