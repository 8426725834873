<template>
    
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <el-card class="box-card" style = "backgroundColor: #FBFCFC" shadow="hover">
                    <template #header>
                        <div class="card-header" >
                            <span><strong><p class="card-text" v-html="this.estadist_introduc_tit"></p></strong></span>
                        </div>
                    </template>


                    <div class="row">
                            <div class="article-body" >
                                <p class="card-text" v-html="this.estadist_introduccion"></p>
                            </div>
                    </div>

                </el-card>
            </form>
        </div>
    </div>
    

    
</template>

<script>

import getEstadist_introduccionById from '@/helpers/getEstadist_introduccionById'


export default {
    data() {
        return {
            id: 0,
            estadist_introduc_tit: '',
            estadist_introduccion: '',
        }
    },
    props:{
        msg: String,
        param_opc:String,
    },
    computed: {
        parametroid() {
            return this.param_opc;
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        async setData( ) {
            this.id = this.parametroid //this.parametro_pasado2;
            const datos = await getEstadist_introduccionById( this.id )
            this.estadist_introduccion = datos.estadist_introduccion;
            this.estadist_introduc_tit = datos.estadist_introduc_tit;
        },

    },
    async mounted() {
        await this.setData()
       // window.scrollTo(0, 0)
    },
    beforeUpdate() {
        this.setData()
    }
}
</script>

<style scoped>

</style>