<template>
    <div class="mainDiv">
        <Navbar :user="true" />
        <div class="mt-5 container principal text-left mb-0">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/adminSec">Administración de la plataforma</a></li>
            </ol>
        </div>
        <div class="container text-center mb-5">
            <div class="row rowCenter">
                <h2 class="name">Bienvenid@ {{ localUser }}</h2>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <router-link class="btn btn-circle btn-xl" :to="`/user/usrAdmin/edit/${ localIdEnc }`"><i class="icon-user iconUser"></i></router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-right center-resp">
                    <router-link class="btn btn-default btn-sm mt-3 width-resp" :to="`/user/usrAdmin/edit/${ localIdEnc }`">Actualizar información</router-link>
                </div>
                <div class="col-md-6 text-left center-resp">
                    <router-link class="btn btn-default btn-sm mt-3 width-resp" :to="`/user/usrAdmin/psw/${ localIdEnc }`">Cambiar contraseña</router-link>
                </div>
            </div>
            <hr class="red mt-5" />
            <div class="row">
                <div class="col-12">
                    <div class="card bg-light mb-3 card-primary">
                        
                        
                        <!--TIPO 1 Administrador del Sistema -->
                        <div class="card-header"><h5>Menú de administración de la plataforma</h5></div>
                        <div class="card-body" v-if="localTipo === 1">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="icon-user menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Administración de usuarios</h5>
                                                    <router-link to="/user/usrAdmin/add" class="btn btn-sm form-control">Crear nuevo usuario</router-link>
                                                    <router-link to="/user/usrAdmin" class="btn btn-sm form-control">Administrar usuarios</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Seguimiento del PROJUVENTUD <br>2021-2024</h5>
                                                    <router-link to="/projuventud/acciones" class="btn btn-sm form-control mb-4">Registro de avances de <br>acciones puntuales</router-link>
                                                    <router-link to="/accion_objetivo_av/accion_objetivo_avAdmin" class="btn btn-sm form-control mb-4">Registro de avances de <br>acciones puntuales a nivel de objetivo</router-link>
                                                    <router-link to="/projuventud/metas" class="btn btn-sm form-control mb-4">Registro de avances de metas <br>para el bienestar y parámetros</router-link>
                                                    <router-link to="/informe/informeAdmin" class="btn btn-sm form-control mb-2">Registro de informes del PROJUVENTUD</router-link>    

                                                    <h5>Presupuesto con perspectiva de juventud (IIPJ)</h5>
                                                    
                                                    <router-link :to=rl_ppj class="btn btn-sm form-control">Mantenimiento de parámetros del presupuesto<br>con perspectiva de juventud</router-link>
                                                    <a class="sobre" :href="`/ubApi/exp_perspectiva_juventud.php`" >Exportación del presupuesto con perspectiva de juventud </a>
                                                    


                                                    <h5>Catálogo de oferta de servicios</h5>
                                                    <router-link :to=rl_int class="btn btn-sm form-control mb-4">Mantenimiento de parámetros del catálogo de<br> oferta para las y los jóvenes</router-link>
                                                    <router-link to="/intervenciones/intervencionesAdmin" class="btn btn-sm form-control mb-4">Autorización de ofertas nacionales <br> para las y los jóvenes</router-link>
                                                    <router-link to="/oportunidades_serv/oportunidades_servAdmin" class="btn btn-sm form-control mb-4">Autorización de oportunidades<br> y servicios estatales</router-link>
                                                    <router-link to="/intervenciones/intervencionesOrder" class="btn btn-sm form-control mb-4">Orden de despliegue del catálogo de<br>oferta para las y los jóvenes</router-link>
                                                    <router-link to="/ubApi/exp_intervenciones.php" target="_blank" class="btn btn-sm form-control mb-4">Exportación del catálogo de<br>oferta para las y los jóvenes</router-link>

                                                    <h5>Estadísticas en Juventud</h5>
                                                    <router-link to="/estadisticas_int/estadisticas_intAdmin" class="btn btn-sm form-control">Mantenieminto de la introducción<br> de estadídticas en juventud</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Mantenimiento de PROJUVENTUD <br>2021-2024</h5>
                                                    <router-link :to=rl_pg class="btn btn-sm form-control mb-4">Parámetros generales para el registro de <br>avances de acciones puntuales</router-link>
                                                    <router-link to="/accion_puntual/accion_puntualAdmin" class="btn btn-sm form-control mb-4">Mantenimiento de<br>acciones puntuales</router-link>
                                                    <router-link to="/meta_ficha/meta_fichaAdmin" class="btn btn-sm form-control mb-4">Mantenimiento de metas <br>para el bienestar y parámetros</router-link>
                                                    <router-link to="/compromiso_dep/compromiso_depAdmin" class="btn btn-sm form-control mb-4">Mantenimiento de Compromisos <br>de las Dependencias</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-dashboard menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Administración del contenido de la plataforma</h5>
                                                    <router-link :to=rl_pp class="btn btn-sm form-control">Página principal</router-link>
                                                    <router-link :to=rl_qep class="btn btn-sm form-control">Qué es PROJUVENTUD</router-link>
                                                    <router-link to="/accion_sectorial/accion_sectorialAdmin" class="btn btn-sm form-control">Mantenimiento de programas sectoriales</router-link>
                                                    <!-- <router-link :to=rl_ppj class="btn btn-sm form-control">Presupuesto con perspectiva de juventud</router-link> /> -->
                                                    <router-link to="/descargable/descargableAdmin" class="btn btn-sm form-control">Registro de documentos descargables</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-list-alt menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Administración de catálogos</h5>
                                                    <router-link to="/anio/anioAdmin" class="btn btn-sm form-control">Años</router-link>
                                                    <router-link to="/clase_ind/clase_indAdmin" class="btn btn-sm form-control">Clases de indicador</router-link>
                                                    <router-link to="/dependencia/dependenciaAdmin" class="btn btn-sm form-control">Dependencias</router-link>
                                                    <router-link to="/dependencia_op/dependencia_opAdmin" class="btn btn-sm form-control">Dependencias Estatales</router-link>
                                                    <router-link to="/dimension_indicador/dimension_indicadorAdmin/" class="btn btn-sm form-control">Dimensión del indicador</router-link>
                                                    <router-link to="/estado/estadoAdmin" class="btn btn-sm form-control">Estados</router-link>
                                                    <router-link to="/estrategia/estrategiaAdmin" class="btn btn-sm form-control">Estrategias</router-link>
                                                    <router-link to="/formula/formulaAdmin" class="btn btn-sm form-control">Fórmulas</router-link>
                                                    <router-link to="/mes/mesAdmin" class="btn btn-sm form-control">Meses</router-link>
                                                    <router-link to="/nivel/nivelAdmin" class="btn btn-sm form-control">Niveles de desagregación</router-link>
                                                    <router-link to="/objetivo/objetivoAdmin" class="btn btn-sm form-control">Objetivos</router-link>
                                                    <router-link to="/periodicidad/periodicidadAdmin" class="btn btn-sm form-control">Periodos de cálculo para indicadores</router-link>
                                                    <router-link to="/periodo_recoleccion/periodo_recoleccionAdmin" class="btn btn-sm form-control">Periodos de recolección</router-link>
                                                    <router-link to="/sector_op/Sector_opAdmin" class="btn btn-sm form-control">Sectores de las Oportunidades y Servicios Estatales</router-link>
                                                    <router-link to="/tendencia_indicador/tendencia_indicadorAdmin" class="btn btn-sm form-control">Tendencias del indicador</router-link>
                                                    <router-link to="/tipo_ap/tipo_apAdmin" class="btn btn-sm form-control">Tipos de acción puntual</router-link>
                                                    <router-link to="/tipo_calculo/tipo_calculoAdmin" class="btn btn-sm form-control">Tipos de cálculo</router-link>
                                                    <router-link to="/tipo_indicador/tipo_indicadorAdmin" class="btn btn-sm form-control">Tipos de indicador</router-link>
                                                    <router-link to="/unidad_medida/unidad_medidaAdmin" class="btn btn-sm form-control">Unidades de medida</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                         <!--TIPO 3 Enlace de la Administración Pública Federal (APF) -->
                        <div class="card-body" v-if="localTipo === 3">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Mantenimiento de Projuventud <br>2021-2024</h5>
                                                    <router-link to="/projuventud/acciones" class="btn btn-sm form-control mb-4">Registro de avances de acciones puntuales</router-link>
                                                    <router-link to="/accion_objetivo_av/accion_objetivo_avAdmin" class="btn btn-sm form-control mb-4">Registro de avances de acciones puntuales a nivel de objetivo</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!--TIPO 4 Administrador PROJUVENTUD -->
                        
                        <div class="card-body" v-if="localTipo === 4">
                            <div class="card-header"><h5>Administrador PROJUVENTUD</h5></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="icon-user menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Administración de usuarios</h5>
                                                    <router-link to="/user/usrAdmin/add" class="btn btn-sm form-control">Crear nuevo usuario APF</router-link>
                                                    <router-link to="/user/usrAdmin" class="btn btn-sm form-control">Administrar usuarios de la APF</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Seguimiento del PROJUVENTUD <br>2021-2024</h5>
                                                    <router-link to="/projuventud/acciones" class="btn btn-sm form-control mb-4">Registro de avances de <br>acciones puntuales</router-link>
                                                    <router-link to="/accion_objetivo_av/accion_objetivo_avAdmin" class="btn btn-sm form-control mb-4">Registro de avances de <br>acciones puntuales a nivel de objetivo</router-link>
                                                    <router-link to="/projuventud/metas" class="btn btn-sm form-control mb-4">Registro de avances de metas <br>para el bienestar y parámetros</router-link>
                                                    <router-link to="/informe/informeAdmin" class="btn btn-sm form-control mb-2">Registro de informes del PROJUVENTUD</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Mantenimiento del PROJUVENTUD <br>2021-2024</h5>
                                                    <router-link :to=rl_pg class="btn btn-sm form-control mb-4">Parámetros generales para el registro de <br>avances de acciones puntuales</router-link>
                                                    <router-link to="/accion_puntual/accion_puntualAdmin" class="btn btn-sm form-control mb-4">Mantenimiento de<br>acciones puntuales</router-link>
                                                    <router-link to="/meta_ficha/meta_fichaAdmin" class="btn btn-sm form-control mb-4">Mantenimiento de metas <br>para el bienestar y parámetros</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-dashboard menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Administración del contenido de la plataforma</h5>
                                                    <router-link :to=rl_qep class="btn btn-sm form-control">Qué es PROJUVENTUD</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-list-alt menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Administración de catálogos de PROJUVENTUD</h5>
                                                    <router-link to="/anio/anioAdmin" class="btn btn-sm form-control">Años</router-link>
                                                    <router-link to="/clase_ind/clase_indAdmin" class="btn btn-sm form-control">Clases de indicador</router-link>
                                                    <router-link to="/dependencia/dependenciaAdmin" class="btn btn-sm form-control">Dependencias</router-link>
                                                    <router-link to="/dimension_indicador/dimension_indicadorAdmin/" class="btn btn-sm form-control">Dimensión del indicador</router-link>
                                                    <router-link to="/estado/estadoAdmin" class="btn btn-sm form-control">Estados</router-link>
                                                    <router-link to="/estrategia/estrategiaAdmin" class="btn btn-sm form-control">Estrategias</router-link>
                                                    <router-link to="/formula/formulaAdmin" class="btn btn-sm form-control">Fórmulas</router-link>
                                                    <router-link to="/mes/mesAdmin" class="btn btn-sm form-control">Meses</router-link>
                                                    <router-link to="/nivel/nivelAdmin" class="btn btn-sm form-control">Niveles de desagregación</router-link>
                                                    <router-link to="/objetivo/objetivoAdmin" class="btn btn-sm form-control">Objetivos</router-link>
                                                    <router-link to="/periodicidad/periodicidadAdmin" class="btn btn-sm form-control">Periodos de cálculo para indicadores</router-link>
                                                    <router-link to="/periodo_recoleccion/periodo_recoleccionAdmin" class="btn btn-sm form-control">Periodos de recolección</router-link>
                                                    <router-link to="/tendencia_indicador/tendencia_indicadorAdmin" class="btn btn-sm form-control">Tendencias del indicador</router-link>
                                                    <router-link to="/tipo_ap/tipo_apAdmin" class="btn btn-sm form-control">Tipos de acción puntual</router-link>
                                                    <router-link to="/tipo_calculo/tipo_calculoAdmin" class="btn btn-sm form-control">Tipos de cálculo</router-link>
                                                    <router-link to="/tipo_indicador/tipo_indicadorAdmin" class="btn btn-sm form-control">Tipos de indicador</router-link>
                                                    <router-link to="/unidad_medida/unidad_medidaAdmin" class="btn btn-sm form-control">Unidades de medida</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        

                         <!--TIPO 5 Administrador de Programas sectoriales -->
                         <div class="card-body" v-if="localTipo === 5">
                            <div class="card-header"><h5>Administrador de Programas Sectoriales</h5></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Seguimiento de Programas Sectoriales</h5>
                                                    <router-link to="/sectoriales" class="btn btn-sm form-control mb-4">Programas Sectoriales</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Mantenimiento de Programas Sectoriales</h5>
                                                    <router-link to="/accion_sectorial/accion_sectorialAdmin" class="btn btn-sm form-control">Mantenimiento de programas sectoriales</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        

                        <!--TIPO 6 Administrador de atálogo de oferta para las y los jóvenes y Calculadora IIPJ -->
                        <div class="card-body" v-if="localTipo === 6" >
                            <div class="card-header"><h5>Administrador del Catálogo de oferta para las y los jóvenes y Calculadora IIPJ</h5></div>
                            <div class="card-header"><h5>Presupuesto con Perspectiva de Juventud (IIPJ)</h5></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Seguimiento del Presupuesto con Perspectiva de Juventud (IIPJ)</h5>
                                                    <router-link to="/presup_juventud" class="btn btn-sm form-control mb-4">Presupuesto con Perspectiva de Juventud (IIPJ)</router-link>
                                                
                                                    <h5>Mantenimiento del Presupuesto con Perspectiva de Juventud (IIPJ)</h5>
                                                    <router-link :to=rl_ppj class="btn btn-sm form-control">Mantenimiento de parámetros del presupuesto<br>con perspectiva de juventud</router-link>
                                                    <a class="sobre" :href="`/ubApi/exp_perspectiva_juventud.php`" >Exportación del presupuesto con perspectiva de juventud </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="card-header"><h5>Catálogo de oferta para las y los jóvenes</h5></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Seguimiento del catálogo de oferta nacional de servicios</h5>
                                                    <router-link to="/intervenciones/ver" class="btn btn-sm form-control mb-4">Catálogo de oferta para las y los jóvenes</router-link>
                                                    
                                                    <h5>Mantenimiento del catálogo de oferta nacional de servicios</h5>
                                                    <router-link :to=rl_int class="btn btn-sm form-control mb-4">Mantenimiento de parámetros del catálogo de<br> oferta para las y los jóvenes</router-link>
                                                    <router-link to="/intervenciones/intervencionesAdmin" class="btn btn-sm form-control mb-4">Autorización de ofertas nacionales <br> para las y los jóvenes</router-link>
                                                    <router-link to="/intervenciones/intervencionesOrder" class="btn btn-sm form-control mb-4">Orden de despliegue del catálogo de<br>oferta para las y los jóvenes</router-link>
                                                    <router-link to="/ubApi/exp_intervenciones.php" target="_blank" class="btn btn-sm form-control mb-4">Exportación del catálogo de<br>oferta para las y los jóvenes</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        

                        <!--TIPO 7 Administrador de Participación Ciudadana -->
                        <div class="card-body" v-if="localTipo === 7">
                            <div class="card-header"><h5>Administrador de Participación Ciudadana</h5></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Seguimiento</h5>
                                                    <router-link to="/aten_ciudadana" class="btn btn-sm form-control mb-4">Participación Ciudadana</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <!--TIPO 8 Administrador de Documentos Descargables -->
                        <div class="card-body" v-if="localTipo === 8">
                            <div class="card-header"><h5>Administrador de Documentos Descargables</h5></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Seguimiento</h5>
                                                    <router-link to="/doctos" class="btn btn-sm form-control mb-4">Documentos descargables</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Mantenimiento</h5>
                                                    <router-link to="/descargable/descargableAdmin" class="btn btn-sm form-control">Mantenimiento de documentos descargables</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>

                        
                        <!--TIPO 9 Administrador de Ofertas Estatales -->
                        <div class="card-body" v-if="localTipo === 9" >
                            <div class="card-header"><h5>Administrador de ofertas estatales para las y los jovenes</h5></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Catálogo de Oportunidades y Servicios Estatales para las y los Jóvenes</h5>
                                                    <router-link to="/oportunidades_serv/oportunidades_servAdmin" class="btn btn-sm form-control mb-4">Autorización de oportunidades<br> y servicios estatales</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-th menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5>Administración de catálogos</h5>
                                                    <router-link to="/dependencia_op/dependencia_opAdmin" class="btn btn-sm form-control">Dependencias Estatales</router-link>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>

                        
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import validateTkn from '@/api/validateToken'
import charsService from '@/services/CharsService';

export default {
    components: {
        Navbar
    },
    data() {
        return {
            localUser: charsService.decode(localStorage.getItem('nombre'), 'string'),
            localId: charsService.decode(localStorage.getItem('id')),
            localIdEnc: localStorage.getItem('id'),
            localTipo: parseInt(charsService.decode(localStorage.getItem('tipo_usuario'))),
            rl_pp: "/bienvenida/bienvenidaAdmin/edit/" + charsService.encode(1),
            rl_qep: "/projuventud/projuventudAdmin/edit/" + charsService.encode(1),
            rl_ppj: "/presupuesto/presupuestoAdmin/edit/" + charsService.encode(1), 
            rl_int: "/intervenciones/intervencionesAdm/edit/" + charsService.encode(1),           
            rl_pg: "/param_gen/param_genAdmin/edit/" + charsService.encode(1)  
        }
    },
    methods: {
        validation() {
            validateTkn()
        }
    },
    beforeMount() {
        this.validation()
    },
    async mounted() {
        if (!this.localId)
            this.$router.push('/login')
        this.$gtag.event('Sección administrativa');    //Registra el Evento en Google Analytics con nombre
    },
}
</script>

<style>
    .name {
        color: #235B4E;
    }
    .rowCenter {
        display: block;
    }
    .iconUser {
        color: #6F7271;
        font-size: 70px;
    }
    .btn-circle.btn-xl {
        width: 100px;
        height: 100px;
        padding: 10px 16px;
        border-radius: 80px;
        border: 3px solid #6F7271;
        font-size: 24px;
        line-height: 1.33;
    }

    .row .no-gutters {
        margin: 0;
    }
    .card-primary {
        min-width: 330px;
    }

    .menuIcon {
        color: #BC955C;
        font-size: 70px;
    }

    .mb-3 {
        margin-bottom: 15px;
    }


    a.sobre {
        color: #8B8B8C;
        font-size: 15px;
        text-decoration: none;
    }


</style>