/* Regresa todos los datos de los periodos con el id enviado de la tabla 'cat_periodo_recoleccion' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getPeriodos_recoleccionById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_periodo_recoleccion/${ id }`)
    ]

    const [ cat_periodo_recoleccion ] = await Promise.all( promiseArr )

    return cat_periodo_recoleccion.data
}

export default getPeriodos_recoleccionById