/* Regresa todos los datos de la periodicidad con el id enviado de la tabla 'cat_periodicidad' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getPeriodicidadesById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_periodicidad/${ id }`)
    ]

    const [ cat_periodicidad ] = await Promise.all( promiseArr )

    return cat_periodicidad.data
}

export default getPeriodicidadesById