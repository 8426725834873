<template>
    <div class="row">
        <div v-show=" this.usrThis  == 1">
            <div class="col-12 text-right">
                <!-- <button class="btn btn-primary btn-sm active" @click="downloadPdf">Descargar reporte</button> -->
                <a :href="`/ubApi/avanceMetasRep.php?dep=${ depNombre }&tipousuario=${ tipoUsr }&acciones=${accionesUsr}`" class="btn btn-primary btn-sm active">Descarga de acciones puntuales </a> <a ></a> 
                <a :href="`/ubApi/avanceAccionesRep.php?dep=${ depNombre }&tipousuario=${ tipoUsr }&acciones=${accionesUsr}`" class="btn btn-primary btn-sm active">Reporte de avances </a>
            </div>
        </div> <!--De v-show -->
        <div v-show=" this.usrThis  == 3">
            <div class="col-12 text-right">
                <!-- <button class="btn btn-primary btn-sm active" @click="downloadPdf">Descargar reporte</button> -->
                <a :href="`/ubApi/accion_puntual_x_apf.php?dep=${ depNombre }&tipousuario=${ tipoUsr }&acciones=${accionesUsr}`" class="btn btn-primary btn-sm active">Formato de avance </a> <a ></a>
                <a :href="`/ubApi/avanceAccionesRep.php?dep=${ depNombre }&tipousuario=${ tipoUsr }&acciones=${accionesUsr}`" class="btn btn-primary btn-sm active">Reporte de avances </a>
            </div>
        </div> <!--De v-show -->
    </div>
    <h3>Reportes y consultas de Acciones Puntuales</h3>
    <hr>
    <div class="row">
        <div class="col-lg-3 busqueda">
            <!-- <div class="row">
                <input type="text" class="form-control" placeholder="Buscar" aria-label="Buscar" aria-describedby="basic-addon1">
            </div> -->
            <div class="row mt-4">
                <div class="card w-100">
                    <div class="card-body">
                        <select name="b_obj0" id="b_obj0" class="form-control mt-2" @change="f_b_obj0">
                            <option selected value="" class="color-grey">Año de ejercicio</option>
                            <option v-for="p in arrAnios" :key="p.id" :value="p.id"  class="color-grey">{{ p.desc }}</option>
                        </select>
                        <select name="b_obj1" id="b_obj1" class="form-control mt-2" v-model="b_obj1">
                            <option selected value="" class="color-grey">Todos los objetivos</option>
                            <option value="op1" class="color-grey">Objetivo Prioritario 1</option>
                            <option value="op2" class="color-grey">Objetivo Prioritario 2</option>
                            <option value="op3" class="color-grey">Objetivo Prioritario 3</option>
                            <option value="op4" class="color-grey">Objetivo Prioritario 4</option>
                            <option value="op5" class="color-grey">Objetivo Prioritario 5</option>
                            <option value="op6" class="color-grey">Objetivo Prioritario 6</option>
                        </select>
                        <select name="b_obj2" id="b_obj2" class="form-control mt-2" v-model="b_obj2" @change="f_b_obj2">
                            <option selected value="" class="color-grey">Tipo de Acción Puntual</option>
                            <option v-for="z in arrTipoAp" :key="z" :value="z.id" class="color-grey">{{ z.desc }}</option>
                        </select>
                        <select name="b_obj3" id="b_obj3" class="form-control mt-2" @change="f_b_obj3">
                            <option selected value="" class="color-grey">Entidad coordinadora</option>
                            <option v-for="y in arrDependenciasObj" :key="y.id" :value="y.id"  class="color-grey">{{ y.desc }}</option>
                        </select>
                        <select name="b_obj4" id="b_obj4" class="form-control mt-2" @change="f_b_obj4">
                            <option selected value="" class="color-grey">Entidad responsable</option>
                            <option v-for="x in arrDependenciasObj" :key="x.id" :value="x.id"  class="color-grey">{{ x.desc }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <!-- Panel derecho (resultados) -->
        <div class="col-lg-9" ref="toPdf">
            <!-- Se pintan los objetivos -->
            <div class="callout callout-default" v-for="e in arrObjetivos" :key="e" v-show="(!b_obj1 || b_obj1 === `op${e.id}`)">
                <div><span class="objetivoTitle objetivo">Objetivo prioritario {{ e.id }}: </span><span class="objetivo">{{ e.desc }}</span></div>

                <!-- Se pintan las estrategias -->
                <div v-for="f in arrEstrategias" :key="f" class="mt-3">
                    <div v-if="f.idObj === e.id">
                        <span class="estrategiasTitle estrategias">Estrategia prioritaria {{ f.noEst }}: </span><span class="estrategias">{{ f.desc }}</span>
                        
                        <!-- Se pintan las acciones -->
                        <div v-for="g in arrAcciones" :key="g" class="mt-3" :data-id="g.idAccion">
                            <div class="card" v-if="g.idEstr === f.id">
                                <div class="card-header">
                                    <!-- Se pinta el detalle de cada acción -->
                                    <strong>Acción puntual {{ g.codAccion }}: </strong>{{ g.nomAccion }}

                                    <div class="card-body">
                                        <p><span class="apTitle">Dependencia o Entidad coordinadora:</span> <span class="depenSep">{{ arrCoordinadoras[g.idAccion] }}</span></p>
                                        <p><span class="apTitle">Dependencias y/o Entidades responsables: </span> <span class="depenSep">{{ arrResponsables[g.idAccion] }}</span></p>

                                        <p><span class="apTitle">Compromisos de las dependencias: </span> <span v-for="h in arrCompromisos[g.idAccion]" :key="h"><a :href="`${ ubApi }/download.php?tmpFileName=${ h.tmp_name }&fileName=${ h.file_name }`" target="_blank" title="Descargar"> {{ h.file_desc}} </a>&nbsp;</span></p>	


                                        <p><span class="apTitle">Cantidad de jóvenes a impactar: </span> <span v-if="g.jovenesNd"> Dato no disponible </span> <span v-else>{{ g.jovenes }}</span></p>
                                        <p><span class="apTitle">Periodo de cumplimiento: </span> <span v-if="g.cumpFechaI || g.cumpFechaT">{{ g.cumpFechaI }} - {{ g.cumpFechaT }}</span></p>
                                        <p><strong>Reporte de avance:</strong></p>

                                        <!-- Se pinta la tabla de los avances -->
                                        <table class="table table-hover">
                                            <thead class="table-secondary">
                                                <tr>
                                                    <th rowspan="2"></th>
                                                    <th rowspan="2">Descripción del avance</th>
                                                    <th rowspan="2">Entidad responsable</th>
                                                    <th colspan="2">Periodo que cubre el reporte</th>
                                                    <th rowspan="2">Cantidad de jóvenes impactados</th>
                                                </tr>
                                                <tr>
                                                    <th>Inicio</th>
                                                    <th>Fin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="h in arrAvanceAcc[g.idAccion]" :key="h">
                                                    <td v-if="(usrThis === 1 || usrThis === 2 || ( usrThis === 3 && usrDep === h.id_dependencia )) && (MxTime_anio(h.fecha_termino) > '2022' ) ">
                                                        <router-link :to="`/avance/acciones/edit/${h.id}`" class="icoLink"><i class="glyphicon glyphicon-pencil"></i></router-link>
                                                    </td>
                                                    <td v-else></td>
                                                   
                                                    <td>{{ h.avance_resumen }}</td>
                                                    <td>{{ arrDependencias[h.id_dependencia] }}</td>
                                                    <td>{{ MxTime(h.fecha_inicio) }}</td>
                                                    <td>{{ MxTime(h.fecha_termino) }}</td>
                                                    <td class="text-right">{{ h.cantidad_jovenes_imp }}</td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                        
                                        <!-- Se pinta la gráfica -->
                                        <div class="row ml-3 mr-3" v-if="arrAvance[ g.idAccion ]">
                                            <BarCharts :chartData="{
                                                datasets: [
                                                    { label: 'Meta', backgroundColor: '#8E7146', data: [ g.jovenes ], barThickness: 200, skipNull: true },
                                                    { label: 'Avance', backgroundColor: '#545656', data: [ arrTotalJovenes[g.idAccion] ], barThickness: 200, skipNull: true },
                                                    { label: '', backgroundColor: '#ffffff', data: [null], skipNull: true, hidden: true }
                                                ],
                                            }" :chartOptions="state.chartOptions" class="charts" @on-receive="update(this)" />
                                        </div>
                                        
                                        <!-- Se pinta el botón de reportar avance -->
                                        <div class="card-footer" v-if="usrThis === 1 || usrThis === 2 || ( arrAccionesAsignadas.length < 1 && usrThis === 3 && arrAccionesFilt.includes(g.idAccion)) || ( usrThis === 3 && arrAccionesAsignadas.length > 0 && arrAccionesAsignadas.includes(g.idAccion))">
                                            <router-link class="btn btn-primary active btnCond btn-sm" :to="`/avance/acciones/add/${ g.idAccionEnc }`">
                                                <i class="glyphicon glyphicon-check"></i> Registrar avance
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Importo estilos
import '../scss/callouts.scss'

import axios from 'axios'
import { ubApi } from '@/api/baseUrl'

// Importo los helpers
import getObjetivos from '@/helpers/getObjetivos'
import getEstrategias from '@/helpers/getEstrategias'
import getDependenciasNc from '@/helpers/getDependenciasNc'
import getCoordinadoras from '@/helpers/getCoordinadoras'
import getResponsables from '@/helpers/getResponsables'
import getAvanceAcciones from '@/helpers/getAvanceAcciones'
import getAccionesPuntuales from '@/helpers/getAccionesPuntuales'
import getCatAnios from '@/helpers/getCatAnios'
import getCatTipos_ap from '@/helpers/getCatTipos_ap'
import getAccionbyCoord from '@/helpers/getAccionbyCoord'
import getAccionbyResp from '@/helpers/getAccionbyResp'
import getAccesoByUser from '@/helpers/getAccesoByUser'
import moment from 'moment'
import BarCharts from '@/components/Charts/BarCharts'

import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import charsService from '@/services/CharsService';

export default {
    components: {
        BarCharts
    },
    data() {
        return {
            ubApi: ubApi.value,
            b_obj1: '',
            b_obj2: '',
            arrObjetivos: [],
            arrEstrategias: [],
            arrAcciones: [],
            arrAccionesFilt: [],
            arrDependencias: [],
            arrDependenciasObj: [],
            arrCoordinadoras: [],
            arrResponsables: [],
            arrCompromisos: [],
            arrAvanceAcc: {},
            arrAvance: [],
            arrAnios: [],
            arrTipoAp: [],
            arrTotalJovenes: [],
            arrAccionesCoord: [],
            state: {
                chartData: {},
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true,
                                fontSize: 14,
                                fontFamily: 'Montserrat'
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                autoSkip: false,
                                fontSize: 14,
                                fontFamily: 'Montserrat',
                            },
                        }],
                        x: {
                            grid: {
                                offset: false
                            }
                        }
                    },
                    legend: {
                        position: 'bottom',
                        labels: {
                            fontSize: 14,
                            fontFamily: 'Montserrat'
                        }
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            title: () => { return '' },
                        },
                        bodyFontSize: 16,
                        bodyFontFamily: 'Montserrat'
                    }
                }
            },
            arrAccionesAsignadas: [],
            depNombre: '',
            tipoUsr: '',
            accionesUsr: '',
            usrThis: charsService.decode(localStorage.getItem('tipo_usuario')),
            usrDep: charsService.decode(localStorage.getItem('dependencia')),
        }
    },
    methods: {
        async getAllAcciones() {
            const accionesArr = await getAccionesPuntuales()
            const arrShow = []
            let idObjetivoAnt = ''
            let idEstrategiaAnt = ''

            for (let i=0; i<accionesArr.length; i++) {
                const idObj = accionesArr[i].idobjetivo
                let idObjV = false
                const idEstr = accionesArr[i].idestrategiap
                let idEstrV = false
                const idAccion = accionesArr[i].id
                const idAccionEnc = charsService.encode(accionesArr[i].id)
                const codAccion = accionesArr[i].codigo_accion_puntual
                const nomAccion = accionesArr[i].nom_accion_puntual
                const tipoAccion = accionesArr[i].idtipo_ap
                const cumpFechaI = accionesArr[i].cump_fecha_inicio
                const cumpFechaT = accionesArr[i].cump_fecha_termino
                const jovenesNd = accionesArr[i].cantidad_jovenes_nd
                const jovenes = accionesArr[i].cantidad_jovenes
                
                // Se hace visible o no IdObjetivo
                if( idObj !== idObjetivoAnt ) {
                    idObjetivoAnt = idObj
                    idObjV = true
                }
                

                // Se hace visible o no IdEstrategiaP
                if( idEstr !== idEstrategiaAnt ) {
                    idEstrategiaAnt = idEstr
                    idEstrV = true
                }

                arrShow.push({
                    idAccion,
                    idAccionEnc,
                    idObj,
                    idObjV,
                    idEstr,
                    idEstrV,
                    codAccion,
                    nomAccion,
                    tipoAccion,
                    cumpFechaI,
                    cumpFechaT,
                    jovenesNd,
                    jovenes,
                })
            }

            return arrShow
        },

        async getDepName() {
            const depNo = charsService.decode(localStorage.getItem('dependencia'))
            this.depNombre = this.arrDependencias[ depNo ]

            this.tipoUsr = charsService.decode(localStorage.getItem('tipo_usuario'))

            // if ()
            let accionResp = [];
            if ( this.arrAccionesAsignadas.length < 1 )
                accionResp = await getAccionbyResp( charsService.decode( localStorage.getItem('dependencia') ) )
            else
                accionResp = this.arrAccionesAsignadas
            
            let accList = '';
            accionResp.forEach(accion => {
                if (accion.idaccion_puntual)
                    accList += accion.idaccion_puntual + ','
                else
                    accList += accion + ','
            })

            this.accionesUsr = accList.slice(0, -1)
        },

        async getAccesoData( id ) {
            const accesoData = await getAccesoByUser( id )
            const accionesUsr = accesoData[0].acciones_usuario_2022
            const arrAccionesUsr = (accionesUsr)? accionesUsr.split(',') : ''

            if (arrAccionesUsr !== '') {
                arrAccionesUsr.forEach(accion => {
                    this.arrAccionesAsignadas.push(parseInt( accion ))
                })
            }
        },

        async getCatalogos() {
            const consObjetivos = await getObjetivos()
            for (let h = 0; h < consObjetivos.length; h++) {
                this.arrObjetivos.push( { id: consObjetivos[h].id, desc: consObjetivos[h].nom_objetivo } )
            }

            const consEstrategias = await getEstrategias()
            for (let i = 0; i < consEstrategias.length; i++) {
                this.arrEstrategias.push( { id: consEstrategias[i].id, noEst: consEstrategias[i].num_estrategia, idObj: consEstrategias[i].idobjetivo, desc: consEstrategias[i].nom_estrategia } )
            }

            const consDeps = await getDependenciasNc( true )
            for (let j = 0; j < consDeps.length; j++) {
                this.arrDependencias[consDeps[j].id] = consDeps[j].nom_corto_dependencia
                this.arrDependenciasObj.push({ id: consDeps[j].id, desc: consDeps[j].nom_corto_dependencia })
            }

            const consCoordinadoras = await getCoordinadoras()
            for (let k = 0; k < consCoordinadoras.length; k++) {
                if (!this.arrCoordinadoras[consCoordinadoras[k].idaccion_puntual]) {
                    this.arrCoordinadoras[consCoordinadoras[k].idaccion_puntual] = this.arrDependencias[consCoordinadoras[k].iddependencia]
                } else {
                     this.arrCoordinadoras[consCoordinadoras[k].idaccion_puntual] += ', ' + this.arrDependencias[consCoordinadoras[k].iddependencia]
                }
            }

            const consResponsables = await getResponsables()
            for (let l = 0; l < consResponsables.length; l++) {
                if (!this.arrResponsables[consResponsables[l].idaccion_puntual]) {
                    this.arrResponsables[consResponsables[l].idaccion_puntual] = this.arrDependencias[consResponsables[l].iddependencia]
                } else {
                    this.arrResponsables[consResponsables[l].idaccion_puntual] += ', ' + this.arrDependencias[consResponsables[l].iddependencia] 
                }
            }
//Prueba
            const consCompromisos = await getResponsables()
            for (let n = 0; n < consCompromisos.length; n++ ) {
                const noAp = consCompromisos[n].idaccion_puntual
                if ( !this.arrCompromisos[noAp] )
                    this.arrCompromisos[ noAp ] = []
                
                    this.arrCompromisos[noAp].push({ 
                        id: charsService.encode( consCompromisos[n].id ),
                        idaccion_puntual: consCompromisos[n].idaccion_puntual,
                        tmp_name: charsService.encode(consCompromisos[n].tmp_name),
                        file_name: charsService.encode(consCompromisos[n].file_name),
                        file_desc: consCompromisos[n].file_desc,
                    })
            }


            //const consCompromisos = await getResponsables()
            //for (let i = 0; i < consEstrategias.length; i++) {
                //this.arrEstrategias.push( { id: consEstrategias[i].id, noEst: consEstrategias[i].num_estrategia, idObj: consEstrategias[i].idobjetivo, desc: consEstrategias[i].nom_estrategia } )
            //}
//            const consCompromisos = await getResponsables()
//            for (let l = 0; l < consCompromisos.length; l++) {
//                const noAp = consCompromisos[l].idaccion_puntual
                    
//                this.arrCompromisos[noAp].push({ 
//                    id_dependencia: consCompromisos[l].iddependencia,
          //          tmp_name: consCompromisos[l].tmp_name,
          //          file_name: consCompromisos[l].file_name,
//                })
//            }


            const consAvanceAcc = await getAvanceAcciones( '', (charsService.decode(localStorage.getItem('tipo_usuario'))) )
            for (let n = 0; n < consAvanceAcc.length; n++ ) {
                const noAp = consAvanceAcc[n].idaccion_puntual
                if ( !this.arrAvanceAcc[noAp] )
                    this.arrAvanceAcc[ noAp ] = []
                
                if ((consAvanceAcc[n].sin_reporte_avance !== 1 && !localStorage.getItem('tipo_usuario')) || localStorage.getItem('tipo_usuario')){
                    const av_res = ( consAvanceAcc[n].avance_resumen && consAvanceAcc[n].avance_resumen.includes('<') )
                                        ? consAvanceAcc[n].avance_resumen.replace(/<\/?[^>]+>/ig, "") 
                                        : consAvanceAcc[n].avance_resumen

                    this.arrAvanceAcc[noAp].push({ 
                        id: charsService.encode( consAvanceAcc[n].id ),
                        avance_resumen: av_res,
                        id_dependencia: consAvanceAcc[n].id_dependencia,
                        fecha_inicio: consAvanceAcc[n].fecha_inicio,
                        fecha_termino: consAvanceAcc[n].fecha_termino,
                        cantidad_jovenes_imp: consAvanceAcc[n].cantidad_jovenes_imp,
                    })
                }
            }

            const consAvance = await getAvanceAcciones();
            for (let m = 0; m < consAvance.length; m++) {
                if (!this.arrTotalJovenes[consAvance[m].idaccion_puntual]){
                    this.arrTotalJovenes[consAvance[m].idaccion_puntual] = 0
                }
                this.arrAvance[consAvance[m].idaccion_puntual] = true
                this.arrTotalJovenes[consAvance[m].idaccion_puntual] += Number(consAvance[m].cantidad_jovenes_imp)
            }
            const consAnio = await getCatAnios();
            for (let p = 0; p < consAnio.length; p++) {
                this.arrAnios.push({ id: consAnio[p].id, desc: consAnio[p].nom_anio })
            }
            const consTipoAp = await getCatTipos_ap();
            for (let o = 0; o < consTipoAp.length; o++) {
                this.arrTipoAp.push({ id: consTipoAp[o].id, desc: consTipoAp[o].nom_tipo_ap })
            }

        },

        filter(array, key, value){
            let i, j, hash = [], item;
            for(i =  0, j = array.length; i<j; i++){
                item = array[i];
                if(typeof item[key] !== "undefined" && item[key] === value){
                    hash.push(item);
                }
            }
            return hash;
        },

        // Filtro exclusivo para cat de entidades (se hace referencia al registro que viene como "Todas")
        filterIn(array, key, arrayIn){
            let i, j, hash = [], item;
            for(i =  0, j = array.length; i<j; i++){
                item = array[i];
                if((typeof item[key] !== "undefined" && arrayIn.indexOf(item[key]) !== -1)){
                    hash.push(item);
                }
            }
            return hash;
        },


        async f_b_obj0() {  //En proceso
            /*
            const valor = document.querySelector("#b_obj0").value;
            this.arrAccionesFilt = []

            //if (!valor || parseInt(valor) === 101) {
            //    this.arrAcciones = this.arrAccionesBak
            //    const accionResp = await getAccionbyResp( charsService.decode( localStorage.getItem('dependencia') ) )
            //    accionResp.forEach(accion => {
            //        this.arrAccionesFilt.push( accion.idaccion_puntual )
            //    })
            //} else {
                let arrAccResp = []

                if ( this.arrAccionesAsignadas.length < 1 ) {
                    const consAccResp = await getAccionbyResp( valor );
                    consAccResp.forEach( e => {
                        arrAccResp.push( e.idaccion_puntual )
                        this.arrAccionesFilt.push(e.idaccion_puntual)
                    });
                } else {
                    arrAccResp = this.arrAccionesAsignadas
                }
                this.arrAcciones = this.filterIn(this.arrAccionesBak, 'idAccion', arrAccResp)
            //}
            */
        },


        f_b_obj2() {
            const valor = document.querySelector("#b_obj2").value;
            if (!valor)
                this.arrAcciones = this.arrAccionesBak
            else
                this.arrAcciones = this.filter(this.arrAccionesBak, 'tipoAccion', parseInt(valor))
        },

        async f_b_obj3() {
            const valor = document.querySelector("#b_obj3").value;
            if (!valor || parseInt(valor) === 101)
                this.arrAcciones = this.arrAccionesBak
            else {
                const consAccCoord = await getAccionbyCoord( valor );
                const arrAccCoord = []
                consAccCoord.forEach( e => {
                    arrAccCoord.push( e.idaccion_puntual )
                });
                this.arrAcciones = this.filterIn(this.arrAccionesBak, 'idAccion', arrAccCoord)
            }
        },

        async f_b_obj4() {
            const valor = document.querySelector("#b_obj4").value;
            this.arrAccionesFilt = []

            if (!valor || parseInt(valor) === 101) {
                this.arrAcciones = this.arrAccionesBak
                const accionResp = await getAccionbyResp( charsService.decode( localStorage.getItem('dependencia') ) )
                accionResp.forEach(accion => {
                    this.arrAccionesFilt.push( accion.idaccion_puntual )
                })
            } else {
                let arrAccResp = []

                if ( this.arrAccionesAsignadas.length < 1 ) {
                    const consAccResp = await getAccionbyResp( valor );
                    consAccResp.forEach( e => {
                        arrAccResp.push( e.idaccion_puntual )
                        this.arrAccionesFilt.push(e.idaccion_puntual)
                    });
                } else {
                    arrAccResp = this.arrAccionesAsignadas
                }
                this.arrAcciones = this.filterIn(this.arrAccionesBak, 'idAccion', arrAccResp)
            }
        },

        MxTime( date ) {
            moment.locale("es")
            return moment( date ).format('DD/MM/YYYY')
        },
        MxTime_anio( date ) {
            moment.locale("es")
            return moment( date ).format('YYYY')
        },

        downloadPdf() {
            const doc = new jsPDF({
                orientation: 'p',
                unit: 'px',
                format: 'a4',
                hotfixes: ['px_scaling'],
            });
            let date = new Date()
            let day = `${(date.getDate())}`.padStart(2,'0');
            let month = `${(date.getMonth()+1)}`.padStart(2,'0');
            let year = date.getFullYear();
            
            let dateShow = `${day}-${month}-${year}`
            /** WITH CSS */
            var canvasElement = document.createElement('canvas');
            doc.context2d.pageWrapYEnabled = true;
            html2canvas(this.$refs.toPdf, {
                width: doc.internal.pageSize.getWidth(),
                height: doc.internal.pageSize.getHeight() + 90
            }).then((canvas) => {
                const img = canvas.toDataURL("image/jpeg", 0.8);
                doc.addImage(img, "JPEG", 20, 20);
                doc.save("acciones_puntuales_" + (dateShow) + ".pdf");
            })
        }
    },
    async mounted() {
        this.getAccesoData(charsService.decode(localStorage.getItem('id')))
        await this.getCatalogos()
        this.arrAcciones = await this.getAllAcciones()
        this.arrAccionesBak = await this.getAllAcciones()

        // Selecciona el año actual
        let date = new Date()
        let anio_actual = date.getFullYear();
        const element = document.querySelector("#b_obj0")
        const event = new Event('change')
        element.value = anio_actual
        element.dispatchEvent( event )
        // Fin de selecciona el año actual

        if (parseInt(charsService.decode(localStorage.getItem('tipo_usuario'))) === 3) {
            const element = document.querySelector("#b_obj4")
            const event = new Event('change')
            element.value = charsService.decode(localStorage.getItem('dependencia'))
            element.dispatchEvent( event )
        } 

        this.$gtag.event('Acciones puntuales');     //Registra el Evento en Google Analytics con nombre
        await this.getDepName()
    }
}
</script>

<style scoped>
    .callout {
        background-color: rgba(250, 250, 250, 0.5);
    }
    .objetivoTitle, .estrategiasTitle {
        color: #9F2241;
        font-weight: bold;
    }
    .objetivo {
        font-size: 20px;
        font-weight: bold;
    }
    .estrategias {
        font-size: 18px;
    }
    .card {
        font-size: medium;
    }
    .apTitle {
        font-weight: bold;
    }

    .charts {
        width: 100%;
        height: auto;
    } 

    .icoLink {
        color: #212529;
    }

    .dontShow {
        display: none;
    }

    .btnCond {
        margin-bottom: 40px;
    }
</style>