/* Regresa un registro de la tabla de cat_clase_ind */

import simejuvApi from '../api/simejuvApi'

const getDataByAnyClase_ind = async( table, column, value ) => {
    const promiseArr = [
        simejuvApi.get(`/${ table }?transform=1&filter=${ column },eq,${ value }`)
    ]

    const [ datos ] = await Promise.all( promiseArr )
    
    return datos.data.cat_clase_ind

}

export default getDataByAnyClase_ind