/* Regresa todos los datos de accionp_av_objetivo con el id enviado de la tabla 'accionp_av_objetivo' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccionp_av_objetivoById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/accionp_av_objetivo/${ id }`)
    ]

    const [ accionp_av_objetivo ] = await Promise.all( promiseArr )

    return accionp_av_objetivo.data
}

export default getAccionp_av_objetivoById