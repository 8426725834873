/* Regresa los registros de la tabla 'archivos' que correspondan con el módulo y registrio pasados como parámetros en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getArchivosAvance = async( modulo, idRegistro, idRegistroSec ) => {
    const promiseArr = [
//        simejuvApi.get(`/archivos?transform=1&filter[]=modulo,eq,${modulo}&filter[]=id_registro,eq,${idRegistro}&filter[]=id_registro_sec,eq,${idRegistroSec}&filter[]=tmp,lt,1&filter[]=borrar,lt,1`)
        simejuvApi.get(`/archivos?transform=1&filter[]=modulo,eq,${modulo}&filter[]=id_registro,eq,${idRegistro}&filter[]=id_registro_sec,eq,${idRegistroSec}&filter[]=tmp&filter[]=borrar,lt,1`)
//        simejuvApi.get(`/archivos?transform=1&filter[]=modulo,eq,${modulo}&filter[]=id_registro,eq,${idRegistroSec}&filter[]=id_registro_sec,eq,${idRegistro}&filter[]=tmp&filter[]=borrar,lt,1`)
]

    const [ archivos ] = await Promise.all( promiseArr )

    return archivos.data.archivos
}

export default getArchivosAvance