<template>
    <h4>Administración del catálogo de clases del indicador</h4>
    <hr>

    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar la clase del indicador? </h5>
            <h6> {{ datosClase_ind }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getClases_indById from '@/helpers/getClases_indById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosClase_ind: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'cat_clase_ind', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getClase_ind() {
            const dataClase_ind = await getClases_indById(charsService.decode( this.$route.params.id ));

            const cadena = `${ dataClase_ind.nom_clase_ind } `;

            return cadena
        }
    },
    async mounted() {
        this.datosClase_ind = await this.getClase_ind();
        // this.getClase_ind()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>