<template>
    <h4 class="mt-0">Agregar una dimensión del indicador</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre de la dimensión del indicador:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idDimension_indicador" name="idDimension_indicador" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_dimension_indicador }" id="nom_dimension_indicador" name="nom_dimension_indicador" placeholder="Nombre de la dimensión del indicador" v-model="form.nom_dimension_indicador" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDimension_indicador" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDimension_indicador" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra dimensión del indicador" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addDimension_indicador" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import addData from '@/helpers/addData'
import getDataByAnyDimension_indicador from '@/helpers/getDataByAnyDimension_indicador'

export default {
    data() {
        return {
            form: {
                nom_dimension_indicador: ''
            },
            noValid: {
                nom_dimension_indicador: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nom_dimension_indicador = ''
            this.added = false
        },
        async addDimension_indicador() {
            this.noValid.nom_dimension_indicador = ( this.form.nom_dimension_indicador )? false : true

            const valNom_dimension_indicador = await getDataByAnyDimension_indicador( 'cat_dimension_indicador', 'nom_dimension_indicador', this.form.nom_dimension_indicador );
            
            if ( valNom_dimension_indicador.length < 1 ){
                if ( !this.noValid.nom_dimension_indicador) {
                    const dataForm = {
                        nom_dimension_indicador: this.form.nom_dimension_indicador
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addDimension_indicador = await addData('cat_dimension_indicador', jsonData)
                    //if ( addDimension_indicador[0].res && addDimension_indicador[0].res > 0 ) {
                    if ( addDimension_indicador[0].res.status === 200 ) {    
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre de la dimensión del indicador', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una dimensión del indicador registrada con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>