/* Regresa todos los registros de la tabla 'interv_param' en la base de datos */

// Esta linea se queda igual siempre (base url api)
import simejuvApi from '../api/simejuvApi'

// Se nombra la funcion
const getIntervParam = async() => {
    const promiseArr = [
        // Se modifica de acuerdo a la búsqueda en la tabla
        simejuvApi.get(`/interv_param?transform=1&filter=borrar,lt,1`)
    ]

    // Cambiar el nombre de la variable contra los datos a mostrar
    const [ intervenciones ] = await Promise.all( promiseArr )

    return intervenciones.data.interv_param
}

// Se exporta con el mismo nombre de la funcion
export default getIntervParam