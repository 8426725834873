/* Regresa todos los datos de los años con el id enviado de la tabla 'cat_tendencia_indicador' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getTendencias_indicadorById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_tendencia_indicador/${ id }`)
    ]

    const [ cat_tendencia_indicador ] = await Promise.all( promiseArr )

    return cat_tendencia_indicador.data
}

export default getTendencias_indicadorById