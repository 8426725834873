/* Regresa todos los datos del usuario con el id enviado de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getMesesById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_mes/${ id }`)
    ]

    const [ cat_mes ] = await Promise.all( promiseArr )

    return cat_mes.data
}

export default getMesesById