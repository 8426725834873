<template>
    <h4 class="mt-0">Agregar un tipo de cálculo</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre del tipo de cálculo:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idTipo_calculo" name="idTipo_calculo" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_tipo_calculo }" id="nom_tipo_calculo" name="nom_tipo_calculo" placeholder="Nombre del tipo de cálculo" v-model="form.nom_tipo_calculo" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateTipo_calculo" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateTipo_calculo" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otro tipo de cálculo" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addTipo_calculo" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import addData from '@/helpers/addData'
import getDataByAnyTipo_calculo from '@/helpers/getDataByAnyTipo_calculo'

export default {
    data() {
        return {
            form: {
                nom_tipo_calculo: ''
            },
            noValid: {
                nom_tipo_calculo: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nom_tipo_calculo = ''
            this.added = false
        },
        async addTipo_calculo() {
            this.noValid.nom_tipo_calculo = ( this.form.nom_tipo_calculo )? false : true

            const valNom_tipo_calculo = await getDataByAnyTipo_calculo( 'cat_tipo_calculo', 'nom_tipo_calculo', this.form.nom_tipo_calculo );
            
            if ( valNom_tipo_calculo.length < 1 ){
                if ( !this.noValid.nom_tipo_calculo) {
                    const dataForm = {
                        nom_tipo_calculo: this.form.nom_tipo_calculo
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addTipo_calculo = await addData('cat_tipo_calculo', jsonData)
                    //if ( addTipo_calculo[0].res && addTipo_calculo[0].res > 0 ) {
                    if ( addTipo_calculo[0].res.status === 200 ) {    
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre del tipo de cálculo', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe un tipo de cálculo registrado con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>