/* Regresa todos los registros de la vista 'v_op_serv_estatales_todos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAllOp_serv_estatales = async() => {
    const promiseArr = [
        simejuvApi.get(`/v_op_serv_estatales_todos?transform=1&filter=borrar,lt,1`)
    ]

    const [ op_serv_estatales ] = await Promise.all( promiseArr )

    return [
        { oportunidades: op_serv_estatales.data.v_op_serv_estatales_todos }
    ]
}

export default getAllOp_serv_estatales