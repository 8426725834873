<template>
    <h4 class="mt-0">Actualizar información del catálogo de Dependencias</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>



                <div class="form-group row">
                    <label class="col-md-4 col-sm-12 col-form-label" to="idestado">Nombre del estado:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="idestado" name="idestado" v-model="form.idestado">
                            <option v-for="e_tf in cat_estadoArr" :key="e_tf.id" :value="e_tf.id">{{ e_tf.id }} {{ e_tf.nom_estado }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4 col-sm-12 col-form-label" to="id_sector_op">Tipo de sector:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="id_sector_op" name="id_sector_op" v-model="form.id_sector_op">
                            <option v-for="e_tf in cat_sector_opArr" :key="e_tf.id" :value="e_tf.id">{{ e_tf.id }} {{ e_tf.nom_sector_op }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="num_dependencia_op">Número de la Dependencia:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idDependencia" name="idDependencia" v-model="form.id" />
                        <input type="text" class="form-control" id="num_dependencia_op" name="num_dependencia_op" placeholder="Número de la Dependencia" v-model="form.num_dependencia_op" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_dependencia_op">Nombre de la Dependencia:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_dependencia_op" name="nom_dependencia_op" placeholder="Nombre de la Dependencia" v-model="form.nom_dependencia_op" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_corto_dependencia_op">Nombre corto de la Dependencia (Siglas):</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_corto_dependencia_op" name="nom_corto_dependencia_op" placeholder="Siglas" v-model="form.nom_corto_dependencia_op" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getCatNC from '@/helpers/getCatNC'
import getCatsNc from '@/helpers/getCatsNc'
import getDependencias_opById from '@/helpers/getDependencias_opById'

import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            idestado: '',
            cat_estadoNc: '',
            id_sector_op: '',
            cat_sector_opNc: '',
            form: {
                id: '',
                num_dependencia_op: '',
                nom_dependencia_op: '',
                nom_corto_dependencia_op: '',
                idestado: '',
                id_sector_op: '',


            },
            cat_estadoArr: [],
            cat_sector_opArr: [],
        }
    },
    methods: {
        async getDependenciaData( id ) {
            const dependenciaArr = await getDependencias_opById( id )
            
            return dependenciaArr[0].cat_dependencias_op
        },

        async setData( id ) {
            const datos = await getDependencias_opById( id )

            this.form.id = datos.id
            this.form.num_dependencia_op = datos.num_dependencia_op
            this.form.nom_dependencia_op = datos.nom_dependencia_op
            this.form.nom_corto_dependencia_op = datos.nom_corto_dependencia_op

            this.form.idestado = datos.idestado
            this.cat_estadoNc = await getCatNC(`/cat_estado/${ datos.idestado }?transform=1&columns=id,nom_estado`).nom_estado

            this.form.id_sector_op = datos.id_sector_op
            this.cat_sector_opNc = await getCatNC(`/cat_sector_op/${ datos.id_sector_op }?transform=1&columns=id,nom_sector_op`).nom_sector_op



        },

        async updateDependencia() {
            const dataForm = {
                num_dependencia_op: this.form.num_dependencia_op,
                nom_dependencia_op: this.form.nom_dependencia_op,
                nom_corto_dependencia_op: this.form.nom_corto_dependencia_op,

                idestado: this.form.idestado,
                cat_estadoNc: this.cat_estadoNc,

                id_sector_op: this.form.id_sector_op,
                cat_sector_opNc: this.cat_sector_opNc,


            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_dependencias_op', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },
        async getCat_estado() {
            const c_estado = await getCatsNc('/cat_estado?transform=1&columns=id,nom_estado&filter=id,lt,100')
            return c_estado.cat_estado

        },
        async getcat_sector_op() {
            const sector_op = await getCatsNc('/cat_sector_op?transform=1&columns=id,nom_sector_op&filter=id,lt,100')
            return sector_op.cat_sector_op
        },


    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
        this.cat_estadoArr = await this.getCat_estado()
        this.cat_sector_opArr = await this.getcat_sector_op()
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>