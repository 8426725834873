<template>
    <div class="form-group row">
        <div class="col-md-6 text-left">
            <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
            <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
        </div>
        <div class="col-md-6 text-right">
            <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
            <input type="button" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="saveIntervencion" />
        </div>
    </div>

    <h4 class="mt-0">Mantenimiento del catálogo de ofertas o servicios estatales para las y los jóvenes</h4>
    <hr>
    
    <el-card class="box-card" style = "backgroundColor: #FBFCFC" shadow="hover">
        <template #header>
            <div class="card-header" >
            <span><strong>Autorización de ofertas para las y los jóvenes</strong></span>
            </div>
        </template>
            <div class="row mb-5">
                <div class="col-md-12">
                    <form>
                        <div class="form-group row">
                            <label class="col-md-4" to="estatus">Estatus:<div class="subQuest">Indicar si se publica o no en la Página WEB del SIMEJUV</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="estatus" id="estatus1" v-model="form.estatus" value='Pendiente de revisar' :checked="this.form.estatus == 'Pendiente de revisar' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">Pendiente de revisar</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="estatus" id="estatus2" v-model="form.estatus" value='No se publica' :checked="this.form.estatus == 'No se publica' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">No se publica</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="estatus" id="estatus3" v-model="form.estatus" value='Si se publica' :checked="this.form.estatus == 'Si se publica' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">Si se publica</span>
                                    </label><br>
                                </el-card>   
                        <!--    <span>Eligió: {{ form.estatus }}</span>    -->
                            </div>


                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_funcionario_pub">Nombre de la persona funcionaria pública:<div class="subQuest">Nombre del funcionario que registra la Intervención Gubernamental</div></label>
                            <div class="col-md-8">
                                <div >
                                    <input type="text" class="form-control" id="nom_funcionario_pub" name="nom_funcionario_pub" placeholder="Nombre del funcionario" v-model="form.nom_funcionario_pub" />
                                </div>
                            </div>    
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_cargo_pub">Cargo de la persona funcionaria pública:<div class="subQuest">Cargo del funcionario que registra la Intervención Gubernamental</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="nom_cargo_pub" name="nom_cargo_pub" placeholder="Cargo del funcionario" v-model="form.nom_cargo_pub" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4" to="email_pub">Correo electrónico del funcionario:<div class="subQuest">Correo oficial del funcionario</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="email_pub" name="email_pub" placeholder="Correo electrónico" v-model="form.email_pub" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="idestado">Estado:<div class="subQuest">Nombre del estado</div></label>
                            <div class="col-md-8">
                                <select class="form-control" id="idestado" name="idestado" v-model="form.idestado" @change="f_b_obj2">
                                    <option value="" disabled selected>[Seleccione]</option>
                                    <option v-for="e in estadoArr" :key="e.id" :value="e.id">{{ e.nom_estado }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4 col-form-label" for="coberturaMunicipios">Cobertura municipal:<div class="subQuest">Municipios donde tiene influencia la oportunidad o servicio ofrecido <br>Debe seleccionar todos los municipios donde tendra influencia la oferta presionando la tecla Mayus o Ctrl para seleccionar más de uno.</div></label>
                            <div class="col-md-8">
                                <select class="form-control" multiple="multiple" name="coberturaMunicipios" id="coberturaMunicipios" v-model="form.coberturaMunicipios" size="10" :disabled="isDisabled">
                                    <option value="" selected>[Seleccione]</option>
                                    <option v-for="e in arrMunicipios" :key="e.id" :value="e.id" :selected="this.form.arrMunicipios.includes(e.id.toString())">{{ e.id }} - {{ e.nc }}</option>
                                </select>
                                <div><small>Para seleccionar varios presione la tecla Mayus o Ctrl</small></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nivel_implementacion">Nivel de implementación:<div class="subQuest">Nivel al que afectara la implementación de la Oferta o servicio</div></label>
                            <div class="col-md-8">
                                <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="nivel_implementacion" id="nivel_implementacion1" v-model="form.nivel_implementacion" value='NACIONAL' :checked="this.form.nivel_implementacion == 'NACIONAL' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">NACIONAL</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="nivel_implementacion" id="nivel_implementacion2" v-model="form.nivel_implementacion" value='ESTATAL' :checked="this.form.nivel_implementacion == 'ESTATAL' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">ESTATAL</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="nivel_implementacion" id="nivel_implementacion3" v-model="form.nivel_implementacion" value='MUNICIPAL' :checked="this.form.nivel_implementacion == 'MUNICIPAL' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">MUNICIPAL</span>
                                    </label><br>
                                    <label class="toggler">
                                        <input class="toggler-radio" type="radio" :name="nivel_implementacion" id="nivel_implementacion4" v-model="form.nivel_implementacion" value='INTERMUNICIPAL' :checked="this.form.nivel_implementacion == 'INTERMUNICIPAL' ">
                                        <div class="toggler-switch"></div><span class="toggler-label">INTERMUNICIPAL</span>
                                    </label><br>
                                </el-card>   
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4" to="iddependencia_op">Dependencia:<div class="subQuest">Nombre de la Dependencia o Entidad Federativa bajo la cual se desarrolla la intervención</div></label>
                            <div class="col-md-8">
                                <select class="form-control" id="iddependencia_op" name="iddependencia_op" v-model="form.iddependencia_op">
                                    <option value="" disabled selected>[Seleccione]</option>
                                    <option v-for="e in arrDependenciasOp" :key="e.id" :value="e.id">{{ e.nom_dependencia_op }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_programa">Nombre del programa:<div class="subQuest">Programa a través del cuál se opera y/o desarrolla la oportunidad o servicio <br>(500 caracteres máximo)</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="nom_programa" name="nom_programa" placeholder="Programa" v-model="form.nom_programa" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="publico_meta">Público meta:<div class="subQuest">Dirigido a jóvenes hombres ó mujeres cuyas edades estén entre 12 a 29 años.</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="publico_meta" id="publico_meta" placeholder="Público meta" rows="3" v-model="form.publico_meta"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="objetivo_programa">Objetivo de la oferta:<div class="subQuest">Especifica el propósito que persigue la oferta al ser implementada</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="objetivo_programa" id="objetivo_programa" placeholder="Objetivo de la oferta" rows="3" v-model="form.objetivo_programa"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="programa_descripcion">Descipción de la oferta:<div class="subQuest">Descripción de la oportunidad o servicio</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="programa_descripcion" id="programa_descripcion" placeholder="Descipción de la oferta" rows="3" v-model="form.programa_descripcion"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="vigencia">Vigencia:<div class="subQuest">Se refiere al periodo durante el cual la oportunidad o servicio está activo y disponible para su solicitud y/o aprovechamiento por parte de la población objetivo</div></label>
                            <div class="col-md-8">
                                <div class="form-group row">
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <div><small>Fecha de Inicio</small></div>
                                        <Datepicker v-model="form.fecha_inicio" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatFechaInicio" name="fecha_inicio" id="fecha_inicio" placeholder="Seleccione fecha" class="datepicker"></Datepicker>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12"> </div> 
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <div><small>Fecha de Termino</small></div>
                                        <Datepicker v-model="form.fecha_fin" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatFechaFin" name="fecha_fin" id="fecha_fin" placeholder="Seleccione fecha" class="datepicker" :minDate="form.fecha_inicio"></Datepicker>
                                    </div>
                                </div>
                            </div>
                            <label class="col-md-8" to="vigencia"><div class="subQuest"> </div></label>
                            <div class="col-md-8">
                                <div><small>Observaciones:</small></div>
                                <textarea class="form-control" name="vigencia" id="vigencia" placeholder="Vigencia" rows="3" v-model="form.vigencia"></textarea>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4" to="nom_titular">Titular encargado:<div class="subQuest">Titular encargado responsable de la implementación de la oportunidad o servicio</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="nom_titular" name="nom_titular" placeholder="Unidad o área ejecutora" v-model="form.nom_titular" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="num_tel_contacto">Teléfono de contacto:<div class="subQuest">Número telefónico de contacto de la oferta o servicio</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="num_tel_contacto" name="num_tel_contacto" placeholder="Número telefónico de contacto de la oferta o servicio" v-model="form.num_tel_contacto" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="email_contacto">Correo electrónico de contacto:<div class="subQuest">Correo oficial de contacto de la oferta o servicio</div></label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="email_contacto" name="email_contacto" placeholder="Correo electrónico de contacto de la oferta o servicio" v-model="form.email_contacto" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="redes_sociales">Redes sociales:<div class="subQuest">Enlace hacia las redes sociales donde pueda localizarse información completa respecto a la oferta o servicio</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="redes_sociales" id="redes_sociales" placeholder="Redes sociales" rows="3" v-model="form.redes_sociales"></textarea>
                            </div>	
                        </div>
                        
                        
                        
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label" for="idmunicipio">Municipio de la oportunidad o servicio:<div class="subQuest">Seleccionar el municipio de la oportunidad o servicio.</div></label>
                            <div class="col-md-8">
                                <select class="form-control" id="idmunicipio" name="idmunicipio" v-model="form.idmunicipio">
                                    <option value="" disabled selected>[Seleccione]</option>
                                    <option v-for="e in arrMunicipios" :key="e.id" :value="e.id">{{ e.id }} - {{ e.nc }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4" to="direccion">Dirección:<div class="subQuest">Dirección de la Dependencia o Institución que implementa la oportunidad o servicio</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="direccion" id="direccion" placeholder="Redes sociales" rows="3" v-model="form.direccion"></textarea>
                            </div>	
                        </div>



                       




                        <div class="form-group row">
                            <label class="col-md-4" to="vigencia">Ubicación geografica::<div class="subQuest">Seleccionar la ubicación geográfica de la oportunidad o servicio dando click en el mapa</div></label>
                            <div class="col-md-8">
                                <div class="form-group row">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div><small>Latitud</small></div>
                                        <input class="form-control" type="number" min="0" name="lat_decimal" id="lat_decimal" v-model="form.lat_decimal" placeholder="Decimal" >
                                    </div>
                                    <div class="col-lg-1 col-md-6 col-sm-12"> </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div><small>Longitud</small></div>
                                        <input class="form-control" type="number" min="0" name="lon_decimal" id="lon_decimal" v-model="form.lon_decimal" placeholder="Decimal" >
                                    </div>
                                    <div style="padding: 15px;">
                                        <button type="button" class="btn btn-primary" @click="search">Localizar</button>
                                    </div>
                                </div>
                            </div>
                            <label class="col-md-4" to="vigencia"><div class="subQuest"> </div></label>
                            
                            <div class="col-md-8">
                                <div><small>Seleccione la ubicación dando click en el mapa</small></div>
                                
                                
                                <div class="row mt-3">
                                    <div class="col">
                                        <div style="height:400px;">
                                        <l-map ref="map" :zoom="zoom" :use-global-leaflet="false" :center="center" @click="moveMarker">
                                            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                                            name="OpenStreetMap"></l-tile-layer>
                                            <l-marker :lat-lng="markerLatLng"></l-marker>

                                        </l-map>
                                        </div>
                                    </div>
                                </div>   
                                
                            </div>
                        </div>






                        <div class="form-group row">
                            <label class="col-md-4" to="referencia_web">Referencia web de la oferta:<div class="subQuest">Enlace hacia el sitio web donde pueda localizarse información completa respecto a la oferta o servicio</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="referencia_web" id="referencia_web" placeholder="Referencia web de la oferta o servicio" rows="3" v-model="form.referencia_web"></textarea>
                            </div>	
                        </div>

                        <div class="form-group row">
                            <label class="col-md-4" to="observaciones">Observaciones:<div class="subQuest">Comentarios relacionadas con la oferta o servicio ofrecido</div></label>
                            <div class="col-md-8">
                                <textarea class="form-control" name="observaciones" id="observaciones" placeholder="Redes sociales" rows="4" v-model="form.observaciones"></textarea>
                            </div>	
                        </div>
                        <!-- Inicio de carga de archivos -->
                        <div class="form-group row">
                            <label class="col-md-5 col-form-label" for="archivo">Material gráfico sobre las oportunidades o servicios ofrecidos:<div class="subQuest">Sólo se permite subir imagenes en los siguientes formatos y tamaño: <br> - Imagenes en formato jpg, png y bmp. <br> - El tamaño máximo permitidos por imagen es de 5 MB</div></label>
                            <div class="col-md-7">
                                <div v-if="successAlert" class="alert alert-success alert-dismissible">
                                    <a href="#" class="close" aria-label="close" @click="successAlert=false">&times;</a>
                                    {{ successMessage }}
                                </div>
                                <div v-if="errorAlert" class="alert alert-danger alert-dismissible">
                                    <a href="#" class="close" aria-label="close" @click="errorAlert=false">&times;</a>
                                    {{ errorMessage }}
                                </div>
                                <input type="file" ref="file" :disabled='isDisabled' id="fileUp" />
                                <br>
                                <button type="button" @click="uploadFile" class="btn btn-default btn-sm" :disabled='isDisabled'>Almacenar archivo</button>
                            </div>
                        </div>
                        <div>
                            <div class="form-group row">
                                <div class="col-md-5"></div>
                                <div class="col-md-7">
                                    <table id="tblFiles" class="table table-striped">
                                        <thead class="table-striped">
                                            <tr>
                                                <th>Nombre del archivo</th>
                                                <th colspan="2">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(e, index) in arrArchivos" :key="e">
                                                <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><img :src="require(`@/assets/images/icons/${ e.extension }.svg`)" class="iconImg" /> {{ e.file_name_dec }}</a></td>
                                                <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download-alt"></i></a></td>
                                                <td><a href="#" @click="deleteFile( e.id, index)" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- Fin de carga de archivos -->
                        <div class="form-group row">
                            <div class="col-md-6 text-left">
                                <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
                                <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                            </div>
                            <div class="col-md-6 text-right">
                                <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
                                <input type="button" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="saveIntervencion" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
    </el-card>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'
import '/src/assets/css/vue-datepicker/main.css'

import updateData from '@/helpers/updateData'
import getCatDependencias_op from '@/helpers/getCatDependencias_op'
import getCatEstados from '@/helpers/getCatEstados'
import getMunicipiosNc from '@/helpers/getMunicipiosNc'
import getDependenciasOpNc from '@/helpers/getDependenciasOp'          
import getOp_serv_estatalesById from '@/helpers/getOp_serv_estatalesById'


import { mount } from '@vue/test-utils'

/* Archivos */
import getArchivos from '@/helpers/getArchivos'
import getArchivosAvance from '@/helpers/getArchivosAvance'
import axios from 'axios'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';
import insertData from '@/helpers/addData'


import { es } from 'date-fns/locale';       //No va
import moment from 'moment'                 //no va
moment.locale("es")                         //No va

import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";



export default {
    components: {
        Datepicker,

        LMap,
        LTileLayer,
        LMarker
    },
    data() {
        return {

            zoom: 7,
            center: [20, -100],
            markerLatLng: [20.1485935,-99.087053],

            idestado: '',
            arrMunicipios: [],
            arrMunicipiosBak: [],

            arrDependenciasOp: [],
            arrDependenciasOpBak: [],
            form: {
                id: '',
                nom_funcionario_pub: '',
                nom_cargo_pub: '',
                email_pub: '',
                iddependencia_op: '',
                idestado: '',
                nom_programa: '',
                publico_meta: '',
                programa_descripcion: '',
                objetivo_programa: '',
                nivel_implementacion: '',
                vigencia: '',
                fecha_inicio: '',
                fecha_fin: '',
                nom_titular: '',
                num_tel_contacto: '',
                email_contacto: '',
                redes_sociales: '',
                direccion: '',
                idmunicipio: '',
                lat_decimal: '',
                lon_decimal: '',
                referencia_web: '',
                observaciones: '',
                estatus: '',

                arrMunicipios: [],         
                coberturaMunicipios: [],   
            },
            catMunicipios: [],             
            ubApi: ubApi.value,
            dependencias_opArr: [],
            estadoArr: [],
            fecha_inicio: '',
            fecha_fin: '',
            arrArchivos: [],
            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },

        search(e) {
            this.center = [this.form.lat_decimal, this.form.lon_decimal]
            this.markerLatLng = this.center
        },
        moveMarker(e) {
            if (!e.latlng) { return; }
            //this.center = [e.latlng.lat, e.latlng.lng]
            this.markerLatLng = [e.latlng.lat, e.latlng.lng]
            this.form.lat_decimal=e.latlng.lat
            this.form.lon_decimal=e.latlng.lng
        },

        
        async setData( id ) { //Se trae solo el nombre del elemento seleccionado para colocarlo como primer registro del combobox
            const datos = await getOp_serv_estatalesById( id )
            
            this.form.id = datos.id
            this.form.nom_funcionario_pub = datos.nom_funcionario_pub
            this.form.nom_cargo_pub = datos.nom_cargo_pub
            this.form.email_pub = datos.email_pub
            this.form.iddependencia_op = datos.iddependencia_op
            this.form.idestado = datos.idestado
            this.form.nom_programa = datos.nom_programa
            this.form.publico_meta = datos.publico_meta
            this.form.programa_descripcion = datos.programa_descripcion
            this.form.objetivo_programa = datos.objetivo_programa
            this.form.nivel_implementacion = datos.nivel_implementacion
            this.form.vigencia = datos.vigencia
            this.form.fecha_inicio = new Date(datos.vigencia_fecha_inicio + ' 00:00')
            this.form.fecha_fin = new Date(datos.vigencia_fecha_termino + ' 00:00')
            this.form.nom_titular = datos.nom_titular
            this.form.num_tel_contacto = datos.num_tel_contacto
            this.form.email_contacto = datos.email_contacto
            this.form.redes_sociales = datos.redes_sociales
            this.form.direccion = datos.direccion
            this.form.idmunicipio = datos.idmunicipio
            this.form.lat_decimal = datos.lat_decimal
            this.form.lon_decimal = datos.lon_decimal
            this.form.referencia_web = datos.referencia_web
            this.form.observaciones = datos.observaciones
            this.form.estatus = datos.estatus

            this.form.coberturaMunicipios = (datos.cobertura_municipios)? datos.cobertura_municipios.split(',') : []
            //Georeferencia por default en caso de ser nula
            if (!this.form.lat_decimal) {this.form.lat_decimal = 19}
            if (!this.form.lon_decimal) {this.form.lon_decimal = -99}
            this.arrMunicipios = this.filter(this.arrMunicipiosBak, 'idestado', parseInt(this.form.idestado))
            this.arrDependenciasOp = this.filter(this.arrDependenciasOpBak, 'idestado', parseInt(this.form.idestado))

        },

        async saveIntervencion() {
            const fecha_inicio = moment( this.form.fecha_inicio ).format('YYYY/MM/DD')
            const fecha_fin =  moment( this.form.fecha_fin ).format('YYYY/MM/DD')
            const dataForm = {
                nom_funcionario_pub: this.form.nom_funcionario_pub,
                nom_cargo_pub: this.form.nom_cargo_pub,
                email_pub: this.form.email_pub,
                iddependencia_op: this.form.iddependencia_op,
                idestado: this.form.idestado,
                nom_programa: this.form.nom_programa,
                publico_meta: this.form.publico_meta,
                programa_descripcion: this.form.programa_descripcion,
                objetivo_programa: this.form.objetivo_programa,
                nivel_implementacion: this.form.nivel_implementacion,
                vigencia: this.form.vigencia,
                vigencia_fecha_inicio: fecha_inicio,
                vigencia_fecha_termino: fecha_fin,
                nom_titular: this.form.nom_titular,
                num_tel_contacto: this.form.num_tel_contacto,
                email_contacto: this.form.email_contacto,
                redes_sociales: this.form.redes_sociales,
                direccion: this.form.direccion,
                idmunicipio: this.form.idmunicipio,
                lat_decimal: this.form.lat_decimal,
                lon_decimal: this.form.lon_decimal,
                referencia_web: this.form.referencia_web,
                observaciones: this.form.observaciones,
                cobertura_municipios: this.form.coberturaMunicipios.toString(),

                  
                
                estatus: this.form.estatus
            }

            const jsonData = JSON.parse(JSON.stringify( dataForm ))
            const updateOp_serv_estatales = await updateData('op_serv_estatales', this.form.id, jsonData)

            if ( updateOp_serv_estatales[0].res === 1 ) {
                this.$notify({
                    title: 'Correcto', text: 'Se guardó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },
        
        async getEstados() {                               
            const edos = await getCatEstados()
            return edos
        },
        async getMunicipios() {                                           
            const consMunicipios = await getMunicipiosNc()
            for(let i=0; i<consMunicipios.length; i++){
                this.arrMunicipios.push({id: consMunicipios[i].id,idestado: consMunicipios[i].idestado, nc: consMunicipios[i].nom_municipio})
            }
            this.arrMunicipiosBak  = this.arrMunicipios; 
        },
        async getDependencias_op() {
            const depInt = await getDependenciasOpNc()
            for(let i=0; i<depInt.length; i++){
                this.arrDependenciasOp.push({id: depInt[i].id,idestado: depInt[i].idestado, nom_dependencia_op: depInt[i].nom_dependencia_op})
            }
            this.arrDependenciasOpBak  = this.arrDependenciasOp; 
        },


        filter(array, key, value){
          let i, j, hash = [], item;
          for(i =  0, j = array.length; i<j; i++){
                item = array[i];
              if(typeof item[key] !== "undefined" && item[key] === value){
                  hash.push(item);
              }
          }
          return hash;
        },
        async f_b_obj2() {

            const valor1 = document.querySelector("#idestado").value;
            if (valor1)
            {    
                this.arrMunicipios = this.filter(this.arrMunicipiosBak, 'idestado', parseInt(valor1))
                this.arrDependenciasOp = this.filter(this.arrDependenciasOpBak, 'idestado', parseInt(valor1))
            }
        },
        
        async uploadFile() {
            this.file = this.$refs.file.files[0]
            var formData = new FormData()
            formData.append('file', this.file)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            await axios.post( ubApi.value + '/uploadFiles.php', formData, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlert = z_errorAlert
            this.successAlert = z_successAlert
            this.errorMessage = z_errorMessage
            this.successMessage = z_successMessage
            this.$refs.file.value = z_fileValue

            if (z_successAlert) {
                // op_serv_estatalesReg módulo para tabla de archivos
                const jsonInsFile = JSON.parse(JSON.stringify({
                    "modulo": "op_serv_estatalesReg",
                    "id_registro": this.form.id,
                    "id_registro_sec": charsService.decode(this.$route.params.id),
                    "id_usuario": z_userId,
                    "tmp_name": z_tmpFileName,
                    "file_name": z_baseName,
                    "extension": z_extension,
                    "tmp": true
                }))
                const lastInsert = await insertData('archivos', jsonInsFile);
                this.arrArchivos.push({borrar:0, extension:z_extension, file_name:charsService.encode(z_baseName), file_name_dec:z_baseName, id:lastInsert[0].res.data, id_registro:this.form.id, id_usuario:z_userId, modulo:"op_serv_estatalesReg", tmp_name:charsService.encode(z_tmpFileName)})
            }
        },


        readFormatFechaInicio() {
            return this.readFormat( this.form.fecha_inicio )
        },

        readFormatFechaFin() {
            return this.readFormat( this.form.fecha_fin )
        },

        readFormat( date ) {
            if ( date !== '' ) {
                const day = (date.getDate() < 10)? '0' + date.getDate() : date.getDate()
                const month = (date.getMonth() < 9)? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
                const year = date.getFullYear()

                return `${day}-${month}-${year}`;
            } else {
                return ''
            }
        },

        async getArchivos() {
            return await getArchivos( 'op_serv_estatalesReg', charsService.decode(this.$route.params.id) )
        },

        async deleteFile( id, i ) {
            
            if ( confirm('¿Confirma eliminar el archivo?') ) {
                this.arrArchivos.splice( i, 1)
                await updateData( 'archivos', parseInt(charsService.decode( id )), JSON.parse(JSON.stringify({ borrar: 1 })) )
            }
        },
        async setArrArchivos( id ) {
            const arrFiles = await getArchivosAvance( 'op_serv_estatalesReg', parseInt(charsService.decode( id )), parseInt(charsService.decode( id )))

            arrFiles.forEach(archivo => {
                console.log( archivo );
                this.arrArchivos.push({id: charsService.encode(archivo.id), tmp_name: charsService.encode(archivo.tmp_name), file_name: charsService.encode(archivo.file_name), extension: archivo.extension, file_name_dec:archivo.file_name })
            
            
               // this.$notify({
               //         title: 'ubApi=', text: ubApi , type: 'success', duration: 5000, speed: 1000
               //     })
            
            
            });
        },

    },

    async mounted() {
        
        
        this.estadoArr = await this.getEstados()
        await this.getMunicipios()
        await this.getDependencias_op() 
        await this.setData( charsService.decode( this.$route.params.id ))

        await this.setArrArchivos( this.$route.params.id )
        await this.search()
        
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #DC3545;
    }

    .subQuest {
        font-size: small;
        color: #C0C0C0;
    }

    /*    Estilo del radio   */
    .el-radio, input[type="radio"]{
        border: 5px solid #AAAAAA;
        border-radius: 100%;
        height: 25px;
        width: 25px;
        position: relative;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
    }
/*    Fin estilo del radio   */
    form input[type="text"], select, textarea {
        padding:3px 10px;
        border:1px solid #f6f6f6;
        border-radius:1px;
        background-color: #E6E8EB;
        color: #000000;
        margin:8px 0;
        display:inline-block;
    }
    
    form input:focus {
       /* border: 2px solid #c8c8c8; */
        box-shadow:1px 1px 1px  #c8c8c8;
       /* color: #ff0000; */
        /* background: #8c8c8c; */ 
        background: rgba(142,113,70,0.2)
    }
    
    ::placeholder { color: #CCCCCC; }

    /* ------ Estilo de Toggle -----------*/
    .toggle {
        cursor: pointer;
        display: inline-block;
    }

    .toggle-switch {
        display: inline-block;
        background: #CCCCCC; /*#9D2449 Guinda*/
        border-radius: 16px;
        width: 40px;
        height: 20px;
        position: relative;
        vertical-align: middle;
        transition: background 0.45s;
    }
    .toggle-switch:before, .toggle-switch:after {
        content: "";
    }
    .toggle-switch:before {
        display: block;
        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
        border-radius: 50%;
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25);
        width: 18px;
        height: 18px;
        position: absolute;
        top: 1px;
        left: 2px;
        transition: left 0.45s;
    }
    .toggle:hover .toggle-switch:before {
        background: linear-gradient(to bottom, #fff 0%, #fff 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    .toggle-checkbox:checked + .toggle-switch {
        background: #CCCCCC;    /*#235B4E Verde*/
    }
    .toggle-checkbox:checked + .toggle-switch:before {  /* Cuando se selecciona */
        background: #999999;    /*#235B4E Verde*/
        left: 20px;
    }

    .toggle-checkbox:disabled +  .toggle-switch:before {  
        background: #CCCCCC;    /*#235B4E Verde*/
        /*background: linear-gradient(to bottom, #235B4E 0%, #235B4E 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5); */
        
    }

    .toggle-checkbox {
        position: absolute;
        visibility: hidden;
    }

    .toggle-label {
        margin-left: 5px;
        position: relative;
        top: 2px;
        font-weight: normal;
    }
    /* ---Fin de estilo de Toggle --------*/

    .scrollbar-demo-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        margin: 0px;
        text-align: left;
        border-radius: 0px;
        /*background: #bdbdbd;*/ 
        font-family: 'Montserrat';
        font-weight: normal;
        font-size: 18px;
    }


    /* ------ Estilo de Toggler Radio -----------*/
    .toggler {
        cursor: pointer;
        display: inline-block;
    }

    .toggler-switch {
        display: inline-block;
        background: #999999; /*#9D2449 Guinda*/
        border-radius: 16px;
        width: 25px;
        height: 25px;
        position: relative;
        vertical-align: middle;  
        transition: background 0.45s;
    }
    .toggler-switch:before, .toggler-switch:after {
        content: "";
    }
    .toggler-switch:before {
        display: block;
        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
        border-radius: 50%;
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25);
        width: 17px;
        height: 17px;
        position: absolute;
        top: 4px;
        left: 4px;
        transition: left 0.45s;
    }
    .toggler:hover .toggler-switch:before {
        background: linear-gradient(to bottom, #fff 0%, #fff 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    .toggler-radio:checked + .toggler-switch {
        background: #CCCCCC;    /*#235B4E Verde*/
    }
    .toggler-radio:checked + .toggler-switch:before {  /* Cuando se selecciona */
        background: #999999;    /*#235B4E Verde*/  
    }

     .toggler-radio:disabled +  .toggler-switch:before {  /* Aqui voy*/
        background: #CCCCCC;    /*#235B4E Verde*/
        /*background: linear-gradient(to bottom, #235B4E 0%, #235B4E 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5); */
    }

    .toggler-radio {
        position: absolute;
        visibility: hidden;
    }

    .toggler-label {
        margin-left: 5px;
        position: relative;
        top: 2px;
        font-weight: normal;
    }
    /* ---Fin de estilo de Toggler --------*/
    /* ---Inicio Archivos -----------------*/

    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

    small {
        color: #235B4E;
        font-weight: bold;
    }
   
</style>