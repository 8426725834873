/* Regresa todas las metas de la tabla 'meta_ficha' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getMetasAvance = async( ) => {
    const promiseArr = [
        simejuvApi.get(`/meta_avance?transform=1&filter=borrar,lt,1`)
    ]

    const [ metas ] = await Promise.all( promiseArr )

    return metas.data.meta_avance
}

export default getMetasAvance