
<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item"><a href="/aten_ciud">PARTICIPACIÓN CIUDADANA</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/consepp">RECOMENDACIONES CONSEPP</a></li>
            </ol>

            <h2>Recomendaciones CONSEPP</h2>
            <hr class="red">

            <div class="panel panel-default">
                <div class="panel-body">
                    <RecomConsepp />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import RecomConsepp from '@/components/Participacion/RecomConsepp';
import getIntervParam from '@/helpers/getIntervParam';

export default {
    components: {
        Navbar,
        RecomConsepp
    },

    async mounted() {
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    .activeNav {
        background-color: #9D2449;
    }
</style>