<template>
    <h3 class="mt-0">Actualizar información de metas y parámetros</h3>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <h4>Datos generales de la meta o parámetro</h4>
                    <hr>
                </div>


                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="codigo_meta">Código de la meta para el bienestar o parámetro:</label>
                    <div class="col-md-9 col-sm-12">
                        <input type="hidden" id="idMeta_ficha" name="idMeta_ficha" v-model="form.id" />
                        <input type="text" class="form-control" id="codigo_meta" name="codigo_meta" placeholder="#.#" v-model="form.codigo_meta" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="nom_meta">Nombre de la meta para el bienestar o parámetro:</label>
                    <div class="col-md-9 col-sm-12">
                        <textarea rows="2" class="form-control" name="nom_meta" id="nom_meta" v-model="form.nom_meta" placeholder="Nombre de la meta para el bienestar o parámetro" @keyup="conteo_real('nom_meta')"></textarea>
                        <div id="nom_meta_count" class="noChars"></div>
                    </div>
                </div>
                <hr>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="clase_ind">Tipo de indicador:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="clase_ind" name="clase_ind" v-model="form.clase_ind">
                            <option v-for="ee in cla_indsArr" :key="ee.id" :value="ee.id">{{ ee.id }} {{ ee.nom_clase_ind }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="objetivo_prio">Objetivo prioritario:</label>
                    <div class="col-md-9 col-sm-12">
                        <select class="form-control" id="objetivo_prio" name="objetivo_prio" v-model="form.objetivo_prio">
                            <option v-for="eee in objetivo_priosArr" :key="eee.id" :value="eee.id">{{ eee.num_objetivo }} {{ eee.nom_corto_objetivo }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" to="descripcion_meta">Definición o descripción:</label>
                    <div class="col-md-9 col-sm-12">
                        <textarea rows="4" class="form-control" name="descripcion_meta" id="descripcion_meta" v-model="form.descripcion_meta" placeholder="Definición o descripción" @keyup="conteo_real('descripcion_meta')"></textarea>
                        <div id="descripcion_meta_count" class="noChars"></div>
                    </div>
                </div>
                <hr>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="niv_desag">Nivel de desagregación:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                        <select class="form-control" id="niv_desag" name="niv_desag" v-model="form.niv_desag">
                            <option v-for="e_nd in niv_desagsArr" :key="e_nd.id" :value="e_nd.id">{{ e_nd.id }} {{ e_nd.nom_nivel_desagregacion }}</option>
                        </select>
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="periodicidad">Periodicidad o frecuencia de medición:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="periodicidad" name="periodicidad" v-model="form.periodicidad">
                            <option v-for="e_period in periodicidadesArr" :key="e_period.id" :value="e_period.id">{{ e_period.id }} {{ e_period.nom_periodicidad }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="tipo_indicador">Tipo de indicador:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                        <select class="form-control" id="tipo_indicador" name="tipo_indicador" v-model="form.tipo_indicador">
                            <option v-for="e_ti in tipo_indicadoresArr" :key="e_ti.id" :value="e_ti.id">{{ e_ti.id }} {{ e_ti.nom_tipo_indicador }}</option>
                        </select>
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="tipo_calculo">Acumulado o periódico:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="tipo_calculo" name="tipo_calculo" v-model="form.tipo_calculo">
                            <option v-for="e_tc in tipo_calculosArr" :key="e_tc.id" :value="e_tc.id">{{ e_tc.id }} {{ e_tc.nom_tipo_calculo }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="unidad_medida">Unidad de medida:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                        <select class="form-control" id="unidad_medida" name="unidad_medida" v-model="form.unidad_medida">
                            <option v-for="e_um in unidad_medidaArr" :key="e_um.id" :value="e_um.id">{{ e_um.id }} {{ e_um.nom_unidad_medida }}</option>
                        </select>
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="periodo_recoleccion">Periodo de recolección de datos:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="periodo_recoleccion" name="periodo_recoleccion" v-model="form.periodo_recoleccion">
                            <option v-for="e_pr in periodo_recoleccionArr" :key="e_pr.id" :value="e_pr.id">{{ e_pr.id }} {{ e_pr.nom_periodo_recoleccion }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="dimension_indicador">Dimensión:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                        <select class="form-control" id="dimension_indicador" name="dimension_indicador" v-model="form.dimension_indicador">
                            <option v-for="e_di in dimension_indicadorArr" :key="e_di.id" :value="e_di.id">{{ e_di.id }} {{ e_di.nom_dimension_indicador }}</option>
                        </select>
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="mes_disponibilidad">Disponibilidad de la información:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="mes_disponibilidad" name="mes_disponibilidad" v-model="form.mes_disponibilidad">
                            <option v-for="e_md in mes_disponibilidadArr" :key="e_md.id" :value="e_md.id">{{ e_md.id }} {{ e_md.nom_mes }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="tendencia_indicador">Tendencia esperada:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                        <select class="form-control" id="tendencia_indicador" name="tendencia_indicador" v-model="form.tendencia_indicador">
                            <option v-for="e_ti in tendencia_indicadorArr" :key="e_ti.id" :value="e_ti.id">{{ e_ti.id }} {{ e_ti.nom_tendencia_indicador }}</option>
                        </select>
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="dependencia">Unidad Responsable de reportar el avance:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia">
                            <option v-for="e_d in depsArr" :key="e_d.id" :value="e_d.id">{{ e_d.id }} {{ e_d.nom_corto_dependencia }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="metodo_calculo">Método de cálculo:</label>
                    <div class="col-md-9 col-sm-12">
                        <textarea rows="4" class="form-control" name="metodo_calculo" id="metodo_calculo" v-model="form.metodo_calculo" placeholder="Método de cálculo" @keyup="conteo_real('metodo_calculo')"></textarea>
                        <div id="metodo_calculo_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="tipo_formula">Tipo de fórmular:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <select class="form-control" id="tipo_formula" name="tipo_formula" v-model="form.tipo_formula">
                            <option v-for="e_tf in tipo_formulaArr" :key="e_tf.id" :value="e_tf.id">{{ e_tf.id }} {{ e_tf.nom_tipo_formula }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="metodo_calculo">Observaciones:</label>
                    <div class="col-md-9 col-sm-12">
                        <textarea rows="3" class="form-control" name="observaciones" id="observaciones" v-model="form.observaciones" placeholder="Observaciones" @keyup="conteo_real('observaciones')"></textarea>
                        <div id="observaciones_count" class="noChars"></div>
                    </div>
                </div>


                <div class="form-group row">
                    <h4>Línea de base</h4>
                    <hr>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group row">
                            <h5>Variable A</h5>
                            <hr>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_variable_a">Nombre de la variable:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="nom_variable_a" name="nom_variable_a" placeholder="Nombre de la variable" v-model="form.nom_variable_a" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="lb_var_a_valor">Valor de la variable:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="lb_var_a_valor" name="lb_var_a_valor" placeholder="Valor de la variable:" v-model="form.lb_var_a_valor" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="lb_var_a_fuente">Fuente de inf. variable:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="lb_var_a_fuente" name="lb_var_a_fuente" placeholder="Fuente de inf. variable" v-model="form.lb_var_a_fuente" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="lb_var_a_url">Url variable:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="lb_var_a_url" name="lb_var_a_url" placeholder="Url variable" v-model="form.lb_var_a_url" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group row">
                            <h5>Variable B</h5>
                            <hr>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="nom_variable_b">Nombre de la variable:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="nom_variable_b" name="nom_variable_b" placeholder="Nombre de la variable" v-model="form.nom_variable_b" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="lb_var_b_valor">Valor de la variable:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="lb_var_b_valor" name="lb_var_b_valor" placeholder="Valor de la variable:" v-model="form.lb_var_b_valor" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="lb_var_b_fuente">Fuente de inf. variable:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="lb_var_b_fuente" name="lb_var_b_fuente" placeholder="Fuente de inf. variable" v-model="form.lb_var_b_fuente" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-4" to="lb_var_b_url">Url variable:</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" id="lb_var_b_url" name="lb_var_b_url" placeholder="Url variable" v-model="form.lb_var_b_url" />
                            </div>
                        </div>
                    </div>
                </div>




















                <hr>
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="lb_formula_expresion">Sustitución en método de cálculo del indicador:</label>
                    <div class="col-md-9 col-sm-12">
                        <input type="text" class="form-control" id="lb_formula_expresion" name="lb_formula_expresion" placeholder="Sustitución en método de cálculo del indicador" v-model="form.lb_formula_expresion" />
                    </div>
                </div>
                <hr>
                <div class="form-group row">
                    <h4>Valor de línea de base y metas</h4>
                    <hr>
                </div>
                <hr>
                <div class="form-group row">
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="lb_valor">Valor de la Línea Base:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12 midSep">
                        <input type="text" class="form-control" id="lb_valor" name="lb_valor" placeholder="Valor de la Línea Base" v-model="form.lb_valor" />
                    </div>
                    <label class="col-lg-3 col-md-6 col-sm-12 col-form-label" for="lb_ano">Año de la Línea de Base:</label>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <input type="text" class="form-control" id="lb_ano" name="lb_ano" placeholder="Año de la Línea de Base" v-model="form.lb_ano" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="lb_nota">Nota sobre la línea base:</label>
                    <div class="col-md-9 col-sm-12">
                        <textarea rows="3" class="form-control" name="lb_nota" id="lb_nota" v-model="form.lb_nota" placeholder="Nota sobre la línea base" @keyup="conteo_real('lb_nota')"></textarea>
                        <div id="lb_nota_count" class="noChars"></div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="meta_24_valor">Valor de la meta 2024:</label>
                    <div class="col-md-9 col-sm-12">
                        <input type="text" class="form-control" id="meta_24_valor" name="meta_24_valor" placeholder="META 2024" v-model="form.meta_24_valor" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-sm-12 col-form-label" to="meta_24_nota">Nota sobre la meta 2024:</label>
                    <div class="col-md-9 col-sm-12">
                        <textarea rows="3" class="form-control" name="meta_24_nota" id="meta_24_nota" v-model="form.meta_24_nota" placeholder="Nota sobre la meta 2024" @keyup="conteo_real('meta_24_nota')"></textarea>
                        <div id="meta_24_nota_count" class="noChars"></div>
                    </div>
                </div>



        <div class="form-group row">
            <h4>Serie histórica de la meta para el bienestar o parámetro</h4>
            <hr class="hrCond">
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" to="serie_hist_2012">2012:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2012" name="serie_hist_2012" v-model="form.serie_hist_2012" />
            </div>
            <label class="col-md-2 alignCond" to="serie_hist_2013">2013:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2013" name="serie_hist_2013" v-model="form.serie_hist_2013" />
            </div>
            <label class="col-md-2 alignCond" to="serie_hist_2014">2014:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2014" name="serie_hist_2014" v-model="form.serie_hist_2014" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" to="serie_hist_2015">2015:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2015" name="serie_hist_2015" v-model="form.serie_hist_2015" />
            </div>
            <label class="col-md-2 alignCond" to="serie_hist_2016">2016:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2016" name="serie_hist_2016" v-model="form.serie_hist_2016" />
            </div>
            <label class="col-md-2 alignCond" to="serie_hist_2017">2017:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2017" name="serie_hist_2017" v-model="form.serie_hist_2017" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" to="serie_hist_2018">2018:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2018" name="serie_hist_2018" v-model="form.serie_hist_2018" />
            </div>
            <label class="col-md-2 alignCond" to="val_2019">2019:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2019" name="serie_hist_2019" v-model="form.serie_hist_2019" />
            </div>
            <label class="col-md-2 alignCond" to="serie_hist_2020">2020:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="serie_hist_2020" name="serie_hist_2020" v-model="form.serie_hist_2020" />
            </div>
        </div>

        <div class="form-group row">
            <h4>Metas intermedias</h4>
            <hr>
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" for="meta_2021">2021:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="meta_2021" name="meta_2021" v-model="form.meta_2021" />
            </div>
            <label class="col-md-2 alignCond" for="meta_2022">2022:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="meta_2022" name="meta_2022" v-model="form.meta_2022" />
            </div>
            <label class="col-md-2 alignCond" for="meta_2023">2023:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="meta_2023" name="meta_2023" v-model="form.meta_2023" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-2 alignCond" for="meta_2024">2024:</label>
            <div class="col-md-2">
                <input type="text" class="form-control" id="meta_2024" name="meta_2024" v-model="form.meta_2024" />
            </div>
        </div>




                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateMeta_ficha" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateMeta_ficha" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateMeta_ficha" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getMetas_fichaById from '@/helpers/getMetas_fichaById'

import getCatNC from '@/helpers/getCatNC'
import getCatsNc from '@/helpers/getCatsNc'

import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            clase_ind: '',
            clase_indNc: '',
            objetivo_prio: '',
            objetivo_prioNc: '',
            niv_desag: '',
            niv_desagNc: '',
            periodicidad: '',
            periodicidadNc: '',
            tipo_indicador: '',
            tipo_indicadorNc: '',
            tipo_calculo: '',
            tipo_calculoNc: '',
            unidad_medida: '',
            unidad_medidaNc: '',
            periodo_recoleccion: '',
            periodo_recoleccionNc: '',
            dimension_indicador: '',
            dimension_indicadorNc: '',
            mes_disponibilidad: '',
            mes_disponibilidadNc: '',
            tendencia_indicador: '',
            tendencia_indicadorNc: '',
            dependencia: '',
            dependenciaNc: '',
            tipo_formula: '',
            tipo_formulaNc: '',
            form: {
                id: '',
                codigo_meta: '',
                nom_meta: '',
                clase_ind: '',
                objetivo_prio: '',
                descripcion_meta: '',
                niv_desag: '',
                periodicidad: '',
                tipo_indicador: '',
                tipo_calculo: '',
                unidad_medida: '',
                periodo_recoleccion: '',
                dimension_indicador: '',
                mes_disponibilidad: '',
                tendencia_indicador: '',
                dependencia: '',
                metodo_calculo: '',
                tipo_formula: '',
                nom_variable_a: '',
                lb_var_a_valor: '',
                lb_var_a_fuente: '',
                lb_var_a_url: '',
                nom_variable_b: '',
                lb_var_b_valor: '',
                lb_var_b_fuente: '',
                lb_var_b_url: '',
                lb_formula_expresion: '',
                lb_valor: '',
                lb_ano: '',
                lb_nota: '',
                meta_24_valor: '',
                meta_24_nota: '',
                serie_hist_2012: '',
                serie_hist_2013: '',
                serie_hist_2014: '',
                serie_hist_2015: '',
                serie_hist_2016: '',
                serie_hist_2017: '',
                serie_hist_2018: '',
                serie_hist_2019: '',
                serie_hist_2020: '',
                meta_2021: '',
                meta_2022: '',
                meta_2023: '',
                meta_2024: '',
                observaciones: '',
            },
            cla_indsArr: [],
            objetivo_priosArr: [],
            niv_desagsArr: [],
            periodicidadesArr: [],
            tipo_indicadoresArr: [],
            tipo_calculosArr: [],
            unidad_medidaArr: [],
            periodo_recoleccionArr: [],
            dimension_indicadorArr: [],
            mes_disponibilidadArr: [],
            tendencia_indicadorArr: [],
            depsArr: [],
            tipo_formulaArr: [],
        }
    },
    methods: {
        async getMeta_fichaData( id ) {
            const meta_fichaArr = await getMetas_fichaById( id )
            
            return meta_fichaArr[0].meta_ficha
        },

        async setData( id ) {
            const datos = await getMetas_fichaById( id )

            this.form.id = datos.id
            this.form.codigo_meta = datos.codigo_meta
            this.form.nom_meta = datos.nom_meta

            this.form.clase_ind = datos.idclase_ind
            this.clase_indNc = await getCatNC(`/cat_clase_ind/${ datos.idclase_ind }?transform=1&columns=id,nom_clase_ind`).nom_clase_ind

            this.form.objetivo_prio = datos.idobjetivo
            this.objetivo_prioNc = await getCatNC(`/cat_objetivos/${ datos.idobjetivo }?transform=1&columns=id,nom_corto_objetivo`).nom_corto_objetivo
            this.form.descripcion_meta = datos.descripcion_meta

            this.form.niv_desag = datos.idnivel_desagregacion
            this.niv_desagNc = await getCatNC(`/cat_nivel_desagregacion/${ datos.idnivel_desagregacion }?transform=1&columns=id,nom_nivel_desagregacion`).nom_nivel_desagregacion

            this.form.periodicidad = datos.idperiodicidad
            this.periodicidadNc = await getCatNC(`/cat_periodicidad/${ datos.idperiodicidad }?transform=1&columns=id,nom_periodicidad`).nom_periodicidad

            this.form.tipo_indicador = datos.idtipo_indicador
            this.tipo_indicadorNc = await getCatNC(`/cat_tipo_indicador/${ datos.idtipo_indicador }?transform=1&columns=id,nom_tipo_indicador`).nom_tipo_indicador

            this.form.tipo_calculo = datos.idtipo_calculo
            this.tipo_calculoNc = await getCatNC(`/cat_tipo_calculo/${ datos.idtipo_calculo }?transform=1&columns=id,nom_tipo_calculo`).nom_tipo_calculo

            this.form.unidad_medida = datos.idunidad_medida
            this.unidad_medidaNc = await getCatNC(`/cat_unidad_medida/${ datos.idunidad_medida }?transform=1&columns=id,nom_unidad_medida`).nom_unidad_medida

            this.form.periodo_recoleccion = datos.idperiodo_recoleccion
            this.periodo_recoleccionNc = await getCatNC(`/cat_periodo_recoleccion/${ datos.idperiodo_recoleccion }?transform=1&columns=id,nom_periodo_recoleccion`).nom_periodo_recoleccion

            this.form.dimension_indicador = datos.iddimension_indicador
            this.dimension_indicadorNc = await getCatNC(`/cat_dimension_indicador/${ datos.iddimension_indicador }?transform=1&columns=id,nom_dimension_indicador`).nom_dimension_indicador

            this.form.mes_disponibilidad = datos.idmes_disponibilidad
            this.mes_disponibilidadNc = await getCatNC(`/cat_mes/${ datos.idmes_disponibilidad }?transform=1&columns=id,nom_mes`).nom_mes

            this.form.tendencia_indicador = datos.idtendencia_indicador
            this.tendencia_indicadorNc = await getCatNC(`/cat_tendencia_indicador/${ datos.idtendencia_indicador }?transform=1&columns=id,nom_tendencia_indicador`).nom_tendencia_indicador

            this.form.dependencia = datos.id_dependencia
            this.dependenciaNc = await getCatNC(`/cat_dependencias/${ datos.id_dependencia }?transform=1&columns=id,nom_corto_dependencia`).nom_corto_dependencia

            this.form.metodo_calculo = datos.metodo_calculo

            this.form.tipo_formula = datos.idtipo_formula
            this.tipo_formulaNc = await getCatNC(`/cat_tipo_formula/${ datos.idtipo_formula }?transform=1&columns=id,nom_tipo_formula`).nom_tipo_formula

            this.form.nom_variable_a = datos.nom_variable_a
            this.form.lb_var_a_valor = datos.lb_var_a_valor
            this.form.lb_var_a_fuente = datos.lb_var_a_fuente
            this.form.lb_var_a_url = datos.lb_var_a_url
            this.form.nom_variable_b = datos.nom_variable_b
            this.form.lb_var_b_valor = datos.lb_var_b_valor
            this.form.lb_var_b_fuente = datos.lb_var_b_fuente
            this.form.lb_var_b_url = datos.lb_var_b_url
            this.form.lb_formula_expresion = datos.lb_formula_expresion
            this.form.lb_valor = datos.lb_valor
            this.form.lb_ano = datos.lb_ano
            this.form.lb_nota = datos.lb_nota
            this.form.meta_24_valor = datos.meta_24_valor
            this.form.meta_24_nota = datos.meta_24_nota
            this.form.serie_hist_2012 = datos.serie_hist_2012
            this.form.serie_hist_2013 = datos.serie_hist_2013
            this.form.serie_hist_2014 = datos.serie_hist_2014
            this.form.serie_hist_2015 = datos.serie_hist_2015
            this.form.serie_hist_2016 = datos.serie_hist_2016
            this.form.serie_hist_2017 = datos.serie_hist_2017
            this.form.serie_hist_2018 = datos.serie_hist_2018
            this.form.serie_hist_2019 = datos.serie_hist_2019
            this.form.serie_hist_2020 = datos.serie_hist_2020
            this.form.meta_2021 = datos.meta_2021
            this.form.meta_2022 = datos.meta_2022
            this.form.meta_2023 = datos.meta_2023
            this.form.meta_2024 = datos.meta_2024
            this.form.observaciones = datos.observaciones



        },

        async updateMeta_ficha() {
            const dataForm = {
                codigo_meta: this.form.codigo_meta,
                nom_meta: this.form.nom_meta,
               
                idclase_ind: this.form.clase_ind,
                clase_indNc: this.clase_indNc,

                idobjetivo: this.form.objetivo_prio,
                objetivo_prioNc: this.objetivo_prioNc,

                descripcion_meta: this.form.descripcion_meta,

                idnivel_desagregacion: this.form.niv_desag,
                niv_desagNc: this.niv_desagNc,

                idperiodicidad: this.form.periodicidad,
                periodicidadNc: this.periodicidadNc,

                idtipo_indicador: this.form.tipo_indicador,
                tipo_indicadorNc: this.tipo_indicadorNc,

                idtipo_calculo: this.form.tipo_calculo,
                tipo_calculoNc: this.tipo_calculoNc,

                idunidad_medida: this.form.unidad_medida,
                unidad_medidaNc: this.unidad_medidaNc,

                idperiodo_recoleccion: this.form.periodo_recoleccion,
                periodo_recoleccionNc: this.periodo_recoleccionNc,

                iddimension_indicador: this.form.dimension_indicador,
                dimension_indicadorNc: this.dimension_indicadorNc,

                idmes_disponibilidad: this.form.mes_disponibilidad,
                mes_disponibilidadNc: this.mes_disponibilidadNc,

                idtendencia_indicador: this.form.tendencia_indicador,
                tendencia_indicadorNc: this.tendencia_indicadorNc,

                id_dependencia: this.form.dependencia,
                dependenciaNc: this.dependenciaNc,

                metodo_calculo: this.form.metodo_calculo,

                idtipo_formula: this.form.tipo_formula,
                tipo_formulaNc: this.tipo_formulaNc,

                nom_variable_a: this.form.nom_variable_a,
                lb_var_a_valor: this.form.lb_var_a_valor,
                lb_var_a_fuente: this.form.lb_var_a_fuente,
                lb_var_a_url: this.form.lb_var_a_url,
                nom_variable_b: this.form.nom_variable_b,
                lb_var_b_valor: this.form.lb_var_b_valor,
                lb_var_b_fuente: this.form.lb_var_b_fuente,
                lb_var_b_url: this.form.lb_var_b_url,
                lb_formula_expresion: this.form.lb_formula_expresion,
                lb_valor: this.form.lb_valor,
                lb_ano: this.form.lb_ano,
                lb_nota: this.form.lb_nota,
                meta_24_valor: this.form.meta_24_valor,
                meta_24_nota: this.form.meta_24_nota,
                serie_hist_2012: this.form.serie_hist_2012,
                serie_hist_2013: this.form.serie_hist_2013,
                serie_hist_2014: this.form.serie_hist_2014,
                serie_hist_2015: this.form.serie_hist_2015,
                serie_hist_2016: this.form.serie_hist_2016,
                serie_hist_2017: this.form.serie_hist_2017,
                serie_hist_2018: this.form.serie_hist_2018,
                serie_hist_2019: this.form.serie_hist_2019,
                serie_hist_2020: this.form.serie_hist_2020,
                meta_2021: this.form.meta_2021,
                meta_2022: this.form.meta_2022,
                meta_2023: this.form.meta_2023,
                meta_2024: this.form.meta_2024,
                observaciones: this.form.observaciones


            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('meta_ficha', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },

        async getClase_inds() {
            const cla_inds = await getCatsNc('/cat_clase_ind?transform=1&columns=id,nom_clase_ind&filter=id,lt,100')
            return cla_inds.cat_clase_ind
        },

        async getObjetivo_prios() {
            const objetivo_prios = await getCatsNc('/cat_objetivos?transform=1&columns=id,num_objetivo,nom_corto_objetivo&filter=id,lt,100')
            return objetivo_prios.cat_objetivos
        },

        async getNiv_desags() {
            const niv_desags = await getCatsNc('/cat_nivel_desagregacion?transform=1&columns=id,nom_nivel_desagregacion&filter=id,lt,100')
            return niv_desags.cat_nivel_desagregacion
        },

        async getPeriodicidades() {
            const periodicidades = await getCatsNc('/cat_periodicidad?transform=1&columns=id,nom_periodicidad&filter=id,lt,100')
            return periodicidades.cat_periodicidad
        },

        async getTipo_indicadores() {
            const tipo_indicadores = await getCatsNc('/cat_tipo_indicador?transform=1&columns=id,nom_tipo_indicador&filter=id,lt,100')
            return tipo_indicadores.cat_tipo_indicador
        },

        async getTipo_calculos() {
            const tipo_calculos = await getCatsNc('/cat_tipo_calculo?transform=1&columns=id,nom_tipo_calculo&filter=id,lt,100')
            return tipo_calculos.cat_tipo_calculo
        },

        async getUnidades_medida() {
            const unidades_medida = await getCatsNc('/cat_unidad_medida?transform=1&columns=id,nom_unidad_medida&filter=id,lt,100')
            return unidades_medida.cat_unidad_medida
        },

        async getPeriodos_recoleccion() {
            const periodos_recoleccion = await getCatsNc('/cat_periodo_recoleccion?transform=1&columns=id,nom_periodo_recoleccion&filter=id,lt,100')
            return periodos_recoleccion.cat_periodo_recoleccion
        },

        async getDimensiones_indicador() {
            const dimensiones_indicador = await getCatsNc('/cat_dimension_indicador?transform=1&columns=id,nom_dimension_indicador&filter=id,lt,100')
            return dimensiones_indicador.cat_dimension_indicador
        },

        async getMeses_disponibilidad() {
            const meses_disponibilidad = await getCatsNc('/cat_mes?transform=1&columns=id,nom_mes&filter=id,lt,100')
            return meses_disponibilidad.cat_mes
        },

        async getTendencias_indicador() {
            const tendencias_indicador = await getCatsNc('/cat_tendencia_indicador?transform=1&columns=id,nom_tendencia_indicador&filter=id,lt,100')
            return tendencias_indicador.cat_tendencia_indicador
        },

        async getDependencias() {
            const deps = await getCatsNc(`/cat_dependencias?transform=1&columns=id,nom_corto_dependencia&filter=id,lt,100`)
            return deps.cat_dependencias

        },

        async getTipos_formula() {
            const tipos_formula = await getCatsNc('/cat_tipo_formula?transform=1&columns=id,nom_tipo_formula&filter=id,lt,100')
            return tipos_formula.cat_tipo_formula

        },

        conteo_real( id ) {
            const charsCount = document.querySelector("#"+id).value.length
            document.querySelector('#'+ id +'_count').innerHTML = `Número de caraceres: ${charsCount}`
        }


    },
    async mounted() {
        this.setData( charsService.decode( this.$route.params.id ) )
        this.cla_indsArr = await this.getClase_inds()
        this.objetivo_priosArr = await this.getObjetivo_prios()
        this.niv_desagsArr = await this.getNiv_desags()
        this.periodicidadesArr = await this.getPeriodicidades()
        this.tipo_indicadoresArr = await this.getTipo_indicadores()
        this.tipo_calculosArr = await this.getTipo_calculos()
        this.unidad_medidaArr = await this.getUnidades_medida()
        this.periodo_recoleccionArr = await this.getPeriodos_recoleccion()
        this.dimension_indicadorArr = await this.getDimensiones_indicador()
        this.mes_disponibilidadArr = await this.getMeses_disponibilidad()
        this.tendencia_indicadorArr = await this.getTendencias_indicador()
        this.depsArr = await this.getDependencias()
        this.tipo_formulaArr = await this.getTipos_formula()
        this.conteo_real('nom_meta')
        this.conteo_real('descripcion_meta')
        this.conteo_real('metodo_calculo')
        this.conteo_real('observaciones')
        this.conteo_real('lb_nota')
        this.conteo_real('meta_24_nota')

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    h4, h5 {
        color: #9F2241;
    }

    .alignCond {
        text-align: right;
    }

    .divBtn {
        padding: 50px 0;
    }

    @media screen and (max-width: 992px) {
        .midSep {
            margin-bottom: 15px;
        }
    }

    @media screen and (max-width: 768px) {
        .hrCond {
            display: none;
        }

        .alignCond {
            text-align: left;
        }

        .btnCond {
            display: block;
            width: 100%;
        }
    }

    form {
        padding-top: 0;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
        font-size: medium;
    }
</style>