<template>
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
        <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
        <li class="breadcrumb-item active" aria-current="page"><a href="#">Cambio de contraseña</a></li>
    </ol>
    <h2>Cambio de contraseña</h2>
    <hr class="red mb-5">
    <div class="row">
        <div>
            Se recomienda usar una contraseña segura que no uses en ningún otro sitio y debe estar conformada por lo menos con:
            <ul>
                <li>8 caracteres</li>
                <li>Una mayúscula</li>
                <li>Una minúscula</li>
                <li>Un número</li>
                <li>Un caracter especial</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 col-sm-12">
            <div class="panel panel-success">
                <div class="panel-heading text-center">
                    <h3 class="panel-title">Cambio de contraseña</h3>
                </div>
                <div class="panel-body">
                    <form role="form" @submit.prevent="changePass">
                        <div class="form-group row has-success has-feedback">
                            <label class="control-label" for="password">Nueva contraseña:</label>
                            <div class="col-sm-12">
                                <div class="input-group flex-nowrap">
                                    <span class="input-group-addon">*</span>
                                    <input type="password" id="password" v-model="password" class="form-control" placeholder="Nueva contraseña" aria-label="Nueva contraseña" aria-describedby="inputPassword">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row has-success has-feedback">
                            <label class="control-label" for="password">Confirma nueva contraseña:</label>
                            <div class="col-sm-12">
                                <div class="input-group flex-nowrap">
                                    <span class="input-group-addon">*</span>
                                    <input type="password" id="newPassword" v-model="newPassword" class="form-control" placeholder="Confirma nueva contraseña" aria-label="Confirma nueva contraseña" aria-describedby="inputNewPassword">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 text-right">
                                <input type="submit" class="btn btn-primary active btnCond" value="Cambiar" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import getDataByAny from '@/helpers/getDataByAny'
import updateDataByUserId from '@/helpers/updateDataByUserId'
import sendMailService from '@/services/SendMailService'
import getAccesoByCode from '@/helpers/getAccesoByCode'
import sha1 from 'sha1';
import router from '../../router/index';

export default {
    data() {
        return {
            password: null,
            newPassword: null,
            mensaje: ''
        }
    },
    methods: {
        async changePass() {
            const pssNew = document.querySelector('#password').value;
            const pssRe = document.querySelector('#newPassword').value;
            const specialChars = /[(@!#$%^&*)(+=._-]{1,}/

            if ( !pssNew ) {
                this.$notify({
                    title: 'Error!', text: `La contraseña nueva no puede estar vacía`, type: 'error', duration: 5000, speed: 1000
                })
            } else if ( pssNew.length < 8 ) {
                this.$notify({
                    title: 'Error!', text: 'La contraseña debe contener por lo menos 8 caracteres', type: 'error', duration: 5000, speed: 1000
                })
            } else if ( !/[a-z]/.test(pssNew) || !/[A-Z]/.test(pssNew) || !/[0-9]/.test(pssNew) || !specialChars.test(pssNew) ) {
                this.$notify({
                    title: 'Error!', text: 'La contraseña debe contener por lo menos una mayúscula, una minúscula, un número y un caracter especial', type: 'error', duration: 5000, speed: 1000
                })
            } else if ( pssNew !== pssRe ) {
                this.$notify({
                    title: 'Error!', text: 'La contraseña nueva y su confirmación no coinciden', type: 'error', duration: 5000, speed: 1000
                })
            } else {
                const arrUpd = [{ password: sha1(pssNew), code: null }]
                const jsonUpd = JSON.parse(JSON.stringify( arrUpd ))
                
                const acceso = await getAccesoByCode( this.$route.params.hash )
                
                if ( acceso.length > 0){
                    const resp = await updateDataByUserId('acceso', acceso[0].id_usuarios, jsonUpd)
                                        .then( resp => {
                                            this.$notify({
                                                title: 'Correcto', text: 'La contraseña fue actualizada correctamente. Ahora puedes empezar a utilizarla', type: 'success', duration: 5000, speed: 1000
                                            })
                                            this.$router.push('/login')
                                        })
                } else {
                    this.$notify({
                        title: 'Error!', text: 'Hubo un problema al cambiar la contraseña, por favor vuelve a solicitar la dirección para realizar el cambio', type: 'error', duration: 5000, speed: 1000
                    })
                    this.$router.push('/recover')
                }
            }
        }
    }
}
</script>

<style scoped>
    form {
        padding-top: 0;
    }

    .card {
        margin: 0 auto;
    }

    .card-header {
        background-color: #235B4E;
        color: #FFF;
    }

    .card-body {
        background-color: #FFF;
    }

    .content {
        padding: 20px;
    }

    .note {
        width: 100%;
        font-size: small;
        text-align: right;
    }

    .input-group {
        display: flex;
    }

    .panel-center {
        margin: auto 0;
    }

    .panel-heading {
        background-color: #235B4E;
        color: #FFF;
    }

    .panel-body {
        padding: 30px;
    }

    .input-group-addon {
        width: 40px;
    }
    
</style>