/* Regresa todos los registros de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'
import getAccesoByUser from './getAccesoByUser'

const updateDataByIdCol = async( table, id, data, useUsr = true ) => {

    if (useUsr) {
        const getIdUsr = await getAccesoByUser( id )
        const idAcc = getIdUsr.id;
    }

    const promiseArr = [
        simejuvApi.put(`/${ table }/${ id }`, data)
    ]

    const [ upd ] = await Promise.all( promiseArr )

    return [
        { res: upd.data }
    ]
}

export default updateDataByIdCol