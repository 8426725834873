/* Regresa un registro de la tabla de cat_dimension_indicador */

import simejuvApi from '../api/simejuvApi'

const getDataByAnyDimension_indicador = async( table, column, value ) => {
    const promiseArr = [
        simejuvApi.get(`/${ table }?transform=1&filter=${ column },eq,${ value }`)
    ]

    const [ datos ] = await Promise.all( promiseArr )
    
    return datos.data.cat_dimension_indicador

}

export default getDataByAnyDimension_indicador