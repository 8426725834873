<template>
    <div  v-for="e in datosProjuventud" :key="e.id">
        <h3>{{ e.que_es_titulo }} </h3>
        <div class="row">
            <h4 class="subTitle">{{ e.que_es_subtitulo }}</h4>
                <div class="article-body" >
                    <p class="card-text" v-html="e.que_es_contenido"></p>
                </div>
                <div class="resp-container" align='center'>
                    <iframe width="560" height="315" class="embed-responsive-item" allowfullscreen :src="url_video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
        </div>
    </div>
</template>

<script>
import getCatProjuventud from '@/helpers/getCatProjuventud'
import { url } from '@/api/baseUrl'


export default {
    data() {
        return { 
            datosProjuventud: [],
        }
    },
    methods: {
        async getProjuventud() {
            const dataProjuventud = await getCatProjuventud();
            const arrShow = [];

            const que_es_titulo = `${ dataProjuventud[0].que_es_titulo } `;
            const que_es_subtitulo = `${ dataProjuventud[0].que_es_subtitulo } `;
            const que_es_contenido = `${ dataProjuventud[0].que_es_contenido } `;
            const url_videoDB =  url.value + '/uploads/' + `${ dataProjuventud[0].tmp_name }`;

               arrShow.push({
                    que_es_titulo,
                    que_es_subtitulo,
                    que_es_contenido,
                    url_videoDB
                })

            return arrShow
        }
    },
    async mounted() {
        
        this.datosProjuventud = await this.getProjuventud();
        this.url_video= this.datosProjuventud[0].url_videoDB;

        // this.$gtag.event('page_view', {'page_title' : 'Que es PROJUVENTUD'}); //Para registrar el evento en Google Analytics y colocar titulo a la Página
        this.$gtag.event('Que es PROJUVENTUD');                                  //Registra el Evento en Google Analytics con nombre    
        window.scrollTo(0, 0)
    } 
}

</script>
<style scoped>
    .subTitle {
        font-weight: normal;
        padding: 15px;
    }
    .article-body {
        padding: 15px;
    }

    .article-body>p {
        padding: 10px 0;
    }

    strong {
        color: #235B4E;
    }
    .article-body {
        text-align: justify;
    }
</style>