/* Regresa todos los datos del usuario con el id enviado de la tabla 'cat_projuventud' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getProjuventudesById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_projuventud/${ id }`)
    ]

    const [ cat_projuventud ] = await Promise.all( promiseArr )

    return cat_projuventud.data
}

export default getProjuventudesById