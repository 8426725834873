<template>
    <h4>Metas para el bienestar y Parámetros</h4>
    <hr>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar la meta o parámetro? </h5>
            <h6> {{ datosMeta_ficha }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getMetas_fichaById from '@/helpers/getMetas_fichaById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosMeta_ficha: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
            const delItem = await updateDataByIdCol( 'meta_ficha', charsService.decode (this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getMeta_ficha() {
            const dataUser = await getMetas_fichaById( charsService.decode (this.$route.params.id) );

            const cadena = `${ dataUser.codigo_meta } - ${ dataUser.nom_meta }`;

            return cadena
        }
    },
    async mounted() {
        this.datosMeta_ficha = await this.getMeta_ficha();
        // this.getMeta_ficha()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>