<template>
    <div class="row">
            <div class="article-body" >
                <p class="card-text" v-html="this.interv_contenido"></p>
            </div>
    </div>

    <div class="row mb-5">
        <div class="col-md-12">
            <form>


<el-card class="box-card" style = "backgroundColor: #FBFCFC" shadow="hover">
    <template #header>
        <div class="card-header" >
            <span><strong><p class="card-text" v-html="this.interv_subtitulo"></p></strong></span>
        </div>
    </template>




                <div class="form-group row">
                    <label class="col-md-4" to="nom_funcionario">Nombre de la persona funcionaria pública:<div class="subQuest">Nombre de la persona funcionaria pública que registra la Intervención Gubernamental</div></label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_funcionario" name="nom_funcionario" placeholder="Nombre de la persona funcionaria pública" v-model="form.nom_funcionario" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_cargo">Cargo de la persona funcionaria pública:<div class="subQuest">Cargo de la persona funcionaria pública que registra la Intervención Gubernamental</div></label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nom_cargo" name="nom_cargo" placeholder="Cargo de la persona funcionaria pública" v-model="form.nom_cargo" />
                    </div>
                </div>



                <div class="form-group row">
                    <label class="col-md-4" to="email">Correo electrónico:<div class="subQuest">Correo oficial del funcionario</div></label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="email" name="email" placeholder="Correo electrónico" v-model="form.email" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="dependencia">Dependencia o Entidad Federativa:<div class="subQuest">Nombre de la Dependencia o Entidad Federativa bajo la cual se desarrolla la intervención</div></label>
                    <div class="col-md-8">
                        <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e in depsArr" :key="e.id" :value="e.id">{{ e.dependencia }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="areaImplementa">Unidad o área ejecutora:<div class="subQuest">Área específica responsable de la implementación de la intervención</div></label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="areaImplementa" name="areaImplementa" placeholder="Unidad o área ejecutora" v-model="form.areaImplementa" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="interGuberna">Nombre de la intervención gubernamental:<div class="subQuest">Nombre asignado a la intervención para su identificación</div></label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="interGuberna" name="interGuberna" placeholder="Nombre de la intervención gubernamental" v-model="form.interGuberna" />
                    </div>
                </div>
                
                <div class="form-group row">
                    <label class="col-md-4" to="vigencia">Vigencia:<div class="subQuest">Se refiere al periodo durante el cual la oportunidad o servicio está activo y disponible para su solicitud y/o aprovechamiento por parte de la población objetivo</div></label>
                    <div class="col-md-8">
                        <div class="form-group row">
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div><small>Fecha de Inicio</small></div>
                                <Datepicker v-model="form.vigencia_inicio" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatInicio" name="vigencia_inicio" id="vigencia_inicio" placeholder="Seleccione fecha" class="datepicker"></Datepicker>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12"> </div> 
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div><small>Fecha de Termino</small></div>
                                <Datepicker v-model="form.vigencia_fin" locale="es-MX" autoApply :enableTimePicker="false" :format="readFormatFin" name="vigencia_fin" id="vigencia_fin" placeholder="Seleccione fecha" class="datepicker"></Datepicker>
                            </div>
                        </div>
                    </div>
                    <label class="col-md-8" to="vigencia"><div class="subQuest"> </div></label>
                    <div class="col-md-8">
                        <div><small>Observaciones:</small></div>
                        <textarea class="form-control" name="vigencia" id="vigencia" placeholder="Vigencia" rows="3" v-model="form.vigencia"></textarea>
                    </div>
                </div>


                
                
                
             
                
                
                <div class="form-group row">
                    <label class="col-md-4" to="tipoIntervencion">Tipo de Intervención:<div class="subQuest">Forma de la acción implementada con impacto en la población joven. Se presentan a través de participaciones, regulaciones o actividades.</div></label>
                    <div class="col-md-8">
                        <select class="form-control" id="tipoIntervencion" name="tipoIntervencion" v-model="form.tipoIntervencion" @change="otrasSelected">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e in tipoIntArr" :key="e.id" :value="e.id">{{ e.tipo_intervencion }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" v-if="form.tipoIntervencion === 10">
                    <label class="col-md-4" to="otroIntervencion">"Otro" tipo de intervención:<div class="subQuest">Especifica el tipo de intervención que no se encuentra dentro de la clasificación anterior.</div></label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="otroIntervencion" name="otroIntervencion" placeholder="Otro tipo de intervención" v-model="form.otroIntervencion" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="objetivo">Objetivo de la intervención gubernamental:<div class="subQuest">Especifica el propósito que persigue la intervención gubernamental al ser implementada</div></label>
                    <div class="col-md-8">
                        <textarea class="form-control" name="objetivo" id="objetivo" placeholder="Objetivo de la intervención gubernamental" rows="3" v-model="form.objetivo"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="tipoSolicitante">Tipo de población beneficiaria:<div class="subQuest">Se refiere al responsable de tramitar directamente el servicio o beneficio.</div></label>
                    <div class="col-md-8">
                        <select class="form-control" id="tipoSolicitante" name="tipoSolicitante" v-model="form.tipoSolicitante" @click="LimpiaTipoActor">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e in tipoSolic" :key="e.id" :value="e.id">{{ e.tipo_solicitante }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" v-show="form.tipoSolicitante === 2 || form.tipoSolicitante === 3">
                    <div class="form-group row">
                        <label class="col-md-4" to="tipoActor1">Tipo de actor solicitante/Responsable de la información:<div class="subQuest">Se refiere a la clasificación de actores sociales que pueden solicitar el beneficio o servicio de la intervención gubernamental.</div></label>
                        <div class="col-md-8">
                            <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                                <div v-for="e in tipoResIn" :key="e" class="col-md-6" style="padding:0px">                                    
                                    <label class="toggle">
                                        <input class="toggle-checkbox" type="checkbox" :name="'tipoActor' + e.id" :id="'tipoActor' + e.id" :value='e.tipo_resp_info' v-model="form.tipoActor[e.id]" @click="tipoActorTodos([e.id])">
                                        <div class="toggle-switch"></div><span class="toggle-label">{{ e.tipo_resp_info }}</span>
                                    </label><br>
                                </div>    
                            </el-card>    
                        </div>
                    </div>    
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="poblacionObjetivo">Población objetivo:<div class="subQuest">Dirigido a jóvenes hombres ó mujeres cuyas edades estén entre 12 a 29 años.</div></label>
                    <div class="col-md-8">
                        <textarea class="form-control" name="poblacionObjetivo" id="poblacionObjetivo" placeholder="Población objetivo" rows="3" v-model="form.poblacionObjetivo"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="grupoEdad">Grupo de edad atendido:<div class="subQuest">Se refiere a la población a la cual están dirigidos los beneficios producidos por la intervención.</div></label>
                    <div class="col-md-8">
                        <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" name="grupoEdad1" id="grupoEdad1" value="Adolescentes" v-model="form.gpoEdad[1]">
                                <div class="toggle-switch"></div><span class="toggle-label">12 a 17 años</span>
                            </label><br>   
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" name="grupoEdad2" id="grupoEdad2" value="Jóvenes mayores de edad" v-model="form.gpoEdad[2]">
                                <div class="toggle-switch"></div><span class="toggle-label">18 a 29 años</span>
                            </label><br>   
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" name="grupoEdad3" id="grupoEdad3" value="Personas adultas" v-model="form.gpoEdad[3]"  @click="NoAplicaChecked">
                                <div class="toggle-switch"></div><span class="toggle-label">No se especifica en tanto no es exclusivo para algún rango de edad</span>
                            </label>
                        </el-card>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="tipoMontosApoyo">Tipo y montos de apoyo:<div class="subQuest">Forma y descripción del apoyo, estímulo o subvención que ofrece la intervención gubernamental en caso de ser beneficiario(a).</div></label>
                    <div class="col-md-8">
                        <textarea class="form-control" name="tipoMontosApoyo" id="tipoMontosApoyo" placeholder="Tipo y montos de apoyo" rows="3" v-model="form.tipoMontosApoyo"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="webIntervencion">Referencia web de la intervención gubernamental:<div class="subQuest">Enlace hacia el sitio web donde pueda localizarse información completa respecto a la intervención gubernamental</div></label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="webIntervencion" name="webIntervencion" placeholder="Referencia web de la intervención gubernamental" v-model="form.webIntervencion" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nomPrograma">Programa presupuestario:<div class="subQuest">Nombre del programa presupuestario a través del cuál se opera y/o desarrolla la intervención</div></label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="nomPrograma" name="nomPrograma" placeholder="Programa presupuestario" v-model="form.nomPrograma" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="normatividad">¿Cuenta con normatividad? (Reglas, Políticas o Lineamientos de Operación):<div class="subQuest">La intervención gubernamental puede o no contar con algún tipo de normatividad que la rija.</div></label>
                    <div class="col-md-8">
                        <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                            <label class="toggler">
                                <input class="toggler-radio" type="radio" :name="normatividad" id="normatividad1" v-model="form.normatividad" value='Si'>
                                <div class="toggler-switch"></div><span class="toggler-label">Si</span>
                            </label>
                            <label class="toggler">
                                <input class="toggler-radio" type="radio" :name="normatividad" id="normatividad2" v-model="form.normatividad" value='No' @click="LimpiaNormatividad">
                                <div class="toggler-switch"></div><span class="toggler-label">No</span>
                            </label><br>
                        </el-card>   
                        <!--    <span>Eligió: {{ form.normatividad }}</span>    -->
                    </div>
                </div>
                <div class="form-group row" v-if="form.normatividad === 'Si'">
                    <label class="col-md-4" to="tipoNormatividad">Tipo de normatividad:<div class="subQuest">Se refiere al tipo de documento normativo que ampara la intervención gubernamental</div></label>
                    <div class="col-md-8">
                        <select class="form-control" id="tipoNormatividad" name="tipoNormatividad" v-model="form.tipoNormatividad">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e in tipoNorma" :key="e.id" :value="e.id">{{ e.tipo_normatividad }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row" v-if="form.normatividad === 'Si'">
                    <label class="col-md-4" to="webNormatividad">Referencia web de la normatividad:<div class="subQuest">Dirección electrónica donde se pueden encontrar la documentación normativa para regir la operación de las intervenciones gubernamentales.</div></label>
                    <div class="col-md-8">
                        <textarea class="form-control" name="webNormatividad" id="webNormatividad" placeholder="Referencia web de la normatividad" rows="3" v-model="form.webNormatividad"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="estado">Ámbito geográfico de la intervención:<div class="subQuest">Se refiera a la determinación del espacio geográfico (nacional o por entidad federativa) donde es aplicada la intervención gubernamental.</div></label>
                    <div class="col-md-8">
                        <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                            <div v-for="e in ambGeo" :key="e" class="col-md-4" style="padding:2px">                                    
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" :name="'nom_estado' + e.id" :id="'nom_estado' + e.id" :value='e.nom_estado' v-model="form.nom_estado[e.id]" @click="estadoNacional([e.id])">
                                    <div class="toggle-switch"></div><span class="toggle-label">{{ e.nom_estado }}</span>
                                </label><br>
                            </div>    
                        </el-card>    
                    </div>
                </div>
                <!-- Inicio de carga de archivos -->
                <div class="form-group row">
                    <label class="col-md-4" for="archivo">Material gráfico sobre la intervención:<div class="subQuest">Sólo se permite subir imagenes en los siguientes formatos y tamaño: <br> - Imagenes en formato jpg, png y bmp. <br> - El tamaño máximo permitidos por imagen es de 5 MB</div></label>
                    <div class="col-md-7">
                        <div v-if="successAlert" class="alert alert-success alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="successAlert=false">&times;</a>
                            {{ successMessage }}
                        </div>
                        <div v-if="errorAlert" class="alert alert-danger alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="errorAlert=false">&times;</a>
                            {{ errorMessage }}
                        </div>
                        <input type="file" ref="file" :disabled='isDisabled' id="fileUp" />
                        <br>
                        <button type="button" @click="uploadFile" class="btn btn-default btn-sm" :disabled='isDisabled'>Almacenar archivo</button>
                    </div>
                </div>
                <div>
                    <div class="form-group row">
                        <div class="col-md-5"></div>
                        <div class="col-md-7">
                            <table id="tblFiles" class="table table-striped">
                                <thead class="table-striped">
                                    <tr>
                                        <th>Nombre del archivo</th>
                                        <th colspan="2">Intervenciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(e, index) in arrArchivos" :key="e">
                                        <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><img :src="require(`@/assets/images/icons/${ e.extension }.svg`)" class="iconImg" /> {{ e.file_name_dec }}</a></td>
                                        <td><a :href="`${ubApi}/download.php?tmpFileName=${ e.tmp_name }&fileName=${ e.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download-alt"></i></a></td>
                                        <td><a href="#" @click="deleteFile( e.id, index)" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> 
                <!-- Fin de carga de archivos -->


                <div class="form-group row">
                    <label class="col-md-4" to="ejeGral">Eje General del Plan Nacional de Desarrollo 2019-2024:<div class="subQuest">Alineación de las intervenciones gubernamentales con cada uno de los ejes generales descritos en el PND 2019-2024</div></label>
                    <div class="col-md-8">
                        <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                            <label class="toggler">
                                <input class="toggler-radio" type="radio" :name="ejeGral" id="ejeGral1" v-model="form.ejeGral" value='Política y Gobierno'>
                                <div class="toggler-switch"></div><span class="toggler-label">Política y Gobierno</span>
                            </label><br>
                            <label class="toggler">
                                <input class="toggler-radio" type="radio" :name="ejeGral" id="ejeGral2" v-model="form.ejeGral" value='Política Social'>
                                <div class="toggler-switch"></div><span class="toggler-label">Política Social</span>
                            </label><br>
                            <label class="toggler">
                                <input class="toggler-radio" type="radio" :name="ejeGral" id="ejeGral3" v-model="form.ejeGral" value='Economía'>
                                <div class="toggler-switch"></div><span class="toggler-label">Economía</span>
                            </label><br>
                        </el-card>   
                        <!--    <span>Eligió: {{ form.ejeGral }}</span>    -->
                    </div>
                </div>
                
                <div class="form-group row">
                    <label class="col-md-4" to="nom_objetivo">Alineación con los Objetivos Prioritarios del Programa Nacional de Juventud 2021-2024<div class="subQuest">Alineación de las intervenciones con alguno de los Objetivos Prioritarios del PROJUVENTUD 2021-2024, documento rector para la definición, articulación e instrumentación de una política nacional en materia de juventud.</div></label>
                    <div class="col-md-8">
                        <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                            <div v-for="e in arrObjetivos" :key="e" class="col-md-10" style="padding:2px">                                    
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" :name="'nom_objetivo' + e.id" :id="'nom_objetivo' + e.id" :value='e.nom_objetivo' :v-model="form.nom_objetivo[e.id]">
                                    <div class="toggle-switch"></div><span class="toggle-label">{{ e.num_objetivo }}. {{ e.nom_objetivo }}</span>
                                </label><br>
                            </div>    
                        </el-card>    
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_objetivo_ods">Armonización de los Objetivos de Desarrollo Sostenible de la Agenda 2030<div class="subQuest">Alineación de las intervenciones con alguno de los 17 Objetivos de Desarrollo Sostenible (ODS) que integran la Agenda 2030</div></label>
                    <div class="col-md-8">
                        <el-card class="box-card" style = "backgroundColor: #E6E8EB" shadow="hover">
                            <div v-for="e in arrObjetivosODS" :key="e" class="col-md-10" style="padding:2px">                                    
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" :name="'nom_objetivo_ods' + e.id" :id="'nom_objetivo_ods' + e.id" :value='e.nom_objetivo_ods' :v-model="form.nom_objetivo_ods[e.id]">
                                    <div class="toggle-switch"></div><span class="toggle-label">{{ e.nom_objetivo_ods }}</span>
                                </label><br>
                            </div>    
                        </el-card>    
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateClase_ind" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra intervención" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Guardar intervención" @click="addIntervencion" :disabled="added" />
                    </div>
                </div>


</el-card>
            </form>



        </div>
    </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'
import '/src/assets/css/vue-datepicker/main.css'

import addData from '@/helpers/addData'
import getIntervParam from '@/helpers/getIntervParam'

import getCatDepIntervencion from '@/helpers/getCatDepIntervencion'
//import getCatProgPresupuestario from '@/helpers/getCatProgPresupuestario'
import getCatTipoIntervencion from '@/helpers/getCatTipoIntervencion'
import getCatTipoNormatividad from '@/helpers/getCatTipoNormatividad'
import getCatTipoSolicitante from '@/helpers/getCatTipoSolicitante'
import getCatTipoRespInfo from '@/helpers/getCatTipoRespInfo'
import getCatEstados from '@/helpers/getCatEstados'
import getCatObj_PROJUVENTUD from '@/helpers/getCatObjetivos'
import getCatObjetivos_ODS from '@/helpers/getCatObjetivos_ODS'
//import { mount } from '@vue/test-utils'

//Para archivos
import insertData from '@/helpers/addData'
import updateData from '@/helpers/updateData'
import axios from 'axios'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';
import getArchivos from '@/helpers/getArchivos'
import getArchivosAvance from '@/helpers/getArchivosAvance'
import getArchivosTmp from '@/helpers/getArchivosTmp'
import sendMailService from '@/services/SendMailService'
import getUsersById from '@/helpers/getUsersById';


export default {
    components: {
        Datepicker,
    },
    data() {
        return {
            interv_contenido: '',
            interv_subtitulo: '',
            form: {
                nom_funcionario: '',
                nom_cargo: '',
                email: '',
                dependencia: '',
                areaImplementa: '',
                interGuberna: '',
                webIntervencion: '',
                nomPrograma: '',
                tipoIntervencion: '',
                otroIntervencion: '',
                objetivo: '',
                normatividad: '',
                tipoNormatividad: '',
                webNormatividad: '',
                tipoSolicitante: '',
                tipoActor: [],
                tipoMontosApoyo: '',
                poblacionObjetivo: '',
                gpoEdad: [],
                vigencia: '',
                vigencia_inicio: '',
                vigencia_fin: '',
                nom_estado: [],
                ejeGral: '',
                nom_objetivo: [],
                nom_objetivo_ods: [],
            },
            noValid: {
                nom_clase_ind: false,
            },
            fecha_hoy: new Date(),
            vigencia_inicio: new Date().toLocaleDateString("es-MX").substr(0, 10),
            vigencia_fin: new Date().toLocaleDateString("es-MX").substr(0, 10),
            depsArr: [],
            //ppArr: [],
            tipoIntArr: [],
            tipoNorma: [],
            tipoSolic: [],
            tipoResIn: [],
            ambGeo: [],
            arrObjetivos: [],
            arrObjetivosODS: [],
            passDif: false,
            added: false,

            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,
            uploadedImage: '',
            arrFilesUploaded: [],
            arrArchivos: [],
            iconImg: '',
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nom_funcionario = ''
            this.form.nom_cargo = ''
            this.form.email = ''
            this.form.dependencia = ''
            this.form.areaImplementa = ''
            this.form.interGuberna = ''
            this.form.webIntervencion = ''
            this.form.nomPrograma = ''
            this.form.tipoIntervencion = ''
            this.form.otroIntervencion = ''
            this.form.objetivo = ''
            this.form.normatividad = ''
            this.form.tipoNormatividad = ''
            this.form.webNormatividad = ''
            this.form.tipoSolicitante = ''
            //this.form.tipoActor = ''
            this.form.tipoActor[1] = ''
            this.form.tipoActor[2] = ''
            this.form.tipoActor[3] = ''
            this.form.tipoActor[4] = ''
            this.form.tipoActor[5] = ''
            this.form.tipoActor[6] = ''
            this.form.tipoActor[7] = ''
            this.form.tipoActor[8] = ''
            this.form.tipoActor[9] = ''
            this.form.tipoActor[10] = ''
            this.form.tipoActor[11] = ''
            this.form.tipoMontosApoyo = ''
            this.form.poblacionObjetivo = ''
            this.form.gpoEdad[1] = ''
            this.form.gpoEdad[2] = ''
            this.form.gpoEdad[3] = ''
            this.form.vigencia = ''
            this.form.vigencia_inicio = ''
            this.form.vigencia_fin = ''
            this.form.nom_estado[1] = ''
            this.form.nom_estado[2] = ''
            this.form.nom_estado[3] = ''
            this.form.nom_estado[4] = ''
            this.form.nom_estado[5] = ''
            this.form.nom_estado[6] = ''
            this.form.nom_estado[7] = ''
            this.form.nom_estado[8] = ''
            this.form.nom_estado[9] = ''
            this.form.nom_estado[10] = ''
            this.form.nom_estado[11] = ''
            this.form.nom_estado[12] = ''
            this.form.nom_estado[13] = ''
            this.form.nom_estado[14] = ''
            this.form.nom_estado[15] = ''
            this.form.nom_estado[16] = ''
            this.form.nom_estado[17] = ''
            this.form.nom_estado[18] = ''
            this.form.nom_estado[19] = ''
            this.form.nom_estado[20] = ''
            this.form.nom_estado[21] = ''
            this.form.nom_estado[22] = ''
            this.form.nom_estado[23] = ''
            this.form.nom_estado[24] = ''
            this.form.nom_estado[25] = ''
            this.form.nom_estado[26] = ''
            this.form.nom_estado[27] = ''
            this.form.nom_estado[28] = ''
            this.form.nom_estado[29] = ''
            this.form.nom_estado[30] = ''
            this.form.nom_estado[31] = ''
            this.form.nom_estado[32] = ''
            this.form.nom_estado[33] = ''
            this.form.ejeGral = ''
            
            this.form.nom_objetivo[1] = ''
            this.form.nom_objetivo[2] = ''
            this.form.nom_objetivo[3] = ''
            this.form.nom_objetivo[4] = ''
            this.form.nom_objetivo[5] = ''
            this.form.nom_objetivo[6] = ''

            this.form.nom_objetivo_ods[1] = ''
            this.form.nom_objetivo_ods[2] = ''
            this.form.nom_objetivo_ods[3] = ''
            this.form.nom_objetivo_ods[4] = ''
            this.form.nom_objetivo_ods[5] = ''
            this.form.nom_objetivo_ods[6] = ''
            this.form.nom_objetivo_ods[7] = ''
            this.form.nom_objetivo_ods[8] = ''
            this.form.nom_objetivo_ods[9] = ''
            this.form.nom_objetivo_ods[10] = ''
            this.form.nom_objetivo_ods[11] = ''
            this.form.nom_objetivo_ods[12] = ''
            this.form.nom_objetivo_ods[13] = ''
            this.form.nom_objetivo_ods[14] = ''
            this.form.nom_objetivo_ods[15] = ''
            this.form.nom_objetivo_ods[16] = ''
            this.form.nom_objetivo_ods[17] = ''

            this.added = false

            window.scrollTo(0, 0)
        },
        async addIntervencion() {
            let errors = 0
            errors += this.validarVacios(this.form.nom_funcionario, '#nom_funcionario')
            errors += this.validarVacios(this.form.nom_cargo, '#nom_cargo')
            errors += this.validarVacios(this.form.email, '#email')
            errors += this.validarVacios(this.form.dependencia, '#dependencia')
            errors += this.validarVacios(this.form.areaImplementa, '#areaImplementa')
            errors += this.validarVacios(this.form.interGuberna, '#interGuberna')

            errors += this.validarVacios(this.form.vigencia, '#vigencia')
            errors += this.validarVacios(this.form.vigencia_inicio, '#vigencia_inicio')
            errors += this.validarVacios(this.form.vigencia_fin, '#vigencia_fin')
            //errors += this.validarVacios(this.form.nomPrograma, '#nomPrograma')
            errors += this.validarVacios(this.form.tipoIntervencion, '#tipoIntervencion')
            errors += this.validarVacios(this.form.objetivo, '#objetivo')
            //errors += this.validarVacios(this.form.normatividad, '#normatividad')
            errors += this.validarVacios(this.form.tipoSolicitante, '#tipoSolicitante')
          //  errors += this.validarVacios(this.form.tipoActor, '#tipoActor')
            errors += this.validarVacios(this.form.tipoMontosApoyo, '#tipoMontosApoyo')
            errors += this.validarVacios(this.form.poblacionObjetivo, '#poblacionObjetivo')
            errors += this.validarVacios(this.form.webIntervencion, '#webIntervencion')

            //errors += (this.validarVacios(this.form.grupoEdad1, '#grupoEdad1'))
            
          //  errors += this.validarVacios(this.form.ejeGral, '#ejeGral')
          
          //  errors += this.validarVacios(this.form.nom_objetivo_ods, '#nom_objetivo_ods')
            if ( errors > 0 ){
                this.$notify({
                    title: 'Error', text: 'Hay campos requeridos que necesitan ser llenados. Por favor verifique', type: 'error', duration: 5000, speed: 1000
                })
                return
            } else {
                this.fecha_hoy = (this.fecha_hoy)? this.fecha_hoy.toISOString().split('T')[0] : '' 
                const vigencia_inicio = (this.form.vigencia_inicio)? this.form.vigencia_inicio.toISOString().split('T')[0] : ''
                const vigencia_fin = (this.form.vigencia_fin)? this.form.vigencia_fin.toISOString().split('T')[0] : ''

                const dataForm = {
                    fecha_registro: this.fecha_hoy,
                    nom_funcionario: this.form.nom_funcionario,
                    nom_cargo: this.form.nom_cargo,
                    email: this.form.email,
                    dependencia: this.form.dependencia,
                    areaImplementa: this.form.areaImplementa,
                    interGuberna: this.form.interGuberna,
                    webIntervencion: this.form.webIntervencion,
                    nomPrograma: this.form.nomPrograma,
                    tipoIntervencion: this.form.tipoIntervencion,
                    otroIntervencion: this.form.otroIntervencion,
                    objetivo: this.form.objetivo,
                    normatividad: this.form.normatividad,
                    tipoNormatividad: this.form.tipoNormatividad,
                    webNormatividad: this.form.webNormatividad,
                    tipoSolicitante: this.form.tipoSolicitante,
                    tipoActor1: (document.querySelector('#tipoActor1').checked)? 1 : null,
                    tipoActor2: (document.querySelector('#tipoActor2').checked)? 1 : null,
                    tipoActor3: (document.querySelector('#tipoActor3').checked)? 1 : null,
                    tipoActor4: (document.querySelector('#tipoActor4').checked)? 1 : null,
                    tipoActor5: (document.querySelector('#tipoActor5').checked)? 1 : null,
                    tipoActor6: (document.querySelector('#tipoActor6').checked)? 1 : null,
                    tipoActor7: (document.querySelector('#tipoActor7').checked)? 1 : null,
                    tipoActor8: (document.querySelector('#tipoActor8').checked)? 1 : null,
                    tipoActor9: (document.querySelector('#tipoActor9').checked)? 1 : null,
                    tipoActor10: (document.querySelector('#tipoActor10').checked)? 1 : null,
                    tipoActor11: (document.querySelector('#tipoActor11').checked)? 1 : null,
                    tipoMontosApoyo: this.form.tipoMontosApoyo,
                    gpoEdad1: (this.form.gpoEdad[1])? this.form.gpoEdad[1] : null,
                    gpoEdad2: (this.form.gpoEdad[2])? this.form.gpoEdad[2] : null,
                    gpoEdad3: (this.form.gpoEdad[3])? this.form.gpoEdad[3] : null,
                    poblacionObjetivo: this.form.poblacionObjetivo,
                    vigencia: this.form.vigencia,
                    vigencia_inicio: vigencia_inicio,
                    vigencia_fin: vigencia_fin,
                    nom_estado1: (document.querySelector('#nom_estado1').checked)? 1 : null,
                    nom_estado2: (document.querySelector('#nom_estado2').checked)? 1 : null,
                    nom_estado3: (document.querySelector('#nom_estado3').checked)? 1 : null,
                    nom_estado4: (document.querySelector('#nom_estado4').checked)? 1 : null,
                    nom_estado5: (document.querySelector('#nom_estado5').checked)? 1 : null,
                    nom_estado6: (document.querySelector('#nom_estado6').checked)? 1 : null,
                    nom_estado7: (document.querySelector('#nom_estado7').checked)? 1 : null,
                    nom_estado8: (document.querySelector('#nom_estado8').checked)? 1 : null,
                    nom_estado9: (document.querySelector('#nom_estado9').checked)? 1 : null,
                    nom_estado10: (document.querySelector('#nom_estado10').checked)? 1 : null,
                    nom_estado11: (document.querySelector('#nom_estado11').checked)? 1 : null,
                    nom_estado12: (document.querySelector('#nom_estado12').checked)? 1 : null,
                    nom_estado13: (document.querySelector('#nom_estado13').checked)? 1 : null,
                    nom_estado14: (document.querySelector('#nom_estado14').checked)? 1 : null,
                    nom_estado15: (document.querySelector('#nom_estado15').checked)? 1 : null,
                    nom_estado16: (document.querySelector('#nom_estado16').checked)? 1 : null,
                    nom_estado17: (document.querySelector('#nom_estado17').checked)? 1 : null,
                    nom_estado18: (document.querySelector('#nom_estado18').checked)? 1 : null,
                    nom_estado19: (document.querySelector('#nom_estado19').checked)? 1 : null,
                    nom_estado20: (document.querySelector('#nom_estado20').checked)? 1 : null,
                    nom_estado21: (document.querySelector('#nom_estado21').checked)? 1 : null,
                    nom_estado22: (document.querySelector('#nom_estado22').checked)? 1 : null,
                    nom_estado23: (document.querySelector('#nom_estado23').checked)? 1 : null,
                    nom_estado24: (document.querySelector('#nom_estado24').checked)? 1 : null,
                    nom_estado25: (document.querySelector('#nom_estado25').checked)? 1 : null,
                    nom_estado26: (document.querySelector('#nom_estado26').checked)? 1 : null,
                    nom_estado27: (document.querySelector('#nom_estado27').checked)? 1 : null,
                    nom_estado28: (document.querySelector('#nom_estado28').checked)? 1 : null,
                    nom_estado29: (document.querySelector('#nom_estado29').checked)? 1 : null,
                    nom_estado30: (document.querySelector('#nom_estado30').checked)? 1 : null,
                    nom_estado31: (document.querySelector('#nom_estado31').checked)? 1 : null,
                    nom_estado32: (document.querySelector('#nom_estado32').checked)? 1 : null,
                    nom_estado33: (document.querySelector('#nom_estado33').checked)? 1 : null,
                    ejeGral: this.form.ejeGral,
                   
                    nom_objetivo1: (document.querySelector('#nom_objetivo1').checked)? 1 : null,
                    nom_objetivo2: (document.querySelector('#nom_objetivo2').checked)? 1 : null,
                    nom_objetivo3: (document.querySelector('#nom_objetivo3').checked)? 1 : null,
                    nom_objetivo4: (document.querySelector('#nom_objetivo4').checked)? 1 : null,
                    nom_objetivo5: (document.querySelector('#nom_objetivo5').checked)? 1 : null,
                    nom_objetivo6: (document.querySelector('#nom_objetivo6').checked)? 1 : null,

                    nom_objetivo_ods1: (document.querySelector('#nom_objetivo_ods1').checked)? 1 : null,
                    nom_objetivo_ods2: (document.querySelector('#nom_objetivo_ods2').checked)? 1 : null,
                    nom_objetivo_ods3: (document.querySelector('#nom_objetivo_ods3').checked)? 1 : null,
                    nom_objetivo_ods4: (document.querySelector('#nom_objetivo_ods4').checked)? 1 : null,
                    nom_objetivo_ods5: (document.querySelector('#nom_objetivo_ods5').checked)? 1 : null,
                    nom_objetivo_ods6: (document.querySelector('#nom_objetivo_ods6').checked)? 1 : null,
                    nom_objetivo_ods7: (document.querySelector('#nom_objetivo_ods7').checked)? 1 : null,
                    nom_objetivo_ods8: (document.querySelector('#nom_objetivo_ods8').checked)? 1 : null,
                    nom_objetivo_ods9: (document.querySelector('#nom_objetivo_ods9').checked)? 1 : null,
                    nom_objetivo_ods10: (document.querySelector('#nom_objetivo_ods10').checked)? 1 : null,
                    nom_objetivo_ods11: (document.querySelector('#nom_objetivo_ods11').checked)? 1 : null,
                    nom_objetivo_ods12: (document.querySelector('#nom_objetivo_ods12').checked)? 1 : null,
                    nom_objetivo_ods13: (document.querySelector('#nom_objetivo_ods13').checked)? 1 : null,
                    nom_objetivo_ods14: (document.querySelector('#nom_objetivo_ods14').checked)? 1 : null,
                    nom_objetivo_ods15: (document.querySelector('#nom_objetivo_ods15').checked)? 1 : null,
                    nom_objetivo_ods16: (document.querySelector('#nom_objetivo_ods16').checked)? 1 : null,
                    nom_objetivo_ods17: (document.querySelector('#nom_objetivo_ods17').checked)? 1 : null,
                    estatus: 'Pendiente de revisar'
                }

                const jsonData = JSON.parse(JSON.stringify( dataForm ))
                const addIntervenciones = await addData('intervenciones', jsonData)
                if ( addIntervenciones[0].res.status === 200 ) {
                    
                    //Archivos                    
                    this.newId = addIntervenciones[0].res.data;
                    for (let x = 0; x < this.arrFilesUploaded.length; x++) {
                        await updateData('archivos', this.arrFilesUploaded[x].fileId, JSON.parse(JSON.stringify({ id_registro:this.newId,id_registro_sec:this.newId, tmp:true })))
                    }
                    this.$notify({
                        title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                    });
                    // Se envía el correo 
                    const arrUsr = await getUsersById(charsService.decode(localStorage.getItem('id')));
                    await sendMailService.addIntervencion( arrUsr.nombre, arrUsr.a_paterno, arrUsr.email, this.form.interGuberna)
                    this.added = true
                    this.clearSelect
                }
            }
        },
        validarVacios( valor, campo, combo = false ) {
            let errors = 0
            //document.querySelector('#grupoEdad1').style.backgroundColor='#fddfe2'
            if (!combo) {
                if (!valor) { 
                    document.querySelector( campo ).style.backgroundColor='#fddfe2'
                    errors++
                } else {
                    document.querySelector( campo ).style.backgroundColor=''
                }
            } else {
                if (valor.length > 0) { 
                    document.querySelector( campo ).style.backgroundColor=''
                } else {
                    document.querySelector( campo ).style.backgroundColor='#fddfe2'
                    errors++
                }
            }
            return errors
        },
        async getInterv_param() {
            const dataInterv_param = await getIntervParam();
            this.interv_contenido = `${ dataInterv_param[0].interv_contenido } `;
            this.interv_subtitulo = `${ dataInterv_param[0].interv_subtitulo } `;
           return
        },

        async getDepIntervencion() {
            const depInt = await getCatDepIntervencion()
            return depInt
        },
//        async getProgPresupuestario() {
//            const progPres = await getCatProgPresupuestario()
//            return progPres
//        },
        async getTipoIntervencion() {
            const tipoInter = await getCatTipoIntervencion()
            return tipoInter
        },
        async getTipoNormatividad() {
            const tipoNorma = await getCatTipoNormatividad()
            return tipoNorma
        },
        async getTipoSolicitante() {
            const tipoSol = await getCatTipoSolicitante()
            return tipoSol
        },
        async getTipoRespInfo() {
            const tipoRi = await getCatTipoRespInfo()
            return tipoRi
        },
        async getEstados() {
            const edos = await getCatEstados()
            return edos
        },
        async getObjetivos() {
            const objetivo = await getCatObj_PROJUVENTUD()
            return objetivo
        },
        async getObjetivosODS() {
            const objetivo_ods = await getCatObjetivos_ODS()
            return objetivo_ods
        },
        async LimpiaTipoActor() {   //Aqui vamos 
            const sTipo = document.querySelector('#tipoSolicitante').value
            if (sTipo == 1 || sTipo == 4) {
                this.form.tipoActor[1]=false
                this.form.tipoActor[2]=false
                this.form.tipoActor[3]=false
                this.form.tipoActor[4]=false
                this.form.tipoActor[5]=false
                this.form.tipoActor[6]=false
                this.form.tipoActor[7]=false
                this.form.tipoActor[8]=false
                this.form.tipoActor[9]=false
                this.form.tipoActor[10]=false
                this.form.tipoActor[11]=false
            }        
        },
        async NoAplicaChecked() {
            this.form.gpoEdad[1]=false
            this.form.gpoEdad[2]=false
                        
            const sEdad = document.querySelector('#grupoEdad3').checked
            if (sEdad) {
                document.querySelector('#grupoEdad1').disabled = true
                document.querySelector('#grupoEdad2').disabled = true
            } else {
                document.querySelector('#grupoEdad1').disabled = false
                document.querySelector('#grupoEdad2').disabled = false
            }        
        },
        async estadoNacional(nTipo) {
            if (nTipo == 33) {    
                const sTipo = document.querySelector('#nom_estado33').checked
                if (sTipo == true) {
                    for (let n = 1; n < 33; n++) {
                        this.form.nom_estado[n]=false
                        document.querySelector('#nom_estado'+n).disabled = true
                    }
                } else {
                    for (let n = 1; n < 33; n++) {
                        document.querySelector('#nom_estado'+n).disabled = false
                    }
                }
            }
        },
        async tipoActorTodos(nTipo) {
            if (nTipo == 10) {    
                const sTipo = document.querySelector('#tipoActor10').checked
                if (sTipo == true) {
                    this.form.tipoActor[1]=false
                    this.form.tipoActor[2]=false
                    this.form.tipoActor[3]=false       
                    this.form.tipoActor[4]=false
                    this.form.tipoActor[5]=false
                    this.form.tipoActor[6]=false
                    this.form.tipoActor[7]=false
                    this.form.tipoActor[8]=false
                    this.form.tipoActor[9]=false
                    this.form.tipoActor[11]=false
                    document.querySelector('#tipoActor1').disabled = true
                    document.querySelector('#tipoActor2').disabled = true
                    document.querySelector('#tipoActor3').disabled = true
                    document.querySelector('#tipoActor4').disabled = true
                    document.querySelector('#tipoActor5').disabled = true
                    document.querySelector('#tipoActor6').disabled = true
                    document.querySelector('#tipoActor7').disabled = true
                    document.querySelector('#tipoActor8').disabled = true
                    document.querySelector('#tipoActor9').disabled = true
                    document.querySelector('#tipoActor11').disabled = true
                } else {
                    document.querySelector('#tipoActor1').disabled = false
                    document.querySelector('#tipoActor2').disabled = false
                    document.querySelector('#tipoActor3').disabled = false
                    document.querySelector('#tipoActor4').disabled = false
                    document.querySelector('#tipoActor5').disabled = false
                    document.querySelector('#tipoActor6').disabled = false
                    document.querySelector('#tipoActor7').disabled = false
                    document.querySelector('#tipoActor8').disabled = false
                    document.querySelector('#tipoActor9').disabled = false
                    document.querySelector('#tipoActor11').disabled = false
                }
            }
        },
        LimpiaNormatividad() {
            this.form.webNormatividad=''
            this.form.tipoNormatividad = ''
        },
        otrasSelected() {
            this.form.otroIntervencion = ''
        },

        //Archivos
        async uploadFile() {
            this.file = this.$refs.file.files[0]
            var formData = new FormData()
            formData.append('file', this.file)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            // await axios.post('http://localhost/ubApi/uploadFiles.php', formData, {
            await axios.post( ubApi.value + '/uploadFiles.php', formData, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlert = z_errorAlert
            this.successAlert = z_successAlert
            this.errorMessage = z_errorMessage
            this.successMessage = z_successMessage
            this.$refs.file.value = z_fileValue

            if (z_successAlert) {
                // IntervenReg módulo para tabla de archivos
                const jsonInsFile = JSON.parse(JSON.stringify({
                    "modulo": "IntervenReg",
                    "id_registro": 0,
                    "id_registro_sec": 0,   //Esta no estaba yo la puse
                    "id_usuario": z_userId,
                    "tmp_name": z_tmpFileName,
                    "file_name": z_baseName,
                    "extension": z_extension,
                    "tmp": true
                }))
                const lastInsert = await insertData('archivos', jsonInsFile);
                this.arrFilesUploaded.push({ fileId: lastInsert[0].res.data })
                this.arrArchivos.push({borrar:0, extension:z_extension, file_name:z_baseName, file_name_dec:z_baseName, id:lastInsert[0].res.data, id_registro:this.$route.params.id, id_usuario:z_userId, modulo:"IntervenReg", tmp_name:z_tmpFileName})

            }

            console.log(this.arrArchivos);
        },

        async getArchivos() {
            return await getArchivos( 'IntervenReg', this.$route.params.id )
        },

        async deleteFile( id, i ) {
            if ( confirm('¿Confirma eliminar el archivo?') ) {
                this.arrArchivos.splice( i, 1)
                await updateData( 'archivos', id, JSON.parse(JSON.stringify({ borrar: 1 })) )
            }
        },

        async delTmp() {
            const getTmp = await getArchivosTmp()
            const arrTmp = []

            for (let z = 0; z < getTmp.length; z++) {
                await updateData('archivos', getTmp[z].id, JSON.parse(JSON.stringify({ borrar: 1, tmp: 0 })))
            }
        },
        async setArrArchivos( id ) {
            const arrFiles = await getArchivosAvance( 'IntervenReg', this.form.accion_puntual, parseInt(charsService.decode( id )))

            arrFiles.forEach(archivo => {
                console.log( archivo );
                this.arrArchivos.push({tmp_name: charsService.encode(archivo.tmp_name), file_name: charsService.encode(archivo.file_name), extension: archivo.extension, file_name_dec:archivo.file_name })
            });
        },

        readFormatInicio() {
            return this.readFormat( this.form.vigencia_inicio )
        },

        readFormatFin() {
            return this.readFormat( this.form.vigencia_fin )
        },

        readFormat( date ) {
            if ( date !== '' ) {
                const day = (date.getDate() < 10)? '0' + date.getDate() : date.getDate()
                const month = (date.getMonth() < 9)? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
                const year = date.getFullYear()

                return `${day}-${month}-${year}`;
            } else {
                return ''
            }
        },

    },
    async mounted() {
        //this.delTmp()
        await this.getInterv_param();
        this.depsArr = await this.getDepIntervencion()
        //this.ppArr = await this.getProgPresupuestario()
        this.tipoIntArr = await this.getTipoIntervencion()
        this.tipoNorma = await this.getTipoNormatividad()
        this.tipoSolic = await this.getTipoSolicitante()
        this.tipoResIn = await this.getTipoRespInfo()
        this.ambGeo = await this.getEstados()
        this.arrObjetivos = await this.getObjetivos()
        this.arrObjetivosODS = await this.getObjetivosODS()
        await this.setArrArchivos( this.$route.params.id )
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #DC3545;
    }

    .subQuest {
        font-size: small;
        color: #C0C0C0;
    }

    /*    Estilo del radio   */
    .el-radio, input[type="radio"]{
        border: 5px solid #AAAAAA;
        border-radius: 100%;
        height: 25px;
        width: 25px;
        position: relative;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
    }
/*    Fin estilo del radio   */
    form input[type="text"], select, textarea {
        padding:3px 10px;
        border:1px solid #f6f6f6;
        border-radius:1px;
        background-color: #E6E8EB;
        color: #000000;
        margin:8px 0;
        display:inline-block;
    }
    
    form input:focus {
       /* border: 2px solid #c8c8c8; */
        box-shadow:1px 1px 1px  #c8c8c8;
       /* color: #ff0000; */
        /* background: #8c8c8c; */ 
        background: rgba(142,113,70,0.2)
    }
    
    ::placeholder { color: #CCCCCC; }

    /* ------ Estilo de Toggle -----------*/
    .toggle {
        cursor: pointer;
        display: inline-block;
    }

    .toggle-switch {
        display: inline-block;
        background: #CCCCCC; /*#9D2449 Guinda*/
        border-radius: 16px;
        width: 40px;
        height: 20px;
        position: relative;
        vertical-align: middle;
        transition: background 0.45s;
    }
    .toggle-switch:before, .toggle-switch:after {
        content: "";
    }
    .toggle-switch:before {
        display: block;
        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
        border-radius: 50%;
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25);
        width: 18px;
        height: 18px;
        position: absolute;
        top: 1px;
        left: 2px;
        transition: left 0.45s;
    }
    .toggle:hover .toggle-switch:before {
        background: linear-gradient(to bottom, #fff 0%, #fff 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    .toggle-checkbox:checked + .toggle-switch {
        background: #CCCCCC;    /*#235B4E Verde*/
    }
    .toggle-checkbox:checked + .toggle-switch:before {  /* Cuando se selecciona */
        background: #999999;    /*#235B4E Verde*/
        left: 20px;
    }

    .toggle-checkbox:disabled +  .toggle-switch:before {  
        background: #CCCCCC;    /*#235B4E Verde*/
        /*background: linear-gradient(to bottom, #235B4E 0%, #235B4E 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5); */
        
    }

    .toggle-checkbox {
        position: absolute;
        visibility: hidden;
    }

    .toggle-label {
        margin-left: 5px;
        position: relative;
        top: 2px;
        font-weight: normal;
    }
    /* ---Fin de estilo de Toggle --------*/

    .scrollbar-demo-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        margin: 0px;
        text-align: left;
        border-radius: 0px;
        /*background: #bdbdbd;*/ 
        font-family: 'Montserrat';
        font-weight: normal;
        font-size: 18px;
    }


    /* ------ Estilo de Toggler Radio -----------*/
    .toggler {
        cursor: pointer;
        display: inline-block;
    }

    .toggler-switch {
        display: inline-block;
        background: #999999; /*#9D2449 Guinda*/
        border-radius: 16px;
        width: 25px;
        height: 25px;
        position: relative;
        vertical-align: middle;  
        transition: background 0.45s;
    }
    .toggler-switch:before, .toggler-switch:after {
        content: "";
    }
    .toggler-switch:before {
        display: block;
        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
        border-radius: 50%;
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25);
        width: 17px;
        height: 17px;
        position: absolute;
        top: 4px;
        left: 4px;
        transition: left 0.45s;
    }
    .toggler:hover .toggler-switch:before {
        background: linear-gradient(to bottom, #fff 0%, #fff 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }
    .toggler-radio:checked + .toggler-switch {
        background: #CCCCCC;    /*#235B4E Verde*/
    }
    .toggler-radio:checked + .toggler-switch:before {  /* Cuando se selecciona */
        background: #999999;    /*#235B4E Verde*/  
    }

     .toggler-radio:disabled +  .toggler-switch:before {  /* Aqui voy*/
        background: #CCCCCC;    /*#235B4E Verde*/
        /*background: linear-gradient(to bottom, #235B4E 0%, #235B4E 100%);
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5); */
    }

    .toggler-radio {
        position: absolute;
        top: 7px;
        left: 24px;
        
       visibility: hidden;  

    }

    .toggler-label {
        margin-left: 5px;
        position: relative;
        top: 2px;
        font-weight: normal;
    }
    /* ---Fin de estilo de Toggler --------*/
    /* ---Inicio Archivos --------*/
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }
    /* ---Fin Archivos --------*/

</style>