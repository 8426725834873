<template>
    <h4 class="mt-0">Actualizar información del catálogo de dimensiones del indicador</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_dimension_indicador">Nombre de la dimensión del indicador:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idAnio" name="idAnio" v-model="form.id" />
                        <input type="text" class="form-control" id="nom_dimension_indicador" name="nom_dimension_indicador" placeholder="Nombre de la dimensión del indicador" v-model="form.nom_dimension_indicador" />
                    </div>
                </div>
                <hr>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateAnio" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateAnio" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateAnio" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getDimensiones_indicadorById from '@/helpers/getDimensiones_indicadorById'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                nom_dimension_indicador: '',
            }
        }
    },
    methods: {
        async getAnioData( id ) {
            const dimension_indicadorArr = await getDimensiones_indicadorById( id )
            
            return dimension_indicadorArr[0].cat_dimension_indicador
        },

        async setData( id ) {
            const datos = await getDimensiones_indicadorById( id )

            this.form.id = datos.id
            this.form.nom_dimension_indicador = datos.nom_dimension_indicador
        },

        async updateAnio() {
            const dataForm = {
                nom_dimension_indicador: this.form.nom_dimension_indicador
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_dimension_indicador', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>