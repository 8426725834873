/* Regresa todos los id de acciones puntuales de acuerdo a la entidad coordinadora */

// Esta linea se queda igual siempre (base url api)
import simejuvApi from '../api/simejuvApi'

// Se nombra la funcion
const getAccionbyResp = async( id ) => {
    const promiseArr = [
        // Se modifica de acuerdo a la búsqueda en la tabla
        simejuvApi.get(`/accionp_ent_resp?transform=1&filter=iddependencia,eq,${id}`)
    ]

    // Cambiar el nombre de la variable contra los datos a mostrar
    const [ accionp ] = await Promise.all( promiseArr )

    // Cambiar la primer variable a como se definió arriba
    // Cambiar la ultima variable a como se llama la tabla en la base de datos
    return accionp.data.accionp_ent_resp
}

// Se exporta con el mismo nombre de la funcion
export default getAccionbyResp