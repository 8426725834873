/* Regresa todos los objetivos prioritarios de la vista 'v_accion_sectorial' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccionesSectoriales = async() => {
    const promiseArr = [
        simejuvApi.get(`/v_accion_sectorial?transform=1&filter=borrar,lt,1`)
    ]

    const [ v_acsectoriales ] = await Promise.all( promiseArr )

    return v_acsectoriales.data.v_accion_sectorial
}

export default getAccionesSectoriales