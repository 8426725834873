<template>
    <!-- TODO: Ajustar nuevo submenú -->
    <nav class="navbar navbar-inverse sub-navbar navbar-fixed-top">
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#subenlaces">
                    <span class="sr-only">Interruptor de Navegación</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>  
                </button>
                
                
                <ul class="nav navbar-nav navbar-right">
                    <li class="nav-item">
                        <router-link class="nav-link subnav-link persSJ" to="/" title="Iniciar sesión"><i class="glyphicon glyphicon glyphicon-home"></i><a class="navbar-brand persSJ" style="font-size: 20px;" href="/">&nbsp;SIMEJUV</a></router-link>
                    </li>   
                </ul>



            </div>
            <div class="collapse navbar-collapse" id="subenlaces">
                <ul class="nav navbar-nav navbar-right">
                    <li class="nav-item">
                        <router-link class="nav-link subnav-link persSJ" to="/projuventud" title="PROJUVENTUD 2021-2024"> PROJUVENTUD<br>2021-2024 </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link subnav-link persSJ" to="/sectoriales" title="Programas sectoriales"> Programas<br>sectoriales </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link subnav-link persSJ" to="/presup_juventud" title="Presupuesto con perspectiva de juventud"> Perspectiva<br>de juventud </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link subnav-link persSJ" to="/intervenciones" title="Catálogo de oferta para las y los jóvenes"> Catálogo de oferta<br>de servicios </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link subnav-link persSJ" to="/aten_ciudadana" title="Participación Ciudadana"> Participación<br>Ciudadana </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link subnav-link persSJ" to="/estadisticas" title="Estadísticas en Juventud"> Estadísticas<br>en Juventud</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link subnav-link persSJ" to="/doctos" title="Documentos descargables"> Documentos<br>descargables </router-link>
                    </li>
                    <li class="nav-item" v-if="!logged">
                        <router-link class="nav-link subnav-link persSJ" to="/login" title="Iniciar sesión"><i class="glyphicon glyphicon-log-in"></i></router-link>
                    </li>
                    <li class="nav-item" v-if="logged">
                        <router-link class="nav-link subnav-link persSJ" :to="`/user/usrAdmin/edit/${userIdEnc}`" title="Información de usuario"><i class="glyphicon glyphicon-user"></i></router-link>
                    </li>
                    <li class="nav-item" v-if="logged && $route.params.tUser != 'enlace'">
                        <router-link class="nav-link subnav-link persSJ" :to="`/adminSec`" title="Administración de la plataforma"><i class="glyphicon glyphicon-cog"></i></router-link>
                    </li>
                    <li class="nav-item" v-if="logged">
                        <router-link class="nav-link subnav-link persSJ" to="/logout" title="Cerrar sesión"><i class="glyphicon glyphicon-log-out"></i></router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import charsService from '@/services/CharsService'

export default {
    props: {
        user: Boolean
    },
    data() {
        return {
            userId: charsService.decode(localStorage.getItem('id')),
            userIdEnc: localStorage.getItem('id'),
            logged: this.user
        }
    },
    mounted() {
        if ( localStorage.getItem('nombre') ) {
            this.logged = true
            this.$route.params.tUser = this.userId
        }
    }
}
</script>

<style>
    .sub-navbar {
        height: 80px;
    }

    .persSJ {
        height: 70px;    
        display: flex !important;
        align-items: center;
        font-size: 13.5px;
    }

    .breadcrumb {
        margin-top: 40px;
    }
</style>