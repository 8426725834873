<template>
    <h4 class="mt-0">Agregar una Dependencia</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Número de la Dependencia:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idDependencia" name="idDependencia" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.num_dependencia }" id="num_dependencia" name="num_dependencia" placeholder="Número de la dependencia" v-model="form.num_dependencia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_dependencia">Nombre de la Dependencia:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_dependencia }" id="nom_dependencia" name="nom_dependencia" placeholder="Nombre de la Dependencia" v-model="form.nom_dependencia" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_corto_dependencia">Nombre corto de la Dependencia (Siglas):</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.nom_corto_dependencia }" id="nom_corto_dependencia" name="nom_corto_dependencia" placeholder="Siglas" v-model="form.nom_corto_dependencia" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" /> -->
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateDependencia" /> -->
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra Dependencia" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addDependencia" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import addData from '@/helpers/addData'
import getDataByAnyDependencia from '@/helpers/getDataByAnyDependencia'

export default {
    data() {
        return {
            form: {
                num_dependencia: '',
                nom_dependencia: '',
                nom_corto_dependencia: ''
            },
            noValid: {
                num_dependencia: false,
                nom_dependencia: false,
                nom_corto_dependencia: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.num_dependencia = ''
            this.form.nom_dependencia = ''
            this.form.nom_corto_dependencia = ''
            this.added = false
        },
        async addDependencia() {
            this.noValid.num_dependencia = ( this.form.num_dependencia )? false : true
            this.noValid.nom_dependencia = ( this.form.nom_dependencia )? false : true
            this.noValid.nom_corto_dependencia = ( this.form.nom_corto_dependencia )? false : true

            const valNom_dependencia = await getDataByAnyDependencia( 'cat_dependencias', 'nom_dependencia', this.form.nom_dependencia );
            
            if ( valNom_dependencia.length < 1 ){
                if ( !this.noValid.num_dependencia) {
                    if ( !this.noValid.nom_dependencia) {
                        if ( !this.noValid.nom_corto_dependencia) {
               
               
                            const dataForm = {
                                num_dependencia: this.form.num_dependencia,
                                nom_dependencia: this.form.nom_dependencia,
                                nom_corto_dependencia: this.form.nom_corto_dependencia
                            }
                            const jsonData = JSON.parse(JSON.stringify( dataForm ))
                            const addDependencia = await addData('cat_dependencias', jsonData)
                            if ( addDependencia[0].res.status === 200 ) {
                                this.$notify({
                                    title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                                });
                                this.added = true
                            } else {
                                this.$notify({
                                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                                });
                            }
                        } else {
                            this.$notify({
                                title: 'Error', text: 'Favor de capturar el nombre corto de la Dependencia', type: 'error'
                            });
                        }
                    } else {
                        this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre de la Dependencia', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el número de la Dependencia', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una Dependencia registrada con ese nombre. Verificar los datos', type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>
    .validated {
        border: 3px solid #dc3545;
    }
</style>