
<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="http://www.gob.mx/"><i class="icon icon-home"></i></a></li>
                <li class="breadcrumb-item"><a href="https://www.gob.mx/imjuve">IMJUVE</a></li>
                <li class="breadcrumb-item"><a href="/">SIMEJUV</a></li>
                <li class="breadcrumb-item"><a href="/aten_ciud">PARTICIPACIÓN CIUDADANA</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a href="/consepp">PARTICIPACIÓN JUVENIL</a></li>
            </ol>
            <h2>Participación juvenil</h2>

            <hr class="red">

            <div class="row mt-5"> 
                <div class="col-md-12">
                    <div class="row mb-5">
                        <div class="col-md-6 text-center">
                            <router-link :to="{name: 'RecomPartJuv'}" class="btn btn btn-default active">Recomendaciones</router-link>
                            <p class="txtDesc">Espacio para realizar recomendaciones a la política pública de juventud del gobierno federal.</p>
                        </div>
                        <div class="col-md-6 text-center">
                            <router-link :to="{name: 'SeguimRecom'}" class="btn btn btn-default active">Seguimiento a <br>recomendaciones</router-link>
                        </div>
                    </div>
                </div>
            </div>          
            <br><br><br><br>
        </div>
    </div>
</template>

<script>

import Navbar from '@/components/Common/Navbar'

export default {
    components: {
        Navbar
    },
    async mounted() {
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
    .btn {
        width: 240px;
    }

    .txtDesc {
        font-size: 12px;
        margin-top: 15px;
    }

    .mt-5 {
        margin-top: 60px;
    }
</style>