/* Regresa todos los datos con el id enviado de la tabla 'cat_tipo_calculo' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getTipo_calculoById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_tipo_calculo/${ id }`)
    ]

    const [ cat_tipo_calculo ] = await Promise.all( promiseArr )

    return cat_tipo_calculo.data
}

export default getTipo_calculoById