<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar el documento de compromisos de la dependencia? </h5>
            <h6> {{ datosAccionp_ent_resp }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import updateData from '@/helpers/updateData'
import getAccionp_ent_respById from '@/helpers/getAccionp_ent_respById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosAccionp_ent_resp: '' }
    },
    methods: {
        async confirmDel() {
            const dataForm = JSON.parse(JSON.stringify({
                "id_usuario": null,
                "file_desc": null,
                "tmp_name": null,
                "file_name": null,
                "extension": null
            }))
            const jsonData = JSON.parse(JSON.stringify( dataForm ));
           // const delItem = await updateDataByIdCol( 'accionp_ent_resp', charsService.decode(this.$route.params.id), jsonData )
            const resUpd = await updateData('accionp_ent_resp', charsService.decode(this.$route.params.id) , jsonData)
            this.$router.push('../');
        },
        async getAccionp_ent_resp() {
            const dataAccionp_ent_resp = await getAccionp_ent_respById(charsService.decode( this.$route.params.id ))

            const cadena = `${ dataAccionp_ent_resp.file_name },  ${ dataAccionp_ent_resp.file_desc }`;

            return cadena
        }
    },
    async mounted() {
        this.datosAccionp_ent_resp = await this.getAccionp_ent_resp();
         this.getAccionp_ent_resp()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>