/* Regresa todos los registros de la vista 'intervenciones' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAllIntervenciones = async() => {
    const promiseArr = [
        //simejuvApi.get(`/intervenciones?transform=1&filter=borrar,lt,1`)
        simejuvApi.get(`/intervenciones?transform=1&order[]=orden,asc&filter=estatus,eq,Si se publica`)
    ]

    const [ inter ] = await Promise.all( promiseArr )

    return [
        { intervenciones: inter.data.intervenciones }
    ]
}

export default getAllIntervenciones