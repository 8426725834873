/* Regresa id y nombre corto de todos los registros de la tabla 'cat_dependencias' en la base de datos */

import simejuvApi from '../api/simejuvApi'

/* 
* @param incTodas: false: no regresa NO-SECTORIZADA y TODAS (default)
*                  true: regresa todos los registros
*/
const getDependenciasOp = async( incTodas = false ) => {
    let filter = ''

    //if ( !incTodas ){
    //    filter = '&filter=id,lt,100'
    //}

    const promiseArr = [
        simejuvApi.get(`/cat_dependencias_op?transform=1&columns=id,idestado,nom_dependencia_op${ filter }`)
    ]

    const [ deps ] = await Promise.all( promiseArr )

    return deps.data.cat_dependencias_op
}

export default getDependenciasOp