/* Regresa todos los registros de la tabla 'cat_tipo_ap' en la base de datos */

// Esta linea se queda igual siempre (base url api)
import simejuvApi from '../api/simejuvApi'

// Se nombra la funcion
const getCatTipos_ap = async() => {
    const promiseArr = [
        // Se modifica de acuerdo a la búsqueda en la tabla
        simejuvApi.get(`/cat_tipo_ap?transform=1&filter=borrar,lt,1`)
    ]

    // Cambiar el nombre de la variable contra los datos a mostrar
    const [ tipos_ap ] = await Promise.all( promiseArr )

    // Cambiar la primer variable a como se definió arriba
    // Cambiar la ultima variable a como se llama la tabla en la base de datos
    return tipos_ap.data.cat_tipo_ap
}

// Se exporta con el mismo nombre de la funcion
export default getCatTipos_ap