/* Regresa todos los datos de los años con el id enviado de la tabla 'cat_estrategia' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getEstrategiasById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_estrategia/${ id }`)
    ]

    const [ cat_estrategia ] = await Promise.all( promiseArr )

    return cat_estrategia.data
}

export default getEstrategiasById